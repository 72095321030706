import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../public/assets/logo.png";
import { useDispatch } from "react-redux";
import { logoutAction, setPathName } from "../redux/actions";

export default function Sidenav() {
  const [menuOpen, setMenuOpen] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const [redirectToHome, setRedirectToHome] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logoutAction());
    setRedirectToHome(true);
  };

  const handleButton = () => setMenuOpen(menuOpen === "active" ? "" : "active");

  const checkActive = slug => {
    return location.pathname === slug;
  };

  const Logo = () => (
    <div className="brand">
      <img src={logo} alt="" className="logo img-fluid" />
    </div>
  );
  useEffect(() => {
    if (redirectToHome) {
      navigate("/");
    }
  }, [redirectToHome, navigate]);

  const MenuItem = ({ label, url, subItems, isActive, onClick }) => {
    const activeClass = isActive ? "item-active" : "";
    //const hasSubItems = subItems ? "" : "no-intern-items";
    const isExpandable = subItems && subItems.length > 0;
    const handleItemClick = () => {
      if (onClick) {
        onClick();
      }
    };
    return (
      <Accordion.Item
        eventKey={label.toLowerCase()}
        className={`${activeClass} ${isExpandable ? "" : "no-intern-items"}`}
      >
        {isExpandable ? (
          <Accordion.Header>
            <h6 className="m-0">{label}</h6>
          </Accordion.Header>
        ) : (
          <Link
            to={url || "#"}
            className="d-block py-1 accordion-header py-3 ps-4"
            onClick={onClick || handleItemClick}
          >
            <h6 className="m-0">{label}</h6>
          </Link>
        )}
        {isExpandable && (
          <Accordion.Body>
            <ul className="list-unstyled">
              {subItems.map((subItem, index) => (
                <li key={index} className={checkActive(subItem.url) ? "item-active" : ""}>
                  <Link
                    to={subItem.url}
                    className="d-block py-1"
                    onClick={() => dispatch(setPathName(subItem.url.split("/")))}
                  >
                    {subItem.label}
                  </Link>
                </li>
              ))}
            </ul>
          </Accordion.Body>
        )}
      </Accordion.Item>
    );
  };

  return (
    <>
      <div className="col-12 d-flex d-lg-none justify-content-between align-items-center bg-white p-3 border-bottom">
        <Logo />
        <div className="d-flex align-items-center cursor-pointer" onClick={handleButton}>
          <i className="bi bi-list"></i>
        </div>
      </div>
      <div id="navBar" className={`col d-flex flex-column justify-content-between p-0 border-end ${menuOpen}`}>
        <div>
          <div className="p-3 py-lg-5">
            <Logo />
          </div>
          <Accordion defaultActiveKey={location.pathname} alwaysOpen>
            <MenuItem label="Dashboard" url="/" isActive={checkActive("/")} />
            <MenuItem
              label="Amministrazione"
              url="/amministrazione"
              isActive={
                location.pathname.startsWith("/") &&
                location.pathname.length > 1 &&
                !location.pathname.includes("strumenti") &&
                !location.pathname.includes("account") &&
                !location.pathname.includes("logout")
              }
              subItems={[
                { label: "Clienti", url: "/cliente" },
                { label: "Offerte", url: "/offerta" },
                { label: "Siti", url: "/sito" },
                { label: "Attività", url: "/attivita" },
                { label: "Tipologia attività", url: "/tipologia-attivita" },
                { label: "Commesse", url: "/commessa" },
                { label: "Stati", url: "/stato" },
                { label: "Ordini", url: "/ordine" },
                { label: "Fornitori", url: "/fornitore" },
                { label: "Incarico Fornitori", url: "/incarico-fornitore" },
                { label: "Fatture", url: "/fattura" },
                { label: "Banche", url: "/banca" },
                { label: "Dipendenti", url: "/dipendente" },
                { label: "Sedi", url: "/sede" },
              ]}
            />
            <MenuItem
              label="Strumenti"
              subItems={[
                { label: "Logs", url: "/strumenti/logs" },
                { label: "Registrazione", url: "/strumenti/registration" },
              ]}
            />
          </Accordion>
        </div>
        <div id="headerNav">
          <Accordion alwaysOpen>
            {/* <MenuItem label="Account" url="/account" /> */}
            <MenuItem label="Log Out" onClick={handleLogout} />
          </Accordion>
        </div>
      </div>
    </>
  );
}
