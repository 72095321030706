import { URL_BANCA, URL_INDIRIZZO_SITO, URL_SITO } from "../../../lib/url";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  SET_URL_GET,
  SET_URL_GET3,
  SET_URL_GET6,
  setShow,
  setUrlFetch,
} from "../../../redux/actions";

export const setUrlFilterSiti = (arrayPills, info, dispatch) => {
  let url = "?";
  let url2 = "?";
  if (arrayPills.length > 0) {
    if (info.sito_o_punto_vendita)
      url = url + "sito_o_punto_vendita=" + info.sito_o_punto_vendita;
    if (info.cliente && url !== "?") url = url + "&&cliente=" + info.cliente;
    else {
      if (info.cliente) url = url + "cliente=" + info.cliente;
    }
    // if (info.regione && url !== "?") url = url + "&&regione=" + info.regione;
    // else {
    //   if (info.regione) url = url + "regione=" + info.regione;
    // }
    if (info.codice_vecchio && url !== "?")
      url = url + "&&codice_vecchio=" + info.codice_vecchio;
    else {
      if (info.codice_vecchio)
        url = url + "codice_vecchio=" + info.codice_vecchio;
    }
  } else {
    dispatch(setUrlFetch(SET_URL_GET, URL_SITO));
  }

  if (url !== "?") {
    url = URL_SITO + url;
    dispatch(setUrlFetch(SET_URL_FILTER, url));
  }
  //   dispatch(setShow(SET_SHOW_FILTER, false));
};
