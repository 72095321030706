export const GetIncaricoFornitore = (lista, lista2, lista3, setData) => {
  if (
    lista &&
    lista2 &&
    lista.length >= 0 &&
    lista2.length >= 0 &&
    lista3 &&
    lista3.length >= 0
  ) {
    const combinedList = lista.map((item) => {
      let fornitore = lista2.filter((item2) => item.fornitore === item2.id);
      let fullName = fornitore[0].nome + " " + fornitore[0].cognome;
      let combinedItem = {
        ...item,
        fornitore: fullName,
        idFornitore: fornitore[0].id,
      };

      return combinedItem;
    });
    const combinedList2 = combinedList.map((item) => {
      let commessa = lista3.filter((item3) => item.commessa === item3.id);
      let protocollo = commessa[0].protocollo;
      let combinedItem = {
        ...item,
        commessa: protocollo,
        idCommessa: commessa[0].id,
        openModal: (
          <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
        ),
      };

      return combinedItem;
    });

    setData(combinedList2);
  } else {
    if (lista && lista.length > 0) {
      const updatedList = lista.map((item) => ({
        ...item,
        openModal: (
          <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
        ),
      }));
      setData(updatedList);
    }
  }
};
