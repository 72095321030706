import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ADD_CLIENTE } from "../../lib/payloads";
import {
  SET_INFO1,
  fetchSelectAttivita,
  fetchSelectOrdine,
  setInfo,
} from "../../redux/actions";
import { fetchSelectFornitore, fetchSelectCommesse } from "../../redux/actions";

const FormIncaricoFornitori = () => {
  const info1 = useSelector((state) => state.modal.info1);
  const show1 = useSelector((state) => state.modal.show1);
  const show2 = useSelector((state) => state.modal.show2);
  const show3 = useSelector((state) => state.modal.show3);
  const show4 = useSelector((state) => state.modal.show4);
  const allFornitori = useSelector((state) => state.select.dataFornitore);
  const allCommesse = useSelector((state) => state.select.dataCommesse);
  const allOrdini = useSelector((state) => state.select.dataOrdine);
  const allAttivita = useSelector((state) => state.select.dataAttivita);

  const dispatch = useDispatch();
  const [info, setInformazioni] = useState({});
  const token = useSelector((state) => state.login.token);
  const tipoModal = useSelector((state) => state.modal.type);
  const [ordiniCommessa, setOrdiniCommessa] = useState([]);

  const [attivitaCommessa, setAttivitaCommessa] = useState([]);

  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };

  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const handleFornitoreChange = (value) => {
    changeInfo(value, "fornitore");
    setIsFormDisabled(false);
  };

  const handleCommessaChange = (value) => {
    changeInfo(value, "commessa");

    const selectedCommessa = allCommesse.find(
      (commessa) => commessa.id == value
    );

    const filteredOrdini = allOrdini.filter((ordine) =>
      selectedCommessa?.ordini.includes(ordine.id)
    );

    setOrdiniCommessa(filteredOrdini);

    const filteredAttivita = allAttivita.filter((attivita) =>
      selectedCommessa?.attivita.includes(attivita.id)
    );

    setAttivitaCommessa(filteredAttivita);

    // Abilita il resto del form se necessario
    setIsFormDisabled(false);
  };

  useEffect(() => {
    dispatch(fetchSelectFornitore(token));
    dispatch(fetchSelectCommesse(token));
    dispatch(fetchSelectOrdine(token));
    dispatch(fetchSelectAttivita(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setInfo(SET_INFO1, info));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  useEffect(() => {
    if (show1 && show2 && show3 && show4) {
      setInformazioni(ADD_CLIENTE);
    } else if (show1 && show2 && show3) {
      setInformazioni(ADD_CLIENTE);
    } else if (show1 && show2) {
      setInformazioni(ADD_CLIENTE);
    } else {
      setInformazioni(info1);
      if (tipoModal == "modifica") {
        setIsFormDisabled(false);

        const selectedCommessa = allCommesse.find(
          (commessa) => commessa.id == info1.idCommessa
        );

        const filteredOrdini = allOrdini.filter((ordine) =>
          selectedCommessa.ordini.includes(ordine.id)
        );

        setOrdiniCommessa(filteredOrdini);

        const filteredAttivita = allAttivita.filter((attivita) =>
          selectedCommessa.attivita.includes(attivita.id)
        );

        setAttivitaCommessa(filteredAttivita);

        setInformazioni({
          ...info1,
          fornitore: info1.idFornitore,
          commessa: info1.idCommessa,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrdini, allFornitori, allCommesse, allAttivita]);

  return (
    <Row>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label
            column
            sm="2"
            className="d-flex justify-content-between align-items-center"
          >
            Fornitore *
          </Form.Label>
          <Form.Select
            value={info.fornitore}
            className="custom-select"
            onChange={(e) => {
              changeInfo(e.target.value, "fornitore");
              handleFornitoreChange(e.target.value);
            }}
            aria-label="Seleziona Fornitore"
            required
          >
            <option value="" disabled hidden>
              Seleziona Fornitore
            </option>
            {allFornitori &&
              allFornitori.map((c, index) => (
                <option key={index} value={c.id}>
                  {c.nome} {c.cognome}
                </option>
              ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label
            column
            sm="2"
            className="d-flex justify-content-between align-items-center"
          >
            N° Commessa *
          </Form.Label>
          <Form.Select
            value={info.commessa}
            className="custom-select"
            onChange={(e) => handleCommessaChange(e.target.value)}
            aria-label="Seleziona nCommessa"
            required
            disabled={isFormDisabled}
          >
            <option value="" disabled hidden>
              Seleziona Commessa
            </option>
            {allCommesse &&
              allCommesse.map((c, index) => (
                <option key={index} value={c.id}>
                  {c.protocollo}
                </option>
              ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Numero fattura
          </Form.Label>

          <Form.Control
            type="text"
            value={info.n_fattura}
            onChange={(e) => changeInfo(e.target.value, "n_fattura")}
            placeholder="inserisci Numero Fattura"
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Inizio *
          </Form.Label>
          <Form.Control
            type="date"
            value={info.data_inizio}
            onChange={(e) => changeInfo(e.target.value, "data_inizio")}
            required
            disabled={isFormDisabled}
          />
        </Form.Group>
        <Form.Control.Feedback type="invalid">
          campo obbligatorio
        </Form.Control.Feedback>
      </Col>{" "}
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Importo
          </Form.Label>
          <div className="d-flex align-items-center">
            <span style={{ marginRight: "8px" }}>€</span>
            <Form.Control
              type="number"
              value={info.importo}
              onChange={(e) => changeInfo(e.target.value, "importo")}
              placeholder="Inserisci l'importo"
              disabled={isFormDisabled}
            />
          </div>
        </Form.Group>
        <Form.Control.Feedback type="invalid">
          campo obbligatorio
        </Form.Control.Feedback>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Scadenza *
          </Form.Label>
          <Form.Control
            type="date"
            value={info.data_fine}
            onChange={(e) => changeInfo(e.target.value, "data_fine")}
            disabled={isFormDisabled}
            required
          />
        </Form.Group>
        <Form.Control.Feedback type="invalid">
          campo obbligatorio
        </Form.Control.Feedback>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Consegna
          </Form.Label>
          <Form.Control
            type="date"
            value={info.data_consegna}
            onChange={(e) => changeInfo(e.target.value, "data_consegna")}
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>
      <Col xs="2" className="d-flex align-items-center">
        <Form.Group>
          <Form.Check
            type="switch"
            label="accettato"
            checked={info.accettato}
            onChange={(e) => changeInfo(e.target.checked, "accettato")}
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>
      <Col xs="2" className="d-flex align-items-center">
        <Form.Group>
          <Form.Check
            type="switch"
            label="Dati Inviati"
            checked={info.dati_inviati}
            onChange={(e) => changeInfo(e.target.checked, "dati_inviati")}
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>
      <Col xs="12">
        <Form.Group className="mb-3">
          <Form.Label>Descrizione</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={info.descrizione}
            onChange={(e) => changeInfo(e.target.value, "descrizione")}
            placeholder="Inserisci descrizione"
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>
      <Col xs="12">
        <h5>Lista Ordini</h5>
        {ordiniCommessa.length > 0 ? (
          <ul>
            {ordiniCommessa.map((ordine, index) => (
              <li key={index}>
                Protocollo: {ordine.protocollo}, Importo totale: €{" "}
                {ordine.importo_totale}
              </li>
            ))}
          </ul>
        ) : (
          <p>Nessun ordine associato a questa commessa</p>
        )}
      </Col>
      <Col xs="12">
        <h5>Lista Attività</h5>
        {attivitaCommessa.length > 0 ? (
          <ul>
            {attivitaCommessa.map((attivita, index) => (
              <li key={index}>
                Denominazione: {attivita.denominazione}, Protocollo:{" "}
                {attivita.protocollo}, Importo: € {attivita.importo}
              </li>
            ))}
          </ul>
        ) : (
          <p>Nessun attività associato a questa commessa</p>
        )}
      </Col>
    </Row>
  );
};

export default FormIncaricoFornitori;
