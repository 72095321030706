import { Button, Container, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();

  const redirectTo = () => {
    navigate("/");
  };
  return (
    <Container className=" d-flex  justify-content-center  " style={{ height: "90vh" }}>
      <div>
        <Image src="/assets/positvo.png" />
        <p className="fw-medium  fs-3">OOPS, non sembri essere autorizzato ad accedere a questi dati :/</p>
        <p className="mt-3">Clicca qui e torna alla home page</p>
        <Button style={{ backgroundColor: "#2B75BC" }} onClick={redirectTo}>
          homepage
        </Button>
      </div>
    </Container>
  );
};

export default Unauthorized;
