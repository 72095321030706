import React from "react";
// import { useSelector } from "react-redux";
import { useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";

export default function GraphInserimento() {
  // const attivitastate = useSelector((state) => state.attivita.attivita);

  const attivitaCount = useSelector((state) => state.stats.attivita);
  const offerteCount = useSelector((state) => state.stats.offerta);
  const ordiniCount = useSelector((state) => state.stats.ordine);
  const commesseCount = useSelector((state) => state.stats.commessa);

  const totAttivita =
    attivitaCount !== undefined ? attivitaCount.toString() : 0;

  const totOfferte = offerteCount !== undefined ? offerteCount.toString() : 0;

  const totOrdini = ordiniCount !== undefined ? ordiniCount.toString() : 0;

  const totCommesse =
    commesseCount !== undefined ? commesseCount.toString() : 0;

  const array = [
    {
      id: 0,
      title: "Attività",
      tot: totAttivita,
      text: "Lorem ipsum dolor sit amet",
      link: "/attivita",
    },
    {
      id: 1,
      title: "Offerte",
      tot: totOfferte,
      text: "Lorem ipsum dolor sit amet",
      link: "/offerta",
    },
    {
      id: 2,
      title: "Ordini",
      tot: totOrdini,
      text: "Lorem ipsum dolor sit amet",
      link: "/ordine",
    },
    {
      id: 3,
      title: "Commesse",
      tot: totCommesse,
      text: "Lorem ipsum dolor sit amet",
      link: "/commessa",
    },
  ];

  return (
    <div className="row">
      {array.map((e, i) => (
        <div key={`item_${i}`} className="col-12 col-sm-6 col-xl-3 mb-4">
          <div className="card">
            <div
              className="card-body d-flex flex-column justify-content-between"
              style={{ height: "200px", borderRadius: "30px" }}
            >
              <div className="h-50">
                <h3 className="card-title mt-2 mb-4">{e.title}</h3>
              </div>
              <div className="border-top pt-2 d-flex flex-column justify-content-between">
                <h6 className="text-primary my-2 w-100 cursor-pointer">
                  <div className="d-flex justify-content-between w-100">
                    <Link to={e.link} className="text-primary">
                      <span>Visualizza</span>
                    </Link>
                    <i className="bi bi-box-arrow-up-right" />
                  </div>
                </h6>
                <p className="m-0">Totale: {e.tot}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
