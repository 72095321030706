import { URL_BANCA, URL_ORDINE } from "../../../lib/url";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  SET_URL_GET,
  setShow,
  setUrlFetch,
} from "../../../redux/actions";

export const setUrlFilterOrdini = (arrayPills, info, dispatch) => {
  let url = "?";
  if (arrayPills.length > 0) {
    if (info.cliente) url = url + "cliente=" + info.cliente;
    if (info.offerte && url !== "?") url = url + "&&offerte=" + info.offerte;
    else {
      if (info.offerte) url = url + "offerte=" + info.offerte;
    }
    if (info.attivita && url !== "?") url = url + "&&attivita=" + info.attivita;
    else {
      if (info.attivita) url = url + "attivita=" + info.attivita;
    }
  } else {
    dispatch(setUrlFetch(SET_URL_GET, URL_ORDINE));
  }

  if (url !== "?") {
    url = URL_ORDINE + url;
    dispatch(setUrlFetch(SET_URL_FILTER, url));
  }
  //   dispatch(setShow(SET_SHOW_FILTER, false));
};
