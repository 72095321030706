import {
  fetchAllPut,
  fetchGeneric,
  fetchSelectTipologiaAttivita,
} from "../../redux/actions";
import { URL_TIPOLOGIA_ATTIVITA } from "../url";

export const setFetchTipologiaAttivita = (type, payload, token, dispatch) => {
  if (type === "modifica") {
    dispatch(fetchAllPut(URL_TIPOLOGIA_ATTIVITA + payload.id, payload, token));
  } else if (type === "aggiungi") {
    dispatch(fetchGeneric(URL_TIPOLOGIA_ATTIVITA, payload, token));
    dispatch(fetchSelectTipologiaAttivita(token));
  }
};
