import {
  SET_ISLOAD_POST,
  fetchAllPut,
  fetchGeneric,
  fetchSelectSiti,
  hideAlert,
  setIsLoadFetch,
  showAlert,
} from "../../redux/actions";
import { URL_SITO, URL_INDIRIZZO_SITO } from "../url";

export const sitoPiuIndirizzoPost = async (token, payload, dispatch) => {
  try {
    const resp = await fetch(
      "https://be.civetta.maritsrl.com/api/v1/" + URL_SITO,
      {
        method: "POST",
        headers: {
          Authorization: `Token ` + token,
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const data = await resp.json();

    const payload2 = { ...payload, sito: data.id };
    const resp2 = await fetch(
      "https://be.civetta.maritsrl.com/api/v1/" + URL_INDIRIZZO_SITO,
      {
        method: "POST",
        headers: {
          Authorization: `Token ` + token,
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload2),
      }
    );
    dispatch(showAlert("Record salvato con successo", "success"));
    setTimeout(() => {
      dispatch(hideAlert());
    }, 3000);
  } catch (error) {
    dispatch(showAlert("Errore", "primary"));
    setTimeout(() => {
      dispatch(hideAlert());
    }, 3000);
  }
};

export const setFetchSito = (type, payload, token, dispatch) => {
  if (type === "modifica") {
    dispatch(fetchAllPut(URL_SITO + payload.id, payload, token));
    if (payload.via && payload.provincia && payload.regione && payload.cap) {
      if (payload.idIndirizzo) {
        dispatch(
          fetchAllPut(URL_INDIRIZZO_SITO + payload.idIndirizzo, payload, token)
        );
      } else {
        payload = { ...payload, sito: payload.id };
        dispatch(fetchGeneric(URL_INDIRIZZO_SITO, payload, token));
      }
    }
  } else if (type === "aggiungi") {
    sitoPiuIndirizzoPost(token, payload, dispatch);
    dispatch(setIsLoadFetch(SET_ISLOAD_POST, true));
    dispatch(fetchSelectSiti(token));
  }
};
