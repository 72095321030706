import { URL_BANCA, URL_FORNITORE, URL_SEDE } from "../../../lib/url";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  SET_URL_GET,
  setShow,
  setUrlFetch,
} from "../../../redux/actions";

export const setUrlFilterFornitori = (arrayPills, info, dispatch) => {
  let url = "?";
  if (arrayPills.length > 0) {
    if (info.cognome) url = url + "cognome=" + info.cognome;
    if (info.nome && url !== "?") url = url + "&&nome=" + info.nome;
    else {
      if (info.nome) url = url + "nome=" + info.nome;
    }
  } else {
    dispatch(setUrlFetch(SET_URL_GET, URL_FORNITORE));
  }

  if (url !== "?") {
    url = URL_FORNITORE + url;
    dispatch(setUrlFetch(SET_URL_FILTER, url));
  }
  dispatch(setShow(SET_SHOW_FILTER, false));
  //   dispatch(setShow(SET_SHOW_FILTER, false));
};
