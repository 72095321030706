import { useEffect, useState } from "react";
import { Alert, Button, Container, Form, Image, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout";
import { useNavigate } from "react-router-dom";
import Unauthorized from "./Unauthorized";
import {
  SET_ISLOAD_GET,
  SET_ISLOAD_GET2,
  SET_ISLOAD_GET3,
  SET_ISLOAD_GET4,
  SET_ISLOAD_GET5,
  setIsLoadFetch,
} from "../../redux/actions";

const RegistrationPage = () => {
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [emailMatch, setEmailMatch] = useState(true);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [ruolo, setRuolo] = useState("");
  const role = useSelector(state => state.login.role);
  const baseEndpoint = "https://be.civetta.maritsrl.com/api/v1/";
  const token = useSelector(state => state.login.token);
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" });
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);

  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const redirectTo = () => {
    navigate("/");
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const form = e.currentTarget;
    setPasswordMatch(password === confirmPassword);
    setEmailMatch(email === confirmEmail);
    if (form.checkValidity() === false || !passwordMatch || !emailMatch) {
      e.stopPropagation();
    } else {
      const data = {
        email: email,
        password1: password,
        password2: password,
        dipendente: {
          email_utente: email,
          nome: name,
          cognome: surname,
          telefono: phone,
        },
      };
      await postRegistration(data);
    }
  };
  const userRegistration = data =>
    fetch(baseEndpoint + "auth/registration/", {
      method: "POST",
      headers: {
        Authorization: `Token ` + token,
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

  const roleRegistration = id =>
    fetch(baseEndpoint + ruolo + "/", {
      method: "POST",
      headers: {
        Authorization: `Token ` + token,
        "Content-type": "application/json",
      },
      body: JSON.stringify(id),
    });
  const userDipendenteID = user =>
    fetch(baseEndpoint + "dipendente/?utente=" + user, {
      headers: {
        Authorization: `Token ` + token,
      },
    });

  const postRegistration = async data => {
    try {
      const registrationResponse = await userRegistration(data);
      if (registrationResponse.ok) {
        const data = await registrationResponse.json();
        const user = data.user;
        const dipendenteId = await userDipendenteID(user);
        if (dipendenteId.ok) {
          const data2 = await dipendenteId.json();
          const id = { dipendente: data2.results[0].id };
          const roleResponse = await roleRegistration(id);
          if (roleResponse.ok) {
            setAlert({
              show: true,
              message: "Utente creato con successo!",
              variant: "success",
            });
          }
        }
      } else {
        if (registrationResponse.status === 500) {
          setAlert({
            show: true,
            message: "Errore del server. Riprova più tardi.",
            variant: "warning",
          });
        } else {
          const errorData = await registrationResponse.json();
          let errorMessage = "Qualcosa è andato storto, riprova!";

          if (errorData && typeof errorData === "object") {
            const errorMessages = Object.values(errorData).flat().join(". ");
            errorMessage = errorMessages || errorMessage;
          }

          setAlert({ show: true, message: errorMessage, variant: "warning" });
        }
      }
    } catch (error) {
      console.log(error);
      setAlert({
        show: true,
        message: "Errore di rete o server non disponibile.",
        variant: "danger",
      });
    }
  };

  useEffect(() => {
    dispatch(setIsLoadFetch(SET_ISLOAD_GET, false));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET2, false));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET3, false));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET4, false));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET5, false));
  }, []);

  return (
    <Layout>
      {role === "Amministratore" ? (
        <Container className="d-flex justify-content-center pt-1">
          <div className="w-75">
            <h1 className="my-5 text-primary">Nuovo Account</h1>

            <Form noValidate onSubmit={handleSubmit} className="mt-4">
              <Form.Group className="w-50">
                <Form.Label>Inserisici nome utente</Form.Label>
                <Form.Control
                  required
                  type="name"
                  placeholder="Mario"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="w-50 mt-3">
                <Form.Label>Inserisici cognome utente</Form.Label>
                <Form.Control
                  required
                  type="surname"
                  placeholder="Rossi"
                  value={surname}
                  onChange={e => setSurname(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="w-50 mt-3">
                <Form.Label>Inserisici ruolo utente</Form.Label>
                <Form.Select
                  required
                  value={ruolo}
                  onChange={e => setRuolo(e.target.value)}
                  aria-label="Seleziona Ruolo"
                >
                  <option value="" disabled hidden>
                    ruolo
                  </option>
                  <option value="amministratore">Amministratore</option>
                  <option value="amministrazione">Amministrazione</option>
                  <option value="commerciale">Commerciale</option>
                  <option value="project-manager">Project Manager</option>
                  <option value="tecnico">Tecnico</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="w-50 mt-3">
                <Form.Label>Inserisci email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="my.email@email.com"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  isInvalid={!emailMatch}
                />
                <Form.Control.Feedback type="invalid">
                  {emailMatch ? "Inserisci una email valida" : "Le email inserite non coincidono"}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="w-50 mt-3">
                <Form.Label>Ripeti email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="my.email@email.com"
                  value={confirmEmail}
                  onChange={e => setConfirmEmail(e.target.value)}
                  isInvalid={!emailMatch}
                />
                <Form.Control.Feedback type="invalid">
                  {emailMatch ? "Inserisci una email valida" : "Le email inserite non coincidono"}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="w-50 mt-3">
                <Form.Label>Inserisci numero di telefono</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="3285678903"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="w-50 mt-3">
                <Form.Label>Inserisci la tua password</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    required
                    type={passwordShown ? "text" : "password"}
                    placeholder="******"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    isInvalid={!passwordMatch}
                  />
                  <InputGroup.Text onClick={togglePasswordVisibility}>
                    {passwordShown ? <i className="bi bi-eye-slash-fill"></i> : <i className="bi bi-eye-fill"></i>}
                  </InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {passwordMatch ? "Inserisci una password valida" : "Le password inserite non coincidono"}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group className="w-50 mt-3">
                <Form.Label>Ripeti la password</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    required
                    type={passwordShown ? "text" : "password"}
                    placeholder="******"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    isInvalid={!passwordMatch}
                  />
                  <InputGroup.Text onClick={togglePasswordVisibility}>
                    {passwordShown ? <i className="bi bi-eye-slash-fill"></i> : <i className="bi bi-eye-fill"></i>}
                  </InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {passwordMatch ? "Inserisci una password valida" : "Le password inserite non coincidono"}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Button size="lg" type="submit" className="mt-3 btn-primary">
                invia
              </Button>
            </Form>
            {alert.show && (
              <Alert className="mt-2" variant={alert.variant}>
                {alert.message}
              </Alert>
            )}
          </div>
        </Container>
      ) : (
        <Unauthorized />
      )}
    </Layout>
  );
};
export default RegistrationPage;
