import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/globals.scss";
import ComponentTablePage from "./component/table/ComponentTablePage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "./component/dashboard/Dashboard";
import { logoutAction } from "./redux/actions";
import LoginPage from "./component/login/LoginPage";
import RequestPassword from "./component/login/RequestPassword";
import NewPassword from "./component/login/NewPassword";
import RegistrationPage from "./component/login/RegistrationPage";
import NotFoundPage from "./component/NotFoundPage";
import PrivateRoute from "./component/login/RoleBasedRoute";
import RoleBasedRoute from "./component/login/RoleBasedRoute";
import LogsPage from "./component/LogsPage";
import Unauthorized from "./component/login/Unauthorized";

function App() {
  const dispatch = useDispatch();
  const stayLoggedIn = useSelector(state => state.login.stayLoggedIn);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (!stayLoggedIn) {
        const currentTime = new Date().getTime();
        sessionStorage.setItem("lastUnloadTime", currentTime.toString());
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    const checkIfLeavingPage = () => {
      const lastUnloadTime = parseInt(sessionStorage.getItem("lastUnloadTime") || "0");
      const currentTime = new Date().getTime();

      // Controlla se sono passati più di 30 secondi dall'ultimo unload
      if (currentTime - lastUnloadTime > 30000 && !stayLoggedIn) {
        // 30000 ms = 30 secondi
        dispatch(logoutAction());
      }
    };

    checkIfLeavingPage();

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dispatch, stayLoggedIn]);
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />

          <Route
            path="/cliente"
            element={
              <RoleBasedRoute>
                <ComponentTablePage />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/fattura"
            element={
              <RoleBasedRoute>
                <ComponentTablePage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/attivita"
            element={
              <RoleBasedRoute>
                <ComponentTablePage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/offerta"
            element={
              <RoleBasedRoute>
                <ComponentTablePage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/ordine"
            element={
              <RoleBasedRoute>
                <ComponentTablePage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/commessa"
            element={
              <RoleBasedRoute>
                <ComponentTablePage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/sede"
            element={
              <RoleBasedRoute>
                <ComponentTablePage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/banca"
            element={
              <RoleBasedRoute>
                <ComponentTablePage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/fornitore"
            element={
              <RoleBasedRoute>
                <ComponentTablePage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/incarico-fornitore"
            element={
              <RoleBasedRoute>
                <ComponentTablePage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/tipologia-attivita"
            element={
              <PrivateRoute>
                <ComponentTablePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/sito"
            element={
              <PrivateRoute>
                <ComponentTablePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/stato"
            element={
              <RoleBasedRoute>
                <ComponentTablePage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/dipendente"
            element={
              <RoleBasedRoute>
                <ComponentTablePage />
              </RoleBasedRoute>
            }
          />
          <Route path="/request-password" element={<RequestPassword />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/strumenti/logs" element={<LogsPage />} />
          <Route
            path="strumenti/registration"
            element={
              <RoleBasedRoute>
                <RegistrationPage />
              </RoleBasedRoute>
            }
          />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
