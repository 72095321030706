export const GetClienti = (lista, lista2, lista3, setData) => {
  if (lista && lista2 && lista.length >= 0 && lista2.length >= 0 && lista3 && lista3.length >= 0) {
    const combinedList = lista.map(item => {
      let indirizzo = lista2.filter(item2 => item.id === item2.cliente);
      let combinedItem;
      if (indirizzo.length > 0) {
        combinedItem = {
          ...item,
          ...(indirizzo[0] || {}),
          id: item.id,
          idIndirizzo: indirizzo[0].id,
          openModal: <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>,
        };
      } else {
        combinedItem = {
          ...item,
          ...(indirizzo[0] || {}),
          id: item.id,
          idIndirizzo: "",
          openModal: <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>,
        };
      }
      return combinedItem;
    });

    const combinedList2 = combinedList.map(item => {
      let informazioni = lista3.filter(item3 => item.id === item3.cliente);

      let combinedItem;

      if (informazioni.length > 0) {
        combinedItem = {
          ...item,
          ...(informazioni[0] || {}),
          id: item.id,
          idInformazioni: informazioni[0].id,
        };
      } else {
        combinedItem = {
          ...item,
          fatturazione_elettronica: "",
          sito_web: "",
          idInformazioni: "",
        };
      }
      return combinedItem;
    });

    setData(combinedList2);
  } else {
    if (lista && lista.length >= 0) {
      const updatedList = lista.map(item => ({
        ...item,
        openModal: <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>,
      }));
      setData(updatedList);
    }
  }
};
