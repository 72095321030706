import { URL_BANCA, URL_OFFERTA } from "../../../lib/url";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  SET_URL_GET,
  setShow,
  setUrlFetch,
} from "../../../redux/actions";

export const setUrlFilterOfferta = (arrayPills, info, dispatch) => {
  let url = "?";
  if (arrayPills.length > 0) {
    if (info.cliente) url = url + "cliente=" + info.cliente;
    if (info.sito && url !== "?") url = url + "&&sito=" + info.sito;
    else {
      if (info.sito) url = url + "sito=" + info.sito;
    }
    if (info.referente_cliente && url !== "?")
      url = url + "&&referente_cliente=" + info.referente_cliente;
    else {
      if (info.referente_cliente)
        url = url + "referente_cliente=" + info.referente_cliente;
    }
    if (info.referente_interno && url !== "?")
      url = url + "&&referente_interno=" + info.referente_interno;
    else {
      if (info.referente_interno)
        url = url + "referente_interno=" + info.referente_interno;
    }
  } else {
    dispatch(setUrlFetch(SET_URL_GET, URL_OFFERTA));
  }

  if (url !== "?") {
    url = URL_OFFERTA + url;
    dispatch(setUrlFetch(SET_URL_FILTER, url));
  }
  //   dispatch(setShow(SET_SHOW_FILTER, false));
};
