import React from "react";
import { useEffect, useState } from "react";
import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_ISLOAD_GET,
  SET_ISLOAD_GET2,
  SET_ISLOAD_GET3,
  SET_ISLOAD_GET4,
  SET_ISLOAD_GET5,
  fetchAllDipendenti,
  fetchAllLogs,
  setIsLoadFetch,
} from "../redux/actions";
import DataTable from "react-data-table-component";
import LogDetailsModal from "./LogDetailsModal";
import { useNavigate } from "react-router-dom";
import { Button, Image } from "react-bootstrap";
import Unauthorized from "./login/Unauthorized";

function LogsPage() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.token);
  const allLogs = useSelector((state) => state.fetch.dataGetLogs);

  const allDipendenti = useSelector((state) => state.fetch.dataGetDipendenti);
  const [dataToBeShow, setDataToBeShow] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [selectedLog, setSelectedLog] = useState([]);
  const role = useSelector((state) => state.login.role);
  const navigate = useNavigate();

  const redirectTo = () => {
    navigate("/");
  };

  const handleShowModal = (logItem) => {
    setSelectedLog(logItem);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    dispatch(fetchAllLogs(token));
    dispatch(fetchAllDipendenti(token));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET, false));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET2, false));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET3, false));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET4, false));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET5, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      allLogs &&
      allDipendenti &&
      allLogs.length > 0 &&
      allDipendenti.length > 0
    ) {
      const combinedList = allLogs.map((item) => {
        let interoDipendente = allDipendenti.filter(
          (item2) => item.user === item2.id
        );
        let fullName =
          interoDipendente.length > 0
            ? interoDipendente[0].nome + " " + interoDipendente[0].cognome
            : "Utente eliminato";

        let combinedItem = {
          ...item,
          user: fullName,
        };

        return combinedItem;
      });
      setDataToBeShow(combinedList);
    }
  }, [allLogs, allDipendenti]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString();
  };

  const formatActionType = (action) => {
    switch (action) {
      case "create":
        return "Creazione";
      case "update":
        return "Modifica";
      case "delete":
        return "Eliminazione";
      default:
        return action;
    }
  };
  const getActionColor = (action) => {
    switch (action) {
      case "create":
        return "green";
      case "update":
        return "#ffa500";
      case "delete":
        return "red";
      default:
        return "grey";
    }
  };
  const columns = [
    {
      name: "",
      selector: (row) => (
        <span
          style={{
            height: "15px",
            width: "15px",
            backgroundColor: getActionColor(row.action),
            borderRadius: "50%",
            display: "inline-block",
          }}
        />
      ),
      maxWidth: "30px",
      sortable: false,
    },
    {
      name: "Tipo di azione",
      selector: (row) => formatActionType(row.action),
      sortable: true,
    },
    {
      name: "Azione fatta da",
      selector: (row) => row.user,
      sortable: true,
    },
    {
      name: "Tabella",
      selector: (row) => row.model_name.slice(4),
      sortable: true,
    },
    {
      name: "Data modifica",
      selector: (row) => formatDate(row.date),
      sortable: true,
    },
    {
      name: "Ora modifica",
      selector: (row) => formatTime(row.date),
      sortable: true,
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <span
          className="bi bi-three-dots m-0"
          style={{ cursor: "pointer" }}
          onClick={() => handleShowModal(row)}
        ></span>
      ),
    },
  ];

  return (
    <Layout>
      {role === "Amministratore" || role === "Amministrazione" ? (
        <>
          {" "}
          <h1>Logs</h1>
          <DataTable
            columns={columns}
            data={dataToBeShow || []}
            defaultSortField="tipo"
            pagination
          />{" "}
          <LogDetailsModal
            show={showModal}
            handleClose={handleCloseModal}
            logItem={selectedLog}
          />
        </>
      ) : (
        <Unauthorized />
      )}
    </Layout>
  );
}

export default LogsPage;
