import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ADD_STATI } from "../../lib/payloads";
import { SET_INFO1, SET_INFO3, setInfo } from "../../redux/actions";

const FormStati = () => {
  const info1 = useSelector((state) => state.modal.info1);
  const show1 = useSelector((state) => state.modal.show1);
  const show2 = useSelector((state) => state.modal.show2);
  const show3 = useSelector((state) => state.modal.show3);
  const show4 = useSelector((state) => state.modal.show4);
  const dispatch = useDispatch();
  const [info, setInformazioni] = useState({});
  const path = useSelector((state) => state.locationPage.path);
  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };

  useEffect(() => {
    if (path == "stato") {
      dispatch(setInfo(SET_INFO1, info));
    } else {
      dispatch(setInfo(SET_INFO3, info));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);
  useEffect(() => {
    if (show1 && show2 && show3 && show4) {
      setInformazioni(ADD_STATI);
    } else if (show1 && show2 && show3) {
      setInformazioni(ADD_STATI);
    } else if (show1 && show2) {
      setInformazioni(ADD_STATI);
    } else {
      setInformazioni(info1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      {" "}
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Nome *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.nome}
            onChange={(e) => changeInfo(e.target.value, "nome")}
            placeholder="Inserisci Descrizione"
            required
          />{" "}
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Descrizione
          </Form.Label>
          <Form.Control
            type="text"
            value={info.descrizione}
            onChange={(e) => changeInfo(e.target.value, "descrizione")}
            placeholder="inserisci descrizione"
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Colore *
          </Form.Label>
          <Form.Control
            type="color"
            value={info.colore || "#ffffff"}
            onChange={(e) => changeInfo(e.target.value, "colore")}
            placeholder="inserisci Colore"
            required
          />{" "}
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>{" "}
    </Row>
  );
};
export default FormStati;
