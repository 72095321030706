import {
  FETCH_ATTIVITA_COUNT,
  FETCH_COMMESSE_COUNT,
  FETCH_ORDINI_COUNT,
  FETCH_OFFERTE_COUNT,
} from "../actions";

const initialState = {
  attivita: 0,
  commessa: 0,
  ordine: 0,
  offerta: 0,
};

const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ATTIVITA_COUNT:
    case FETCH_COMMESSE_COUNT:
    case FETCH_ORDINI_COUNT:
    case FETCH_OFFERTE_COUNT:
      return { ...state, [action.payload.type]: action.payload.count };
    default:
      return state;
  }
};

export default statsReducer;
