import { Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SET_SHOW_FILTER, setShow } from "../../redux/actions";
import { useState } from "react";
import FilterAttivita from "../filters/FilterAttivita";
import FilterClienti from "../filters/FilterClienti";
import FilterFatture from "../filters/FilterFatture";
import FilterOrdini from "../filters/FilterOrdini";
import FilterCommesse from "../filters/FilterCommesse";
import FilterOfferte from "../filters/FilterOfferte";
import FilterSedi from "../filters/FilterSedi";
import FilterBanche from "../filters/FilterBanche";
import FilterFornitori from "../filters/FilterFornitori";
import FilterIncaricoFornitori from "../filters/FilterIncaricoFornitori";
import FilterSiti from "../filters/FilterSiti";
import FilterStati from "../filters/FilterStati";
import FilterTipologiaAttivita from "../filters/FilterTipologiaAttivita";
import FilterDipendenti from "../filters/FilterDipendenti";

const Filter = () => {
  const show = useSelector((state) => state.modal.showFilter);
  const [info, setInformazioni] = useState({});
  const classe = useSelector((state) => state.modal.class1);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setShow(SET_SHOW_FILTER, false));
  };
  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Filtri</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {classe === "cliente" ? (
          <FilterClienti info={info} setInformazioni={setInformazioni} />
        ) : classe === "fattura" ? (
          <FilterFatture info={info} setInformazioni={setInformazioni} />
        ) : classe === "attivita" ? (
          <FilterAttivita info={info} setInformazioni={setInformazioni} />
        ) : classe === "ordine" ? (
          <FilterOrdini info={info} setInformazioni={setInformazioni} />
        ) : classe === "commessa" ? (
          <FilterCommesse info={info} setInformazioni={setInformazioni} />
        ) : classe === "offerta" ? (
          <FilterOfferte info={info} setInformazioni={setInformazioni} />
        ) : classe === "sede" ? (
          <FilterSedi info={info} setInformazioni={setInformazioni} />
        ) : classe === "banca" ? (
          <FilterBanche info={info} setInformazioni={setInformazioni} />
        ) : classe === "fornitore" ? (
          <FilterFornitori info={info} setInformazioni={setInformazioni} />
        ) : classe === "incarico-fornitore" ? (
          <FilterIncaricoFornitori
            info={info}
            setInformazioni={setInformazioni}
          />
        ) : classe === "sito" ? (
          <FilterSiti info={info} setInformazioni={setInformazioni} />
        ) : classe === "stato" ? (
          <FilterStati info={info} setInformazioni={setInformazioni} />
        ) : classe === "tipologia-attivita" ? (
          <FilterTipologiaAttivita
            info={info}
            setInformazioni={setInformazioni}
          />
        ) : classe === "dipendente" ? (
          <FilterDipendenti info={info} setInformazioni={setInformazioni} />
        ) : (
          ""
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};
export default Filter;
