import {
  fetchAllPut,
  fetchGeneric,
  fetchSelectCommesse,
} from "../../redux/actions";
import { URL_COMMESSA } from "../url";

export const setFetchCommessa = (type, payload, token, dispatch) => {
  if (type === "modifica") {
    // SEMPLICE PUT: DA GESTIRE LA QUESTIONE DEGLI ORDINI SELEZIONATI E DA INSERIRE NELL ARRAY
    // COMMESSA.ORDINI . BASTANO GLI ORDINI O SERVONO ANCHE LE ATTIVITA E LE OFFERTE (BACK-END?) ?
    dispatch(fetchAllPut(URL_COMMESSA + payload.id, payload, token));
  } else if (type === "aggiungi") {
    dispatch(fetchGeneric(URL_COMMESSA, payload, token));
    dispatch(fetchSelectCommesse(token));
    // SEMPLICE POST: DA GESTIRE LA QUESTIONE DEGLI ORDINI SELEZIONATI E DA INSERIRE NELL ARRAY
    // COMMESSA.ORDINI . BASTANO GLI ORDINI O SERVONO ANCHE LE ATTIVITA E LE OFFERTE (BACK-END?) ?
  }
};
