import { useDispatch, useSelector } from "react-redux";
import React from "react";
import {
  SET_CLASS1,
  SET_DATA_GET,
  SET_DATA_GET2,
  SET_DATA_GET3,
  SET_DATA_GET4,
  SET_DATA_GET5,
  SET_DATA_GET6,
  SET_INFO1,
  SET_ISLOAD_GET,
  SET_ISLOAD_GET2,
  SET_ISLOAD_GET3,
  SET_ISLOAD_GET4,
  SET_ISLOAD_GET5,
  SET_ISLOAD_GET6,
  SET_SHOW1,
  SET_URL_FILTER,
  SET_URL_GET,
  SET_URL_GET2,
  SET_URL_GET3,
  SET_URL_GET4,
  SET_URL_GET5,
  SET_URL_GET6,
  fetchAllFilter,
  fetchAllGet,
  fetchAllGet2,
  fetchAllGet3,
  fetchAllGet4,
  fetchAllGet5,
  fetchAllGet6,
  fetchSelectDipendente,
  fetchSelectLavori,
  fetchSelectProjectManager,
  setClass,
  setDataFetch,
  setInfo,
  setIsLoadFetch,
  setShow,
  setType,
  setUrlFetch,
} from "../../redux/actions";
import DataTableExtensions from "react-data-table-component-extensions";
import { useEffect, useState } from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import DataTable from "react-data-table-component";
import {
  ATTIVITA_COL,
  BANCHE_COL,
  CLIENTI_COL,
  COMMESSE_COL,
  DIPENDENTI_COL,
  FATTURE_COL,
  FORNITORI_COL,
  INCARICO_FORNITORI_COL,
  OFFERTE_COL,
  ORDINI_COL,
  SEDI_COL,
  SITI_COL,
  STATI_COL,
  TIPOLOGIA_ATTIVITA_COL,
} from "../../lib/payloadsColumns";
import Details from "./Details";
import {
  URL_ATTIVITA,
  URL_BANCA,
  URL_CLIENTI,
  URL_COMMESSA,
  URL_FATTURA,
  URL_FORNITORE,
  URL_INCARICO_FORNITORE,
  URL_INDIRIZZO_CLIENTE,
  URL_INDIRIZZO_FORNITORE,
  URL_INDIRIZZO_SITO,
  URL_INFORMAZIONI_CLIENTE,
  URL_LAVORI,
  URL_OFFERTA,
  URL_ORDINE,
  URL_RESPONSABILI,
  URL_SEDE,
  URL_SITO,
  URL_STATO,
  URL_TIPOLOGIA_ATTIVITA,
} from "../../lib/url";
import { GetClienti } from "../../lib/fetch/AllGetTable/GetCliente";
import { GetOrdine } from "../../lib/fetch/AllGetTable/GetOrdine";
import { GetSito } from "../../lib/fetch/AllGetTable/GetSito";
import { GetOfferte } from "../../lib/fetch/AllGetTable/GetOfferte";
import { GetCommessa } from "../../lib/fetch/AllGetTable/GetCommessa";
import { GetFornitore } from "../../lib/fetch/AllGetTable/GetFornitore";
import { GetIncaricoFornitore } from "../../lib/fetch/AllGetTable/GetIncaricoFornitore";
import { GetAttivita } from "../../lib/fetch/AllGetTable/GetAttivita";
import { GetFattura } from "../../lib/fetch/AllGetTable/GetFatture";
import { GetSedi } from "../../lib/fetch/AllGetTable/GetSedi";
import { GetBanca } from "../../lib/fetch/AllGetTable/GetBanca";
import { GetStato } from "../../lib/fetch/AllGetTable/GetStato";
import { GetTipologiaAttivita } from "../../lib/fetch/AllGetTable/GetTipologiaAttivita";
import { GetDipendenti } from "../../lib/fetch/AllGetTable/GetDipendenti";
import { URL_DIPENDENTE } from "../../lib/url";
import { URL_AMMINISTRATORE } from "../../lib/url";
import { URL_AMMINISTRAZIONE } from "../../lib/url";
import { URL_PROJECT_MANAGER } from "../../lib/url";
import { URL_COMMERCIALE } from "../../lib/url";
import { URL_TECNICO } from "../../lib/url";
import { Button } from "react-bootstrap";

const TableData = () => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [columns, setColumn] = useState(CLIENTI_COL);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const path = useSelector((state) => state.locationPage.path);
  const lista = useSelector((state) => state.fetch.dataGet);
  const lista2 = useSelector((state) => state.fetch.dataGet2);
  const lista3 = useSelector((state) => state.fetch.dataGet3);
  const lista4 = useSelector((state) => state.fetch.dataGet4);
  const lista5 = useSelector((state) => state.fetch.dataGet5);
  const lista6 = useSelector((state) => state.fetch.dataGet6);
  const token = useSelector((state) => state.login.token);
  const url = useSelector((state) => state.fetch.urlGet);
  const url2 = useSelector((state) => state.fetch.urlGet2);
  const url3 = useSelector((state) => state.fetch.urlGet3);
  const url4 = useSelector((state) => state.fetch.urlGet4);
  const url5 = useSelector((state) => state.fetch.urlGet5);
  const url6 = useSelector((state) => state.fetch.urlGet6);
  const allProjectManager = useSelector(
    (state) => state.select.dataProjectManager
  );
  const allDipendenti = useSelector((state) => state.select.dataDipendente);
  const urlFilter = useSelector((state) => state.fetch.urlFilter);
  const isLoadedGet = useSelector((state) => state.fetch.isLoadedGet);
  const isLoadedGet2 = useSelector((state) => state.fetch.isLoadedGet2);
  const isLoadedGet3 = useSelector((state) => state.fetch.isLoadedGet3);
  const isLoadedGet4 = useSelector((state) => state.fetch.isLoadedGet4);
  const isLoadedGet5 = useSelector((state) => state.fetch.isLoadedGet5);
  const isLoadedGet6 = useSelector((state) => state.fetch.isLoadedGet6);
  const isLoadedPost = useSelector((state) => state.fetch.isLoadedPost);
  const isLoadedPut = useSelector((state) => state.fetch.isLoadedPut);
  const isLoadedDel = useSelector((state) => state.fetch.isLoadedDel);
  const dispatch = useDispatch();
  const allAttivita = useSelector((state) => state.select.dataAttivita);
  const [refresh, setRefresh] = useState(false);
  const [stringAlert, setStringAlert] = useState("vuoto");

  useEffect(() => {
    setLoading(true);
    dispatch(fetchSelectDipendente(token));
    dispatch(fetchSelectProjectManager(token));
    dispatch(fetchSelectLavori(token));
    dispatch(setDataFetch(SET_DATA_GET, {}));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET, false));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET2, false));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET3, false));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET4, false));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET5, false));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET6, false));
    dispatch({ type: SET_DATA_GET, payload: [] });
    dispatch({ type: SET_DATA_GET2, payload: [] });
    dispatch({ type: SET_DATA_GET3, payload: [] });
    dispatch({ type: SET_DATA_GET4, payload: [] });
    dispatch({ type: SET_DATA_GET5, payload: [] });
    dispatch({ type: SET_DATA_GET6, payload: [] });
    dispatch(setUrlFetch(SET_URL_GET, ""));
    dispatch(setUrlFetch(SET_URL_GET2, ""));
    dispatch(setUrlFetch(SET_URL_GET3, ""));
    dispatch(setUrlFetch(SET_URL_GET4, ""));
    dispatch(setUrlFetch(SET_URL_GET5, ""));
    dispatch(setUrlFetch(SET_URL_GET6, ""));
    setData([]);
    columsConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, refresh]);

  useEffect(() => {
    if (urlFilter) {
      setLoading(true);
      dispatch(setDataFetch(SET_DATA_GET, {}));
      // dispatch(setIsLoadFetch(SET_ISLOAD_GET, false));
      // dispatch(setIsLoadFetch(SET_ISLOAD_GET2, false));
      // dispatch(setIsLoadFetch(SET_ISLOAD_GET3, false));
      // dispatch(setIsLoadFetch(SET_ISLOAD_GET4, false));
      // dispatch(setIsLoadFetch(SET_ISLOAD_GET5, false));
      // dispatch(setIsLoadFetch(SET_ISLOAD_GET6, false));
      setData([]);
      columsConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlFilter]);

  useEffect(() => {
    if (url !== urlFilter) {
      dispatch(fetchAllGet(url, token));
    } else {
      dispatch(fetchAllFilter(url, token));
      dispatch(setUrlFetch(SET_URL_FILTER, ""));
    }
    if (url2) dispatch(fetchAllGet2(url2, token));
    if (url3) dispatch(fetchAllGet3(url3, token));
    if (url4) dispatch(fetchAllGet4(url4, token));
    if (url5) dispatch(fetchAllGet5(url5, token));
    if (url6) dispatch(fetchAllGet6(url6, token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, url2, url3, url4, url5, url6]);

  useEffect(() => {
    if (refresh) {
      if (url !== urlFilter) {
        dispatch(fetchAllGet(url, token));
      } else {
        dispatch(fetchAllFilter(url, token));
        dispatch(setUrlFetch(SET_URL_FILTER, ""));
      }
      if (url2) dispatch(fetchAllGet2(url2, token));
      if (url3) dispatch(fetchAllGet3(url3, token));
      if (url4) dispatch(fetchAllGet4(url4, token));
      if (url5) dispatch(fetchAllGet5(url5, token));
      if (url6) dispatch(fetchAllGet6(url6, token));
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    switch (path) {
      case "cliente":
        if (isLoadedGet3 && isLoadedGet2 && isLoadedGet) {
          setTimeout(() => {
            GetClienti(lista, lista2, lista3, setData);
            setLoading(false);
          }, 200);
        }
        break;
      case "fattura":
        if (isLoadedGet4 && isLoadedGet3 && isLoadedGet2 && isLoadedGet) {
          setTimeout(() => {
            GetFattura(lista, lista2, lista3, lista4, setData);
            setLoading(false);
          }, 200);
        }
        break;
      case "ordine":
        if (isLoadedGet2 && isLoadedGet && isLoadedGet3) {
          setTimeout(() => {
            GetOrdine(lista, lista2, lista3, setData);
            setLoading(false);
          }, 200);
        }
        break;
      case "attivita":
        if (
          isLoadedGet5 &&
          isLoadedGet4 &&
          isLoadedGet3 &&
          isLoadedGet2 &&
          isLoadedGet
        ) {
          setTimeout(() => {
            GetAttivita(lista, lista2, lista3, lista4, lista5, setData);
            setLoading(false);
          }, 200);
        }
        break;
      case "offerta":
        if (isLoadedGet3 && isLoadedGet2 && isLoadedGet) {
          setTimeout(() => {
            GetOfferte(lista, lista2, lista3, setData);
            setLoading(false);
          }, 200);
        }

        break;
      case "commessa":
        if (
          isLoadedGet6 &&
          isLoadedGet5 &&
          isLoadedGet4 &&
          isLoadedGet3 &&
          isLoadedGet2 &&
          isLoadedGet
        ) {
          setTimeout(() => {
            GetCommessa(
              lista,
              lista2,
              lista3,
              lista4,
              lista5,
              lista6,
              allProjectManager,
              allDipendenti,
              setData
            );
            setLoading(false);
          }, 200);
        }
        break;
      case "sede":
        if (isLoadedGet) {
          setTimeout(() => {
            GetSedi(lista, setData);
            setLoading(false);
          }, 200);
        }
        break;
      case "banca":
        if (isLoadedGet) {
          setTimeout(() => {
            GetBanca(lista, setData);
            setLoading(false);
          }, 200);
        }
        break;
      case "fornitore":
        if ((isLoadedGet4, isLoadedGet3, isLoadedGet2 && isLoadedGet)) {
          setTimeout(() => {
            GetFornitore(lista, lista2, lista3, lista4, setData);
            setLoading(false);
          }, 200);
        }
        break;
      case "incarico-fornitore":
        if (isLoadedGet3 && isLoadedGet2 && isLoadedGet) {
          setTimeout(() => {
            GetIncaricoFornitore(lista, lista2, lista3, setData);
            setLoading(false);
          }, 200);
        }
        break;
      case "sito":
        if (isLoadedGet3 && isLoadedGet2 && isLoadedGet) {
          setTimeout(() => {
            GetSito(lista, lista2, lista3, setData);
            setLoading(false);
          }, 200);
        }
        break;
      case "stato":
        if (isLoadedGet) {
          setTimeout(() => {
            GetStato(lista, setData);
            setLoading(false);
          }, 200);
        }
        break;
      case "tipologia-attivita":
        if (isLoadedGet) {
          setTimeout(() => {
            GetTipologiaAttivita(lista, setData);
            setLoading(false);
          }, 200);
        }
        break;
      case "dipendente":
        if (
          (isLoadedGet,
          isLoadedGet2,
          isLoadedGet3,
          isLoadedGet4,
          isLoadedGet5,
          isLoadedGet6)
        ) {
          setTimeout(() => {
            GetDipendenti(
              lista,
              lista2,
              lista3,
              lista4,
              lista5,
              lista6,
              setData
            );
            setLoading(false);
          }, 200);
        }
        break;
      default:
        break;
    }
  }, [
    isLoadedGet,
    isLoadedGet2,
    isLoadedGet3,
    isLoadedGet4,
    isLoadedGet5,
    isLoadedGet6,
    lista,
    lista2,
    lista3,
    lista4,
    lista5,
    lista6,
    allDipendenti,
    allProjectManager,
    path,
  ]);

  useEffect(() => {
    if (isLoadedDel || isLoadedPut || isLoadedPost) {
      if (isLoadedPut) {
        setStringAlert("PUT");
      }
      if (isLoadedDel) {
        setStringAlert("DEL");
      }
      if (isLoadedPost) {
        setStringAlert("POST");
      }
      setRefresh(true);
    }
  }, [isLoadedPost, isLoadedDel, isLoadedPut]);

  const columsConfig = () => {
    switch (path) {
      case "cliente":
        setColumn(CLIENTI_COL);
        if (urlFilter) {
          dispatch(setUrlFetch(SET_URL_GET, urlFilter));
        } else {
          dispatch(setUrlFetch(SET_URL_GET, URL_CLIENTI));
        }

        dispatch(setUrlFetch(SET_URL_GET2, URL_INDIRIZZO_CLIENTE));
        dispatch(setUrlFetch(SET_URL_GET3, URL_INFORMAZIONI_CLIENTE));
        break;
      case "fattura":
        setColumn(FATTURE_COL);
        if (urlFilter) {
          dispatch(setUrlFetch(SET_URL_GET, urlFilter));
        } else {
          dispatch(setUrlFetch(SET_URL_GET, URL_FATTURA));
        }
        dispatch(setUrlFetch(SET_URL_GET2, URL_CLIENTI));
        dispatch(setUrlFetch(SET_URL_GET3, URL_ORDINE));
        dispatch(setUrlFetch(SET_URL_GET4, URL_BANCA));
        break;
      case "ordine":
        setColumn(ORDINI_COL);
        if (urlFilter) {
          dispatch(setUrlFetch(SET_URL_GET, urlFilter));
        } else {
          dispatch(setUrlFetch(SET_URL_GET, URL_ORDINE));
        }
        dispatch(setUrlFetch(SET_URL_GET2, URL_CLIENTI));
        dispatch(setUrlFetch(SET_URL_GET3, URL_LAVORI));
        break;
      case "attivita":
        setColumn(ATTIVITA_COL);
        if (urlFilter) {
          dispatch(setUrlFetch(SET_URL_GET, urlFilter));
        } else {
          dispatch(setUrlFetch(SET_URL_GET, URL_ATTIVITA));
        }
        dispatch(setUrlFetch(SET_URL_GET2, URL_CLIENTI));
        dispatch(setUrlFetch(SET_URL_GET3, URL_OFFERTA));
        dispatch(setUrlFetch(SET_URL_GET4, URL_TIPOLOGIA_ATTIVITA));
        dispatch(setUrlFetch(SET_URL_GET5, URL_SITO));

        break;
      case "offerta":
        setColumn(OFFERTE_COL);
        if (urlFilter) {
          dispatch(setUrlFetch(SET_URL_GET, urlFilter));
        } else {
          dispatch(setUrlFetch(SET_URL_GET, URL_OFFERTA));
        }
        dispatch(setUrlFetch(SET_URL_GET2, URL_CLIENTI));
        dispatch(setUrlFetch(SET_URL_GET3, URL_SITO));

        break;
      case "commessa":
        setColumn(COMMESSE_COL);
        if (urlFilter) {
          dispatch(setUrlFetch(SET_URL_GET, urlFilter));
        } else {
          dispatch(setUrlFetch(SET_URL_GET, URL_COMMESSA));
        }
        dispatch(setUrlFetch(SET_URL_GET2, URL_CLIENTI));
        dispatch(setUrlFetch(SET_URL_GET3, URL_FORNITORE));
        dispatch(setUrlFetch(SET_URL_GET4, URL_STATO));
        dispatch(setUrlFetch(SET_URL_GET5, URL_SITO));
        dispatch(setUrlFetch(SET_URL_GET6, URL_RESPONSABILI));

        break;
      case "sede":
        setColumn(SEDI_COL);
        if (urlFilter) {
          dispatch(setUrlFetch(SET_URL_GET, urlFilter));
        } else {
          dispatch(setUrlFetch(SET_URL_GET, URL_SEDE));
        }

        break;
      case "banca":
        setColumn(BANCHE_COL);
        if (urlFilter) {
          dispatch(setUrlFetch(SET_URL_GET, urlFilter));
        } else {
          dispatch(setUrlFetch(SET_URL_GET, URL_BANCA));
        }

        break;
      case "fornitore":
        setColumn(FORNITORI_COL);
        if (urlFilter) {
          dispatch(setUrlFetch(SET_URL_GET, urlFilter));
        } else {
          dispatch(setUrlFetch(SET_URL_GET, URL_FORNITORE));
        }
        dispatch(setUrlFetch(SET_URL_GET2, URL_INDIRIZZO_FORNITORE));
        dispatch(setUrlFetch(SET_URL_GET3, URL_BANCA));
        dispatch(setUrlFetch(SET_URL_GET4, URL_TIPOLOGIA_ATTIVITA));

        break;
      case "incarico-fornitore":
        setColumn(INCARICO_FORNITORI_COL);
        if (urlFilter) {
          dispatch(setUrlFetch(SET_URL_GET, urlFilter));
        } else {
          dispatch(setUrlFetch(SET_URL_GET, URL_INCARICO_FORNITORE));
        }

        dispatch(setUrlFetch(SET_URL_GET2, URL_FORNITORE));
        dispatch(setUrlFetch(SET_URL_GET3, URL_COMMESSA));

        break;
      case "sito":
        setColumn(SITI_COL);
        if (urlFilter) {
          dispatch(setUrlFetch(SET_URL_GET, urlFilter));
        } else {
          dispatch(setUrlFetch(SET_URL_GET, URL_SITO));
        }
        dispatch(setUrlFetch(SET_URL_GET2, URL_CLIENTI));
        dispatch(setUrlFetch(SET_URL_GET3, URL_INDIRIZZO_SITO));

        break;
      case "stato":
        setColumn(STATI_COL);
        if (urlFilter) {
          dispatch(setUrlFetch(SET_URL_GET, urlFilter));
        } else {
          dispatch(setUrlFetch(SET_URL_GET, URL_STATO));
        }
        dispatch(setUrlFetch(SET_URL_GET2, URL_CLIENTI));
        break;
      case "tipologia-attivita":
        setColumn(TIPOLOGIA_ATTIVITA_COL);
        if (urlFilter) {
          dispatch(setUrlFetch(SET_URL_GET, urlFilter));
        } else {
          dispatch(setUrlFetch(SET_URL_GET, URL_TIPOLOGIA_ATTIVITA));
        }
        break;
      case "dipendente":
        setColumn(DIPENDENTI_COL);
        if (urlFilter) {
          dispatch(setUrlFetch(SET_URL_GET, urlFilter));
        } else {
          dispatch(setUrlFetch(SET_URL_GET, URL_DIPENDENTE));
        }
        dispatch(setUrlFetch(SET_URL_GET2, URL_AMMINISTRATORE));
        dispatch(setUrlFetch(SET_URL_GET3, URL_AMMINISTRAZIONE));
        dispatch(setUrlFetch(SET_URL_GET4, URL_PROJECT_MANAGER));
        dispatch(setUrlFetch(SET_URL_GET5, URL_COMMERCIALE));
        dispatch(setUrlFetch(SET_URL_GET6, URL_TECNICO));
        break;

      default:
        break;
    }

    // Creare una nuova copia di columns e aggiungere la colonna dell icona modifica
    setColumn((prevColumns) => [
      ...prevColumns.map((col) => {
        if (col.name === "Incassata") {
          // Assumi che "Incassata" sia il nome della colonna
          return {
            ...col,
            cell: (row) => (row.incassata ? "Sì" : "No"),
          };
        } else if (col.name === "Colore") {
          // Gestione della colonna "Colore"
          return {
            ...col,
            cell: (row) => (
              <div
                style={{
                  display: "inline-block",
                  backgroundColor: row.colore,
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                }}
              />
            ),
          };
        } else if (col.name === "coloreStato") {
          return {
            ...col,
            sortable: false,
            name: "",
            width: "50px",
            cell: (row) => (
              <div
                style={{
                  display: "inline-block",
                  backgroundColor: row.coloreStato,
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                }}
              />
            ),
          };
        }
        return col;
      }),
      {
        name: "",
        selector: (row) => "",
        sortable: false,
        width: "50px",
        cell: (row) => (
          <div>
            <span onClick={() => handleShow(row)} style={{ cursor: "pointer" }}>
              {row.openModal}
            </span>
          </div>
        ),
      },
    ]);
  };

  const handleShow = (row) => {
    // Rimuovi la proprietà openModel dall'oggetto row se no esplode redux
    const { openModal, ...rowWithoutOpenModel } = row;

    // Ora dispatch setInfo con l'oggetto che non contiene openModel
    dispatch(setInfo(SET_INFO1, rowWithoutOpenModel));
    dispatch(setType("modifica"));
    dispatch(setClass(SET_CLASS1, path));
    dispatch(setShow(SET_SHOW1, true));
  };

  const handleRowExpand = (row) => {
    const isRowExpanded = expandedRows.includes(row.id);

    if (isRowExpanded) {
      // Se la riga Ã¨ giÃ  espansa, rimuovila dalla lista delle righe espandibili
      setExpandedRows(expandedRows.filter((id) => id !== row.id));
    } else {
      // Se la riga non Ã¨ espansa, aggiungila alla lista delle righe espandibili
      setExpandedRows([...expandedRows, row.id]);
    }
  };

  const paginationOptions = {
    rowsPerPageText: "Righe per pagina",
    rangeSeparatorText: "di",
  };
  const customMessages = {
    noData: "Non ci sono record da visualizzare",
  };

  function downloadPDF(array) {
    // Imposta l'orientamento del documento su orizzontale (landscape)
    const doc = new jsPDF({
      orientation: "landscape",
    });

    // Prepara le colonne e i dati per l'autotable
    const columns = Object.keys(array[0])
      .filter(
        (key) =>
          key !== "openModal" && !key.startsWith("id") && !key.endsWith("Id")
      )
      .map((key) => ({ header: key, dataKey: key }));
    const data = array.map((row) => {
      const rowData = { ...row };
      delete rowData.openModal; // Rimuovi la proprietà openModal
      return rowData;
    });
    autoTable(doc, { columns: columns, body: data });

    const filename = path + ".pdf";
    doc.save(filename);
  }

  const ExportPDF = ({ onExport }) => (
    <Button
      className="btn-primary ms-2 me-1"
      onClick={(e) => onExport(e.target.value)}
    >
      <i className="bi bi-file-earmark-pdf-fill"></i>
    </Button>
  );

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ";";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]).filter((key) => key !== "openModal");

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result +=
          item[key] === undefined || item[key] === "" || item[key] === null
            ? "ND"
            : item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button
      className="btn-primary ms-2 me-2"
      onClick={(e) => onExport(e.target.value)}
    >
      <i className="bi bi-download"></i>
    </Button>
  );
  const actionsMemo = React.useMemo(
    () => (
      <>
        <Export onExport={() => downloadCSV(data)} />
        <ExportPDF onExport={() => downloadPDF(data)} />
      </>
    ),
    [data]
  );

  const tableData = {
    columns,
    data,
  };

  const nonExpandablePaths = [
    "banca",
    "tipologia-attivita",
    "stato",
    "incarico-fornitore",
    "dipendente",
    "sede",
  ];

  return (
    <>
      <DataTableExtensions
        {...tableData}
        filter={false}
        export={false}
        print={false}
      >
        <DataTable
          id="dataTable"
          className="border"
          columns={columns}
          data={data}
          noDataComponent={customMessages.noData}
          pagination
          expandableRows={!nonExpandablePaths.includes(path)}
          actions={[actionsMemo]}
          progressPending={loading}
          paginationComponentOptions={paginationOptions}
          paginationPerPage={10}
          expandableRowsComponent={({ data }) => (
            <Details classe={path} data={data} />
          )}
          onRowExpandToggled={handleRowExpand}
          expandableRowExpanded={(row) => {
            expandedRows.includes(row.id) ||
              expandedRows.includes(row.n_fattura);
          }}
        />
      </DataTableExtensions>
    </>
  );
};
export default TableData;
