import { URL_BANCA, URL_DIPENDENTE } from "../../../lib/url";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  SET_URL_GET,
  setShow,
  setUrlFetch,
} from "../../../redux/actions";

export const setUrlFilterDipendenti = (arrayPills, info, dispatch) => {
  let url = "?";
  if (arrayPills.length > 0) {
    if (info.nome) url = url + "nome=" + info.nome;
    if (info.cognome && url !== "?") url = url + "&&cognome=" + info.cognome;
    else {
      if (info.cognome) url = url + "cognome=" + info.cognome;
    }
    // if (info.ruolo && url !== "?") url = url + "&&ruolo=" + info.ruolo;
    // else {
    //   if (info.ruolo) url = url + "ruolo=" + info.ruolo;
    // }
  } else {
    dispatch(setUrlFetch(SET_URL_GET, URL_DIPENDENTE));
  }

  if (url !== "?") {
    url = URL_DIPENDENTE + url;
    dispatch(setUrlFetch(SET_URL_FILTER, url));
  }
  //   dispatch(setShow(SET_SHOW_FILTER, false));
};
