import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelectAttivita } from "../../../redux/actions";

const OfferteDetails = ({ data }) => {
  const allAttivita = useSelector((state) => state.select.dataAttivita);
  const token = useSelector((state) => state.login.token);
  const [lista, setLista] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!allAttivita.length) {
      // Fetch activities only if not already fetched
      dispatch(fetchSelectAttivita(token));
    }
    // Always update lista when allAttivita or data.id changes
    const filteredActivities = allAttivita.filter(
      (attivita) => attivita.offerta === data.id
    );
    setLista(filteredActivities);
  }, [allAttivita, data.id, dispatch, token]); // Ensure all dependencies are listed

  return (
    <Container className="border bg-light">
      <Row>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">N° Contratto</p>
          <span className="dettagli"> {data.n_contratto}</span>
        </Col>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">Oggetto</p>
          <span className="dettagli">{data.oggetto}</span>
        </Col>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">
            Telefono Referente
          </p>
          <span className="dettagli">{data.tel_referente_interno}</span>
        </Col>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">
            Telefono Cliente
          </p>
          <span className="dettagli">{data.tel_referente_cliente}</span>
        </Col>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">N° Attività </p>
          <span className="dettagli">{lista.length}</span>
        </Col>
      </Row>
    </Container>
  );
};

export default OfferteDetails;
