import { useEffect, useState } from "react";
import { Col, Form, Row, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ADD_CLIENTE, ADD_ORDINI } from "../../lib/payloads";
import {
  SET_INFO1,
  setInfo,
  fetchSelectClienti,
  fetchAllDelete,
  fetchSelectOfferte,
  fetchSelectAttivita,
  fetchSelectSiti,
  fetchSelectCommesse,
  fetchSelectResponsabili,
  setDataFetch,
  SET_DATA_LAVORI_PER_OFFERTE,
  fetchSelectLavori,
  fetchSelectFatture,
} from "../../redux/actions";
import {
  SET_CLASS2,
  SET_CLASS3,
  SET_CLASS4,
  SET_SHOW2,
  SET_SHOW3,
  SET_SHOW4,
  setClass,
  setShow,
} from "../../redux/actions";

const FormOrdini = () => {
  const dispatch = useDispatch();
  const [info, setInformazioni] = useState({});

  const info1 = useSelector((state) => state.modal.info1);
  const show1 = useSelector((state) => state.modal.show1);
  const show2 = useSelector((state) => state.modal.show2);
  const show3 = useSelector((state) => state.modal.show3);
  const show4 = useSelector((state) => state.modal.show4);
  const tipoModal = useSelector((state) => state.modal.type);

  const allOfferte = useSelector((state) => state.select.dataOfferte);
  const allClienti = useSelector((state) => state.select.dataCliente);
  const allFatture = useSelector((state) => state.select.dataFatture);
  const token = useSelector((state) => state.login.token);

  const allAttivita = useSelector((state) => state.select.dataAttivita);

  const allSiti = useSelector((state) => state.select.dataSiti);

  const allCommesse = useSelector((state) => state.select.dataCommesse);

  const allResponsabili = useSelector((state) => state.select.dataResponsabili);

  const allLavori = useSelector((state) => state.select.dataLavori);

  const [attivitaPerOfferta, setAttivitaPerOfferta] = useState({});
  const [attivitaPerOffertaModifica, setAttivitaPerOffertaModifica] = useState(
    []
  );

  const [tutteLeOfferteSonoUtilizzate, setTutteLeOfferteSonoUtilizzate] =
    useState(false);

  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [clienteSelezionato, setClienteSelezionato] = useState("");
  const [offerteFiltrate, setOfferteFiltrate] = useState([]);
  const [responsabiliFiltrati, setResponsabiliFiltrati] = useState([]);
  const [selezionateOfferte, setSelezionateOfferte] = useState([]);
  const [offertaAggiunta, setOffertaAggiunta] = useState(false);
  const [attivitaAccettate, setAttivitaAccettate] = useState({});
  const [sitiFiltrati, setSitiFiltrati] = useState([]);
  const [listaDaVedere, setListaDaVedere] = useState([]);

  const [lavoriPerOfferta, setLavoriPerOfferta] = useState([]);

  const [listaLavoriDiPartenza, setListaLavoriDiPartenza] = useState([]);
  const [lavoriModificati, setLavoriModificati] = useState([]);

  const [globalCommessa, setGlobalCommessa] = useState({});
  const [globalResponsabile, setGlobalResponsabile] = useState({});
  const [globalSito, setGlobalSito] = useState({});

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [offertaToRemove, setOffertaToRemove] = useState(null);

  const [userInteracted, setUserInteracted] = useState({});

  const invoicesForOrder = allFatture.filter(
    (fattura) => fattura.ordine == info.id
  );

  const confirmRemoveOfferta = (index) => {
    setOffertaToRemove(index);
    setShowConfirmationModal(true);
  };

  useEffect(() => {
    Object.keys(attivitaPerOfferta).forEach((index) => {
      if (
        globalCommessa.hasOwnProperty(index) ||
        globalSito.hasOwnProperty(index) ||
        globalResponsabile.hasOwnProperty(index)
      ) {
        attivitaPerOfferta[index].forEach((attivita) => {
          handleChangeLavori(
            attivita.attivita,
            "commessa",
            globalCommessa[index] || "",
            index
          );
          handleChangeLavori(
            attivita.attivita,
            "responsabile",
            globalResponsabile[index] || "",
            index
          );
          handleChangeLavori(
            attivita.attivita,
            "sito",
            globalSito[index] || "",
            index
          );
        });
      }
    });
  }, [globalCommessa, globalResponsabile, globalSito]);

  useEffect(() => {
    dispatch(fetchSelectClienti(token));
    dispatch(fetchSelectOfferte(token));
    dispatch(fetchSelectAttivita(token));
    dispatch(fetchSelectSiti(token));
    dispatch(fetchSelectCommesse(token));
    dispatch(fetchSelectResponsabili(token));
    dispatch(fetchSelectLavori(token));
    dispatch(fetchSelectFatture(token));
  }, []);

  useEffect(() => {
    dispatch(setInfo(SET_INFO1, info));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  useEffect(() => {
    changeInfo(lavoriPerOfferta, "listaLavori");
  }, [lavoriPerOfferta]);

  useEffect(() => {
    if (show1 && show2 && show3 && show4) {
      setInformazioni(ADD_ORDINI);
    } else if (show1 && show2 && show3) {
      setInformazioni(ADD_ORDINI);
    } else if (show1 && show2) {
      setInformazioni(ADD_ORDINI);
    } else {
      setInformazioni(info1);

      if (tipoModal === "modifica") {
        const offerteStringhe = info1.offerte.map(String);
        setSelezionateOfferte(offerteStringhe);
        setInformazioni({ ...info1, cliente: info1.clienteId });
        // setAttivitaAccettate(info1.attivita);
        const attivitaAccettateIniziale = offerteStringhe.reduce(
          (acc, offerta, index) => {
            // Converti l'ID dell'offerta in un numero per il confronto
            const offertaIdNumerico = parseInt(offerta);

            acc[index] = info1.attivita.reduce((attivitaAcc, attivitaId) => {
              if (
                allAttivita.some(
                  (attivita) =>
                    attivita.id === attivitaId &&
                    attivita.offerta === offertaIdNumerico
                )
              ) {
                attivitaAcc[attivitaId] = true;
              }
              return attivitaAcc;
            }, {});

            return acc;
          },
          {}
        );

        setAttivitaAccettate(attivitaAccettateIniziale);

        const lavoriAccettatiIniziali = allLavori.filter((lavoro) =>
          info1.lavori.includes(lavoro.id)
        );

        setLavoriPerOfferta(lavoriAccettatiIniziali); // TUTTI I LAVORI DA VISUALIZZARE (CHECKATI)
        setListaLavoriDiPartenza(lavoriAccettatiIniziali);

        setLavoriModificati(lavoriAccettatiIniziali);

        const attivitaPerOffertaIniziale = selezionateOfferte.map((offerta) =>
          allAttivita.filter((attivita) => attivita.offerta == offerta)
        );

        setAttivitaPerOffertaModifica(attivitaPerOffertaIniziale); // tutte le attivita o lavori delle offerte selezionate

        const arrayUnico = attivitaPerOffertaModifica.map((arrayDiAttivita) =>
          arrayDiAttivita.map((attivita) => {
            const lavoroCorrispondente = lavoriPerOfferta.find(
              (lavoro) => lavoro.attivita === attivita.id
            );
            if (lavoroCorrispondente) {
              return {
                protocollo: attivita.protocollo,
                denominazione: attivita.denominazione,
                impOfferta: attivita.importo,
                attivita: attivita.id,
                commessa: lavoroCorrispondente.commessa,
                responsabile: lavoroCorrispondente.responsabile,
                sito: lavoroCorrispondente.sito,
                importo: lavoroCorrispondente.importo,
              };
            } else {
              return {
                protocollo: attivita.protocollo,
                denominazione: attivita.denominazione,
                impOfferta: attivita.importo,
                attivita: attivita.id,
                commessa: "",
                responsabile: "",
                sito: "",
                importo: "",
              };
            }
          })
        );

        setListaDaVedere(arrayUnico);

        const offerteFiltratee = allOfferte.filter(
          (offerta) => offerta.cliente === info1.clienteId
        );
        setOfferteFiltrate(offerteFiltratee);

        const sitiFiltr = allSiti.filter(
          (sito) => sito.cliente == info1.clienteId
        );
        setSitiFiltrati(sitiFiltr);
        const responsabiliFiltr = allResponsabili.filter(
          (responsabile) => responsabile.cliente == info1.clienteId
        );
        setResponsabiliFiltrati(responsabiliFiltr);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allOfferte,
    allClienti,
    allAttivita,
    allSiti,
    allCommesse,
    allResponsabili,
    allLavori,
    allFatture,
  ]);

  useEffect(() => {
    if (clienteSelezionato) {
      const offerteFiltrate = allOfferte.filter(
        (offerta) => offerta.cliente === clienteSelezionato
      );
      setOfferteFiltrate(offerteFiltrate);

      const sitiFiltr = allSiti.filter(
        (sito) => sito.cliente === clienteSelezionato
      );
      setSitiFiltrati(sitiFiltr);

      const responsabiliFiltr = allResponsabili.filter(
        (responsabile) => responsabile.cliente == clienteSelezionato
      );
      setResponsabiliFiltrati(responsabiliFiltr);

      setIsFormDisabled(false);
    } else {
      setOfferteFiltrate([]);
      setIsFormDisabled(true);
    }
  }, [clienteSelezionato, allOfferte]);

  useEffect(() => {
    if (attivitaPerOfferta && tipoModal !== "modifica") {
      dispatch(setDataFetch(SET_DATA_LAVORI_PER_OFFERTE, attivitaPerOfferta));
    }
  }, [attivitaPerOfferta]);

  useEffect(() => {
    if (lavoriModificati && tipoModal == "modifica") {
      dispatch(setDataFetch(SET_DATA_LAVORI_PER_OFFERTE, lavoriModificati));
    }
  }, [lavoriModificati]);

  const handleClienteChange = (value) => {
    // Invece di impostare info.cliente come stringa, convertilo in un numero intero
    const clienteId = parseInt(value);
    changeInfo(clienteId, "cliente"); // Assicurati che "cliente" sia ora l'ID del cliente
    setClienteSelezionato(clienteId);
  };

  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };

  const aggiungiOfferta = () => {
    setSelezionateOfferte([...selezionateOfferte, ""]);
    setOffertaAggiunta(true);
  };

  const aggiungiOffertaDentroModifica = () => {
    const nuovaOfferta = "";

    setSelezionateOfferte((prevState) => [...prevState, nuovaOfferta]);

    setAttivitaPerOffertaModifica((prevState) => [...prevState, []]);

    // setListaDaVedere((prevState) => [
    //   ...prevState,
    //   [
    //     {
    //       protocollo: "",
    //       denominazione: "",
    //       impOfferta: 0,
    //       attivita: "",
    //       commessa: "",
    //       responsabile: "",
    //       sito: "",
    //       importo: 0,
    //     },
    //   ],
    // ]);

    // setAttivitaAccettate((prevState) => ({
    //   ...prevState,
    //   [prevState.length]: {},
    // }));

    setInformazioni((prevInfo) => ({
      ...prevInfo,
      offerte: [...prevInfo.offerte, nuovaOfferta],
    }));
  };

  const rimuoviOfferta = (index) => {
    const offerteAggiornate = selezionateOfferte.filter((_, i) => i !== index);
    setSelezionateOfferte(offerteAggiornate);

    // Rimuovi le attività associate dall'offerta rimossa
    const attivitaAggiornate = { ...attivitaAccettate };
    delete attivitaAggiornate[index];

    // Aggiorna le chiavi di attivitaAccettate
    const nuoveAttivitaAccettate = Object.keys(attivitaAggiornate).reduce(
      (newObj, key) => {
        const newKey =
          parseInt(key) > index ? parseInt(key) - 1 : parseInt(key);
        newObj[newKey] = attivitaAggiornate[key];
        return newObj;
      },
      {}
    );

    setAttivitaAccettate(nuoveAttivitaAccettate);

    // Aggiorna attivitaPerOfferta scalando gli indici delle offerte rimanenti
    const nuovaAttivitaPerOfferta = Object.keys(attivitaPerOfferta).reduce(
      (newObj, key) => {
        const newKey =
          parseInt(key) > index ? parseInt(key) - 1 : parseInt(key);
        if (parseInt(key) !== index) {
          // Evita di copiare l'offerta rimossa
          newObj[newKey] = attivitaPerOfferta[key];
        }
        return newObj;
      },
      {}
    );

    setAttivitaPerOfferta(nuovaAttivitaPerOfferta);

    // Se siamo in modalità modifica, elimina anche le attività da info
    if (tipoModal === "modifica") {
      const attivitaDaRimuovere = Object.keys(
        attivitaAccettate[index] || {}
      ).map((id) => parseInt(id));
      const infoAttivitaAggiornate = info.attivita.filter(
        (attivitaId) => !attivitaDaRimuovere.includes(attivitaId)
      );

      setInformazioni((prevInfo) => ({
        ...prevInfo,
        attivita: infoAttivitaAggiornate,
      }));
    }
  };

  const rimuoviOffertaModifica = (index) => {
    const offerteAggiornate = selezionateOfferte.filter((_, i) => i !== index);
    setSelezionateOfferte(offerteAggiornate);

    // Aggiorna attivitaPerOffertaModifica
    const nuoveAttivitaPerOffertaModifica = attivitaPerOffertaModifica.filter(
      (_, i) => i !== index
    );
    setAttivitaPerOffertaModifica(nuoveAttivitaPerOffertaModifica);

    // Aggiorna listaDaVedere
    const nuovaListaDaVedere = listaDaVedere.filter((_, i) => i !== index);
    setListaDaVedere(nuovaListaDaVedere);

    // Aggiorna attivitaAccettate scalando gli indici delle offerte successive
    const nuoveAttivitaAccettate = Object.entries(attivitaAccettate).reduce(
      (acc, [key, value]) => {
        const newKey =
          parseInt(key) > index ? parseInt(key) - 1 : parseInt(key);
        if (parseInt(key) !== index) {
          // Escludi l'offerta eliminata
          acc[newKey] = value;
        }
        return acc;
      },
      {}
    );
    setAttivitaAccettate(nuoveAttivitaAccettate);

    // Identifica le attività che sono ancora accettate
    const attivitaAttuali = Object.values(nuoveAttivitaAccettate).flatMap(
      (att) =>
        Object.entries(att)
          .filter(([_, accepted]) => accepted)
          .map(([id, _]) => parseInt(id))
    );

    // Aggiorna info.attivita e info.offerte per riflettere le offerte e le attività rimanenti
    setInformazioni((prevInfo) => ({
      ...prevInfo,
      offerte: offerteAggiornate.map((offerta) => parseInt(offerta)),
      attivita: attivitaAttuali,
    }));

    // Identifica e salva i lavori da eliminare
    const lavoriDaEliminare = info.listaLavori
      .filter((lavoro) => !attivitaAttuali.includes(lavoro.attivita))
      .map((lavoro) => lavoro.id);

    console.log("Lavori da eliminare:", lavoriDaEliminare);

    // Aggiorna info.lavori eliminando i lavori non più correlati a attività accettate
    setInformazioni((prevInfo) => ({
      ...prevInfo,
      lavori: prevInfo.lavori.filter(
        (lavoroId) => !lavoriDaEliminare.includes(lavoroId)
      ),
    }));

    setShowConfirmationModal(false); // Chiudi la modale di conferma
  };

  function handleChangeLavori(attivitaId, propName, newValue, offertaIndex) {
    setAttivitaPerOfferta((prevState) => {
      const newState = { ...prevState }; // Copia lo stato per trattarlo in modo immutabile
      const lavoriPerOfferta = newState[offertaIndex] || []; // Ottieni l'array di lavori per l'offerta specifica, se esiste

      // Trova l'indice del lavoro all'interno dell'array basato su attivitaId
      const lavoroIndex = lavoriPerOfferta.findIndex(
        (lavoro) => lavoro.attivita === attivitaId
      );
      if (lavoroIndex > -1) {
        // Se il lavoro esiste, crea un nuovo array con le modifiche
        const lavoriAggiornati = [
          ...lavoriPerOfferta.slice(0, lavoroIndex),
          { ...lavoriPerOfferta[lavoroIndex], [propName]: newValue },
          ...lavoriPerOfferta.slice(lavoroIndex + 1),
        ];
        newState[offertaIndex] = lavoriAggiornati; // Usa l'array aggiornato per l'offerta
      } else {
        // Gestisci il caso in cui il lavoro non esiste, se necessario
      }

      return newState; // Restituisce il nuovo stato aggiornato
    });
  }

  const handleChangeLavoriDentroModifica = (
    attivitaId,
    campo,
    valore,
    offertaIndex
  ) => {
    // Trova i dettagli dell'attività utilizzando attivitaId
    const dettagliAttivita = allAttivita.find(
      (attivita) => attivita.id === attivitaId
    ); // Aggiorna lavoriModificati

    setLavoriModificati((prevState) => {
      const index = prevState.findIndex(
        (lavoro) => lavoro.attivita === attivitaId
      );
      let newState;

      if (index !== -1) {
        // Aggiorna il lavoro esistente con i nuovi valori
        newState = prevState.map((lavoro, i) =>
          i === index ? { ...lavoro, [campo]: valore } : lavoro
        );
      } else {
        // Se non esiste, crea un nuovo lavoro con dettagli completi
        const nuovoLavoro = {
          attivita: attivitaId,
          protocollo: dettagliAttivita?.protocollo,
          denominazione: dettagliAttivita?.denominazione,
          impOfferta: dettagliAttivita?.importo,
          [campo]: valore,
        };
        newState = [...prevState, nuovoLavoro];
      }

      return newState;
    }); // Aggiorna listaDaVedere

    setListaDaVedere((prevState) => {
      const nuovaLista = [...prevState];

      if (nuovaLista[offertaIndex]) {
        const lavoriPerOfferta = nuovaLista[offertaIndex];
        const lavoroIndex = lavoriPerOfferta.findIndex(
          (lavoro) => lavoro.attivita === attivitaId
        );

        if (lavoroIndex !== -1) {
          // Aggiorna il lavoro esistente con i nuovi valori
          lavoriPerOfferta[lavoroIndex] = {
            ...lavoriPerOfferta[lavoroIndex],
            [campo]: valore,
          };
        } else {
          // Se il lavoro non esiste, crea un nuovo lavoro con dettagli completi
          const nuovoLavoro = {
            attivita: attivitaId,
            protocollo: dettagliAttivita?.protocollo,
            denominazione: dettagliAttivita?.denominazione,
            impOfferta: dettagliAttivita?.importo,
            [campo]: valore,
          };
          lavoriPerOfferta.push(nuovoLavoro);
        }
      }

      return nuovaLista;
    });
  };

  const handleOffertaChange = (value, index) => {
    const updatedOfferte = [...selezionateOfferte];
    updatedOfferte[index] = value;
    setSelezionateOfferte(updatedOfferte);

    const attivitaFiltrate = allAttivita.filter(
      (attivita) => attivita.offerta == value
    );

    const lavoriPerAttivita = attivitaFiltrate.map((a) => ({
      protocollo: a.protocollo,
      denominazione: a.denominazione,
      impOfferta: a.importo,
      attivita: a.id,
      commessa: "",
      responsabile: "",
      sito: "",
      importo: "",
    }));

    setAttivitaPerOfferta((prevState) => ({
      ...prevState,
      [index]: lavoriPerAttivita,
    }));
    // Svuota le attività selezionate per questa offerta
    setAttivitaAccettate((prevState) => ({
      ...prevState,
      [index]: {},
    }));
  };

  const handleOffertaChangeDentroModifica = (value, index) => {
    const updatedOfferte = [...selezionateOfferte];
    updatedOfferte[index] = value;
    setSelezionateOfferte(updatedOfferte);

    const attivitaFiltrate = allAttivita.filter(
      (attivita) => attivita.offerta == value
    );

    const lavoriPerAttivita = attivitaFiltrate.map((a) => ({
      protocollo: a.protocollo,
      denominazione: a.denominazione,
      impOfferta: a.importo,
      attivita: a.id,
      commessa: "",
      responsabile: "",
      sito: "",
      importo: "",
    }));

    setAttivitaPerOffertaModifica((prevState) => {
      const newState = [...prevState];
      newState[index] = attivitaFiltrate; // Aggiorna solo l'elemento all'indice corretto
      return newState;
    });

    setListaDaVedere((prevState) => {
      const newState = [...prevState];
      newState[index] = lavoriPerAttivita; // Aggiorna solo l'elemento all'indice corretto
      return newState;
    });

    setAttivitaAccettate((prevState) => ({
      ...prevState,
      [index]: {},
    }));

    // Aggiorna le informazioni dell'ordine con le nuove offerte e attività
    const attivitaAccettateIds = Object.values(attivitaAccettate).flatMap(
      (attivitaMap) =>
        Object.entries(attivitaMap)
          .filter(([_, isChecked]) => isChecked)
          .map(([attivitaId, _]) => parseInt(attivitaId))
    );

    setInformazioni((prevInfo) => ({
      ...prevInfo,
      offerte: updatedOfferte
        .filter((offerta) => offerta !== "")
        .map((offerta) => parseInt(offerta)),
      attivita: attivitaAccettateIds,
    }));
  };

  const getFilteredOfferteOptions = (index) => {
    return offerteFiltrate.filter(
      (offerta) =>
        !selezionateOfferte.includes(offerta.id.toString()) ||
        selezionateOfferte[index] === offerta.id.toString()
    );
  };

  const getFilteredOfferta = (offerta) =>
    allOfferte.filter((offertaInd) => offertaInd.id == offerta);

  const areAllOfferteUsed = () => {
    return offerteFiltrate.every((offerta) =>
      selezionateOfferte.includes(offerta.id.toString())
    );
  };

  useEffect(() => {
    setTutteLeOfferteSonoUtilizzate(
      offerteFiltrate.every((offerta) =>
        selezionateOfferte.includes(offerta.id.toString())
      )
    );
  }, [selezionateOfferte]);

  const handleAttivitaCheckDentroModifica = (
    attivitaId,
    offertaIndex,
    isChecked
  ) => {
    // Aggiorna attivitaAccettate
    setAttivitaAccettate((prevState) => ({
      ...prevState,
      [offertaIndex]: {
        ...prevState[offertaIndex],
        [attivitaId]: isChecked,
      },
    }));

    // Aggiorna info.attivita in base alla selezione/deselezione
    let updatedAttivita = [...info.attivita];
    let updatedLavori = [...info.lavori];

    if (isChecked) {
      updatedAttivita = [...updatedAttivita, attivitaId];
      // Aggiungi i lavori associati a questa attività
      const lavoriAssociati = listaLavoriDiPartenza
        .filter((lavoro) => lavoro.attivita === attivitaId)
        .map((lavoro) => lavoro.id);
      updatedLavori = [...new Set([...updatedLavori, ...lavoriAssociati])]; // Unisci evitando duplicati
    } else {
      updatedAttivita = updatedAttivita.filter((id) => id !== attivitaId);
      // Rimuovi i lavori associati a questa attività
      const lavoriDaRimuovere = listaLavoriDiPartenza
        .filter((lavoro) => lavoro.attivita === attivitaId)
        .map((lavoro) => lavoro.id);
      updatedLavori = updatedLavori.filter(
        (id) => !lavoriDaRimuovere.includes(id)
      );
    }

    // Aggiorna lo stato con le nuove selezioni
    setInformazioni((prevInfo) => ({
      ...prevInfo,
      attivita: updatedAttivita,
      lavori: updatedLavori,
    }));

    const dettagliAttivita = allAttivita.find(
      (attivita) => attivita.id == attivitaId
    );

    if (isChecked && tipoModal !== "modifica") {
      // Imposta l'importo al valore di impOfferta dell'attività quando viene selezionata
      handleChangeLavori(
        attivitaId,
        "importo",
        dettagliAttivita.importo,
        offertaIndex
      );
    }

    // Inizialmente, cerca in listaDaVedere per i dettagli del lavoro
    let dettagliLavoro =
      listaDaVedere[offertaIndex]?.find(
        (lavoro) => lavoro.attivita === attivitaId
      ) || {};

    // Cerca in lavoriModificati per un lavoro esistente con l'attivitaId corrispondente
    const lavoroEsistente = listaLavoriDiPartenza.find(
      (lavoro) => lavoro.attivita === attivitaId
    );

    // Se esiste, aggiungi l'id a dettagliLavoro
    if (lavoroEsistente) {
      dettagliLavoro = { ...dettagliLavoro, id: lavoroEsistente.id };
    }

    // Aggiorna lavoriModificati in base alla selezione/deselezione
    if (isChecked && tipoModal == "modifica") {
      // Se il check è true, crea un nuovo oggetto lavoro con dettagli completi e aggiungi a lavoriModificati
      const nuovoLavoro = {
        ...dettagliLavoro, // Usa dettagliLavoro che include ora l'id se disponibile
        attivita: attivitaId,
        protocollo: dettagliAttivita?.protocollo,
        denominazione: dettagliAttivita?.denominazione,
        impOfferta: dettagliAttivita?.importo,
      };

      setLavoriModificati((prevState) => [...prevState, nuovoLavoro]);
    } else if (!isChecked && tipoModal == "modifica") {
      // Se il check è false, rimuovi il lavoro corrispondente da lavoriModificati
      setLavoriModificati((prevState) =>
        prevState.filter((lavoro) => lavoro.attivita !== attivitaId)
      );
    }
  };

  const areAllActivitiesSelected = (offertaIndex) => {
    const attivitaCurrentOfferta = attivitaPerOfferta[offertaIndex] || [];
    const attivitaState = attivitaAccettate[offertaIndex] || {};
    return attivitaCurrentOfferta.every(
      (attivita) => attivitaState[attivita.attivita] === true
    );
  };

  const areAllActivitiesSelectedDentroModifica = (offertaIndex) => {
    const attivitaCurrentOfferta =
      attivitaPerOffertaModifica[offertaIndex] || [];
    const attivitaState = attivitaAccettate[offertaIndex] || {};

    return attivitaCurrentOfferta.every(
      (attivita) => attivitaState[attivita.id] === true
    );
  };

  const handleGlobalCheck = (offertaIndex, isChecked, globalValues) => {
    const attivitaCurrentOfferta = attivitaPerOfferta[offertaIndex] || [];
    const newAttivitaState = {};

    attivitaCurrentOfferta.forEach((attivita) => {
      newAttivitaState[attivita.attivita] = isChecked;
    });

    setAttivitaAccettate((prevState) => ({
      ...prevState,
      [offertaIndex]: newAttivitaState,
    }));

    attivitaCurrentOfferta.forEach((attivita) => {
      if (isChecked) {
        handleChangeLavori(
          attivita.attivita,
          "importo",
          attivita.impOfferta,
          offertaIndex
        );
        handleChangeLavori(
          attivita.attivita,
          "commessa",
          globalValues.commessa,
          offertaIndex
        );
        handleChangeLavori(
          attivita.attivita,
          "responsabile",
          globalValues.responsabile,
          offertaIndex
        );
        handleChangeLavori(
          attivita.attivita,
          "sito",
          globalValues.sito,
          offertaIndex
        );
      } else {
        // Resetta i valori se il check è false
        handleChangeLavori(attivita.attivita, "importo", "", offertaIndex);
        handleChangeLavori(attivita.attivita, "commessa", "", offertaIndex);
        handleChangeLavori(attivita.attivita, "responsabile", "", offertaIndex);
        handleChangeLavori(attivita.attivita, "sito", "", offertaIndex);
      }
    });
  };

  const handleGlobalChange = (value, type, offertaIndex) => {
    // Aggiorna lo stato globale in base al tipo
    const updateState = {
      commessa: setGlobalCommessa,
      responsabile: setGlobalResponsabile,
      sito: setGlobalSito,
    };
    updateState[type]((prev) => ({ ...prev, [offertaIndex]: value }));
  };

  const handleGlobalCheckDentroModifica = (
    offertaIndex,
    isChecked,
    globalValues
  ) => {
    const attivitaCurrentOfferta = listaDaVedere[offertaIndex] || [];

    // Aggiorna lo stato di accettazione di ogni attività basato sul valore isChecked
    const newAttivitaState = {};
    let updatedAttivita = [...info.attivita];

    attivitaCurrentOfferta.forEach((attivita) => {
      newAttivitaState[attivita.attivita] = isChecked;

      // Aggiorna info.attivita in base alla selezione/deselezione
      if (isChecked) {
        if (!updatedAttivita.includes(attivita.attivita)) {
          updatedAttivita.push(attivita.attivita);
        }
      } else {
        updatedAttivita = updatedAttivita.filter(
          (id) => id !== attivita.attivita
        );
      }

      // Applica le modifiche ai valori globali
      handleChangeLavoriDentroModifica(
        attivita.attivita,
        "commessa",
        isChecked ? globalValues.commessa : "",
        offertaIndex
      );
      handleChangeLavoriDentroModifica(
        attivita.attivita,
        "responsabile",
        isChecked ? globalValues.responsabile : "",
        offertaIndex
      );
      handleChangeLavoriDentroModifica(
        attivita.attivita,
        "sito",
        isChecked ? globalValues.sito : "",
        offertaIndex
      );
    });

    // Aggiorna lo stato globale delle attività accettate
    setAttivitaAccettate((prevState) => ({
      ...prevState,
      [offertaIndex]: newAttivitaState,
    }));

    // Aggiorna info.attivita nel stato globale
    setInformazioni((prevInfo) => ({
      ...prevInfo,
      attivita: updatedAttivita,
    }));
  };

  const handleGlobalChangeDentroModifica = (value, type, offertaIndex) => {
    // Aggiorna lo stato globale in base al tipo
    const updateState = {
      commessa: setGlobalCommessa,
      responsabile: setGlobalResponsabile,
      sito: setGlobalSito,
    };
    updateState[type]((prev) => ({ ...prev, [offertaIndex]: value }));

    // Applica le modifiche globali alle attività esistenti
    if (listaDaVedere[offertaIndex]) {
      listaDaVedere[offertaIndex].forEach((attivita) => {
        handleChangeLavoriDentroModifica(
          attivita.attivita,
          type,
          value,
          offertaIndex
        );
      });
    }
  };

  useEffect(() => {
    setInformazioni((prevInfo) => ({
      ...prevInfo,
      offerte: selezionateOfferte
        .filter((offerta) => offerta !== "")
        .map((offerta) => parseInt(offerta)),
    }));
  }, [selezionateOfferte]);

  // Quando cambiano le attività accettate, aggiorna info.attivita
  useEffect(() => {
    if (tipoModal === "aggiungi") {
      const attivitaAccettateIds = Object.values(attivitaAccettate).flatMap(
        (attivitaMap) =>
          Object.entries(attivitaMap)
            .filter(([_, isChecked]) => isChecked)
            .map(([attivitaId, _]) => parseInt(attivitaId))
      );

      setInformazioni((prevInfo) => ({
        ...prevInfo,
        attivita: attivitaAccettateIds,
      }));
    }
  }, [attivitaAccettate]);

  const calculateResiduoDaFatturare = () => {
    // Calculate the total of 'imponibile' for all invoices related to this order
    const totalImponibile = allFatture
      .filter((fattura) => fattura.ordine === info.id)
      .reduce((total, fattura) => {
        const imponibile = parseFloat(fattura.imponibile);
        return total + (isNaN(imponibile) ? 0 : imponibile);
      }, 0);

    // Parse the importo_oda as a floating-point number
    const importoODA = parseFloat(info.importo_oda);

    // Calculate the residual amount to be invoiced
    const residuoDaFatturare = importoODA - totalImponibile;

    // Format the result to two decimal places
    return residuoDaFatturare.toFixed(2);
  };

  const calculateResiduoDaIncassare = () => {
    // Calculate the total of 'imponibile' for all invoices related to this order that are not collected
    const totalNonIncassato = allFatture
      .filter((fattura) => fattura.ordine === info.id && !fattura.incassata)
      .reduce((total, fattura) => {
        const imponibile = parseFloat(fattura.imponibile);
        return total + (isNaN(imponibile) ? 0 : imponibile);
      }, 0);

    // Calculate the residual amount to be collected
    const residuoDaIncassare =
      parseFloat(calculateResiduoDaFatturare()) + totalNonIncassato;

    // Format the result to two decimal places
    return residuoDaIncassare.toFixed(2);
  };

  return (
    <Row>
      {tipoModal === "modifica" && (
        <Col xs="4">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              N° Protocollo *
            </Form.Label>
            <Form.Control
              type="text"
              value={info.protocollo}
              onChange={(e) => changeInfo(e.target.value, "numero_oda")}
              disabled
            />
          </Form.Group>
        </Col>
      )}
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label
            column
            sm="2"
            className="d-flex justify-content-between align-items-center"
          >
            Cliente *
          </Form.Label>
          <Form.Select
            value={info.cliente}
            className="custom-select"
            onChange={(e) => {
              changeInfo(e.target.value, "cliente");
              handleClienteChange(e.target.value);
            }}
            aria-label="Seleziona Cliente"
            required
            disabled={offertaAggiunta || tipoModal === "modifica"}
          >
            <option value="" disabled hidden>
              Seleziona Cliente
            </option>
            {allClienti &&
              allClienti.map((c, index) => (
                <option key={index} value={c.id}>
                  {c.nome} {c.cognome}
                </option>
              ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            N° ODA *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.numero_oda}
            onChange={(e) => changeInfo(e.target.value, "numero_oda")}
            placeholder="Inserisci numero ODA"
          />
        </Form.Group>
      </Col>

      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Importo ODA *
          </Form.Label>
          <div className="d-flex align-items-center">
            <span style={{ marginRight: "8px" }}>€</span>
            <Form.Control
              type="number"
              value={info.importo_oda}
              onChange={(e) => changeInfo(e.target.value, "importo_oda")}
              placeholder="Campo autogenerato"
              disabled
            />
          </div>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Importo stimato *
          </Form.Label>
          <div className="d-flex align-items-center">
            <span style={{ marginRight: "8px" }}>€</span>
            <Form.Control
              type="number"
              value={info.importo_totale}
              onChange={(e) => changeInfo(e.target.value, "importo_totale")}
              placeholder="Campo autogenerato"
              disabled
            />
          </div>
        </Form.Group>
      </Col>

      {tipoModal === "modifica" ? (
        <>
          <Col
            xs="12"
            className="d-flex justify-content-between align-items-center mb-3"
          >
            <h5>Offerte Selezionate</h5>
            <Button
              disabled={
                offerteFiltrate.length == 0 || tutteLeOfferteSonoUtilizzate
              }
              variant={
                offerteFiltrate.length == 0 || tutteLeOfferteSonoUtilizzate
                  ? "secondary"
                  : "primary"
              }
              onClick={aggiungiOffertaDentroModifica}
            >
              <i className="bi bi-plus-circle-fill"></i>
            </Button>
          </Col>
          {selezionateOfferte.map((offerta, offertaIndex) => (
            <Col xs="12" key={offertaIndex} className="mb-3">
              <div
                style={{
                  backgroundColor: "lightgray",
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                <Form.Group className="d-flex align-items-center mb-3">
                  <Form.Select
                    value={offerta}
                    onChange={(e) =>
                      handleOffertaChangeDentroModifica(
                        e.target.value,
                        offertaIndex
                      )
                    }
                    disabled={offerteFiltrate.length === 0}
                    className="me-3"
                    style={{ width: "50%", flexGrow: 0 }}
                  >
                    <option value="" disabled hidden>
                      Seleziona Offerta
                    </option>
                    {getFilteredOfferteOptions(offertaIndex).map(
                      (offertaFiltrata) => (
                        <option
                          key={offertaFiltrata.id}
                          value={offertaFiltrata.id}
                        >
                          {offertaFiltrata.protocollo}
                        </option>
                      )
                    )}
                  </Form.Select>
                  <Button
                    variant="primary"
                    onClick={() => confirmRemoveOfferta(offertaIndex)}
                  >
                    <i className="bi bi-dash-circle-fill"></i>
                  </Button>
                </Form.Group>
                {getFilteredOfferta(offerta).map((offertaFiltrata) => {
                  return (
                    offertaFiltrata.n_contratto && (
                      <h6>Numero contratto : {offertaFiltrata.n_contratto}</h6>
                    )
                  );
                })}
                <div className="container p-0">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th
                          style={{ width: "3%" }}
                          className="thin-column justify-content-center"
                        ></th>
                        <th
                          style={{ width: "10%" }}
                          className="justify-content-center"
                        >
                          Protocollo
                        </th>
                        <th
                          style={{ width: "13%" }}
                          className="justify-content-center"
                        >
                          {" "}
                          Denominazione
                        </th>
                        <th
                          style={{ width: "8%" }}
                          className="justify-content-center"
                        >
                          Imp. offerta
                        </th>
                        <th
                          style={{ width: "13%" }}
                          className="justify-content-center"
                        >
                          Commessa
                        </th>
                        <th
                          style={{ width: "13%" }}
                          className="justify-content-center"
                        >
                          Ref. tecnico
                        </th>
                        <th
                          style={{ width: "13%" }}
                          className="justify-content-center"
                        >
                          Sito
                        </th>
                        <th
                          style={{ width: "10%" }}
                          className="justify-content-center"
                        >
                          Imp. ordine
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {attivitaPerOffertaModifica.length > 0 &&
                        attivitaPerOffertaModifica[offertaIndex].length > 0 && (
                          <tr>
                            <td>
                              <Form.Check
                                type="checkbox"
                                checked={
                                  userInteracted[offertaIndex]
                                    ? areAllActivitiesSelectedDentroModifica(
                                        offertaIndex
                                      )
                                    : false
                                }
                                onChange={(e) => {
                                  setUserInteracted({
                                    ...userInteracted,
                                    [offertaIndex]: true,
                                  });
                                  handleGlobalCheckDentroModifica(
                                    offertaIndex,
                                    e.target.checked,
                                    {
                                      commessa: globalCommessa[offertaIndex],
                                      responsabile:
                                        globalResponsabile[offertaIndex],
                                      sito: globalSito[offertaIndex],
                                    }
                                  );
                                }}
                              />
                            </td>
                            <td colSpan="3" className="text-center">
                              Applica a tutti
                            </td>
                            <td className="text-center">
                              <Form.Select
                                value={globalCommessa[offertaIndex]}
                                disabled={
                                  userInteracted[offertaIndex]
                                    ? !areAllActivitiesSelectedDentroModifica(
                                        offertaIndex
                                      )
                                    : true
                                }
                                onChange={(e) =>
                                  handleGlobalChangeDentroModifica(
                                    e.target.value,
                                    "commessa",
                                    offertaIndex
                                  )
                                }
                              >
                                <option value="">Seleziona Commessa</option>
                                {allCommesse.map((commessa) => (
                                  <option key={commessa.id} value={commessa.id}>
                                    {commessa.protocollo}
                                  </option>
                                ))}
                              </Form.Select>
                            </td>
                            <td className="text-center">
                              <Form.Select
                                value={globalResponsabile[offertaIndex]}
                                onChange={(e) =>
                                  handleGlobalChangeDentroModifica(
                                    e.target.value,
                                    "responsabile",
                                    offertaIndex
                                  )
                                }
                                disabled={
                                  userInteracted[offertaIndex]
                                    ? !areAllActivitiesSelectedDentroModifica(
                                        offertaIndex
                                      )
                                    : true
                                }
                              >
                                <option value="">Seleziona Responsabile</option>
                                {responsabiliFiltrati.map((responsabile) => (
                                  <option
                                    key={responsabile.id}
                                    value={responsabile.id}
                                  >
                                    {responsabile.nome} {responsabile.cognome}
                                  </option>
                                ))}
                              </Form.Select>
                            </td>
                            <td className="text-center">
                              <Form.Select
                                value={globalSito[offertaIndex]}
                                onChange={(e) =>
                                  handleGlobalChangeDentroModifica(
                                    e.target.value,
                                    "sito",
                                    offertaIndex
                                  )
                                }
                                disabled={
                                  userInteracted[offertaIndex]
                                    ? !areAllActivitiesSelectedDentroModifica(
                                        offertaIndex
                                      )
                                    : true
                                }
                              >
                                <option value="">Seleziona Sito</option>
                                {sitiFiltrati.map((sito) => (
                                  <option key={sito.id} value={sito.id}>
                                    {sito.sito_o_punto_vendita}
                                  </option>
                                ))}
                              </Form.Select>
                            </td>
                            <td className="text-center">
                              <Form.Control
                                type="text"
                                placeholder=""
                                disabled
                              />
                            </td>
                          </tr>
                        )}
                      {listaDaVedere[offertaIndex] &&
                        listaDaVedere[offertaIndex].map((attivita) => {
                          return (
                            <tr key={attivita.attivita}>
                              <td className="align-middle">
                                <Form.Check
                                  type="checkbox"
                                  checked={
                                    attivitaAccettate[offertaIndex]?.[
                                      attivita.attivita
                                    ] || false
                                  }
                                  onChange={(e) =>
                                    handleAttivitaCheckDentroModifica(
                                      attivita.attivita,
                                      offertaIndex,
                                      e.target.checked
                                    )
                                  }
                                />
                              </td>
                              <td className="align-middle">
                                {attivita.protocollo}
                              </td>
                              <td className="align-middle">
                                {attivita.denominazione}
                              </td>
                              <td className="align-middle">
                                {"€ " + attivita.impOfferta}
                              </td>
                              <td className="align-middle">
                                <Form.Select
                                  value={attivita.commessa}
                                  onChange={(e) =>
                                    handleChangeLavoriDentroModifica(
                                      attivita.attivita,
                                      "commessa",
                                      e.target.value,
                                      offertaIndex
                                    )
                                  }
                                  required={
                                    !!attivitaAccettate[offertaIndex]?.[
                                      attivita.attivita
                                    ]
                                  }
                                  disabled={
                                    !attivitaAccettate[offertaIndex]?.[
                                      attivita.attivita
                                    ] || allCommesse.length === 1
                                  }
                                >
                                  <option value="" disabled hidden>
                                    Seleziona Commessa
                                  </option>
                                  {allCommesse.map((commessa) => (
                                    <option
                                      key={commessa.id}
                                      value={commessa.id}
                                    >
                                      {commessa.protocollo}
                                    </option>
                                  ))}
                                </Form.Select>
                              </td>
                              <td className="align-middle">
                                <Form.Select
                                  value={attivita.responsabile}
                                  onChange={(e) =>
                                    handleChangeLavoriDentroModifica(
                                      attivita.attivita,
                                      "responsabile",
                                      e.target.value,
                                      offertaIndex
                                    )
                                  }
                                  required={
                                    !!attivitaAccettate[offertaIndex]?.[
                                      attivita.attivita
                                    ]
                                  }
                                  disabled={
                                    !attivitaAccettate[offertaIndex]?.[
                                      attivita.attivita
                                    ]
                                  }
                                >
                                  <option value="" disabled hidden>
                                    Seleziona Responsabile
                                  </option>
                                  {responsabiliFiltrati.map((responsabile) => (
                                    <option
                                      key={responsabile.id}
                                      value={responsabile.id}
                                    >
                                      {responsabile.nome} {responsabile.cognome}
                                    </option>
                                  ))}
                                </Form.Select>
                              </td>
                              <td className="align-middle">
                                <Form.Select
                                  value={attivita.sito}
                                  onChange={(e) =>
                                    handleChangeLavoriDentroModifica(
                                      attivita.attivita,
                                      "sito",
                                      e.target.value,
                                      offertaIndex
                                    )
                                  }
                                  required={
                                    !!attivitaAccettate[offertaIndex]?.[
                                      attivita.attivita
                                    ]
                                  }
                                  disabled={
                                    !attivitaAccettate[offertaIndex]?.[
                                      attivita.attivita
                                    ] || sitiFiltrati.length === 1
                                  }
                                >
                                  <option value="" disabled hidden>
                                    Seleziona Sito
                                  </option>
                                  {sitiFiltrati.map((sito) => {
                                    return (
                                      <option key={sito.id} value={sito.id}>
                                        {sito.sito_o_punto_vendita}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </td>
                              <td className="align-middle">
                                <div className="d-flex align-items-center">
                                  <span style={{ marginRight: "8px" }}>€</span>
                                  <Form.Control
                                    type="number"
                                    value={attivita.importo}
                                    onChange={(e) =>
                                      handleChangeLavoriDentroModifica(
                                        attivita.attivita,
                                        "importo",
                                        e.target.value,
                                        offertaIndex
                                      )
                                    }
                                    required={
                                      !!attivitaAccettate[offertaIndex]?.[
                                        attivita.attivita
                                      ]
                                    }
                                    disabled={
                                      !attivitaAccettate[offertaIndex]?.[
                                        attivita.attivita
                                      ]
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          ))}
          {invoicesForOrder.length > 0 ? (
            <>
              <Col
                xs="12"
                className="d-flex justify-content-between align-items-center mb-2"
              >
                <h5 className="mb-0">Lista Fatture</h5>
              </Col>
              <div className="mb-3">
                {invoicesForOrder.map((fattura, index) => (
                  <div
                    key={index}
                    className="d-flex flex-wrap align-items-center mb-1"
                  >
                    <span className="me-2">
                      <strong>N° Fattura:</strong> {fattura.n_fattura}
                    </span>
                    <span className="me-2">
                      <strong>Data Emissione:</strong> {fattura.data_emissione}
                    </span>
                    <span className="me-2">
                      <strong>Data Scadenza:</strong> {fattura.scadenza}
                    </span>
                    <span className="me-2">
                      <strong>Imponibile:</strong> € {fattura.imponibile}
                    </span>
                    <span className="me-2">
                      <strong>Incassata:</strong>{" "}
                      {fattura.incassata ? "Sì" : "No"}
                    </span>
                  </div>
                ))}
              </div>
              <Col
                xs="12"
                className="d-flex justify-content-between align-items-center mb-3"
              >
                <h5 className="mb-0">
                  Residuo da Fatturare: € {calculateResiduoDaFatturare()}
                </h5>
              </Col>
              <Col
                xs="12"
                className="d-flex justify-content-between align-items-center mb-3"
              >
                <h5 className="mb-0">
                  Residuo da Incassare: € {calculateResiduoDaIncassare()}
                </h5>
              </Col>
            </>
          ) : (
            <Col xs="12" className="mb-3">
              <div className="alert alert-warning" role="alert">
                Non sono state registrate fatture per questo ordine.
              </div>
            </Col>
          )}
        </>
      ) : (
        <>
          <Col
            xs="12"
            className="d-flex justify-content-between align-items-center mb-3"
          >
            <h5>Offerte Selezionate</h5>
            <Button
              disabled={
                isFormDisabled ||
                offerteFiltrate.length == 0 ||
                areAllOfferteUsed()
              }
              variant={
                isFormDisabled ||
                offerteFiltrate.length == 0 ||
                areAllOfferteUsed()
                  ? "secondary"
                  : "primary"
              }
              onClick={aggiungiOfferta}
            >
              <i className="bi bi-plus-circle-fill"></i>
            </Button>
          </Col>

          {selezionateOfferte.map((offerta, offertaIndex) => (
            <Col xs="12" key={offertaIndex} className="mb-3">
              <div
                style={{
                  backgroundColor: "lightgray",
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                <Form.Group className="d-flex align-items-center mb-3">
                  <Form.Select
                    value={offerta}
                    onChange={(e) =>
                      handleOffertaChange(e.target.value, offertaIndex)
                    }
                    disabled={isFormDisabled || offerteFiltrate.length === 0}
                    className="me-3"
                    style={{ width: "50%", flexGrow: 0 }}
                  >
                    <option value="" disabled hidden>
                      Seleziona Offerta
                    </option>
                    {getFilteredOfferteOptions(offertaIndex).map(
                      (offertaFiltrata) => (
                        <option
                          key={offertaFiltrata.id}
                          value={offertaFiltrata.id}
                        >
                          {offertaFiltrata.protocollo}
                        </option>
                      )
                    )}
                  </Form.Select>
                  <Button
                    className="btn-primary"
                    onClick={() => rimuoviOfferta(offertaIndex)}
                  >
                    <i className="bi bi-dash-circle-fill"></i>
                  </Button>
                </Form.Group>

                {getFilteredOfferta(offerta).map((offertaFiltrata) => {
                  return (
                    offertaFiltrata.n_contratto && (
                      <h6>Numero contratto : {offertaFiltrata.n_contratto}</h6>
                    )
                  );
                })}

                {selezionateOfferte[offertaIndex] && (
                  <div className="container p-0">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            style={{ width: "3%" }}
                            className="thin-column justify-content-center"
                          ></th>
                          <th
                            style={{ width: "10%" }}
                            className="justify-content-center"
                          >
                            Protocollo
                          </th>
                          <th
                            style={{ width: "13%" }}
                            className="justify-content-center"
                          >
                            {" "}
                            Denominazione
                          </th>
                          <th
                            style={{ width: "8%" }}
                            className="justify-content-center"
                          >
                            Imp. offerta
                          </th>
                          <th
                            style={{ width: "13%" }}
                            className="justify-content-center"
                          >
                            Commessa
                          </th>
                          <th
                            style={{ width: "13%" }}
                            className="justify-content-center"
                          >
                            Ref. tecnico
                          </th>
                          <th
                            style={{ width: "13%" }}
                            className="justify-content-center"
                          >
                            Sito
                          </th>
                          <th
                            style={{ width: "10%" }}
                            className="justify-content-center"
                          >
                            Imp. ordine
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {attivitaPerOfferta[offertaIndex].length > 0 && (
                          <tr>
                            <td>
                              <Form.Check
                                type="checkbox"
                                checked={areAllActivitiesSelected(offertaIndex)}
                                onChange={(e) =>
                                  handleGlobalCheck(
                                    offertaIndex,
                                    e.target.checked,
                                    {
                                      commessa: globalCommessa[offertaIndex],
                                      responsabile:
                                        globalResponsabile[offertaIndex],
                                      sito: globalSito[offertaIndex],
                                    }
                                  )
                                }
                              />
                            </td>
                            <td colSpan="3" className="text-center">
                              Applica a tutti
                            </td>
                            <td className="text-center">
                              <Form.Select
                                value={globalCommessa[offertaIndex]}
                                onChange={(e) =>
                                  handleGlobalChange(
                                    e.target.value,
                                    "commessa",
                                    offertaIndex
                                  )
                                }
                                disabled={
                                  !areAllActivitiesSelected(offertaIndex)
                                }
                              >
                                <option value="">Seleziona Commessa</option>
                                {allCommesse.map((commessa) => (
                                  <option key={commessa.id} value={commessa.id}>
                                    {commessa.protocollo}
                                  </option>
                                ))}
                              </Form.Select>
                            </td>
                            <td className="text-center">
                              <Form.Select
                                value={globalResponsabile[offertaIndex]}
                                onChange={(e) =>
                                  handleGlobalChange(
                                    e.target.value,
                                    "responsabile",
                                    offertaIndex
                                  )
                                }
                                disabled={
                                  !areAllActivitiesSelected(offertaIndex)
                                }
                              >
                                <option value="">Seleziona Responsabile</option>
                                {responsabiliFiltrati.map((responsabile) => (
                                  <option
                                    key={responsabile.id}
                                    value={responsabile.id}
                                  >
                                    {responsabile.nome} {responsabile.cognome}
                                  </option>
                                ))}
                              </Form.Select>
                            </td>
                            <td className="text-center">
                              <Form.Select
                                value={globalSito[offertaIndex]}
                                onChange={(e) =>
                                  handleGlobalChange(
                                    e.target.value,
                                    "sito",
                                    offertaIndex
                                  )
                                }
                                disabled={
                                  !areAllActivitiesSelected(offertaIndex)
                                }
                              >
                                <option value="">Seleziona Sito</option>
                                {sitiFiltrati.map((sito) => (
                                  <option key={sito.id} value={sito.id}>
                                    {sito.sito_o_punto_vendita}
                                  </option>
                                ))}
                              </Form.Select>
                            </td>
                            <td className="text-center">
                              <Form.Control
                                type="text"
                                placeholder=""
                                disabled
                              />
                            </td>
                          </tr>
                        )}
                        {attivitaPerOfferta[offertaIndex] &&
                          attivitaPerOfferta[offertaIndex].map((attivita) => (
                            <tr key={attivita.attivita}>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  checked={
                                    attivitaAccettate[offertaIndex]?.[
                                      attivita.attivita
                                    ] || false
                                  }
                                  onChange={(e) =>
                                    handleAttivitaCheckDentroModifica(
                                      attivita.attivita,
                                      offertaIndex,
                                      e.target.checked
                                    )
                                  }
                                />
                              </td>
                              <td className="align-middle">
                                {attivita.protocollo}
                              </td>
                              <td className="align-middle">
                                {attivita.denominazione}
                              </td>
                              <td className="align-middle">{`€ ${attivita.impOfferta}`}</td>
                              <td className="align-middle">
                                <Form.Select
                                  value={attivita.commessa}
                                  onChange={(e) =>
                                    handleChangeLavori(
                                      attivita.attivita,
                                      "commessa",
                                      e.target.value,
                                      offertaIndex
                                    )
                                  }
                                  disabled={
                                    !attivitaAccettate[offertaIndex]?.[
                                      attivita.attivita
                                    ] || allCommesse.length === 1
                                  }
                                  required={
                                    !!attivitaAccettate[offertaIndex]?.[
                                      attivita.attivita
                                    ]
                                  }
                                >
                                  <option value="" disabled hidden>
                                    seleziona commessa
                                  </option>
                                  {allCommesse.map((commessa) => (
                                    <option
                                      key={commessa.id}
                                      value={commessa.id}
                                    >
                                      {commessa.protocollo}
                                    </option>
                                  ))}
                                </Form.Select>
                              </td>
                              <td className="align-middle">
                                <Form.Select
                                  value={attivita.responsabile}
                                  onChange={(e) =>
                                    handleChangeLavori(
                                      attivita.attivita,
                                      "responsabile",
                                      e.target.value,
                                      offertaIndex
                                    )
                                  }
                                  disabled={
                                    !attivitaAccettate[offertaIndex]?.[
                                      attivita.attivita
                                    ]
                                  }
                                  required={
                                    !!attivitaAccettate[offertaIndex]?.[
                                      attivita.attivita
                                    ]
                                  }
                                >
                                  <option value="" disabled hidden>
                                    seleziona ref Tecnico
                                  </option>
                                  {responsabiliFiltrati.map((responsabile) => (
                                    <option
                                      key={responsabile.id}
                                      value={responsabile.id}
                                    >
                                      {responsabile.nome} {responsabile.cognome}
                                    </option>
                                  ))}
                                </Form.Select>
                              </td>
                              <td className="align-middle">
                                <Form.Select
                                  value={attivita.sito}
                                  onChange={(e) =>
                                    handleChangeLavori(
                                      attivita.attivita,
                                      "sito",
                                      e.target.value,
                                      offertaIndex
                                    )
                                  }
                                  required={
                                    !!attivitaAccettate[offertaIndex]?.[
                                      attivita.attivita
                                    ]
                                  }
                                  disabled={
                                    !attivitaAccettate[offertaIndex]?.[
                                      attivita.attivita
                                    ] || sitiFiltrati.length === 1
                                  }
                                >
                                  <option value="" disabled hidden>
                                    seleziona siti
                                  </option>
                                  {sitiFiltrati.map((sito) => (
                                    <option key={sito.id} value={sito.id}>
                                      {sito.sito_o_punto_vendita}
                                    </option>
                                  ))}
                                </Form.Select>
                              </td>
                              <td className="align-middle">
                                <Form.Control
                                  type="number"
                                  value={attivita.importo}
                                  onChange={(e) => {
                                    handleChangeLavori(
                                      attivita.attivita,
                                      "importo",
                                      e.target.value,
                                      offertaIndex
                                    );
                                  }}
                                  required={
                                    !!attivitaAccettate[offertaIndex]?.[
                                      attivita.attivita
                                    ]
                                  }
                                  disabled={
                                    !attivitaAccettate[offertaIndex]?.[
                                      attivita.attivita
                                    ]
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </Col>
          ))}
        </>
      )}
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Conferma Rimozione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sicuro di voler rimuovere l&#39;offerta da quest&#39; ordine?
          <br></br>Perderai anche i lavori ad essa associata.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmationModal(false)}
          >
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              rimuoviOffertaModifica(offertaToRemove);
              setShowConfirmationModal(false);
            }}
          >
            Sì
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
};

export default FormOrdini;
