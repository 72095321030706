import {
  fetchAllPut,
  fetchGeneric,
  fetchSelectBanca,
} from "../../redux/actions";
import { URL_BANCA } from "../url";

export const setFetchBanca = (type, payload, token, dispatch) => {
  if (type === "modifica") {
    dispatch(fetchAllPut(URL_BANCA + payload.id, payload, token));
  } else if (type === "aggiungi") {
    dispatch(fetchGeneric(URL_BANCA, payload, token));
    dispatch(fetchSelectBanca(token));
  }
};
