import { URL_CLIENTI } from "../../../lib/url";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  SET_URL_GET,
  setShow,
  setUrlFetch,
} from "../../../redux/actions";

export const setUrlFilterClienti = (arrayPills, info, dispatch) => {
  let url = "?";
  if (arrayPills.length > 0) {
    if (info.cognome) url = url + "cognome=" + info.cognome;
    if (info.nome && url !== "?") url = url + "&&nome=" + info.nome;
    else {
      if (info.nome) url = url + "nome=" + info.nome;
    }
    if (info.telefono && url !== "?") url = url + "&&telefono=" + info.telefono;
    else {
      if (info.telefono) url = url + "telefono=" + info.telefono;
    }
    if (info.tipologia && url !== "?")
      url = url + "&&tipologia=" + info.tipologia;
    else {
      if (info.tipologia) url = url + "tipologia=" + info.tipologia;
    }
    if (info.oil && url !== "?") url = url + "&&oil=" + info.oil;
    else {
      if (info.oil) url = url + "oil=" + info.oil;
    }
  } else {
    dispatch(setUrlFetch(SET_URL_GET, URL_CLIENTI));
  }
  if (url !== "?") {
    url = URL_CLIENTI + url;
    dispatch(setUrlFetch(SET_URL_FILTER, url));
  }
};
