import { Col, Container, Row } from "react-bootstrap";

const AttivitaDetails = ({ data }) => {
  return (
    <Container className="border bg-light">
      <Row>
        <Col className="border p-2 text-center">
          {" "}
          <p className=" ms-2 m-0 dettagli-title">Descrizione</p>
          <span className="dettagli"> {data.descrizione}</span>
        </Col>
      </Row>
    </Container>
  );
};
export default AttivitaDetails;
