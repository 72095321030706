import { URL_BANCA, URL_STATO } from "../../../lib/url";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  SET_URL_GET,
  setShow,
  setUrlFetch,
} from "../../../redux/actions";

export const setUrlFilterStati = (arrayPills, info, dispatch) => {
  let url = "?";
  if (arrayPills.length > 0) {
    if (info.nome) url = url + "nome=" + info.nome;
  } else {
    dispatch(setUrlFetch(SET_URL_GET, URL_STATO));
  }

  if (url !== "?") {
    url = URL_STATO + url;
    dispatch(setUrlFetch(SET_URL_FILTER, url));
  }
  //   dispatch(setShow(SET_SHOW_FILTER, false));
};
