import { Toast } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { hideAlert } from "../../redux/actions";
import "./AlertToast.css";

const AlertToast = () => {
  const dispatch = useDispatch();
  const alert = useSelector(state => state.alertToast);

  if (!alert.show) {
    return null;
  }

  return (
    <Toast
      className="alert-toast d-flex justify-content-center"
      onClose={() => dispatch(hideAlert())}
      show={alert.show}
      delay={3000}
      autohide
      bg={alert.variant}
    >
      <Toast.Body className="text-white">{alert.message}</Toast.Body>
    </Toast>
  );
};

export default AlertToast;
