import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ADD_RESPONSABILE } from "../../lib/payloads";
import {
  SET_INFO1,
  SET_INFO3,
  fetchSelectClienti,
  setInfo,
} from "../../redux/actions";

const FormResponsabile = () => {
  const info1 = useSelector((state) => state.modal.info1);
  const show1 = useSelector((state) => state.modal.show1);
  const show2 = useSelector((state) => state.modal.show2);
  const show3 = useSelector((state) => state.modal.show3);
  const show4 = useSelector((state) => state.modal.show4);
  const dispatch = useDispatch();
  const [info, setInformazioni] = useState({});
  const path = useSelector((state) => state.locationPage.path);
  const token = useSelector((state) => state.login.token);
  const allClienti = useSelector((state) => state.select.dataCliente);

  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };

  useEffect(() => {
    if (path == "responsabili") {
      dispatch(setInfo(SET_INFO1, info));
    } else {
      const x = {
        ...info,
        cliente: info1.cliente,
      };
      dispatch(setInfo(SET_INFO3, x));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  useEffect(() => {
    if (show1 && show2 && show3 && show4) {
      setInformazioni(ADD_RESPONSABILE);
    } else if (show1 && show2 && show3) {
      setInformazioni(ADD_RESPONSABILE);
    } else if (show1 && show2) {
      setInformazioni(ADD_RESPONSABILE);
    } else {
      setInformazioni(info1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("formResp", info);
  console.log("info1", info1);
  return (
    <Row>
      <Col xs="6">
        <Form.Group className="mb-3">
          <Form.Label>Nome *</Form.Label>
          <Form.Control
            type="text"
            value={info.nome}
            onChange={(e) => changeInfo(e.target.value, "nome")}
            placeholder="Inserisci il nome"
            required
          />
        </Form.Group>
      </Col>
      <Col xs="6">
        <Form.Group className="mb-3">
          <Form.Label>Cognome *</Form.Label>
          <Form.Control
            type="text"
            value={info.cognome}
            onChange={(e) => changeInfo(e.target.value, "cognome")}
            placeholder="Inserisci il cognome"
            required
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label
            column
            sm="2"
            className="d-flex justify-content-between align-items-center"
          >
            Cliente *
          </Form.Label>
          <Form.Select
            value={info1.cliente}
            className="custom-select"
            onChange={(e) => {
              changeInfo(e.target.value, "cliente");
            }}
            aria-label="Seleziona Cliente"
            required
            disabled
          >
            {allClienti.map((c, index) => (
              <option key={index} value={c.id}>
                {c.nome} {c.cognome}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default FormResponsabile;
