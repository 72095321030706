export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

const initialState = {
  show: false,
  message: "",
  variant: "success",
};

const AlertToastReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...state,
        show: true,
        message: action.payload.message,
        variant: action.payload.variant,
      };
    case HIDE_ALERT:
      return {
        ...state,
        show: false,
        message: "",
        variant: "success",
      };
    default:
      return state;
  }
};

export default AlertToastReducer;
