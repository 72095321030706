import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { isAuthorizedAction, isAuthorizedDoAction } from "../../redux/actions";
import { useEffect } from "react";

const RoleBasedRoute = ({ children }) => {
  const token = useSelector(state => state.login.token);
  const userRole = useSelector(state => state.login.role);
  const path = useSelector(state => state.locationPage.path) || "/";
  const dispatch = useDispatch();
  // const allowedRolesA = ["Amministratore"];
  // const allowedRolesAA = ["Amministratore", "Amministrazione"];
  // const allowedRolesAAC = ["Amministratore", "Amministrazione", "Commerciale"];
  // const allowedRolesAACP = ["Amministratore", "Amministrazione", "Commerciale", "ProjectManager"];
  // const allowedRolesAACPT = ["Amministratore", "Amministrazione", "Commerciale", "ProjectManager", "Tecnico"];
  // const allowedRolesAAP = ["Amministratore", "Amministrazione", "ProjectManager"];

  const rolesMap = {
    cliente: ["Amministratore", "Amministrazione", "Commerciale", "Tecnico", "ProjectManager"],
    offerta: ["Amministratore", "Amministrazione", "Commerciale", "ProjectManager"],
    ordine: ["Amministratore", "Amministrazione", "Commerciale", "ProjectManager"],
    commessa: ["Amministratore", "Amministrazione", "ProjectManager"],
    fattura: ["Amministratore", "Amministrazione"],
    banca: ["Amministratore", "Amministrazione"],
    sede: ["Amministratore", "Amministrazione", "Commerciale", "ProjectManager"],
    fornitore: ["Amministratore", "Amministrazione", "Commerciale", "ProjectManager"],
    "incarico-fornitore": ["Amministratore", "Amministrazione", "Commerciale", "ProjectManager"],
    attivita: ["Amministratore", "Amministrazione", "Commerciale", "ProjectManager", "Tecnico"],
    "tipologia-attivita": ["Amministratore", "Amministrazione", "Commerciale", "ProjectManager", "Tecnico"],
    sito: ["Amministratore", "Amministrazione", "Commerciale", "ProjectManager", "Tecnico"],
    stato: ["Amministratore", "Amministrazione", "ProjectManager"],
    dipendente: ["Amministratore", "Amministrazione", "ProjectManager"],
    "/": ["Amministratore", "Amministrazione", "Commerciale", "ProjectManager", "Tecnico"],
    strumenti: ["Amministratore", "Amministrazione"],
  };
  const rolesMap2 = {
    cliente: ["Amministratore", "Amministrazione", "Commerciale"],
    offerta: ["Amministratore", "Amministrazione", "Commerciale"],
    ordine: ["Amministratore", "Amministrazione", "Commerciale"],
    commessa: ["Amministratore", "Amministrazione"],
    fattura: ["Amministratore", "Amministrazione"],
    banca: ["Amministratore", "Amministrazione"],
    sede: ["Amministratore", "Amministrazione"],
    fornitore: ["Amministratore", "Amministrazione"],
    "incarico-fornitore": ["Amministratore", "Amministrazione", "ProjectManager"],
    attivita: ["Amministratore", "Amministrazione", "Commerciale", "ProjectManager"],
    "tipologia-attivita": ["Amministratore", "Amministrazione", "Commerciale", "ProjectManager"],
    sito: ["Amministratore", "Amministrazione", "Commerciale"],
    stato: ["Amministratore", "Amministrazione"],
    dipendente: ["Amministratore", "Amministrazione"],
    "/": ["Amministratore", "Amministrazione", "Commerciale", "ProjectManager", "Tecnico"],
    strumenti: ["Amministratore", "Amministrazione"],
  };
  const isAuthenticated = token !== null && token !== "";
  const isAuthorized = isAuthenticated && rolesMap[path] ? rolesMap[path].includes(userRole) : false;
  const isAuthorized2 = isAuthenticated && rolesMap2[path] ? rolesMap2[path].includes(userRole) : false;

  useEffect(() => {
    dispatch(isAuthorizedAction(isAuthorized));
    dispatch(isAuthorizedDoAction(isAuthorized2));
  }, [path, isAuthorized, dispatch, isAuthorized2]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
};

export default RoleBasedRoute;
