import { URL_BANCA, URL_COMMESSA } from "../../../lib/url";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  SET_URL_GET,
  setShow,
  setUrlFetch,
} from "../../../redux/actions";

export const setUrlFilterCommesse = (arrayPills, info, dispatch) => {
  let url = "?";
  if (arrayPills.length > 0) {
    if (info.cliente) url = url + "cliente=" + info.cliente;
    if (info.fornitore && url !== "?")
      url = url + "&&fornitore=" + info.fornitore;
    else {
      if (info.fornitore) url = url + "fornitore=" + info.fornitore;
    }
    if (info.ordini && url !== "?") url = url + "&&ordini=" + info.ordini;
    else {
      if (info.ordini) url = url + "ordini=" + info.ordini;
    }
    if (info.stato && url !== "?") url = url + "&&stato=" + info.stato;
    else {
      if (info.stato) url = url + "stato=" + info.stato;
    }
  } else {
    dispatch(setUrlFetch(SET_URL_GET, URL_COMMESSA));
  }

  if (url !== "?") {
    url = URL_COMMESSA + url;
    dispatch(setUrlFetch(SET_URL_FILTER, url));
  }
  //   dispatch(setShow(SET_SHOW_FILTER, false));
};
