import GraphInserimento from "./GraphInserimento";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout";
import {
  fetchAttivitaCount,
  fetchCommesseCount,
  fetchOrdiniCount,
  fetchOfferteCount,
  setPathName,
  setIsLoadFetch,
  SET_ISLOAD_GET,
  SET_ISLOAD_GET2,
  SET_ISLOAD_GET3,
  SET_ISLOAD_GET4,
  SET_ISLOAD_GET5,
} from "../../redux/actions";
import PieChart from "./PieChart.js";
import { useLocation } from "react-router-dom";

function Dashboard(props) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.token);
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchAttivitaCount(token));
    dispatch(fetchCommesseCount(token));
    dispatch(fetchOrdiniCount(token));
    dispatch(fetchOfferteCount(token));
    dispatch(setPathName(location.pathname.split("/")));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET, false));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET2, false));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET3, false));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET4, false));
    dispatch(setIsLoadFetch(SET_ISLOAD_GET5, false));
  }, [dispatch, token]);

  const offerteCount = useSelector((state) => state.stats.offerta);
  const ordiniCount = useSelector((state) => state.stats.ordine);
  const nome = useSelector((state) => state.login.name);

  const Header = () => (
    <header>
      <div className="row mb-4 d-flex align-items-center">
        <div className="col">
          <h1 className="m-0">Benvenuto, {nome}</h1>
        </div>
        <div className="col-auto"></div>
      </div>
    </header>
  );

  return (
    <Layout {...props}>
      <title>Dashboard</title>

      <Header />
      <GraphInserimento />

      <div className="row">
        <div className="col col-lg-12">
          <div className="card" style={{ minWidth: 600 }}>
            <div
              className="card-body"
              style={{ minHeight: 400, minWidth: 800 }}
            >
              <h5 className="card-title my-2">Offerte/Ordini</h5>
              <PieChart ordini={ordiniCount} offerte={offerteCount} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;
