import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ADD_ATTIVITA } from "../../lib/payloads";
import { SET_INFO1, fetchSelectAttivita, fetchSelectOfferte, fetchSelectOrdine, setInfo } from "../../redux/actions";
import {
  SET_CLASS2,
  SET_CLASS3,
  SET_CLASS4,
  SET_SHOW2,
  SET_SHOW3,
  SET_SHOW4,
  setClass,
  setShow,
} from "../../redux/actions";
import { fetchSelectTipologiaAttivita, fetchSelectSiti } from "../../redux/actions";

const FormAttivita = () => {
  const info1 = useSelector(state => state.modal.info1);
  const show1 = useSelector(state => state.modal.show1);
  const show2 = useSelector(state => state.modal.show2);
  const show3 = useSelector(state => state.modal.show3);
  const show4 = useSelector(state => state.modal.show4);
  const dispatch = useDispatch();
  const allOrdini = useSelector(state => state.select.dataOrdine);
  const tipoModal = useSelector(state => state.modal.type);
  const allOfferte = useSelector(state => state.select.dataOfferte);
  const [info, setInformazioni] = useState({});
  const allTipologiaAttivita = useSelector(state => state.select.dataTipologiaAttivita);
  const token = useSelector(state => state.login.token);
  const allSiti = useSelector(state => state.select.dataSiti);

  const [ordiniFiltrati, setOrdiniFiltrati] = useState([]);
  const [clienteSelezionato, setClienteSelezionato] = useState("");

  const [offertaDaMostrare, setOffertaDaMostrare] = useState(null);
  // const [selectedSito, setSelectedSito] = useState("");

  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };
  useEffect(() => {
    dispatch(fetchSelectTipologiaAttivita(token));
    dispatch(fetchSelectSiti(token));
    dispatch(fetchSelectOrdine(token));
    dispatch(fetchSelectOfferte(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clienteSelezionato) {
      setOrdiniFiltrati(allOrdini.filter(ordine => ordine.cliente == clienteSelezionato));
      const x = allOfferte.filter(offerta => offerta.id == info.offerta);
      setOffertaDaMostrare(x[0]);
    } else {
      setOrdiniFiltrati([]);
    }
  }, [clienteSelezionato, allOrdini, allOfferte]);

  useEffect(() => {
    dispatch(setInfo(SET_INFO1, info));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  useEffect(() => {
    if (show1 && show2 && show3 && show4) {
      setInformazioni(ADD_ATTIVITA);
    } else if (show1 && show2 && show3) {
      setInformazioni(ADD_ATTIVITA);
    } else if (show1 && show2) {
      setInformazioni(ADD_ATTIVITA);
    } else {
      setInformazioni(info1);
      if (tipoModal === "modifica") {
        setClienteSelezionato(info1.idCliente);

        setInformazioni({
          ...info1,
          cliente: info1.idCliente,
          sito: info1.idSito,
          offerta: info1.idOfferta,
          tipologia: info1.idTipologia,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenNextModal = classe => {
    if (show1 && show2 && show3) {
      dispatch(setShow(SET_SHOW4, true));
      dispatch(setClass(SET_CLASS4, classe));
    } else if (show1 && show2) {
      dispatch(setShow(SET_SHOW3, true));
      dispatch(setClass(SET_CLASS3, classe));
    } else {
      dispatch(setShow(SET_SHOW2, true));
      dispatch(setClass(SET_CLASS2, classe));
    }
  };

  return (
    <Row>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Nome Attività *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.denominazione}
            onChange={e => changeInfo(e.target.value, "denominazione")}
            placeholder="Inserisci il nome dell'attività"
            required
          />{" "}
          <Form.Control.Feedback type="invalid">campo obbligatorio</Form.Control.Feedback>
        </Form.Group>
      </Col>

      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2" className="d-flex justify-content-between align-items-center">
            Tipologia *
            {tipoModal !== "modifica" && (
              <span
                onClick={() => handleOpenNextModal("tipologia-attivita")}
                className="text-primary text-decoration-none me-2"
              >
                <i className="bi bi-plus-circle-fill"></i>
              </span>
            )}
          </Form.Label>
          <Form.Select
            value={info.tipologia}
            onChange={e => changeInfo(e.target.value, "tipologia")}
            aria-label="Seleziona Tipologia"
            required
          >
            <option value="" disabled hidden>
              Seleziona Tipologia Attività
            </option>
            {allTipologiaAttivita.length !== 0 ? (
              allTipologiaAttivita.map((c, index) => (
                <option key={index} value={c.id}>
                  {c.denominazione}
                </option>
              ))
            ) : (
              <option value="" disabled hidden>
                Nessuna tipologia disponibile
              </option>
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">campo obbligatorio</Form.Control.Feedback>
        </Form.Group>
      </Col>

      {offertaDaMostrare && (
        <Col xs="4">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Offerta *
            </Form.Label>
            <Form.Control
              type="text"
              value={offertaDaMostrare.protocollo}
              onChange={e => changeInfo(e.target.value, "offerta")}
              placeholder="Inserisci offerta"
              required
              disabled
            />
          </Form.Group>
        </Col>
      )}

      {/* <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label
            column
            sm="2"
            className="d-flex justify-content-between align-items-center"
          >
            Ordine *
          </Form.Label>
          <Form.Select
            value={info.ordine}
            className="custom-select"
            onChange={(e) => changeInfo(e.target.value, "ordine")}
            aria-label="Seleziona Ordine"
            required
            disabled={ordiniFiltrati.length == 0 || tipoModal === "modifica"}
          >
            <option value="" disabled hidden>
              Seleziona Ordine
            </option>
            {ordiniFiltrati.length !== 0 ? (
              ordiniFiltrati.map((ordine, index) => (
                <option key={index} value={ordine.id}>
                  {ordine.protocollo}
                </option>
              ))
            ) : (
              <option value="" disabled hidden>
                {" "}
                Nessun ordine disponibile
              </option>
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col> */}

      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Importo *
          </Form.Label>
          <div className="d-flex align-items-center">
            <span style={{ marginRight: "8px" }}>€</span>
            <Form.Control
              type="text"
              value={info.importo}
              onChange={e => changeInfo(e.target.value, "importo")}
              placeholder="Inserisci l'importo"
              required
            />
          </div>
        </Form.Group>
        <Form.Control.Feedback type="invalid">campo obbligatorio</Form.Control.Feedback>
      </Col>

      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Sito *
          </Form.Label>
          <Form.Select
            value={info.sito}
            onChange={e => changeInfo(e.target.value, "sito")}
            aria-label="Seleziona Sito"
            required
            disabled
            //  DA IMPLEMENTARE UN GIORNO: DISABILITATO SOLO SE L'OFFERTA
            // HA IL SITO UNICO
          >
            <option value="" disabled hidden>
              Seleziona Sito
            </option>
            {allSiti.length !== 0 ? (
              allSiti.map((c, index) => (
                <option key={index} value={c.id}>
                  {c.sito_o_punto_vendita}
                </option>
              ))
            ) : (
              <option value="" disabled hidden>
                Nessun sito disponibile
              </option>
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">campo obbligatorio</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="12">
        <Form.Group className="mb-3">
          <Form.Label>Specifica Attività</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={info.descrizione}
            onChange={e => changeInfo(e.target.value, "descrizione")}
            placeholder="Inserisci la descrizione"
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default FormAttivita;
