import { Col, Form, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  fetchSelectClienti,
  fetchSelectSiti,
  setArrayFilter,
  setInfoFilter,
  setShow,
  setUrlFetch,
} from "../../redux/actions";
import { useEffect } from "react";
import { URL_OFFERTA } from "../../lib/url";
import { setUrlFilterOfferta } from "./setUrl/setUrlFilterOfferta";

const FilterOfferte = ({ info, setInformazioni }) => {
  const token = useSelector((state) => state.login.token);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSelectClienti(token));
    dispatch(fetchSelectSiti(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allClienti = useSelector((state) => state.select.dataCliente);
  const allSiti = useSelector((state) => state.select.dataSiti);
  const changeInfo = (value, name) => {
    setInformazioni({
      ...info,
      [name]: value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setInfoFilter(info));

    let nuovoOggetto = { ...info };

    if (nuovoOggetto.cliente) {
      const x = allClienti.filter((f) => f.id == nuovoOggetto.cliente);
      nuovoOggetto = {
        ...nuovoOggetto,
        cliente: x[0].nome + " " + x[0].cognome,
      };
    }
    if (nuovoOggetto.sito) {
      const j = allSiti.filter((f) => f.id == nuovoOggetto.sito);
      nuovoOggetto = { ...nuovoOggetto, sito: j[0].sito_o_punto_vendita };
    }

    const arrayPills = Object.entries(nuovoOggetto);

    dispatch(setArrayFilter(arrayPills));

    setUrlFilterOfferta(arrayPills, info, dispatch);

    dispatch(setShow(SET_SHOW_FILTER, false));
    setInformazioni({});
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <Row>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label
              column
              sm="2"
              className="d-flex justify-content-between align-items-center"
            >
              Cliente
            </Form.Label>
            <Form.Select
              value={info.cliente || ""}
              onChange={(e) => changeInfo(e.target.value, "cliente")}
              aria-label="Filtra per Cliente"
            >
              <option value="" disabled hidden>
                Filtra per Cliente
              </option>
              {allClienti.length !== 0 ? (
                allClienti.map((c, index) => (
                  <option key={index} value={c.id}>
                    {c.nome} {c.cognome}
                  </option>
                ))
              ) : (
                <option value="" disabled hidden>
                  Nessun Cliente disponibile
                </option>
              )}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label
              column
              sm="2"
              className="d-flex justify-content-between align-items-center"
            >
              Sito
            </Form.Label>
            <Form.Select
              value={info.sito || ""}
              onChange={(e) => changeInfo(e.target.value, "sito")}
              aria-label="Filtra per Sito"
            >
              <option value="" disabled hidden>
                Seleziona Sito
              </option>
              {allSiti.length !== 0 ? (
                allSiti.map((c, index) => (
                  <option key={index} value={c.id}>
                    {c.sito_o_punto_vendita}
                  </option>
                ))
              ) : (
                <option value="" disabled hidden>
                  Nessun sito disponibile
                </option>
              )}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Referente Cliente
            </Form.Label>
            <Form.Control
              type="text"
              value={info.referente_cliente}
              onChange={(e) => changeInfo(e.target.value, "referente_cliente")}
              placeholder="Inserisci Referente Cliente"
            />
          </Form.Group>
        </Col>

        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Referente Interno
            </Form.Label>
            <Form.Control
              type="text"
              value={info.referente_interno}
              onChange={(e) => changeInfo(e.target.value, "referente_interno")}
              placeholder="Inserisci Referente Interno"
            />
          </Form.Group>
        </Col>
      </Row>
      <Col xs={{ span: 12 }} className="d-flex justify-content-end">
        <Button type="submit" variant="primary">
          Applica Filtri
        </Button>
      </Col>
    </Form>
  );
};

export default FilterOfferte;
