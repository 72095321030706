import { URL_BANCA, URL_INCARICO_FORNITORE } from "../../../lib/url";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  SET_URL_GET,
  setShow,
  setUrlFetch,
} from "../../../redux/actions";

export const setUrlFilterIncaricoFornitori = (arrayPills, info, dispatch) => {
  let url = "?";
  if (arrayPills.length > 0) {
    if (info.fornitore) url = url + "fornitore=" + info.fornitore;
    if (info.commessa && url !== "?") url = url + "&&commessa=" + info.commessa;
    else {
      if (info.commessa) url = url + "commessa=" + info.commessa;
    }
  } else {
    dispatch(setUrlFetch(SET_URL_GET, URL_INCARICO_FORNITORE));
  }

  if (url !== "?") {
    url = URL_INCARICO_FORNITORE + url;
    dispatch(setUrlFetch(SET_URL_FILTER, url));
  }
  //   dispatch(setShow(SET_SHOW_FILTER, false));
};
