import { SET_ARRAY_FILTER, SET_INFO_FILTER } from "../actions";

const initialState = {
  dataFilter: "",
  arrayPill: [],
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INFO_FILTER:
      return {
        ...state,
        dataFilter: action.payload,
      };
    case SET_ARRAY_FILTER:
      return {
        ...state,
        arrayPill: action.payload,
      };

    default:
      return state;
  }
};
export default filterReducer;
