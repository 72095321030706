import { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const RequestPassword = () => {
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [emailMatch, setEmailMatch] = useState(true);
  const baseEndpoint = "https://be.civetta.maritsrl.com/api/v1/";
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" });
  const navigate = useNavigate();

  const comeBack = () => {
    navigate("/login");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    setEmailMatch(email === confirmEmail);
    if (form.checkValidity() === false || !emailMatch) {
      e.stopPropagation();
    } else {
      const data = {
        email: email,
      };
      await passwordReset(data);
    }
  };

  const passwordReset = async (data) => {
    try {
      const response = await fetch(baseEndpoint + "auth/password/reset/", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        setAlert({
          show: true,
          message: "Email inviata con successo!",
          variant: "success",
        });
      } else {
        const errorData = await response.json();
        let errorMessage = "Qualcosa è andato storto, riprova!";

        if (errorData && typeof errorData === "object") {
          const errorMessages = Object.values(errorData).flat().join(". ");
          errorMessage = errorMessages || errorMessage;
        }

        setAlert({ show: true, message: errorMessage, variant: "warning" });
      }
    } catch (error) {
      setAlert({
        show: true,
        message: "Errore di rete o server non disponibile.",
        variant: "danger",
      });
    }
  };

  return (
    <Container className="d-none d-md-block m-0 p-0">
      <Row>
        <Col md={4} className="100%">
          <Image
            src="/assets/bkg.jpg"
            style={{ height: "100vh", width: "100%" }}
          />
        </Col>
        <Col
          md={8}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <div className="w-75">
            <h1 style={{ color: "#2B75BC" }} className="my-5">
              Password dimenticata
            </h1>
            <p className="fs-4 pt-5">
              Dopo aver inserito il tuo indirizzo email, riceverai un email con
              le istruzioni per impostare una nuova password.
            </p>
            <Form noValidate onSubmit={handleSubmit} className="mt-4">
              <Form.Group className="w-50">
                <Form.Label>Inserisci la tua email</Form.Label>

                <Form.Control
                  required
                  type="email"
                  placeholder="my.email@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isInvalid={!emailMatch}
                />
                <Form.Control.Feedback type="invalid">
                  {emailMatch
                    ? "Inserisci una email valida"
                    : "Le email inserite non coincidono"}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="w-50 mt-3">
                <Form.Label>Ripeti email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="my.email@email.com"
                  value={confirmEmail}
                  onChange={(e) => setConfirmEmail(e.target.value)}
                  isInvalid={!emailMatch}
                />
                <Form.Control.Feedback type="invalid">
                  {emailMatch
                    ? "Inserisci una email valida"
                    : "Le email inserite non coincidono"}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="d-flex align-items-center">
                <Button
                  style={{ backgroundColor: "#2B75BC" }}
                  size="lg"
                  type="submit"
                  className="mt-3"
                >
                  invia
                </Button>
                <p className=" ms-auto mt-3 mb-0">
                  <a href="#" onClick={comeBack}>
                    Torna alla pagina di login?
                  </a>
                </p>
              </div>
            </Form>
          </div>
        </Col>
        {alert.show && (
          <Alert className="mt-2" variant={alert.variant}>
            {alert.message}
          </Alert>
        )}
      </Row>
    </Container>
  );
};
export default RequestPassword;
