export const GetFornitore = (lista, lista2, lista3, lista4, setData) => {
  if (
    lista &&
    lista2 &&
    lista3 &&
    lista4 &&
    lista.length >= 0 &&
    lista2.length >= 0 &&
    lista3.length >= 0 &&
    lista4.length >= 0
  ) {
    const combinedList = lista.map(item => {
      let indirizzo = lista2.filter(item2 => item.id === item2.fornitore);
      let combinedItem;
      if (indirizzo.length > 0) {
        combinedItem = {
          ...item,
          ...(indirizzo[0] || {}),
          id: item.id,
          idIndirizzo: indirizzo[0].id,
          openModal: <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>,
        };
      } else {
        combinedItem = {
          ...item,
          ...(indirizzo[0] || {}),
          id: item.id,
          idIndirizzo: "",
          openModal: <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>,
        };
      }
      return combinedItem;
    });

    const combinedList2 = combinedList.map(item => {
      let banca = lista3.filter(item3 => item.banca == item3.id);
      let combinedItem;
      if (banca.length > 0) {
        let nome = banca[0].nome;
        combinedItem = {
          ...item,
          banca: nome,
          idBanca: banca[0].id,
          openModal: <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>,
        };
      } else {
        combinedItem = {
          ...item,
          openModal: <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>,
        };
      }
      return combinedItem;
    });

    const combinedList3 = combinedList2.map(item => {
      let tipologia_attivita = lista4.filter(item4 => item.tipologia_attivita === item4.id);
      let combinedItem;
      if (tipologia_attivita.length > 0) {
        let denominazione = tipologia_attivita[0].denominazione;
        combinedItem = {
          ...item,
          tipologia_attivita: denominazione,
          idTipologiaAttivita: tipologia_attivita[0].id,
          openModal: <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>,
        };
      } else {
        combinedItem = {
          ...item,
          openModal: <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>,
        };
      }
      return combinedItem;
    });
    setData(combinedList3);
  } else {
    if (lista && lista.length > 0) {
      const updatedList = lista.map(item => ({
        ...item,
        openModal: <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>,
      }));
      setData(updatedList);
    }
  }
};
