import { Col, Form, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ADD_COMMESSE } from "../../lib/payloads";
import {
  SET_INFO1,
  fetchSelectDipendente,
  fetchSelectOfferte,
  fetchSelectProjectManager,
  fetchSelectResponsabili,
  fetchSelectSiti,
  fetchSelectLavori,
  setInfo,
} from "../../redux/actions";
import {
  SET_CLASS2,
  SET_CLASS3,
  SET_CLASS4,
  SET_SHOW2,
  SET_SHOW3,
  SET_SHOW4,
  setClass,
  setShow,
  fetchSelectClienti,
  fetchSelectFornitore,
  fetchSelectStati,
  fetchSelectOrdine,
  fetchSelectAttivita,
} from "../../redux/actions";

const FormCommesse = () => {
  const info1 = useSelector((state) => state.modal.info1);
  const show1 = useSelector((state) => state.modal.show1);
  const show2 = useSelector((state) => state.modal.show2);
  const show3 = useSelector((state) => state.modal.show3);
  const show4 = useSelector((state) => state.modal.show4);
  const tipoModal = useSelector((state) => state.modal.type);
  const token = useSelector((state) => state.login.token);
  const allClienti = useSelector((state) => state.select.dataCliente);
  const allFornitori = useSelector((state) => state.select.dataFornitore);
  const allStati = useSelector((state) => state.select.dataStati);
  const allOrdini = useSelector((state) => state.select.dataOrdine);
  const allSiti = useSelector((state) => state.select.dataSiti);
  const allOfferte = useSelector((state) => state.select.dataOfferte);
  const [sitiFiltrati, setSitiFiltrati] = useState([]);
  const allProjectManager = useSelector(
    (state) => state.select.dataProjectManager
  );
  const allDipendenti = useSelector((state) => state.select.dataDipendente);

  const allResponsabili = useSelector((state) => state.select.dataResponsabili);

  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [ordiniFiltrati, setOrdiniFiltrati] = useState([]);
  const [offerteFiltrate, setOfferteFiltrate] = useState([]);
  const [clienteSelezionato, setClienteSelezionato] = useState("");
  const [offertaAggiunta, setOffertaAggiunta] = useState(false);
  const allAttivita = useSelector((state) => state.select.dataAttivita);
  const allLavori = useSelector((state) => state.select.dataLavori);
  const [responsabiliFiltrati, setResponsabiliFiltrati] = useState([]);

  const [attivitaPerOrdine, setAttivitaPerOrdine] = useState([]);
  const [attivitaPerOfferta, setAttivitaPerOfferta] = useState([]);
  const [attivitaAccettate, setAttivitaAccettate] = useState([]);
  const [lavoriPerCommessa, setLavoriPerCommessa] = useState([]);
  const [ordiniPerLavoro, setOrdiniPerLavoro] = useState([]);
  const [tutteLeOfferteSonoUtilizzate, setTutteLeOfferteSonoUtilizzate] =
    useState(false);

  const [attivitaAccettateInOfferta, setAttivitaAccettateInOfferta] = useState(
    []
  );
  const [selezionatiOrdini, setSelezionatiOrdini] = useState([]);
  const [selezionateOfferte, setSelezionateOfferte] = useState([]);
  const [lavoriPerOrdine, setLavoriPerOrdine] = useState([]);
  const class2 = useSelector((state) => state.modal.class2);
  const dispatch = useDispatch();
  const [info, setInformazioni] = useState({});

  useEffect(() => {
    dispatch(fetchSelectClienti(token));
    dispatch(fetchSelectSiti(token));
    dispatch(fetchSelectStati(token));
    dispatch(fetchSelectFornitore(token));
    dispatch(fetchSelectOrdine(token));
    dispatch(fetchSelectAttivita(token));
    dispatch(fetchSelectDipendente(token));
    dispatch(fetchSelectProjectManager(token));
    dispatch(fetchSelectOfferte(token));
    dispatch(fetchSelectResponsabili(token));
    dispatch(fetchSelectLavori(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newsitiFiltrati = allSiti.filter(
      (sito) => sito.cliente == info.cliente
    );
    setSitiFiltrati(newsitiFiltrati);
  }, [allSiti]);

  useEffect(() => {
    const responsabiliFiltr = allResponsabili.filter(
      (responsabile) => responsabile.cliente == info.cliente
    );

    setResponsabiliFiltrati(responsabiliFiltr);
  }, [allResponsabili]);

  useEffect(() => {
    // if (class2 !== "sito" && !show2) {
    dispatch(setInfo(SET_INFO1, info));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  useEffect(() => {
    if (show1 && show2 && show3 && show4) {
      setInformazioni(ADD_COMMESSE);
    } else if (show1 && show2 && show3) {
      setInformazioni(ADD_COMMESSE);
    } else if (show1 && show2) {
      if (class2 !== "sito") {
        setInformazioni(ADD_COMMESSE);
      }
    } else {
      setInformazioni(info1);
      if (tipoModal == "modifica") {
        const ordiniStringhe = info1.ordini.map(String);
        setSelezionatiOrdini(ordiniStringhe);

        const offerteStringhe = info1.offerte.map(String);
        setSelezionateOfferte(offerteStringhe);

        const lavoriFiltratiPerCommessa = allLavori.filter(
          (lavoro) => lavoro.commessa == info1.id
        ); //lavori nella commessa

        setLavoriPerCommessa(lavoriFiltratiPerCommessa);

        const ordiniFiltrati = allOrdini.filter((ordine) =>
          selezionatiOrdini.includes(ordine.id.toString())
        ); //oogetto intero degl ordini

        const arrayDilavoriPerOrdine = ordiniFiltrati.map((ordine) => {
          return ordine.lavori.map((idLavoro) =>
            lavoriFiltratiPerCommessa.find((lavoro) => {
              return lavoro.id == idLavoro;
            })
          );
        });

        setLavoriPerOrdine(arrayDilavoriPerOrdine);
        const attivitaPerOrdineInizializzate = selezionatiOrdini.map(
          (ordineId) => {
            const selectedOrdine = allOrdini.find(
              (ordine) => ordine.id === parseInt(ordineId)
            );
            return selectedOrdine
              ? allAttivita.filter((attivita) =>
                  selectedOrdine.attivita.includes(attivita.id)
                )
              : [];
          }
        );

        setAttivitaPerOrdine(attivitaPerOrdineInizializzate);

        let risultato = {};

        Object.keys(attivitaPerOrdine).forEach((key) => {
          risultato[key] = {};
          attivitaPerOrdine[key].forEach((attivita) => {
            if (info1.attivita.includes(attivita.id)) {
              risultato[key][attivita.id] = true;
            }
          });
        });

        setAttivitaAccettate(risultato);

        const attivitaAccettateInOffertaIniziale = offerteStringhe.reduce(
          (acc, offerta, index) => {
            // Converti l'ID dell'offerta in un numero per il confronto
            const offertaIdNumerico = parseInt(offerta);

            acc[index] = info1.attivita.reduce((attivitaAcc, attivitaId) => {
              if (
                allAttivita.some(
                  (attivita) =>
                    attivita.id === attivitaId &&
                    attivita.offerta === offertaIdNumerico
                )
              ) {
                attivitaAcc[attivitaId] = true;
              }
              return attivitaAcc;
            }, {});

            return acc;
          },
          {}
        );

        setAttivitaAccettateInOfferta(attivitaAccettateInOffertaIniziale);

        const attivitaPerOffertaIniziale = selezionateOfferte.map((offerta) =>
          allAttivita.filter((attivita) => attivita.offerta == offerta)
        );
        setAttivitaPerOfferta(attivitaPerOffertaIniziale);

        const offerteFiltratee = allOfferte.filter(
          (offerta) => offerta.cliente === info1.idCliente
        );
        setOfferteFiltrate(offerteFiltratee);

        const newsitiFiltrati = allSiti.filter(
          (sito) => sito.cliente == info1.idCliente
        );
        setSitiFiltrati(newsitiFiltrati);

        const responsabiliFiltr = allResponsabili.filter(
          (responsabile) => responsabile.cliente == info1.idCliente
        );

        setResponsabiliFiltrati(responsabiliFiltr);

        setInformazioni({
          ...info1,
          cliente: info1.idCliente,
          sito: info1.sitoId,
          stato: info1.idStato,
          fornitore: info1.idFornitore,
          project_manager: info1.project_manager,
          responsabile: info1.idResponsabile,
          desinenza: info1.desinenza,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allAttivita,
    allClienti,
    allStati,
    allOrdini,
    allFornitori,
    allSiti,
    allOfferte,
    allResponsabili,
    allLavori,
    allDipendenti,
    allProjectManager,
  ]);

  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };

  useEffect(() => {
    if (info.cliente) {
      setClienteSelezionato(info.cliente);
      setIsFormDisabled(false);
    } else if (clienteSelezionato) {
      setIsFormDisabled(false);
    } else {
      setIsFormDisabled(true);
    }
  }, [clienteSelezionato, info.cliente]);

  const handleClienteChange = (value) => {
    changeInfo(value, "cliente");
    setClienteSelezionato(value);
    const newsitiFiltrati = allSiti.filter((sito) => sito.cliente == value);
    setSitiFiltrati(newsitiFiltrati);
    const newResponsabiliFiltr = allResponsabili.filter(
      (responsabile) => responsabile.cliente == value
    );
    setResponsabiliFiltrati(newResponsabiliFiltr);
  };

  const handleOpenNextModal = (classe) => {
    if (show1 && show2 && show3) {
      dispatch(setShow(SET_SHOW4, true));
      dispatch(setClass(SET_CLASS4, classe));
    } else if (show1 && show2) {
      dispatch(setShow(SET_SHOW3, true));
      dispatch(setClass(SET_CLASS3, classe));
    } else {
      dispatch(setShow(SET_SHOW2, true));
      dispatch(setClass(SET_CLASS2, classe));
    }
  };

  useEffect(() => {
    if (clienteSelezionato) {
      const offerteFiltrate = allOfferte.filter(
        (offerta) => offerta.cliente == clienteSelezionato
      );
      setOfferteFiltrate(offerteFiltrate);
      setIsFormDisabled(false);
    } else {
      setOfferteFiltrate([]);
      setIsFormDisabled(true);
    }

    const ordiniFiltratiPerCliente = allOrdini.filter(
      (ordine) => ordine.cliente == clienteSelezionato
    );
    setOrdiniFiltrati(ordiniFiltratiPerCliente);
  }, [allOrdini, allOfferte, clienteSelezionato]);

  const aggiungiOrdine = () => {
    setSelezionatiOrdini([...selezionatiOrdini, ""]);
  };

  const aggiungiOfferta = () => {
    setSelezionateOfferte([...selezionateOfferte, ""]);
    setOffertaAggiunta(true);
  };

  const rimuoviOrdine = (index) => {
    const updatedOrdini = selezionatiOrdini.filter((_, i) => i !== index);
    setSelezionatiOrdini(updatedOrdini);

    // Rimuovi le attività associate dall'ordine rimossa
    const attivitaAggiornate = { ...attivitaAccettate };
    delete attivitaAggiornate[index];

    // Aggiorna le chiavi di attivitaAccettate
    const nuoveAttivitaAccettate = Object.keys(attivitaAggiornate).reduce(
      (newObj, key) => {
        const newKey =
          parseInt(key) >= index ? parseInt(key) - 1 : parseInt(key);
        newObj[newKey] = attivitaAggiornate[key];
        return newObj;
      },
      {}
    );
    setAttivitaAccettate(nuoveAttivitaAccettate);

    // Aggiorna attivitaPerOrdine per le offerte rimanenti

    const attivitaPerOrdineAggiornata = updatedOrdini.map((ordineId) => {
      const selectedOrdine = allOrdini.find(
        (ordine) => ordine.id === parseInt(ordineId)
      );
      const attivitaIds = selectedOrdine.attivita;
      return allAttivita.filter((attivita) =>
        attivitaIds.includes(attivita.id)
      );
    });

    setAttivitaPerOrdine(attivitaPerOrdineAggiornata);

    // SE SIAMO SU MODIFICA, QUANDO ELIMINO UNA ordine DEVO ELIMINARE ANCHE LE ATTIVITA IN ESSA CONTENUTE DA INFO
    if (tipoModal === "modifica") {
      const attivitaDaRimuovere = Object.keys(
        attivitaAccettate[index] || {}
      ).map((id) => parseInt(id));
      const infoAttivitaAggiornate = info.attivita.filter(
        (attivitaId) => !attivitaDaRimuovere.includes(attivitaId)
      );
      setInformazioni((prevInfo) => ({
        ...prevInfo,
        attivita: infoAttivitaAggiornate,
      }));
    }
  };

  const rimuoviOfferta = (index) => {
    const offerteAggiornate = selezionateOfferte.filter((_, i) => i !== index);
    setSelezionateOfferte(offerteAggiornate);

    // Rimuovi le attività associate dall'offerta rimossa
    const attivitaAggiornateInOfferta = { ...attivitaAccettateInOfferta };
    delete attivitaAccettateInOfferta[index];
    // Aggiorna le chiavi di attivitaAccettate
    const nuoveAttivitaAccettateInOfferta = Object.keys(
      attivitaAggiornateInOfferta
    ).reduce((newObj, key) => {
      const newKey = parseInt(key) >= index ? parseInt(key) - 1 : parseInt(key);
      newObj[newKey] = attivitaAggiornateInOfferta[key];
      return newObj;
    }, {});
    setAttivitaAccettateInOfferta(nuoveAttivitaAccettateInOfferta);
    // Aggiorna attivitaPerOfferta per le offerte rimanenti
    const attivitaPerOffertaAggiornata = offerteAggiornate.map((offerta) =>
      allAttivita.filter((attivita) => attivita.offerta == offerta)
    );
    setAttivitaPerOfferta(attivitaPerOffertaAggiornata);

    // SE SIAMO SU MODIFICA, QUANDO ELIMINO UNA OFFERTA DEVO ELIMINARE ANCHE LE ATTIVITA IN ESSA CONTENUTE DA INFO
    if (tipoModal === "modifica") {
      const attivitaDaRimuovere = Object.keys(
        attivitaAccettateInOfferta[index] || {}
      ).map((id) => parseInt(id));
      const infoAttivitaInOffertaAggiornate = info.attivita.filter(
        (attivitaId) => !attivitaDaRimuovere.includes(attivitaId)
      );
      setInformazioni((prevInfo) => ({
        ...prevInfo,
        attivita: infoAttivitaInOffertaAggiornate,
      }));
    }
  };

  const handleOrdineChange = (value, index) => {
    const updatedOrdini = [...selezionatiOrdini];
    updatedOrdini[index] = value;
    setSelezionatiOrdini(updatedOrdini);

    const selectedOrdine = allOrdini.find(
      (ordine) => ordine.id === parseInt(value)
    );

    if (selectedOrdine) {
      // Ottieni gli ID delle attività associate all'ordine selezionato
      const attivitaIds = selectedOrdine.attivita;

      // Filtra allAttivita per includere solo quelle presenti in attivitaIds
      const attivitaFiltrate = allAttivita.filter((attivita) =>
        attivitaIds.includes(attivita.id)
      );

      // Aggiorna lo stato attivitaPerOrdine
      setAttivitaPerOrdine((prevState) => ({
        ...prevState,
        [index]: attivitaFiltrate,
      }));
    }
  };

  const handleOffertaChange = (value, index) => {
    const updatedOfferte = [...selezionateOfferte];
    updatedOfferte[index] = value;
    setSelezionateOfferte(updatedOfferte);

    const attivitaInOffertaFiltrate = allAttivita.filter(
      (attivita) => attivita.offerta == value
    );
    setAttivitaPerOfferta((prevState) => ({
      ...prevState,
      [index]: attivitaInOffertaFiltrate,
    }));
    // Svuota le attività selezionate per questa offerta
    setAttivitaAccettateInOfferta((prevState) => ({
      ...prevState,
      [index]: {},
    }));
  };

  const getFilteredOrdiniOptions = (index) => {
    return ordiniFiltrati.filter(
      (ordine) =>
        !selezionatiOrdini.includes(ordine.id.toString()) ||
        selezionatiOrdini[index] == ordine.id.toString()
    );
  };

  const getFilteredOfferteOptions = (index) => {
    return offerteFiltrate.filter(
      (offerta) =>
        !selezionateOfferte.includes(offerta.id.toString()) ||
        selezionateOfferte[index] === offerta.id.toString()
    );
  };

  const getFilteredOfferta = (offerta) =>
    allOfferte.filter((offertaInd) => offertaInd.id == offerta);

  const sonoTuttiOrdiniUsati = () => {
    return ordiniFiltrati.every((ordine) =>
      selezionatiOrdini.includes(ordine.id.toString())
    );
  };

  const areAllOfferteUsed = () => {
    return offerteFiltrate.every((offerta) =>
      selezionateOfferte.includes(offerta.id.toString())
    );
  };

  useEffect(() => {
    setTutteLeOfferteSonoUtilizzate(
      offerteFiltrate.every((offerta) =>
        selezionateOfferte.includes(offerta.id.toString())
      )
    );
  }, [selezionateOfferte]);

  const handleAttivitaCheck = (attivitaId, ordineIndex, isChecked) => {
    setAttivitaAccettate((prevState) => ({
      ...prevState,
      [ordineIndex]: {
        ...prevState[ordineIndex],
        [attivitaId]: isChecked,
      },
    }));
  };

  const handleAttivitaInOffertaCheck = (attivitaId, ordineIndex, isChecked) => {
    setAttivitaAccettateInOfferta((prevState) => ({
      ...prevState,
      [ordineIndex]: {
        ...prevState[ordineIndex],
        [attivitaId]: isChecked,
      },
    }));
  };

  const handleAttivitaCheckDentroModifica = (
    attivitaId,
    ordineIndex,
    isChecked
  ) => {
    // Aggiorna attivitaAccettate
    setAttivitaAccettate((prevState) => ({
      ...prevState,
      [ordineIndex]: {
        ...prevState[ordineIndex],
        [attivitaId]: isChecked,
      },
    }));

    // Aggiorna info.attivita in base alla selezione/deselezione
    setInformazioni((prevInfo) => {
      const attivitaAggiornate = isChecked
        ? [...prevInfo.attivita, attivitaId] // Aggiungi l'ID se selezionato
        : prevInfo.attivita.filter((id) => id !== attivitaId); // Rimuovi l'ID se deselezionato

      return {
        ...prevInfo,
        attivita: attivitaAggiornate,
      };
    });
  };

  const handleAttivitaInOffertaCheckDentroModifica = (
    attivitaId,
    offertaIndex,
    isChecked
  ) => {
    // Aggiorna attivitaAccettate
    setAttivitaAccettateInOfferta((prevState) => ({
      ...prevState,
      [offertaIndex]: {
        ...prevState[offertaIndex],
        [attivitaId]: isChecked,
      },
    }));

    // Aggiorna info.attivita in base alla selezione/deselezione
    setInformazioni((prevInfo) => {
      const attivitaAggiornateInOfferta = isChecked
        ? [...prevInfo.attivita, attivitaId] // Aggiungi l'ID se selezionato
        : prevInfo.attivita.filter((id) => id !== attivitaId); // Rimuovi l'ID se deselezionato

      return {
        ...prevInfo,
        attivita: attivitaAggiornateInOfferta,
      };
    });
  };

  useEffect(() => {
    setInformazioni((prevInfo) => ({
      ...prevInfo,
      ordini: selezionatiOrdini
        .filter((offerta) => offerta !== "")
        .map((offerta) => parseInt(offerta)),
    }));
  }, [selezionatiOrdini]);

  useEffect(() => {
    setInformazioni((prevInfo) => ({
      ...prevInfo,
      offerte: selezionateOfferte
        .filter((offerta) => offerta !== "")
        .map((offerta) => parseInt(offerta)),
    }));
  }, [selezionateOfferte]);

  // Quando cambiano le attività accettate, aggiorna info.attivita
  useEffect(() => {
    if (tipoModal === "aggiungi") {
      const attivitaAccettateIds = Object.values(attivitaAccettate).flatMap(
        (attivitaMap) =>
          Object.entries(attivitaMap)
            .filter(([_, isChecked]) => isChecked)
            .map(([attivitaId, _]) => parseInt(attivitaId))
      );

      setInformazioni((prevInfo) => ({
        ...prevInfo,
        attivita: attivitaAccettateIds,
      }));
    }
  }, [attivitaAccettate]);

  useEffect(() => {
    if (tipoModal === "aggiungi") {
      const attivitaAccettateInOffertaIds = Object.values(
        attivitaAccettateInOfferta
      ).flatMap((attivitaMap) =>
        Object.entries(attivitaMap)
          .filter(([_, isChecked]) => isChecked)
          .map(([attivitaId, _]) => parseInt(attivitaId))
      );

      setInformazioni((prevInfo) => ({
        ...prevInfo,
        attivita: attivitaAccettateInOffertaIds,
      }));
    }
  }, [attivitaAccettateInOfferta]);

  return (
    <Row>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Protocollo
          </Form.Label>
          <Form.Control
            type="text"
            value={info.protocollo}
            placeholder={
              info.protocollo ? info.protocollo : "Campo autogenerato"
            }
            disabled
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label
            column
            sm="2"
            className="d-flex justify-content-between align-items-center"
          >
            Cliente *
            {clienteSelezionato == "" && (
              <span
                onClick={() => handleOpenNextModal("cliente")}
                className="text-primary text-decoration-none me-2"
              >
                <i className="bi bi-plus-circle-fill"></i>
              </span>
            )}
          </Form.Label>
          <Form.Select
            value={info.cliente}
            onChange={(e) => {
              changeInfo(e.target.value, "cliente");
              handleClienteChange(e.target.value);
            }}
            required
            disabled={clienteSelezionato !== "" || allClienti.length == 0}
          >
            <option value="" disabled hidden>
              Seleziona Cliente
            </option>
            {allClienti && allClienti.length !== 0 ? (
              allClienti.map((c, index) => (
                <option key={index} value={c.id}>
                  {c.nome} {c.cognome}
                </option>
              ))
            ) : (
              <option value="" disabled hidden>
                Nessun cliente disponibile
              </option>
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>

      <Col xs="4">
        <Form.Group className="mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Form.Label column sm="2" className="d-flex align-items-center">
                Sito *
              </Form.Label>
            </div>
            {tipoModal !== "modifica" && (
              <span
                onClick={() =>
                  isFormDisabled ? null : handleOpenNextModal("sito")
                }
                className={`text-${isFormDisabled ? "secondary" : "primary"}`}
              >
                <i className="bi bi-plus-circle-fill me-2"></i>
              </span>
            )}
          </div>
          <Form.Select
            value={info.sito}
            onChange={(e) => {
              changeInfo(e.target.value, "sito");
            }}
            required
            disabled={isFormDisabled || tipoModal == "modifica"}
          >
            <option value="" disabled hidden>
              Seleziona sito
            </option>
            {sitiFiltrati.length !== 0 ? (
              sitiFiltrati.map((sito, index) => (
                <option key={index} value={sito.id}>
                  {sito.sito_o_punto_vendita}
                </option>
              ))
            ) : (
              <option value="" disabled hidden>
                Nessun sito disponibile per questo cliente
              </option>
            )}
          </Form.Select>
        </Form.Group>
      </Col>

      {tipoModal !== "modifica" && (
        <Col xs="4">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Desinenza Attività *
            </Form.Label>
            <Form.Select
              value={info.desinenza || ""}
              onChange={(e) => changeInfo(e.target.value, "desinenza")}
              disabled={isFormDisabled}
              required
            >
              <option value="" disabled hidden>
                Seleziona Desinenza
              </option>
              <option value="NTI">Nuovi Impianti</option>
              <option value="IEL">Impianto Elettrico</option>
              <option value="FAT">Fattibilità</option>
              <option value="ESE">Progetto esecutivo</option>
              <option value="DEF">Progetto definitivo</option>
              <option value="SIC">Sicurezza</option>
              <option value="DL">Direzione lavori</option>
              <option value="SCA">Autorizzazione agli scarichi</option>
              <option value="AFO">Adeguamento fognario</option>
              <option value="IPL">Iplanet</option>
              <option value="DIS">Dismissioni</option>
              <option value="CPI">Rinnovo CPI</option>
              <option value="DP6">Dopa 6</option>
              <option value="IMM">Cambio immagine/pubblicità</option>
              <option value="RIA">Rimozione amianto</option>
              <option value="PTZ">Potenziamento</option>
              <option value="APE">Certificazione energetica (cen)</option>
              <option value="COL">Collaudi</option>
              <option value="GAR">Tender autostradali</option>
              <option value="VOL">Volture</option>
              <option value="GEO">Consulenza geologica</option>
              <option value="PPA">Pratiche amministrative</option>
              <option value="PER">Perizie</option>
              <option value="AAC">Adeguamento accessi</option>
              <option value="H2">Idrogeno</option>
              <option value="110">110</option>
              <option value="ALT">Altro meglio specificato sull oggetto</option>
              <option value="SIG">
                Servizi di ingegneria generali non oil
              </option>
            </Form.Select>
          </Form.Group>
        </Col>
      )}

      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label
            column
            sm="2"
            className="d-flex justify-content-between align-items-center"
          >
            Stato *
            {tipoModal !== "modifica" && (
              <span
                onClick={() =>
                  isFormDisabled ? null : handleOpenNextModal("stato")
                }
                className={`text-${
                  isFormDisabled ? "secondary" : "primary"
                } text-decoration-none me-2`}
              >
                <i className="bi bi-plus-circle-fill"></i>
              </span>
            )}
          </Form.Label>
          <Form.Select
            value={info.stato}
            onChange={(e) => changeInfo(e.target.value, "stato")}
            required
            disabled={allStati.length == 0 || isFormDisabled}
          >
            <option value="" disabled hidden>
              Seleziona Stato
            </option>
            {allStati && allStati.length !== 0 ? (
              allStati.map((c, index) => (
                <option key={index} value={c.id}>
                  {c.nome} {c.cognome}
                </option>
              ))
            ) : (
              <option value="" disabled hidden>
                Nessun stato disponibile
              </option>
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label
            column
            sm="2"
            className="d-flex justify-content-between align-items-center"
          >
            Data Inizio Prestazione*
          </Form.Label>
          <Form.Control
            type="date"
            value={info.data_inizio}
            onChange={(e) => changeInfo(e.target.value, "data_inizio")}
            required
            disabled={isFormDisabled}
          />{" "}
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label
            column
            sm="2"
            className="d-flex justify-content-between align-items-center"
          >
            Data Fine Prestazione
          </Form.Label>
          <Form.Control
            type="date"
            value={info.data_fine}
            onChange={(e) => changeInfo(e.target.value, "data_fine")}
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label
            column
            sm="2"
            className="d-flex justify-content-between align-items-center"
          >
            Project Manager
          </Form.Label>
          <Form.Select
            value={info.project_manager || ""}
            onChange={(e) => changeInfo(e.target.value, "project_manager")}
            disabled={allProjectManager.length == 0 || isFormDisabled}
          >
            <option value="" disabled hidden>
              Seleziona Project Manager
            </option>
            {allProjectManager && allProjectManager.length !== 0 ? (
              allProjectManager.map((pm, index) => {
                const dipendenteAssociato = allDipendenti.find(
                  (d) => d.id === pm.dipendente
                );
                return (
                  <option key={index} value={pm.id}>
                    {dipendenteAssociato
                      ? `${dipendenteAssociato.nome} ${dipendenteAssociato.cognome}`
                      : "Dipendente non trovato"}
                  </option>
                );
              })
            ) : (
              <option value="" disabled hidden>
                Nessun project manager disponibile
              </option>
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>

      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Numero Contratto
          </Form.Label>
          <Form.Control
            type="text"
            value={info.n_contratto}
            onChange={(e) => changeInfo(e.target.value, "n_contratto")}
            placeholder="Inserisci il numero del contratto"
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>

      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label
            column
            sm="2"
            className="d-flex justify-content-between align-items-center"
          >
            Fornitore
            {tipoModal !== "modifica" && (
              <span
                onClick={() =>
                  isFormDisabled ? null : handleOpenNextModal("fornitore")
                }
                className={`text-${
                  isFormDisabled ? "secondary" : "primary"
                } text-decoration-none me-2`}
              >
                <i className="bi bi-plus-circle-fill"></i>
              </span>
            )}
          </Form.Label>
          <Form.Select
            value={info.fornitore}
            onChange={(e) => changeInfo(e.target.value, "fornitore")}
            disabled={allFornitori.length == 0 || isFormDisabled}
          >
            <option value="" disabled hidden>
              Seleziona Fornitore
            </option>
            {allFornitori && allFornitori.length !== 0 ? (
              allFornitori.map((c, index) => (
                <option key={index} value={c.id}>
                  {c.nome} {c.cognome}
                </option>
              ))
            ) : (
              <option value="" disabled hidden>
                Nessun fornitore disponibile
              </option>
            )}
          </Form.Select>{" "}
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>

      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label
            column
            sm="2"
            className="d-flex justify-content-between align-items-center"
          >
            Referente cliente
            {tipoModal !== "modifica" && (
              <span
                onClick={() =>
                  isFormDisabled ? null : handleOpenNextModal("responsabili")
                }
                className={`text-${
                  isFormDisabled ? "secondary" : "primary"
                } text-decoration-none me-2`}
              >
                <i className="bi bi-plus-circle-fill"></i>
              </span>
            )}
          </Form.Label>
          <Form.Select
            value={info.responsabile || ""}
            onChange={(e) => changeInfo(e.target.value, "responsabile")}
            disabled={isFormDisabled}
          >
            <option value="" disabled hidden>
              Seleziona Referente cliente
            </option>
            {responsabiliFiltrati && responsabiliFiltrati.length !== 0 ? (
              responsabiliFiltrati.map((c, index) => (
                <option key={index} value={c.id}>
                  {c.nome} {c.cognome}
                </option>
              ))
            ) : (
              <option value="" disabled hidden>
                Nessun referente disponibile
              </option>
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>

      <Col xs="12">
        <Form.Group className="mb-3">
          <Form.Label>Note</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={info.note}
            onChange={(e) => changeInfo(e.target.value, "note")}
            placeholder="Inserisci note"
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>

      {tipoModal === "modifica" ? (
        <>
          <Col
            xs="12"
            className="d-flex justify-content-between align-items-center mb-3"
          >
            <h5>Lista Offerte</h5>
            <Button
              disabled={
                isFormDisabled ||
                offerteFiltrate.length === 0 ||
                areAllOfferteUsed()
              }
              variant={
                isFormDisabled ||
                offerteFiltrate.length === 0 ||
                areAllOfferteUsed()
                  ? "secondary"
                  : "primary"
              }
              onClick={aggiungiOfferta}
            >
              <i className="bi bi-plus-circle-fill"></i>
            </Button>
          </Col>

          {selezionateOfferte.map((offerta, index) => {
            const offertaDaMostrare = allOfferte.filter(
              (offertaLista) => offertaLista.id == offerta
            );
            return (
              <Col xs="12" key={index} className="mb-3">
                <Form.Group className="d-flex align-items-center mb-3">
                  <Form.Select
                    value={offerta}
                    onChange={(e) => handleOffertaChange(e.target.value, index)}
                    className="me-3"
                    style={{ width: "50%", flexGrow: 0 }}
                  >
                    <option value="" disabled hidden>
                      Seleziona Offerta
                    </option>
                    {getFilteredOfferteOptions(index).map((offertaFiltrata) => (
                      <option
                        key={offertaFiltrata.id}
                        value={offertaFiltrata.id}
                      >
                        {offertaFiltrata.protocollo}
                      </option>
                    ))}
                  </Form.Select>
                  <Button
                    className="btn-primary"
                    onClick={() => rimuoviOfferta(index)}
                  >
                    <i className="bi bi-dash-circle-fill"></i>
                  </Button>
                </Form.Group>
                {offertaDaMostrare[0] && (
                  <h6>
                    Numero contratto:{" "}
                    {offertaDaMostrare[0].n_contratto ||
                      "Nessun numero contratto da mostrare"}
                  </h6>
                )}
                {attivitaPerOfferta[index] &&
                  attivitaPerOfferta[index].map((attivita) => (
                    <div key={attivita.id} className="mb-2">
                      <Form.Check
                        type="checkbox"
                        label={
                          <>
                            <span className="mx-2">
                              <b>Protocollo:</b> {`${attivita.protocollo}`}
                            </span>
                            <span className="mx-2">
                              <b>Denominazione:</b>{" "}
                              {`${attivita.denominazione}`}
                            </span>
                            <span className="mx-2">
                              <b>Importo:</b> € {`${attivita.importo}`}
                            </span>
                          </>
                        }
                        checked={
                          attivitaAccettateInOfferta[index]?.[attivita.id] ||
                          false
                        }
                        onChange={(e) =>
                          handleAttivitaInOffertaCheckDentroModifica(
                            attivita.id,
                            index,
                            e.target.checked
                          )
                        }
                      />
                    </div>
                  ))}
              </Col>
            );
          })}
        </>
      ) : (
        <>
          <Col
            xs="12"
            className="d-flex justify-content-between align-items-center mb-3"
          >
            <h5>Lista Offerte</h5>
            <Button
              disabled={
                isFormDisabled ||
                offerteFiltrate.length === 0 ||
                areAllOfferteUsed()
              }
              variant={
                isFormDisabled ||
                offerteFiltrate.length === 0 ||
                areAllOfferteUsed()
                  ? "secondary"
                  : "primary"
              }
              onClick={aggiungiOfferta}
            >
              <i className="bi bi-plus-circle-fill"></i>
            </Button>
          </Col>

          {selezionateOfferte.map((offerta, index) => (
            <Col xs="12" key={index} className="mb-3">
              <Form.Group className="d-flex align-items-center mb-3">
                <Form.Select
                  value={offerta}
                  onChange={(e) => handleOffertaChange(e.target.value, index)}
                  className="me-3"
                  style={{ width: "50%", flexGrow: 0 }}
                >
                  <option value="" disabled hidden>
                    Seleziona Offerta
                  </option>
                  {getFilteredOfferteOptions(index).map((offertaFiltrata) => (
                    <option key={offertaFiltrata.id} value={offertaFiltrata.id}>
                      {offertaFiltrata.protocollo}
                    </option>
                  ))}
                </Form.Select>
                <Button
                  className="btn-primary"
                  onClick={() => rimuoviOfferta(index)}
                >
                  <i className="bi bi-dash-circle-fill"></i>
                </Button>
              </Form.Group>

              {attivitaPerOfferta[index] &&
                attivitaPerOfferta[index].map((attivita) => (
                  <div key={attivita.id} className="mb-2">
                    <Form.Check
                      type="checkbox"
                      label={
                        <>
                          <span className="mx-2">{`Protocollo: ${attivita.protocollo}`}</span>
                          <span className="mx-2">{`Denominazione: ${attivita.denominazione}`}</span>
                          <span className="mx-2">{`Importo: € ${attivita.importo}`}</span>
                        </>
                      }
                      checked={
                        attivitaAccettateInOfferta[index]?.[attivita.id] ||
                        false
                      }
                      onChange={(e) =>
                        handleAttivitaInOffertaCheck(
                          attivita.id,
                          index,
                          e.target.checked
                        )
                      }
                    />
                  </div>
                ))}
            </Col>
          ))}
        </>
      )}

      {tipoModal === "modifica" ? (
        <>
          <Col
            xs="12"
            className="d-flex justify-content-between align-items-center mb-1"
          >
            <h5 className="mb-0">Lista Ordini</h5>
          </Col>

          {selezionatiOrdini.map((ordine, index) => {
            const ordineDaMostrare = allOrdini.filter(
              (ordineLista) => ordineLista.id == ordine
            );

            return (
              <Col xs="12" key={index} className="mb-1">
                <Form.Group className="d-flex align-items-center mb-3"></Form.Group>
                {ordineDaMostrare[0] !== undefined && (
                  <h5>{ordineDaMostrare[0].protocollo}</h5>
                )}
                <ul>
                  {lavoriPerOrdine[index] &&
                    lavoriPerOrdine[index].map((lavoro) => {
                      if (lavoro)
                        return (
                          <li key={lavoro.id}>
                            <b>Protocollo:</b> {lavoro.protocollo},{" "}
                            <b>Denominazione: </b>
                            {lavoro.denominazione}, <b>Importo :</b> €{" "}
                            {lavoro.importo}
                          </li>
                        );
                    })}
                </ul>
              </Col>
            );
          })}
        </>
      ) : (
        ""
      )}
    </Row>
  );
};

export default FormCommesse;
