import AttivitaDetails from "./details/AttivitaDetails";
import BancheDetails from "./details/Banche";
import ClienteDetails from "./details/ClientDetails";
import CommesseDetails from "./details/CommesseDetails";
import FattureDetails from "./details/FattureDetails";
import FornitoriDetails from "./details/FornitoriDetails";
import IncaricoFornitoriDetails from "./details/IncaricoFornitoriDetails";
import OfferteDetails from "./details/Offerte";
import OrdiniDetails from "./details/OrdiniDetails";
import SediDetails from "./details/Sedi";
import SitiDetails from "./details/SitiDetails";
import StatiDetails from "./details/StatiDetails";
import TipologiaAttivitaDetails from "./details/TipologiaAttivitaDetails";

const Details = ({ classe, data }) => {
  return classe === "cliente" ? (
    <ClienteDetails data={data} />
  ) : classe === "fattura" ? (
    <FattureDetails data={data} />
  ) : classe === "attivita" ? (
    <AttivitaDetails data={data} />
  ) : classe === "ordine" ? (
    <OrdiniDetails data={data} />
  ) : classe === "commessa" ? (
    <CommesseDetails data={data} />
  ) : classe === "offerta" ? (
    <OfferteDetails data={data} />
  ) : classe === "sede" ? (
    <SediDetails data={data} />
  ) : classe === "banca" ? (
    <BancheDetails data={data} />
  ) : classe === "fornitore" ? (
    <FornitoriDetails data={data} />
  ) : classe === "incarico-fornitore" ? (
    <IncaricoFornitoriDetails data={data} />
  ) : classe === "sito" ? (
    <SitiDetails data={data} />
  ) : classe === "stato" ? (
    <StatiDetails data={data} />
  ) : classe === "tipologia-attivita" ? (
    <TipologiaAttivitaDetails data={data} />
  ) : null;
};
export default Details;
