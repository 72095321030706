import {
  SET_DATA_BANCA,
  SET_DATA_BANCA_INTERNAL,
  SET_DATA_CLIENTI,
  SET_DATA_COMMESSA,
  SET_DATA_FATTURE,
  SET_DATA_DELETE,
  SET_DATA_FORNITORE,
  SET_DATA_GET,
  SET_DATA_OFFERTE,
  SET_DATA_ORDINE,
  SET_DATA_POST,
  SET_DATA_PUT,
  SET_DATA_SITI,
  SET_DATA_STATI,
  SET_DATA_DIPENDENTE,
  SET_DATA_LAVORI,
  SET_DATA_RESPONSABILI,
  SET_DATA_PROJECT_MANAGER,
  SET_DATA_TIPOLOGIA_ATTIVITA,
  SET_ISLOAD_DEL,
  SET_ISLOAD_GET,
  SET_ISLOAD_POST,
  SET_ISLOAD_PUT,
  SET_PAYLOAD_DEL,
  SET_PAYLOAD_POST,
  SET_PAYLOAD_PUT,
  SET_URL_DELETE,
  SET_URL_GET,
  SET_URL_POST,
  SET_URL_PUT,
  SET_DATA_ATTIVITA,
} from "../actions";

const initialState = {
  dataCliente: [],
  dataOfferte: [],
  dataBanca: [],
  dataBancaInternal: [],
  dataOrdine: [],
  dataFatture: [],
  dataSiti: [],
  dataFornitore: [],
  dataCommesse: [],
  dataStati: [],
  dataTipologiaAttivita: [],
  dataAttivita: [],
  dataProjectManager: [],
  dataDipendente: [],
  dataLavori: [],
  dataResponsabili: [],
};

const selectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_CLIENTI:
      return {
        ...state,

        dataCliente: action.payload,
      };
    case SET_DATA_ORDINE:
      return {
        ...state,

        dataOrdine: action.payload,
      };
    case SET_DATA_SITI:
      return {
        ...state,

        dataSiti: action.payload,
      };
    case SET_DATA_FORNITORE:
      return {
        ...state,

        dataFornitore: action.payload,
      };
    case SET_DATA_BANCA:
      return {
        ...state,

        dataBanca: action.payload,
      };
    case SET_DATA_BANCA_INTERNAL:
      return {
        ...state,

        dataBancaInternal: action.payload,
      };
    case SET_DATA_FATTURE:
      return {
        ...state,

        dataFatture: action.payload,
      };
    case SET_DATA_COMMESSA:
      return {
        ...state,

        dataCommesse: action.payload,
      };
    case SET_DATA_OFFERTE:
      return {
        ...state,

        dataOfferte: action.payload,
      };
    case SET_DATA_STATI:
      return {
        ...state,

        dataStati: action.payload,
      };

    case SET_DATA_TIPOLOGIA_ATTIVITA:
      return {
        ...state,

        dataTipologiaAttivita: action.payload,
      };

    case SET_DATA_DIPENDENTE:
      return {
        ...state,

        dataDipendente: action.payload,
      };

    case SET_DATA_PROJECT_MANAGER:
      return {
        ...state,

        dataProjectManager: action.payload,
      };

    case SET_DATA_ATTIVITA:
      return {
        ...state,

        dataAttivita: action.payload,
      };

    case SET_DATA_LAVORI:
      return {
        ...state,

        dataLavori: action.payload,
      };

    case SET_DATA_RESPONSABILI:
      return {
        ...state,

        dataResponsabili: action.payload,
      };

    default:
      return state;
  }
};
export default selectReducer;
