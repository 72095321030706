// CLIENTI_COL
export const CLIENTI_COL = [
  {
    name: "Ragione Sociale / Cognome",
    selector: (row) => row.cognome,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.cognome.toLowerCase();
      const nameB = rowB.cognome.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Nome",
    selector: (row) => row.nome,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.name.toLowerCase();
      const nameB = rowB.name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.email.toLowerCase();
      const nameB = rowB.email.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  { name: "Telefono", selector: (row) => row.telefono, sortable: true },
  { name: "Tipologia", selector: (row) => row.tipologia, sortable: true },
  { name: "Partita IVA", selector: (row) => row.partita_iva, sortable: true },
  // ... Aggiungi altre colonne se necessario ...
];

// FATTURE_COL
export const FATTURE_COL = [
  { name: "N° Fattura", selector: (row) => row.n_fattura, sortable: true },
  {
    name: "Cliente",
    selector: (row) => row.cliente,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.cliente.toLowerCase();
      const nameB = rowB.cliente.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Ordine",
    selector: (row) => row.ordine,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.ordine.toLowerCase();
      const nameB = rowB.ordine.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Imponibile",
    selector: (row) => "€ " + row.imponibile,
    sortable: true,
  },
  { name: "Incassata", selector: (row) => row.incassata, sortable: true },
  { name: "Data Scadenza", selector: (row) => row.scadenza, sortable: true },
  // ... Aggiungi altre colonne se necessario ...
];

// ATTIVITA_COL
export const ATTIVITA_COL = [
  {
    name: "Denominazione",
    selector: (row) => row.denominazione,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.denominazione.toLowerCase();
      const nameB = rowB.denominazione.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Protocollo",
    selector: (row) => row.protocollo,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.protocollo.toLowerCase();
      const nameB = rowB.protocollo.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Offerta",
    selector: (row) => row.offerta,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.offerta.toLowerCase();
      const nameB = rowB.offerta.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Cliente",
    selector: (row) => row.cliente,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.cliente.toLowerCase();
      const nameB = rowB.cliente.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Tipologia",
    selector: (row) => row.tipologia,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.tipologia.toLowerCase();
      const nameB = rowB.tipologia.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Sito",
    selector: (row) => row.sito,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.sito.toLowerCase();
      const nameB = rowB.sito.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  { name: "Importo", selector: (row) => row.importo, sortable: true },
  // ... Aggiungi altre colonne se necessario ...
];
// OFFERTE_COL
export const OFFERTE_COL = [
  {
    name: "Protocollo",
    selector: (row) => row.protocollo,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.protocollo.toLowerCase();
      const nameB = rowB.protocollo.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  { name: "Data", selector: (row) => row.data_offerta, sortable: true },
  {
    name: "Cliente",
    selector: (row) => row.cliente,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.cliente.toLowerCase();
      const nameB = rowB.cliente.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Sito",
    selector: (row) => row.sito,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.sito.toLowerCase();
      const nameB = rowB.sito.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Referente Cliente",
    selector: (row) => row.referente_cliente,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.referente_cliente.toLowerCase();
      const nameB = rowB.referente_cliente.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Referente Interno",
    selector: (row) => row.referente_interno,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.referente_interno.toLowerCase();
      const nameB = rowB.referente_interno.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  // ... Aggiungi altre colonne se necessario ...
];

// ORDINI_COL
export const ORDINI_COL = [
  {
    name: "Protocollo",
    selector: (row) => row.protocollo,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.protocollo.toLowerCase();
      const nameB = rowB.protocollo.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Importo",
    selector: (row) => "€ " + row.importo_totale,
    sortable: true,
  },
  {
    name: "Cliente",
    selector: (row) => row.cliente,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.cliente.toLowerCase();
      const nameB = rowB.cliente.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  // ... Aggiungi altre colonne se necessario ...
];

// COMMESSE_COL
export const COMMESSE_COL = [
  { name: "coloreStato", selector: (row) => row.coloreStato, sortable: false },
  {
    name: "Protocollo",
    selector: (row) => row.protocollo,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.protocollo.toLowerCase();
      const nameB = rowB.protocollo.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Cliente",
    selector: (row) => row.cliente,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.cliente.toLowerCase();
      const nameB = rowB.cliente.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Fornitore",
    selector: (row) => row.fornitore,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.fornitore.toLowerCase();
      const nameB = rowB.fornitore.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  { name: "Data Apertura", selector: (row) => row.data_inizio, sortable: true },
  // ... Aggiungi altre colonne se necessario ...
];

export const SEDI_COL = [
  {
    name: "Denominazione",
    selector: (row) => row.nome,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.nome.toLowerCase();
      const nameB = rowB.nome.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Località",
    selector: (row) => row.via,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.via.toLowerCase();
      const nameB = rowB.via.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  // { name: "Comune", selector: (row) => row.comune, sortable: true },
  { name: "Cap", selector: (row) => row.cap, sortable: true },
  {
    name: "Provincia",
    selector: (row) => row.provincia,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.provincia.toLowerCase();
      const nameB = rowB.provincia.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Regione",
    selector: (row) => row.regione,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.regione.toLowerCase();
      const nameB = rowB.regione.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  { name: "Telefono", selector: (row) => row.telefono, sortable: true },
  // Aggiungi altre colonne
];

export const BANCHE_COL = [
  {
    name: "Nome",
    selector: (row) => row.nome,
    sortable: true,

    sortFunction: (rowA, rowB) => {
      const nameA = rowA.nome.toLowerCase();
      const nameB = rowB.nome.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  { name: "Iban", selector: (row) => row.iban, sortable: true },
  {
    name: "Comune",
    selector: (row) => row.comune,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.comune.toLowerCase();
      const nameB = rowB.comune.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Regione",
    selector: (row) => row.regione,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.regione.toLowerCase();
      const nameB = rowB.regione.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Nome consulente",
    selector: (row) => row.nome_consulente,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.nome_consulente.toLowerCase();
      const nameB = rowB.nome_consulente.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  // Aggiungi altre colonne
];

export const FORNITORI_COL = [
  {
    name: "Nome",
    selector: (row) => row.nome,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.nome.toLowerCase();
      const nameB = rowB.nome.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Cognome",
    selector: (row) => row.cognome,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.cognome.toLowerCase();
      const nameB = rowB.cognome.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  { name: "Partita Iva", selector: (row) => row.partita_iva, sortable: true },
  {
    name: "E-mail",
    selector: (row) => row.email,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.email.toLowerCase();
      const nameB = rowB.email.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  { name: "Telefono", selector: (row) => row.telefono, sortable: true },
  // Aggiungi altre colonne
];

export const INCARICO_FORNITORI_COL = [
  {
    name: "Fornitore",
    selector: (row) => row.fornitore,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.fornitore.toLowerCase();
      const nameB = rowB.fornitore.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Descrizione",
    selector: (row) => row.descrizione,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.descrizione.toLowerCase();
      const nameB = rowB.descrizione.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  { name: "Data Inizio", selector: (row) => row.data_inizio, sortable: true },
  { name: "Data Fine", selector: (row) => row.data_fine, sortable: true },
  {
    name: "Commessa",
    selector: (row) => row.commessa,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.commessa.toLowerCase();
      const nameB = rowB.commessa.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  // Aggiungi altre colonne
];

export const SITI_COL = [
  {
    name: "Sito /n° pv",
    selector: (row) => row.sito_o_punto_vendita,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.sito_o_punto_vendita.toLowerCase();
      const nameB = rowB.sito_o_punto_vendita.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Cliente",
    selector: (row) => row.cliente,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.cliente.toLowerCase();
      const nameB = rowB.cliente.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Località",
    selector: (row) => row.via,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.via.toLowerCase();
      const nameB = rowB.via.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  // { name: "Comune", selector: (row) => row.comune, sortable: true },
  { name: "Telefono", selector: (row) => row.numero_telefono, sortable: true },
  // Aggiungi altre colonne
];

export const STATI_COL = [
  {
    name: "Nome",
    selector: (row) => row.nome,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.nome.toLowerCase();
      const nameB = rowB.nome.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Descrizione",
    selector: (row) => row.descrizione,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.descrizione.toLowerCase();
      const nameB = rowB.descrizione.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  { name: "Colore", selector: (row) => row.colore, sortable: true },
  // Aggiungi altre colonne
];

export const TIPOLOGIA_ATTIVITA_COL = [
  {
    name: "Denominazione",
    selector: (row) => row.denominazione,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.denominazione.toLowerCase();
      const nameB = rowB.denominazione.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "Descrizione",
    selector: (row) => row.descrizione,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.descrizione.toLowerCase();
      const nameB = rowB.descrizione.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  // Aggiungi altre colonne
];

export const DIPENDENTI_COL = [
  {
    name: "nome",
    selector: (row) => row.nome,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.nome.toLowerCase();
      const nameB = rowB.nome.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "cognome",
    selector: (row) => row.cognome,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.cognome.toLowerCase();
      const nameB = rowB.cognome.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "email",
    selector: (row) => row.email_utente,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.email.toLowerCase();
      const nameB = rowB.email.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  { name: "telefono", selector: (row) => row.telefono, sortable: true },
];
