import { useSelector } from "react-redux";

export const GetOrdine = (lista, lista2, lista3, setData) => {
  if (
    lista &&
    lista2 &&
    lista3 &&
    lista.length >= 0 &&
    lista2.length >= 0 &&
    lista3.length >= 0
  ) {
    const combinedList = lista.map((item) => {
      let cliente = lista2.filter((item2) => item.cliente == item2.id);
      let fullName = cliente[0].nome + " " + cliente[0].cognome;

      let combinedItem = {
        ...item,
        cliente: fullName,
        clienteId: cliente[0].id,
        // attivitaId: attivitaNames,
        openModal: (
          <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
        ),
      };
      return combinedItem;
    });

    const combinedList2 = combinedList.map((item) => {
      let combinedItem = {
        ...item,
        lavori: item.lavori,
      };
      return combinedItem;
    });
    setData(combinedList2);
  } else {
    if (lista && lista.length > 0) {
      const updatedList = lista.map((item) => ({
        ...item,
        openModal: (
          <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
        ),
      }));

      setData(updatedList);
    }
  }
};
