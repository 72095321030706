import { Col, Form, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSelectClienti,
  fetchSelectBanca,
  fetchSelectOrdine,
  setUrlFetch,
  SET_URL_FILTER,
  setShow,
  SET_SHOW_FILTER,
  setInfoFilter,
  setArrayFilter,
} from "../../redux/actions";
import { useEffect } from "react";
import { URL_FATTURA } from "../../lib/url";
import { setUrlFilterFatture } from "./setUrl/setUrlFilterFatture";

const FilterFatture = ({ info, setInformazioni }) => {
  const allClienti = useSelector((state) => state.select.dataCliente);
  const allBanche = useSelector((state) => state.select.dataBanca);
  const allOrdini = useSelector((state) => state.select.dataOrdine);
  const token = useSelector((state) => state.login.token);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSelectClienti(token));
    dispatch(fetchSelectOrdine(token));
    dispatch(fetchSelectBanca(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeInfo = (value, name) => {
    setInformazioni({
      ...info,
      [name]: value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setInfoFilter(info));

    let nuovoOggetto = { ...info };

    if (nuovoOggetto.cliente) {
      const y = allClienti.filter((f) => f.id == nuovoOggetto.cliente);
      nuovoOggetto = {
        ...nuovoOggetto,
        cliente: y[0].nome + " " + y[0].cognome,
      };
    }
    if (nuovoOggetto.ordine) {
      const x = allOrdini.filter((f) => f.id == nuovoOggetto.ordine);
      nuovoOggetto = { ...nuovoOggetto, ordine: x[0].protocollo };
    }
    if (nuovoOggetto.banca) {
      const y = allBanche.filter((f) => f.id == nuovoOggetto.banca);
      nuovoOggetto = { ...nuovoOggetto, banca: y[0].nome };
    }

    const arrayPills = Object.entries(nuovoOggetto);

    dispatch(setArrayFilter(arrayPills));

    setUrlFilterFatture(arrayPills, info, dispatch);

    dispatch(setShow(SET_SHOW_FILTER, false));
    setInformazioni({});
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <Row>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Numero fattura
            </Form.Label>
            <Form.Control
              type="text"
              value={info.n_fattura}
              onChange={(e) => changeInfo(e.target.value, "n_fattura")}
              placeholder="Inserisci il numero della fattura"
            />
          </Form.Group>
        </Col>

        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label
              column
              sm="2"
              className="d-flex justify-content-between align-items-center"
            >
              Cliente
            </Form.Label>
            <Form.Select
              value={info.cliente || ""}
              onChange={(e) => changeInfo(e.target.value, "cliente")}
              aria-label="Filtra per Cliente"
            >
              <option value="" disabled hidden>
                Seleziona Cliente
              </option>
              {allClienti.length !== 0 ? (
                allClienti.map((c, index) => (
                  <option key={index} value={c.id}>
                    {c.nome} {c.cognome}
                  </option>
                ))
              ) : (
                <option value="" disabled hidden>
                  Nessun Cliente disponibile
                </option>
              )}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label
              column
              sm="2"
              className="d-flex justify-content-between align-items-center"
            >
              Ordine
            </Form.Label>
            <Form.Select
              value={info.ordine || ""}
              onChange={(e) => changeInfo(e.target.value, "ordine")}
              aria-label="Filtra per ordine"
            >
              <option value="" disabled hidden>
                Seleziona Ordine
              </option>
              {allOrdini.length !== 0 ? (
                allOrdini.map((o, index) => (
                  <option key={index} value={o.id}>
                    {o.protocollo}
                  </option>
                ))
              ) : (
                <option value="" disabled hidden>
                  Nessun Ordine disponibile
                </option>
              )}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label
              column
              sm="2"
              className="d-flex justify-content-between align-items-center"
            >
              Banca
            </Form.Label>
            <Form.Select
              value={info.banca || ""}
              onChange={(e) => changeInfo(e.target.value, "banca")}
              aria-label="Filtra per Banca"
            >
              <option value="" disabled hidden>
                Seleziona Banca
              </option>
              {allBanche.length !== 0 ? (
                allBanche.map((b, index) => (
                  <option key={index} value={b.id}>
                    {b.nome}
                  </option>
                ))
              ) : (
                <option value="" disabled hidden>
                  Nessuna Banca disponibile
                </option>
              )}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Anno Competenza
            </Form.Label>
            <Form.Control
              type="number"
              value={info.anno_competenza}
              onChange={(e) => changeInfo(e.target.value, "anno_competenza")}
              placeholder="Inserisci l'anno di competenza"
            />
          </Form.Group>
        </Col>

        {/* <Col xs="12" className="d-flex align-items-center">
          <Form.Group>
            <Form.Check
              type="switch"
              id="incassata-switch"
              label="Incassata"
              checked={info.incassata}
              onChange={(e) => changeInfo(e.target.checked, "incassata")}
            />
          </Form.Group>
        </Col> */}
        <Col xs="12" className="d-flex align-items-center">
          <Form.Group>
            <div key={`inline-radio`} className="mb-3">
              <Form.Check
                inline
                label="Incassata"
                name="incassata"
                type="radio"
                id={`incassata-true`}
                checked={info.incassata === true}
                onChange={() => changeInfo(true, "incassata")}
              />
              <Form.Check
                inline
                label="Non Incassata"
                name="incassata"
                type="radio"
                id={`incassata-false`}
                checked={info.incassata === false}
                onChange={() => changeInfo(false, "incassata")}
              />
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Col xs={{ span: 12 }} className="d-flex justify-content-end">
        <Button type="submit" variant="primary">
          Applica Filtri
        </Button>
      </Col>
    </Form>
  );
};

export default FilterFatture;
