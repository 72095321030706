import { Col, Container, Row } from "react-bootstrap";

const BancheDetails = ({ data }) => {
  return (
    <Container className="border bg-light">
      <Row>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">Email </p>
          <span className="dettagli"> {data.email}</span>
        </Col>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">Telefono</p>
          <span className="dettagli">{data.telefono}</span>
        </Col>
      </Row>
    </Container>
  );
};
export default BancheDetails;
