import { fetchAllPut, fetchGeneric } from "../../redux/actions";
import { URL_RESPONSABILI } from "../url";

export const setFetchResponsabili = (type, payload, token, dispatch) => {
  if (type === "modifica") {
    dispatch(fetchAllPut(URL_RESPONSABILI + payload.id, payload, token));
  } else if (type === "aggiungi") {
    dispatch(fetchGeneric(URL_RESPONSABILI, payload, token));
  }
};
