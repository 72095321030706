import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ADD_TIPOLOGIA_ATTIVITA } from "../../lib/payloads";
import { SET_INFO1, SET_INFO3, setInfo } from "../../redux/actions";

const FormTipologiaAttivita = () => {
  const info1 = useSelector((state) => state.modal.info1);
  const show1 = useSelector((state) => state.modal.show1);
  const show2 = useSelector((state) => state.modal.show2);
  const show3 = useSelector((state) => state.modal.show3);
  const show4 = useSelector((state) => state.modal.show4);
  const dispatch = useDispatch();
  const [info, setInformazioni] = useState({});
  const path = useSelector((state) => state.locationPage.path);

  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };

  useEffect(() => {
    if (path == "tipologia-attivita") {
      dispatch(setInfo(SET_INFO1, info));
    } else {
      dispatch(setInfo(SET_INFO3, info));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  useEffect(() => {
    if (show1 && show2 && show3 && show4) {
      setInformazioni(ADD_TIPOLOGIA_ATTIVITA);
    } else if (show1 && show2 && show3) {
      setInformazioni(ADD_TIPOLOGIA_ATTIVITA);
    } else if (show1 && show2) {
      setInformazioni(ADD_TIPOLOGIA_ATTIVITA);
    } else {
      setInformazioni(info1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Col xs="12">
        <Form.Group className="mb-3">
          <Form.Label>Denominazione *</Form.Label>
          <Form.Control
            type="text"
            value={info.denominazione}
            onChange={(e) => changeInfo(e.target.value, "denominazione")}
            placeholder="Inserisci la denominazione"
            required
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="12">
        <Form.Group className="mb-3">
          <Form.Label>Descrizione</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={info.descrizione}
            onChange={(e) => changeInfo(e.target.value, "descrizione")}
            placeholder="Inserisci una descrizione"
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default FormTipologiaAttivita;
