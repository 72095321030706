import { fetchAllPut, fetchGeneric } from "../../redux/actions";
import { URL_SEDE } from "../url";

export const setFetchSede = (type, payload, token, dispatch) => {
  if (type === "modifica") {
    dispatch(fetchAllPut(URL_SEDE + payload.id, payload, token));
  } else if (type === "aggiungi") {
    dispatch(fetchGeneric(URL_SEDE, payload, token));
  }
};
