export const ADD_CLIENTE = {
  cognome: "",
  nome: "",
  email: "",
  telefono: "",
  tipologia: "",
  partita_iva: "",
  via: "",
  comune: "",
  provincia: "",
  regione: "",
  cap: "",
  sito_web: "",
  fatturazione_elettronica: "",
};
export const ADD_FATTURE = {
  n_fattura: "",
  cliente: "",
  ordine: "",
  imponibile: "",
  banca: "",
  anno_competenza: "",
  data_emissione: "",
  scadenza: "",
  incassata: false,
  note: "",
};
export const ADD_BANCHE = {
  nome: "",
  iban: "",
  via: "",
  nro: "",
  comune: "",
  regione: "",
  cap: "",
  telefono: "",
  mail: "",
  nome_consulente: "",
};
export const ADD_FORNITORI = {
  nome: "",
  cognome: "",
  partita_iva: "",
  telefono: "",
  email: "",
  via: "",
  comune: "",
  cap: "",
  provincia: "",
  regione: "",
};
export const ADD_INCARICO_FORNITORI = {
  fornitore: "",
  descrizione: ".",
  data_inizio: "",
  data_fine: "",
  commessa: "",
  importo: "",
  dati_inviati: false,
  accettato: false,
};
export const ADD_ATTIVITA = {
  denominazione: "",
  offerta: "",
  cliente: "",
  tipologia: "",
  sito: "",
  importo: "",
  descrizione: "",
};
export const ADD_OFFERTE = {
  sito: "",
  cliente: "",
  data_offerta: "",
  referente_cliente: "",
  tel_referente_cliente: "",
  referente_interno: "",
  tel_referente_interno: "",
  n_contratto: "",
  oggetto: "",
  note: "",
  lista_attivita: "",
  data_inizio_contratto: "",
  data_fine_contratto: "",
};
export const ADD_ORDINI = {
  cliente: "",
  offerte: "",
  attivita: "",
  lavori: [],
};
export const ADD_COMMESSE = {
  protocollo: "",
  fornitore: "",
  stato: "",
  cliente: "",
  data_inizio: "",
  note: "",
  data_fine: null,
  attivita: [],
  attivita_offerte: [],
  offerte: [],
  ordini: [],
  project_manager: "",
  sito: "",
  n_contratto: "",
  desinenza: "",
};
export const ADD_SEDI = {
  nome: "",
  telefono: "",
  via: "",
  numero_civico: "",
  cap: "",
  provincia: "",
  regione: "",
};
export const ADD_SITI = {
  sito_o_punto_vendita: "",
  numero_telefono: "",
  cliente: "",
  coordinate_o_link: "",
  codice_vecchio: "",
  via: "",
  comune: "",
  provincia: "",
  regione: "",
  cap: "",
  sito: "",
};
export const ADD_STATI = {
  nome: "",
  descrizione: "",
  colore: "",
};
export const ADD_TIPOLOGIA_ATTIVITA = {
  denominazione: "",
  descrizione: "",
};

export const ADD_RESPONSABILE = {
  nome: "",
  cognome: "",
  cliente: "",
};

export const ADD_DIPENDENTE = {
  email_utente: "",
  nome: "",
  cognome: "",
  telefono: "",
  utente: null,
};
