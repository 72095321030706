import {
  SET_DATA_DELETE,
  SET_DATA_GET,
  SET_DATA_GET2,
  SET_DATA_GET3,
  SET_DATA_GET4,
  SET_DATA_GET5,
  SET_DATA_GET6,
  SET_DATA_GET_TABLE,
  SET_DATA_POST,
  SET_DATA_PUT,
  SET_ISLOAD_DEL,
  SET_ISLOAD_GET,
  SET_ISLOAD_GET2,
  SET_ISLOAD_GET3,
  SET_ISLOAD_GET4,
  SET_ISLOAD_GET5,
  SET_ISLOAD_GET6,
  SET_ISLOAD_POST,
  SET_ISLOAD_PUT,
  SET_PAYLOAD_DEL,
  SET_PAYLOAD_POST,
  SET_PAYLOAD_PUT,
  SET_URL_DELETE,
  SET_URL_GET,
  SET_URL_GET2,
  SET_URL_GET3,
  SET_URL_GET4,
  SET_URL_GET5,
  SET_URL_GET6,
  SET_URL_POST,
  SET_URL_PUT,
  SET_LOGS,
  SET_URL_FILTER,
  SET_DATA_LOGS_RENDER,
  SET_DATA_LOGS_DIPENDENTI,
} from "../actions";

const initialState = {
  urlGet: "",
  urlGet2: "",
  urlGet3: "",
  urlGet4: "",
  urlGet5: "",
  urlGet6: "",
  urlPost: "",
  urlPut: "",
  urlDel: "",
  urlFilter: "",
  dataGetTable: [],
  dataGet: [],
  dataGet2: [],
  dataGet3: [],
  dataGet4: [],
  dataGet5: [],
  dataGet6: [],
  dataGetDipendenti: [],
  dataGetLogs: [],
  dataPost: [],
  dataPut: [],
  dataDel: [],
  payloadPost: {},
  payloadPut: {},
  payloadDel: {},
  isLoadedGet: false,
  isLoadedGet2: false,
  isLoadedGet3: false,
  isLoadedGet4: false,
  isLoadedGet5: false,
  isLoadedGet6: false,
  isLoadedPost: false,
  isLoadedPut: false,
  isLoadedDel: false,
  dataLogs: [],
};

const fetchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_URL_GET:
      return {
        ...state,

        urlGet: action.payload,
      };
    case SET_URL_GET2:
      return {
        ...state,

        urlGet2: action.payload,
      };
    case SET_URL_GET3:
      return {
        ...state,

        urlGet3: action.payload,
      };
    case SET_URL_GET4:
      return {
        ...state,

        urlGet4: action.payload,
      };
    case SET_URL_GET5:
      return {
        ...state,

        urlGet5: action.payload,
      };
    case SET_URL_GET6:
      return {
        ...state,

        urlGet6: action.payload,
      };
    case SET_URL_POST:
      return {
        ...state,

        urlPost: action.payload,
      };
    case SET_URL_PUT:
      return {
        ...state,

        urlPut: action.payload,
      };
    case SET_URL_DELETE:
      return {
        ...state,

        urlDel: action.payload,
      };
    case SET_URL_FILTER:
      return {
        ...state,

        urlFilter: action.payload,
      };
    case SET_DATA_GET:
      return {
        ...state,

        dataGet: action.payload,
      };
    case SET_DATA_GET2:
      return {
        ...state,

        dataGet2: action.payload,
      };
    case SET_DATA_GET3:
      return {
        ...state,

        dataGet3: action.payload,
      };
    case SET_DATA_GET4:
      return {
        ...state,

        dataGet4: action.payload,
      };
    case SET_DATA_GET5:
      return {
        ...state,

        dataGet5: action.payload,
      };
    case SET_DATA_GET6:
      return {
        ...state,

        dataGet6: action.payload,
      };
    case SET_DATA_GET_TABLE:
      return {
        ...state,

        dataGetTable: action.payload,
      };
    case SET_DATA_POST:
      return {
        ...state,

        dataPost: action.payload,
      };
    case SET_DATA_PUT:
      return {
        ...state,

        dataPut: action.payload,
      };
    case SET_DATA_DELETE:
      return {
        ...state,

        dataDel: action.payload,
      };
    case SET_PAYLOAD_PUT:
      return {
        ...state,

        payloadPut: action.payload,
      };
    case SET_PAYLOAD_POST:
      return {
        ...state,

        payloadPost: action.payload,
      };
    case SET_PAYLOAD_DEL:
      return {
        ...state,

        payloadDel: action.payload,
      };
    case SET_ISLOAD_GET:
      return {
        ...state,

        isLoadedGet: action.payload,
      };
    case SET_ISLOAD_GET2:
      return {
        ...state,

        isLoadedGet2: action.payload,
      };
    case SET_ISLOAD_GET3:
      return {
        ...state,

        isLoadedGet3: action.payload,
      };
    case SET_ISLOAD_GET4:
      return {
        ...state,

        isLoadedGet4: action.payload,
      };
    case SET_ISLOAD_GET5:
      return {
        ...state,

        isLoadedGet5: action.payload,
      };
    case SET_ISLOAD_GET6:
      return {
        ...state,

        isLoadedGet6: action.payload,
      };
    case SET_ISLOAD_POST:
      return {
        ...state,

        isLoadedPost: action.payload,
      };
    case SET_ISLOAD_PUT:
      return {
        ...state,

        isLoadedPut: action.payload,
      };
    case SET_ISLOAD_DEL:
      return {
        ...state,

        isLoadedDel: action.payload,
      };

    case SET_LOGS:
      return {
        ...state,

        dataLogs: action.payload,
      };
    case SET_DATA_LOGS_RENDER:
      return {
        ...state,

        dataGetLogs: action.payload,
      };

    case SET_DATA_LOGS_DIPENDENTI:
      return {
        ...state,

        dataGetDipendenti: action.payload,
      };

    default:
      return state;
  }
};
export default fetchReducer;
