import { Col, Container, Row } from "react-bootstrap";

const FornitoriDetails = ({ data }) => {
  return (
    <Container className="border bg-light">
      <Row>
        <Col className="border p-2 text-center">
          {" "}
          <p className="dettagli-title ms-2 m-0 text-center">località</p>
          <span className="dettagli"> {data.via}</span>
        </Col>
        <Col className="border p-2 text-center">
          {" "}
          <p className="dettagli-title ms-2 m-0 text-center">Comune</p>
          <span className="dettagli">{data.comune}</span>
        </Col>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">Cap</p>
          <span className="dettagli">{data.cap}</span>
        </Col>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">Provincia </p>
          <span className="dettagli">{data.provincia}</span>
        </Col>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">Regione </p>
          <span className="dettagli">{data.regione}</span>
        </Col>
      </Row>
    </Container>
  );
};
export default FornitoriDetails;
