import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ADD_SITI } from "../../lib/payloads";
import { SET_INFO1, SET_INFO3, setInfo } from "../../redux/actions";
import { fetchSelectClienti } from "../../redux/actions";
const FormSiti = () => {
  const info1 = useSelector((state) => state.modal.info1);
  const show1 = useSelector((state) => state.modal.show1);
  const show2 = useSelector((state) => state.modal.show2);
  const show3 = useSelector((state) => state.modal.show3);
  const show4 = useSelector((state) => state.modal.show4);
  const allClienti = useSelector((state) => state.select.dataCliente);
  const token = useSelector((state) => state.login.token);
  const dispatch = useDispatch();
  const [info, setInformazioni] = useState({});
  const path = useSelector((state) => state.locationPage.path);
  const tipoModal = useSelector((state) => state.modal.type);

  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };

  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [clienteSelezionato, setClienteSelezionato] = useState("");

  const handleClienteChange = (value) => {
    changeInfo(value, "cliente");
    setIsFormDisabled(false);
  };

  useEffect(() => {
    dispatch(fetchSelectClienti(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (path == "sito") {
      dispatch(setInfo(SET_INFO1, info));
    } else {
      const x = {
        ...info,
        cliente: info1.cliente,
      };
      dispatch(setInfo(SET_INFO3, x));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  useEffect(() => {
    if (info.cliente) {
      setIsFormDisabled(false);
    } else {
      setIsFormDisabled(true);
    }
  }, [info.cliente]);

  useEffect(() => {
    if (show1 && show2 && show3 && show4) {
      setInformazioni(ADD_SITI);
    } else if (show1 && show2 && show3) {
      setInformazioni(ADD_SITI);
    } else if (show1 && show2) {
      setInformazioni(ADD_SITI);
    } else {
      setInformazioni(info1);
      if (tipoModal == "modifica") {
        setInformazioni({ ...info1, cliente: info1.idCliente });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("infoSiti", info);
  return (
    <Row>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label
            column
            sm="2"
            className="d-flex justify-content-between align-items-center"
          >
            Cliente *
          </Form.Label>
          <Form.Select
            value={info.cliente}
            className="custom-select"
            onChange={(e) => {
              changeInfo(e.target.value, "cliente");
              handleClienteChange(e.target.value);
            }}
            aria-label="Seleziona Cliente"
            required
            disabled={allClienti.length == 0}
          >
            {path == "sito" ? (
              <>
                <option value="" disabled hidden>
                  Seleziona Cliente
                </option>
                {allClienti &&
                  allClienti.map((c, index) => (
                    <option key={index} value={c.id}>
                      {c.nome} {c.cognome}
                    </option>
                  ))}
              </>
            ) : (
              allClienti
                .filter((c) => c.id == info1.cliente)
                .map((filteredClient, index) => (
                  <option key={index} value={filteredClient.id}>
                    {filteredClient.nome} {filteredClient.cognome}
                  </option>
                ))
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Sito / n° pv *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.sito_o_punto_vendita}
            onChange={(e) => changeInfo(e.target.value, "sito_o_punto_vendita")}
            placeholder="Inserisci il sito o n° punto vendita"
            required
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Regione
          </Form.Label>

          <Form.Control
            type="text"
            value={info.regione}
            onChange={(e) => changeInfo(e.target.value, "regione")}
            placeholder="inserisci Regione"
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Provincia
          </Form.Label>

          <Form.Control
            type="text"
            value={info.provincia}
            onChange={(e) => changeInfo(e.target.value, "provincia")}
            placeholder="inserisci Provincia"
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Comune
          </Form.Label>

          <Form.Control
            type="text"
            value={info.comune}
            onChange={(e) => changeInfo(e.target.value, "comune")}
            placeholder="inserisci Comune"
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Località/Via
          </Form.Label>

          <Form.Control
            type="text"
            value={info.via}
            onChange={(e) => changeInfo(e.target.value, "via")}
            placeholder="inserisci Località/Via"
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Cap
          </Form.Label>

          <Form.Control
            type="text"
            value={info.cap}
            onChange={(e) => changeInfo(e.target.value, "cap")}
            placeholder="inserisci Cap"
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Coordinate Geografiche
          </Form.Label>

          <Form.Control
            type="text"
            value={info.coordinate_o_link}
            onChange={(e) => changeInfo(e.target.value, "coordinate_o_link")}
            placeholder="inserisci Coordinate"
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Codice Vecchio
          </Form.Label>

          <Form.Control
            type="text"
            value={info.codice_vecchio}
            onChange={(e) => changeInfo(e.target.value, "codice_vecchio")}
            placeholder="inserisci Codice Vecchio"
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Telefono
          </Form.Label>

          <Form.Control
            type="text"
            value={info.numero_telefono}
            onChange={(e) => changeInfo(e.target.value, "numero_telefono")}
            placeholder="inserisci Telefono"
            disabled={isFormDisabled}
          />
        </Form.Group>
        <Form.Control.Feedback type="invalid">
          campo obbligatorio
        </Form.Control.Feedback>
      </Col>
    </Row>
  );
};

export default FormSiti;
