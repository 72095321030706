export const GetOfferte = (lista, lista2, lista3, setData) => {
  if (lista && lista2 && lista.length >= 0 && lista2.length >= 0 && lista3 && lista3.length >= 0) {
    const combinedList = lista.map(item => {
      let cliente = lista2.filter(item2 => item.cliente === item2.id);
      let fullName = cliente[0].nome + " " + cliente[0].cognome;
      let idCliente = cliente[0].id;
      let combinedItem = {
        ...item,
        cliente: fullName,
        clienteId: idCliente,
      };
      return combinedItem;
    });
    const combinedList2 = combinedList.map(item => {
      let sito = lista3.filter(item3 => {
        return item.sito == item3.id;
      });
      let combinedItem = {};
      if (sito.length > 0) {
        let sitoPuntovendita = sito[0].sito_o_punto_vendita;
        combinedItem = {
          ...item,
          sito: sitoPuntovendita,
          sitoId: sito[0].id,
          openModal: <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>,
        };
      } else {
        let sitoPuntovendita = "vari";
        combinedItem = {
          ...item,
          sito: sitoPuntovendita,
          sitoId: sitoPuntovendita,
          openModal: <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>,
        };
      }

      return combinedItem;
    });

    setData(combinedList2);
  } else {
    if (lista && lista.length > 0) {
      const updatedList = lista.map(item => ({
        ...item,
        openModal: <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>,
      }));
      setData(updatedList);
    }
  }
};
