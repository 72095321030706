import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_INFO1, setInfo } from "../../redux/actions";
import { Col, Form, Row } from "react-bootstrap";
import { ADD_DIPENDENTE } from "../../lib/payloads";

const FormDipendenti = () => {
  const info1 = useSelector((state) => state.modal.info1);
  const tipoModal = useSelector((state) => state.modal.type);
  const token = useSelector((state) => state.login.token);
  const dispatch = useDispatch();
  const [info, setInformazioni] = useState({});
  const show1 = useSelector((state) => state.modal.show1);
  const show2 = useSelector((state) => state.modal.show2);
  const show3 = useSelector((state) => state.modal.show3);
  const show4 = useSelector((state) => state.modal.show4);

  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };

  useEffect(() => {
    dispatch(setInfo(SET_INFO1, info));
  }, [info]);

  useEffect(() => {
    if (show1 && show2 && show3 && show4) {
      setInformazioni(ADD_DIPENDENTE);
    } else if (show1 && show2 && show3) {
      setInformazioni(ADD_DIPENDENTE);
    } else if (show1 && show2) {
      setInformazioni(ADD_DIPENDENTE);
    } else {
      setInformazioni(info1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Nome *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.nome}
            onChange={(e) => changeInfo(e.target.value, "nome")}
            required
            placeholder="inserisci Nome"
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Cognome *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.cognome}
            onChange={(e) => changeInfo(e.target.value, "cognome")}
            placeholder="Inserisci Cognome"
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Email *
          </Form.Label>

          <Form.Control
            type="text"
            value={info.email_utente}
            onChange={(e) => changeInfo(e.target.value, "email_utente")}
            required
            placeholder="inserisci Email"
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Telefono *
          </Form.Label>

          <Form.Control
            type="text"
            value={info.telefono}
            onChange={(e) => changeInfo(e.target.value, "telefono")}
            required
            placeholder="inserisci Telefono"
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Ruolo *
          </Form.Label>
          <Form.Select
            required
            value={info.ruolo || ""}
            onChange={(e) => changeInfo(e.target.value, "ruolo")}
            aria-label="Seleziona Ruolo"
            disabled={tipoModal == "modifica"}
          >
            <option value="" disabled hidden>
              ruolo
            </option>
            <option value="Amministratore">Amministratore</option>
            <option value="Amministrazione">Amministrazione</option>
            <option value="Commerciale">Commerciale</option>
            <option value="Project manager">Project Manager</option>
            <option value="Tecnico">Tecnico</option>
          </Form.Select>
        </Form.Group>
      </Col>
      {info.ruolo === "Tecnico" && (
        <Col xs="4">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              mansione *
            </Form.Label>

            <Form.Control
              type="text"
              value={info.mansione}
              onChange={(e) => changeInfo(e.target.value, "mansione")}
              required
              placeholder="inserisci Mansione"
              disabled={tipoModal == "modifica"}
            />
          </Form.Group>
        </Col>
      )}
    </Row>
  );
};

export default FormDipendenti;
