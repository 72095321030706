import { Alert, Container } from "react-bootstrap";
import Header from "./Header";
import TableData from "./TableData";
import Modale from "./Modale";
import { useSelector } from "react-redux";
import Filter from "./Filter";
import AlertToast from "../Forms/AlertToast";
import Pills from "./Pills";
import TabsStats from "../dashboard/TabsStats";

const TableConteiner = () => {
  const show1 = useSelector((state) => state.modal.show1);
  const show2 = useSelector((state) => state.modal.show2);
  const show3 = useSelector((state) => state.modal.show3);
  const show4 = useSelector((state) => state.modal.show4);
  const class1 = useSelector((state) => state.modal.class1);
  const class2 = useSelector((state) => state.modal.class2);
  const class3 = useSelector((state) => state.modal.class3);
  const class4 = useSelector((state) => state.modal.class4);

  const isLoad = useSelector((state) => state.fetch.isLoaded);

  const path = useSelector((state) => state.locationPage.path);

  return (
    <>
      <Container>
        <div>
          {isLoad ? <Alert variant="success">oggetto salvato</Alert> : ""}
          {(path == "ordine" || path == "fattura") && <TabsStats />}
          <Header />

          <Pills />
          <TableData />
        </div>
      </Container>
      <Modale show={show1} classe={class1} />
      <Modale show={show2} classe={class2} />
      <Modale show={show3} classe={class3} />
      <Modale show={show4} classe={class4} />
      <Filter />
      <AlertToast />
    </>
  );
};
export default TableConteiner;
