import { fetchAllPut, fetchGeneric } from "../../redux/actions";
import { URL_FATTURA } from "../url";

export const setFetchFattura = (type, payload, token, dispatch) => {
  if (type === "modifica") {
    dispatch(fetchAllPut(URL_FATTURA + payload.n_fattura, payload, token));
  } else if (type === "aggiungi") {
    dispatch(fetchGeneric(URL_FATTURA, payload, token));
  }
};
