export const GetDipendenti = (
  lista,
  lista2,
  lista3,
  lista4,
  lista5,
  lista6,
  setData
) => {
  if (
    lista &&
    lista2 &&
    lista3 &&
    lista4 &&
    lista5 &&
    lista6 &&
    lista.length >= 0 &&
    lista2.length >= 0 &&
    lista3.length >= 0 &&
    lista4.length >= 0 &&
    lista5.length >= 0 &&
    lista6.length >= 0
  ) {
    const combinedList = lista.map((item) => {
      let combinedItem = { ...item };

      if (lista2.filter((item2) => item.id === item2.dipendente).length > 0) {
        combinedItem = {
          ...item,
          ruolo: "Amministratore",
          openModal: (
            <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
          ),
        };
      } else if (
        lista3.filter((item3) => item.id === item3.dipendente).length > 0
      ) {
        combinedItem = {
          ...item,
          ruolo: "Amministrazione",
          openModal: (
            <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
          ),
        };
      } else if (
        lista4.filter((item4) => item.id === item4.dipendente).length > 0
      ) {
        combinedItem = {
          ...item,
          ruolo: "Project manager",
          openModal: (
            <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
          ),
        };
      } else if (
        lista5.filter((item5) => item.id === item5.dipendente).length > 0
      ) {
        combinedItem = {
          ...item,
          ruolo: "Commerciale",
          openModal: (
            <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
          ),
        };
      } else if (
        lista6.filter((item6) => item.id === item6.dipendente).length > 0
      ) {
        let mansione = lista6.filter((item6) => item.id === item6.dipendente);
        combinedItem = {
          ...item,
          ruolo: "Tecnico",
          mansione: mansione[0].mansione,
          openModal: (
            <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
          ),
        };
      }
      return combinedItem;
    });
    setData(combinedList);
  } else {
    if (lista && lista.length > 0) {
      const updatedList = lista.map((item) => ({
        ...item,
        openModal: (
          <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
        ),
      }));
      setData(updatedList);
    }
  }
};
