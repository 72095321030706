import React, { useEffect, useState } from "react";
// import Header from "./Header";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Sidenav from "./SideNav";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Unauthorized from "./login/Unauthorized";

export default function Layout(props) {
  const [padding] = useState(!props.noPadding ? "py-5 px-lg-5" : "");
  const IsAuthorized = useSelector((state) => state.login.isAuthorized);
  const navigate = useNavigate();

  const redirectTo = () => {
    navigate("/");
  };

  return (
    <Container fluid>
      <Row>
        <Sidenav />
        <Col id="main_container" className="min-vh-100 bg-light">
          <main className={padding}>
            {IsAuthorized ? props.children : <Unauthorized />}
          </main>
        </Col>
      </Row>
    </Container>
  );
}
