import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import "./PieChart.css";

const PieChart = ({ ordini, offerte }) => {
  const ref = useRef();

  useEffect(() => {
    const data = [
      { name: "Ordini", value: ordini },
      { name: "Offerte", value: offerte },
    ];

    const margin = { top: 20, right: 20, bottom: 30, left: 20 };
    const width = 500 - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;
    const radius = Math.min(width, height) / 2;

    const svg = d3
      .select(ref.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr(
        "transform",
        `translate(${width / 2 + margin.left}, ${height / 2 + margin.top})`
      );

    const color = d3.scaleOrdinal(["#4daf4a", "#377eb8"]);

    const pie = d3.pie().value((d) => d.value);
    const arc = d3.arc().innerRadius(0).outerRadius(radius);

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "pie-chart-tooltip")
      .style("opacity", 0);

    svg
      .selectAll("path")
      .data(pie(data))
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", (d) => color(d.data.name))
      .on("mouseover", (event, d) => {
        tooltip.transition().duration(200).style("opacity", 0.9);
        tooltip
          .html(`${d.data.name}: ${d.data.value}`)
          .style("left", event.pageX + "px")
          .style("top", event.pageY - 28 + "px");
      })
      .on("mouseout", () => {
        tooltip.transition().duration(500).style("opacity", 0);
      });

    // Leggenda
    const legend = svg
      .append("g")
      .attr("transform", `translate(${radius + 20}, -${radius})`);

    data.forEach((d, i) => {
      const legendRow = legend
        .append("g")
        .attr("transform", `translate(0, ${i * 20})`);

      legendRow
        .append("rect")
        .attr("width", 10)
        .attr("height", 10)
        .attr("fill", color(d.name));

      legendRow
        .append("text")
        .attr("x", 20)
        .attr("y", 10)
        .attr("text-anchor", "start")
        .style("text-transform", "capitalize")
        .text(d.name);
    });
  }, [ordini, offerte]);

  return (
    <>
      <svg ref={ref}></svg>
    </>
  );
};

export default PieChart;
