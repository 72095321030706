export const GetFattura = (lista, lista2, lista3, lista4, setData) => {
  if (
    lista &&
    lista2 &&
    lista.length >= 0 &&
    lista2.length >= 0 &&
    lista3 &&
    lista3.length >= 0 &&
    lista4 &&
    lista4.length >= 0
  ) {
    const combinedList = lista.map(item => {
      let cliente = lista2.filter(item2 => item.cliente === item2.id);
      let fullName = cliente[0].nome + " " + cliente[0].cognome;
      let combinedItem = {
        ...item,
        cliente: fullName,
        idCliente: cliente[0].id,
      };

      return combinedItem;
    });
    const combinedList2 = combinedList.map(item => {
      let ordine = lista3.filter(item3 => item.ordine === item3.id);
      let protocollo = ordine[0].protocollo;
      let combinedItem = {
        ...item,
        ordine: protocollo,
        idOrdine: ordine[0].id,
        openModal: <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>,
      };

      return combinedItem;
    });
    const combinedList3 = combinedList2.map(item => {
      let banca = lista4.filter(item4 => item.banca === item4.id);
      let nome = banca[0].nome;
      let combinedItem = {
        ...item,
        banca: nome,
        idBanca: banca[0].id,
        openModal: <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>,
      };

      return combinedItem;
    });
    setData(combinedList3);
  } else {
    if (lista && lista.length > 0) {
      const updatedList = lista.map(item => ({
        ...item,
        openModal: <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>,
      }));
      setData(updatedList);
    }
  }
};
