import { SET_PATH_NAME } from "../actions";

const initialState = {
  name: "",
  path: "",
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PATH_NAME:
      return {
        ...state,
        path: action.payload[1],
        name: action.payload[1].charAt(0).toUpperCase() + action.payload[1].slice(1),
      };

    default:
      return state;
  }
};
export default locationReducer;
