import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ADD_FATTURE } from "../../lib/payloads";
import {
  SET_INFO1,
  fetchSelectBanca,
  fetchSelectBancaInternal,
  fetchSelectClienti,
  fetchSelectFatture,
  fetchSelectLavori,
  fetchSelectOfferte,
  fetchSelectOrdine,
  setInfo,
} from "../../redux/actions";
import {
  SET_CLASS2,
  SET_CLASS3,
  SET_CLASS4,
  SET_SHOW2,
  SET_SHOW3,
  SET_SHOW4,
  setClass,
  setShow,
} from "../../redux/actions";

const FormFatture = () => {
  const info1 = useSelector((state) => state.modal.info1);
  const show1 = useSelector((state) => state.modal.show1);
  const show2 = useSelector((state) => state.modal.show2);
  const show3 = useSelector((state) => state.modal.show3);
  const show4 = useSelector((state) => state.modal.show4);
  const allClienti = useSelector((state) => state.select.dataCliente);
  const allBanche = useSelector(
    (state) => state.select.dataBancaInternal.results
  );
  const allOrdini = useSelector((state) => state.select.dataOrdine);
  const allOfferte = useSelector((state) => state.select.dataOfferte);
  const allLavori = useSelector((state) => state.select.dataLavori);
  const allFatture = useSelector((state) => state.select.dataFatture);
  const token = useSelector((state) => state.login.token);
  const dispatch = useDispatch();
  const [info, setInformazioni] = useState({});

  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };

  const tipoModal = useSelector((state) => state.modal.type);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [clienteSelezionato, setClienteSelezionato] = useState("");

  const [ordiniFiltrati, setOrdiniFiltrati] = useState([]);

  const [offerteOrdine, setOfferteOrdine] = useState([]);
  const [lavoriOrdine, setLavoriOrdine] = useState([]);
  const [fattureOrdine, setFattureOrdine] = useState([]);
  const [residuoDaFatturare, setResiduoDaFatturare] = useState(0);

  useEffect(() => {
    dispatch(fetchSelectClienti(token));
    dispatch(fetchSelectOrdine(token));
    dispatch(fetchSelectBancaInternal(token));
    dispatch(fetchSelectOfferte(token));
    dispatch(fetchSelectLavori(token));
    dispatch(fetchSelectFatture(token));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isFieldsDisabled = tipoModal === "modifica" && info.incassata;

  useEffect(() => {
    if (clienteSelezionato) {
      setOrdiniFiltrati(
        allOrdini.filter((ordine) => ordine.cliente == clienteSelezionato)
      );
      setIsFormDisabled(false); // Abilita il form se un cliente è selezionato
    } else {
      setOrdiniFiltrati([]);
      setIsFormDisabled(true); // Disabilita il form se nessun cliente è selezionato
    }
  }, [clienteSelezionato, allOrdini]);

  const handleClienteChange = (value) => {
    // Aggiorna il cliente selezionato nello stato info e azzera l'ordine selezionato
    setInformazioni({
      ...info,
      cliente: value,
      ordine: "", // Azzera l'ordine quando cambia il cliente
    });
    setClienteSelezionato(value);

    // Resetta anche le offerte ordine visto che l'ordine è stato azzerato
    setOfferteOrdine([]);
  };
  useEffect(() => {
    dispatch(setInfo(SET_INFO1, info));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  useEffect(() => {
    if (show1 && show2 && show3 && show4) {
      setInformazioni(ADD_FATTURE);
    } else if (show1 && show2 && show3) {
      setInformazioni(ADD_FATTURE);
    } else if (show1 && show2) {
      setInformazioni(ADD_FATTURE);
    } else {
      setInformazioni(info1);
      if (tipoModal === "modifica") {
        setClienteSelezionato(info1.idCliente);

        const selectedOrder = allOrdini.find(
          (ordine) => ordine.id == info1.idOrdine
        );

        const filteredOfferte = allOfferte.filter((offerta) =>
          selectedOrder.offerte.includes(offerta.id)
        );

        setOfferteOrdine(filteredOfferte);

        setInformazioni({
          ...info1,
          cliente: info1.idCliente,
          ordine: info1.idOrdine,
          banca: info1.idBanca,
        });
        if (selectedOrder && selectedOrder.lavori) {
          const filteredLavori = allLavori.filter((lavoro) =>
            selectedOrder.lavori.includes(lavoro.id)
          );
          setLavoriOrdine(filteredLavori);
        } else {
          setLavoriOrdine([]);
        }
        if (selectedOrder) {
          const filteredFatture = allFatture.filter(
            (fattura) => fattura.ordine == selectedOrder.id
          );
          setFattureOrdine(filteredFatture);

          // Calcola il residuo da fatturare
          const totaleFatturato = filteredFatture.reduce(
            (acc, fattura) => acc + parseFloat(fattura.imponibile),
            0
          );
          setResiduoDaFatturare(selectedOrder.importo_oda - totaleFatturato);
        } else {
          setFattureOrdine([]);
          setResiduoDaFatturare(0);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBanche, allClienti, allOfferte, allOrdini, allLavori, allFatture]);

  const handleOpenNextModal = (classe) => {
    if (show1 && show2 && show3) {
      dispatch(setShow(SET_SHOW4, true));
      dispatch(setClass(SET_CLASS4, classe));
    } else if (show1 && show2) {
      dispatch(setShow(SET_SHOW3, true));
      dispatch(setClass(SET_CLASS3, classe));
    } else {
      dispatch(setShow(SET_SHOW2, true));
      dispatch(setClass(SET_CLASS2, classe));
    }
  };

  const handleOrdineChange = (event) => {
    const ordineId = event.target.value;
    changeInfo(ordineId, "ordine"); // Aggiorna lo stato info con l'ID dell'ordine selezionato

    // Trova l'ordine selezionato basandosi sull'ID
    const selectedOrder = allOrdini.find((ordine) => ordine.id == ordineId);

    if (selectedOrder && selectedOrder.offerte) {
      // Filtra le offerte per includere solo quelle che appartengono all'ordine selezionato
      const filteredOfferte = allOfferte.filter((offerta) =>
        selectedOrder.offerte.includes(offerta.id)
      );

      // Aggiorna lo stato di offerteOrdine con le offerte filtrate
      setOfferteOrdine(filteredOfferte);
    } else {
      // Se non ci sono offerte per l'ordine selezionato, imposta offerteOrdine a vuoto
      setOfferteOrdine([]);
    }

    if (selectedOrder && selectedOrder.lavori) {
      // Filtra i lavori per includere solo quelli che appartengono all'ordine selezionato
      const filteredLavori = allLavori.filter((lavoro) =>
        selectedOrder.lavori.includes(lavoro.id)
      );

      // Aggiorna lo stato di lavoriOrdine con i lavori filtrati
      setLavoriOrdine(filteredLavori);
    } else {
      // Se non ci sono lavori per l'ordine selezionato, imposta lavoriOrdine a vuoto
      setLavoriOrdine([]);
    }

    if (selectedOrder) {
      // Filtra le fatture per includere solo quelle che appartengono all'ordine selezionato
      const filteredFatture = allFatture.filter(
        (fattura) => fattura.ordine == selectedOrder.id
      );

      // Aggiorna lo stato di fattureOrdine con le fatture filtrate
      setFattureOrdine(filteredFatture);

      // Calcola il residuo da fatturare
      const totaleFatturato = filteredFatture.reduce(
        (acc, fattura) => acc + parseFloat(fattura.imponibile),
        0
      );
      setResiduoDaFatturare(selectedOrder.importo_oda - totaleFatturato);
    } else {
      // Se non ci sono fatture per l'ordine selezionato, imposta fattureOrdine a vuoto
      setFattureOrdine([]);
      setResiduoDaFatturare(0);
    }
  };

  const handleDataEmissioneChange = (e) => {
    const newDataEmissione = e.target.value;
    changeInfo(newDataEmissione, "data_emissione");
    // Qui non c'è bisogno di un nuovo stato se l'anno viene mostrato direttamente
  };

  const handleDataPagamentoChange = (e) => {
    const newDataPagamento = e.target.value;
    changeInfo(newDataPagamento, "data_pagamento");
    // L'aggiornamento di anno_pagamento e mese_pagamento può essere gestito direttamente nella renderizzazione
  };

  return (
    <Row>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Numero fattura *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.n_fattura}
            onChange={(e) => changeInfo(e.target.value, "n_fattura")}
            placeholder="Inserisci il numero della fattura"
            required
            disabled={tipoModal === "modifica" || isFieldsDisabled}
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label
            column
            sm="2"
            className="d-flex justify-content-between align-items-center"
          >
            Cliente *
          </Form.Label>
          <Form.Select
            value={info.cliente}
            className="custom-select"
            onChange={(e) => {
              changeInfo(e.target.value, "cliente");
              handleClienteChange(e.target.value);
            }}
            aria-label="Seleziona Cliente"
            required
            disabled={tipoModal === "modifica" || isFieldsDisabled}
          >
            <option value="" disabled hidden>
              Seleziona Cliente
            </option>
            {allClienti &&
              allClienti.map((c, index) => (
                <option key={index} value={c.id}>
                  {c.nome} {c.cognome}
                </option>
              ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label
            column
            sm="2"
            className="d-flex justify-content-between align-items-center"
          >
            Ordine *
          </Form.Label>
          <Form.Select
            value={info.ordine}
            className="custom-select"
            onChange={handleOrdineChange}
            aria-label="Seleziona Ordine"
            required
            disabled={
              isFormDisabled ||
              ordiniFiltrati.length == 0 ||
              tipoModal === "modifica" ||
              isFieldsDisabled
            }
          >
            <option value="" disabled hidden>
              Seleziona Ordine
            </option>
            {ordiniFiltrati.length !== 0 ? (
              ordiniFiltrati.map((ordine, index) => (
                <option key={index} value={ordine.id}>
                  {ordine.protocollo}
                </option>
              ))
            ) : (
              <option value="" disabled hidden>
                Nessun sito disponibile
              </option>
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label
            column
            sm="2"
            className="d-flex justify-content-between align-items-center"
          >
            Banca *
            {tipoModal !== "modifica" && (
              <span
                onClick={() =>
                  isFormDisabled ? null : handleOpenNextModal("banca")
                }
                className={`text-${
                  isFormDisabled ? "secondary" : "primary"
                } text-decoration-none me-2`}
              >
                <i className="bi bi-plus-circle-fill"></i>
              </span>
            )}
          </Form.Label>
          <Form.Select
            value={info.banca}
            className="custom-select"
            onChange={(e) => changeInfo(e.target.value, "banca")}
            aria-label="Seleziona Banca"
            required
            disabled={isFormDisabled || isFieldsDisabled}
          >
            <option value="" disabled hidden>
              Seleziona Banca
            </option>
            {allBanche &&
              allBanche.map((b, index) => (
                <option key={index} value={b.id}>
                  {b.nome}
                </option>
              ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Imponibile *
          </Form.Label>
          <div className="d-flex align-items-center">
            <span style={{ marginRight: "8px" }}>€</span>
            <Form.Control
              type="number"
              value={info.imponibile}
              onChange={(e) => changeInfo(e.target.value, "imponibile")}
              placeholder="Inserisci imponibile"
              required
              disabled={isFormDisabled || isFieldsDisabled}
            />
          </div>
        </Form.Group>
        <Form.Control.Feedback type="invalid">
          campo obbligatorio
        </Form.Control.Feedback>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Anno Competenza *
          </Form.Label>
          <Form.Control
            type="number"
            value={info.anno_competenza}
            onChange={(e) => changeInfo(e.target.value, "anno_competenza")}
            placeholder="Inserisci anno di competenza"
            required
            disabled={isFormDisabled || isFieldsDisabled}
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      {/* <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Data di Emissione *
          </Form.Label>
          <Form.Control
            type="date"
            value={info.data_emissione}
            onChange={(e) => changeInfo(e.target.value, "data_emissione")}
            required
            disabled={isFormDisabled}
          />
        </Form.Group>
        <Form.Control.Feedback type="invalid">
          campo obbligatorio
        </Form.Control.Feedback>
      </Col> */}
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Data di Emissione *
          </Form.Label>
          <Form.Control
            type="date"
            value={info.data_emissione}
            onChange={handleDataEmissioneChange}
            required
            disabled={isFormDisabled || isFieldsDisabled}
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Anno Emissione
          </Form.Label>
          <Form.Control
            type="text"
            value={
              info.data_emissione
                ? new Date(info.data_emissione).getFullYear().toString()
                : "Campo autocompilato"
            }
            disabled
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Scadenza *
          </Form.Label>
          <Form.Control
            type="date"
            value={info.scadenza}
            onChange={(e) => changeInfo(e.target.value, "scadenza")}
            required
            disabled={isFormDisabled || isFieldsDisabled}
          />
        </Form.Group>
        <Form.Control.Feedback type="invalid">
          campo obbligatorio
        </Form.Control.Feedback>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Data di Pagamento *
          </Form.Label>
          <Form.Control
            type="date"
            value={info.data_pagamento || ""}
            onChange={handleDataPagamentoChange}
            required
            disabled={isFormDisabled || isFieldsDisabled}
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Mese Pagamento
          </Form.Label>
          <Form.Control
            type="text"
            value={
              info.data_pagamento
                ? new Date(info.data_pagamento).toLocaleString("it-IT", {
                    month: "long",
                  })
                : "Campo autocompilato"
            }
            disabled
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Anno Pagamento
          </Form.Label>
          <Form.Control
            type="text"
            value={
              info.data_pagamento
                ? new Date(info.data_pagamento).getFullYear().toString()
                : "Campo autocompilato"
            }
            disabled
          />
        </Form.Group>
      </Col>

      <Col xs="4" className="d-flex align-items-center">
        <Form.Group>
          <Form.Check
            type="switch"
            id="incassata-switch"
            label="Incassata"
            checked={info.incassata}
            onChange={(e) => changeInfo(e.target.checked, "incassata")}
            disabled={isFormDisabled || isFieldsDisabled}
          />
        </Form.Group>
      </Col>
      <Col xs="12">
        <Form.Group className="mb-3">
          <Form.Label>Note</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={info.note}
            onChange={(e) => changeInfo(e.target.value, "note")}
            placeholder="Inserisci note"
            disabled={isFormDisabled || isFieldsDisabled}
          />
        </Form.Group>
      </Col>

      {offerteOrdine.length > 0 && (
        <Col xs="12">
          <h5>Lista Offerte</h5>
          {offerteOrdine.length > 0 ? (
            <ul>
              {offerteOrdine.map((offerta, index) => (
                <li key={index}>
                  <strong> Protocollo:</strong> {offerta.protocollo},
                  <strong> Importo totale:</strong> {offerta.importo_totale} €
                </li>
              ))}
            </ul>
          ) : (
            <p></p>
          )}
        </Col>
      )}
      {lavoriOrdine.length > 0 && (
        <Col xs="12">
          <h5>Lista Lavori</h5>
          <ul>
            {lavoriOrdine.map((lavoro, index) => (
              <li key={index}>
                <strong> Protocollo:</strong> {lavoro.protocollo},
                <strong> Denominazione: </strong> {lavoro.denominazione},
                <strong> Importo:</strong> {lavoro.importo} €
              </li>
            ))}
          </ul>
        </Col>
      )}
      {fattureOrdine.length > 0 && (
        <Col xs="12">
          <h5>Lista fatture di quest&#39;ordine</h5>
          <ul>
            {fattureOrdine.map((fattura, index) => (
              <li key={index}>
                <strong>N Fattura:</strong> {fattura.n_fattura},
                <strong> Imponibile:</strong> {fattura.imponibile} €,
                <strong> Incassata:</strong> {fattura.incassata ? "Sì" : "No"}
              </li>
            ))}
          </ul>
        </Col>
      )}

      <Col xs="12">
        <h5>Residuo da Fatturare : {residuoDaFatturare.toFixed(2)} €</h5>
      </Col>
    </Row>
  );
};

export default FormFatture;
