import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ADD_CLIENTE } from "../../lib/payloads";
import { SET_INFO1, SET_INFO3, setInfo } from "../../redux/actions";

const FormClienti = () => {
  const info1 = useSelector((state) => state.modal.info1);
  const show1 = useSelector((state) => state.modal.show1);
  const show2 = useSelector((state) => state.modal.show2);
  const show3 = useSelector((state) => state.modal.show3);
  const show4 = useSelector((state) => state.modal.show4);
  const dispatch = useDispatch();
  const [info, setInformazioni] = useState({});
  const [azienda, setAzienda] = useState(false);
  const path = useSelector((state) => state.locationPage.path);

  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };

  const [oil, setOil] = useState(false);

  const handleOilChange = (checked) => {
    setOil(checked);
    changeInfo(checked, "oil");
  };

  useEffect(() => {
    if (azienda && info.tipologia === "pubblica amministrazione") {
      setOil(info.oil);
    }
    // Other code...
  }, [azienda, info.tipologia]);

  useEffect(() => {
    if (!info.tipologia) {
      changeInfo("azienda", "tipologia");
      setAzienda(true);
    } else {
      setAzienda(info.tipologia !== "privato");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.tipologia]);

  useEffect(() => {
    if (!azienda) {
      changeInfo("privato", "tipologia");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [azienda]);

  useEffect(() => {
    if (path == "cliente") {
      dispatch(setInfo(SET_INFO1, info));
    } else {
      dispatch(setInfo(SET_INFO3, info));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  useEffect(() => {
    if (show1 && show2 && show3 && show4) {
      setInformazioni(ADD_CLIENTE);
    } else if (show1 && show2 && show3) {
      setInformazioni(ADD_CLIENTE);
    } else if (show1 && show2) {
      setInformazioni(ADD_CLIENTE);
    } else {
      setInformazioni(info1);

      if (info1.oil === true) {
        setOil(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tipoModal = useSelector((state) => state.modal.type);
  return (
    <Row>
      <Col xs="12">
        <Form.Check
          onChange={() => setAzienda(true)}
          inline
          value="azienda"
          label="Azienda / PA"
          name="group1"
          type="radio"
          checked={azienda === true}
          disabled={tipoModal === "modifica"}
        />
        <Form.Check
          onChange={(e) => {
            setAzienda(false);
            changeInfo(info.tipologia, "tipologia");
          }}
          inline
          value="privato"
          label="Privato"
          name="group1"
          type="radio"
          disabled={tipoModal === "modifica"}
          checked={azienda === false}
        />
      </Col>
      {!azienda ? (
        <>
          <Col xs="4">
            <Form.Group className="mb-3">
              <Form.Label column sm="2">
                Cognome *
              </Form.Label>
              <Form.Control
                type="text"
                value={info.cognome}
                onChange={(e) => changeInfo(e.target.value, "cognome")}
                required
                placeholder="inserisci Cognome"
              />
              <Form.Control.Feedback type="invalid">
                campo obbligatorio
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs="4">
            <Form.Group className="mb-3">
              <Form.Label column sm="2">
                Nome *
              </Form.Label>
              <Form.Control
                type="text"
                value={info.nome}
                onChange={(e) => changeInfo(e.target.value, "nome")}
                placeholder="Inserisci Nome"
                required
              />
            </Form.Group>
          </Col>
        </>
      ) : (
        <Col xs="4">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Rag Sociale *
            </Form.Label>
            <Form.Control
              type="text"
              value={info.cognome}
              onChange={(e) => changeInfo(e.target.value, "cognome")}
              required
              placeholder="inserisci Cognome"
            />
            <Form.Control.Feedback type="invalid">
              campo obbligatorio
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      )}
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            {azienda ? "Partita Iva" : "Codice Fiscale"}
          </Form.Label>
          <Form.Control
            type="text"
            value={info.partita_iva}
            onChange={(e) => changeInfo(e.target.value, "partita_iva")}
            placeholder={
              azienda ? "Inserisci Partita Iva" : "Inserisci Codice Fiscale"
            }
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Email
          </Form.Label>

          <Form.Control
            type="text"
            value={info.email}
            onChange={(e) => changeInfo(e.target.value, "email")}
            placeholder="inserisci Email"
          />
          {/* <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback> */}
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Telefono *
          </Form.Label>

          <Form.Control
            type="text"
            value={info.telefono}
            onChange={(e) => changeInfo(e.target.value, "telefono")}
            required
            placeholder="inserisci Telefono"
          />
        </Form.Group>
        {/* <Form.Control.Feedback type="invalid">
            {errors.telefono}
          </Form.Control.Feedback> */}
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Località
          </Form.Label>

          <Form.Control
            type="text"
            value={info.via}
            onChange={(e) => changeInfo(e.target.value, "via")}
            placeholder="inserisci Località"
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Comune
          </Form.Label>

          <Form.Control
            type="text"
            value={info.comune}
            onChange={(e) => changeInfo(e.target.value, "comune")}
            placeholder="inserisci Comune"
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Provincia
          </Form.Label>

          <Form.Control
            type="text"
            value={info.provincia}
            onChange={(e) => changeInfo(e.target.value, "provincia")}
            placeholder="inserisci Provincia"
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Regione
          </Form.Label>

          <Form.Control
            type="text"
            value={info.regione}
            onChange={(e) => changeInfo(e.target.value, "regione")}
            placeholder="inserisci Regione"
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Cap
          </Form.Label>

          <Form.Control
            type="text"
            value={info.cap}
            onChange={(e) => changeInfo(e.target.value, "cap")}
            placeholder="inserisciCap"
          />
        </Form.Group>
      </Col>{" "}
      {azienda ? (
        <Col xs="4">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Tipologia *
            </Form.Label>

            <Form.Select
              value={info.tipologia}
              className="custom-select"
              onChange={(e) => changeInfo(e.target.value, "tipologia")}
              aria-label="Seleziona Tipologia"
            >
              <option value="" disabled hidden>
                Seleziona Tipologia
              </option>
              <option value="pubblica amministrazione">
                pubblica amministrazione
              </option>
              <option value="azienda">azienda</option>
            </Form.Select>
          </Form.Group>
        </Col>
      ) : null}
      {azienda && info.tipologia !== "pubblica amministrazione" && (
        <Col xs="4">
          <Form.Group className="mt-5">
            {/* <Form.Check
              type="checkbox"
              label="Oil"
              checked={oil}
              onChange={(e) => handleOilChange(e.target.checked)}
            /> */}
            <Col xs="12">
              <Form.Check
                onChange={(e) => {
                  handleOilChange(e.target.checked);
                }}
                inline
                value="true"
                label="Oil"
                name="group2"
                type="radio"
                checked={oil === true}
              />
              <Form.Check
                onChange={(e) => {
                  handleOilChange(!e.target.checked);
                }}
                inline
                value="false"
                label="Non-Oil"
                name="group2"
                type="radio"
                checked={oil === false}
              />
            </Col>
          </Form.Group>
        </Col>
      )}
      {azienda && (
        <Col xs="4">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              SDI
            </Form.Label>

            <Form.Control
              type="text"
              value={info.fatturazione_elettronica}
              onChange={(e) =>
                changeInfo(e.target.value, "fatturazione_elettronica")
              }
              placeholder="inserisci SDI"
            />
          </Form.Group>
        </Col>
      )}
      {azienda && (
        <Col xs="4">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Sito Web
            </Form.Label>
            <Form.Control
              type="text"
              value={info.sito_web}
              onChange={(e) => changeInfo(e.target.value, "sito_web")}
              placeholder="inserisci Sito Web"
            />
          </Form.Group>
        </Col>
      )}
    </Row>
  );
};
export default FormClienti;
