import { URL_TIPOLOGIA_ATTIVITA } from "../../../lib/url";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  SET_URL_GET,
  setShow,
  setUrlFetch,
} from "../../../redux/actions";

export const setUrlFilterTipologiaAttivita = (arrayPills, info, dispatch) => {
  let url = "?";
  if (arrayPills.length > 0) {
    if (info.denominazione && url !== "?")
      url = url + "&&denominazione=" + info.denominazione;
    else {
      if (info.denominazione) url = url + "denominazione=" + info.denominazione;
    }
  } else {
    dispatch(setUrlFetch(SET_URL_GET, URL_TIPOLOGIA_ATTIVITA));
  }

  if (url !== "?") {
    url = URL_TIPOLOGIA_ATTIVITA + url;
    dispatch(setUrlFetch(SET_URL_FILTER, url));
  }
  //   dispatch(setShow(SET_SHOW_FILTER, false));
};
