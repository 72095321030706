import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormClienti from "../Forms/FormClienti";
import FormFatture from "../Forms/FormFatture";
import { useEffect, useRef, useState } from "react";
import {
  SET_PAYLOAD_DEL,
  SET_PAYLOAD_POST,
  SET_PAYLOAD_PUT,
  SET_SHOW1,
  SET_SHOW2,
  SET_SHOW3,
  SET_SHOW4,
  SET_URL_DELETE,
  SET_URL_POST,
  SET_URL_PUT,
  fetchAllDelete,
  fetchAllGet,
  fetchAllPut,
  fetchGeneric,
  fetchSelectBancaInternal,
  fetchSelectClienti,
  fetchSelectFornitore,
  fetchSelectResponsabili,
  fetchSelectSiti,
  fetchSelectStati,
  setPayloadFetch,
  setShow,
  setUrlFetch,
} from "../../redux/actions";
import FormAttivita from "../Forms/FormAttivita";
import FormTipologiaAttivita from "../Forms/FormTipologiaAttivita";
import FormSedi from "../Forms/FormSedi";
import FormBanche from "../Forms/FormBanche";
import FormFornitori from "../Forms/FormFornitori";
import FormOfferte from "../Forms/FormOfferte";
import FormStati from "../Forms/FormStati";
import FormIncaricoFornitori from "../Forms/FormIncaricoFornitori";
import FormSiti from "../Forms/FormSiti";
import FormOrdini from "../Forms/FormOrdini";
import FormCommesse from "../Forms/FormCommesse";
import {
  URL_ATTIVITA,
  URL_BANCA,
  URL_CLIENTI,
  URL_COMMESSA,
  URL_DIPENDENTE,
  URL_FATTURA,
  URL_FORNITORE,
  URL_INCARICO_FORNITORE,
  URL_OFFERTA,
  URL_ORDINE,
  URL_SEDE,
  URL_SITO,
  URL_STATO,
  URL_TIPOLOGIA_ATTIVITA,
} from "../../lib/url";
import { setFetchCliente } from "../../lib/fetch/clienteFetch";
import { setFetchFattura } from "../../lib/fetch/fatturaFetch";
import { setFetchAttivita } from "../../lib/fetch/attivitaFetch";
import { setFetchBanca } from "../../lib/fetch/bancaFetch";
import { setFetchCommessa } from "../../lib/fetch/commessaFetch";
import { setFetchFornitore } from "../../lib/fetch/fornitoreFetch";
import { setFetchIncaricoFornitore } from "../../lib/fetch/incaricoFornitoreFetch";
import { setFetchOfferta } from "../../lib/fetch/offertaFetch";
import { setFetchOrdine } from "../../lib/fetch/ordineFetch";
import { setFetchSede } from "../../lib/fetch/sedeFetch";
import { setFetchSito } from "../../lib/fetch/sitoFetch";
import { setFetchStato } from "../../lib/fetch/statoFetch";
import { setFetchTipologiaAttivita } from "../../lib/fetch/tipologiaAttivitaFetch";
import FormDipendenti from "../Forms/FormDipendenti";
import { SetFetchDipendenti } from "../../lib/fetch/dipendentiFetch";
import FormResponsabile from "../Forms/FormResponsabile";
import { setFetchResponsabili } from "../../lib/fetch/responsabiliFetch";

const Modale = ({ show, classe }) => {
  const show1 = useSelector((state) => state.modal.show1);
  const show2 = useSelector((state) => state.modal.show2);
  const show3 = useSelector((state) => state.modal.show3);
  const show4 = useSelector((state) => state.modal.show4);
  const type = useSelector((state) => state.modal.type);
  const isAuthorizedDo = useSelector((state) => state.login.isAuthorizedDo);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.token);
  const payload = useSelector((state) => state.modal.info1);
  const listaAttivita = useSelector((state) => state.modal.info2);
  const payloadModaleInterna = useSelector((state) => state.modal.info3);
  const listaLavori = useSelector((state) => state.modal.lavoriPerOfferte);

  const allCommesse = useSelector((state) => state.select.dataCommesse);
  const allLavori = useSelector((state) => state.select.dataLavori);

  const path = useSelector((state) => state.locationPage.path);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const handleOpenConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(true);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
  };

  const handleClose = () => {
    if (show1 && show2 && show3 && show4) {
      dispatch(setShow(SET_SHOW4, false));
    } else if (show1 && show2 && show3) {
      dispatch(setShow(SET_SHOW3, false));
    } else if (show1 && show2) {
      dispatch(setShow(SET_SHOW2, false));
    } else {
      dispatch(setShow(SET_SHOW1, false));
    }
  };

  const handleSubmit = (e) => {
    switch (classe) {
      case "cliente":
        if (path == "cliente") {
          setFetchCliente(type, payload, token, dispatch);
        } else {
          setFetchCliente(type, payloadModaleInterna, token, dispatch);
          dispatch(fetchSelectClienti(token));
        }
        break;
      case "fattura":
        setFetchFattura(type, payload, token, dispatch);
        break;
      case "attivita":
        setFetchAttivita(type, payload, token, dispatch);
        break;
      case "offerta":
        const payLoadOfferta = {
          ...payload,
          lista: Object.values(listaAttivita),
        };

        setFetchOfferta(type, payLoadOfferta, token, dispatch);

        break;
      case "ordine":
        setFetchOrdine(
          type,
          payload,
          token,
          dispatch,
          listaLavori,
          allCommesse,
          allLavori
        );
        break;
      case "commessa":
        setFetchCommessa(type, payload, token, dispatch);
        break;
      case "sede":
        setFetchSede(type, payload, token, dispatch);
        break;
      case "banca":
        if (path == "banca") {
          setFetchBanca(type, payload, token, dispatch);
        } else {
          setFetchBanca(type, payloadModaleInterna, token, dispatch);
          dispatch(fetchSelectBancaInternal(token));
        }
        break;
      case "fornitore":
        if (path == "fornitore") {
          setFetchFornitore(type, payload, token, dispatch);
        } else {
          setFetchFornitore(type, payloadModaleInterna, token, dispatch);
          dispatch(fetchSelectFornitore(token));
        }
        break;
      case "incarico-fornitore":
        setFetchIncaricoFornitore(type, payload, token, dispatch);
        break;
      case "sito":
        if (path == "sito") {
          setFetchSito(type, payload, token, dispatch);
        } else {
          setFetchSito(type, payloadModaleInterna, token, dispatch);
          dispatch(fetchSelectSiti(token));
        }
        break;
      case "stato":
        if (path == "stato") {
          setFetchStato(type, payload, token, dispatch);
        } else {
          setFetchStato(type, payloadModaleInterna, token, dispatch);
          dispatch(fetchSelectStati(token));
        }
        break;
      case "tipologia-attivita":
        if (path == "tipologia-attivita") {
          setFetchTipologiaAttivita(type, payload, token, dispatch);
        } else {
          setFetchTipologiaAttivita(
            type,
            payloadModaleInterna,
            token,
            dispatch
          );
        }
        break;
      case "dipendente":
        SetFetchDipendenti(type, payload, token, dispatch);
        break;
      case "responsabili":
        if (path == "responsabile") {
          setFetchResponsabili(type, payload, token, dispatch);
        } else {
          setFetchResponsabili(type, payloadModaleInterna, token, dispatch);
          dispatch(fetchSelectResponsabili(token));
        }
        break;
      default:
        break;
    }

    handleClose();
  };

  const handleConfirmDelete = () => {
    switch (classe) {
      case "cliente":
        //PUOI ELIMINARE UN CLIENTE SOLO SE NON E STATO GIA SCELTO IN UNA QUALSIASI
        //SELECT CHE SI RIFERISCE A CLIENTE (Commesse,Fatture,Offerte,Ordini,Siti).
        //QUANDO STAI ELIMINANDO UN CLIENTE, DEVI ANCHE PRENDERE IL SUO ID, ANDARE A CERCARE
        //IN INDIRIZZO-CLIENTE.CLIENTE QUELL ID, PRENDERE QUELL INDIRIZZO CLIENTE, ED ELIMINARE PURE QUELLO (Back-end?)
        dispatch(fetchAllDelete(URL_CLIENTI + payload.id, token));
        break;
      case "fattura":
        //PUOI ELIMINARE UNA FATTURA IN QUALSIASI MOMENTO
        dispatch(fetchAllDelete(URL_FATTURA + payload.id, token));
        break;
      case "attivita":
        //PUOI ELIMINARE UN ATTIVITA SOLO DA DENTRO IL FORM DI OFFERTE, NON DALLA MODALE
        //DI MODIFICA DELLA TABLE ATTIVITA. QUANDO ELIMINI UN ATTIVITA, DEVI ELIMINARE, SE
        //PRESENTE, ANCHE IL SUO ID DALL ARRAY DI ORDINE.ATTIVITA (BACK-END?)
        dispatch(fetchAllDelete(URL_ATTIVITA + payload.id, token));
        break;
      case "offerta":
        //SE ELIMINI UN INTERA OFFERTA, DEVI ANCHE ELIMINARE TUTTE LE ATTIVITA IN ESSA CONTENUTE;
        // PERCIO DEVI ANDARE A CERCARE GLI ID DI ATTIVITA CHE HANNO DENTRO AD ATTIVITA.OFFERTA L ID
        // DELL OFFERTA CHE STAI ELIMINANDO, E ELIMINARE QUELLE ATTIVITA. (Back-end?)
        // INOLTRE, QUANDO ELIMINI UN OFFERTA DEVI ELIMINARE, SE PRESENTE, ANCHE IL SUO ID DALL ARRAY DI
        // ORDINE.OFFERTE (BACK-END?)
        dispatch(fetchAllDelete(URL_OFFERTA + payload.id, token));
        break;
      case "ordine":
        // PUOI ELIMINARE UN ORDINE SOLO SE NON E GIA STATO SCELTO NELLE SELECT CHE SI RIFERISCONO AD ORDINE :
        //(Commesse, Fatture).
        dispatch(fetchAllDelete(URL_ORDINE + payload.id, token));
        break;
      case "commessa":
        // PUOI ELIMINARE UNA COMMESSA SOLO SE NON E GIA STATA SCELTA IN UNAO DEGLI INCARICO FORNITORE
        dispatch(fetchAllDelete(URL_COMMESSA + payload.id, token));
        break;
      case "sede":
        // PUOI ELIMINARE UNA SEDE IN QUALUNQUE MOMENTO
        dispatch(fetchAllDelete(URL_SEDE + payload.id, token));
        break;
      case "banca":
        // PUOI ELIMINARE UNA BANCA SOLO SE NON E GIA STATA SCELTA IN UNA DELLE FATTURE
        dispatch(fetchAllDelete(URL_BANCA + payload.id, token));
        break;
      case "fornitore":
        // PUOI ELIMINARE UN FORNITORE SOLO SE NON E GIA STATA SCELTO NELLA SELECT DI COMMESSA O INCARICO-FORNITORE;
        // QUANDO STAI ELIMINANDO UN FORNITORE, DEVI ANCHE PRENDERE IL SUO ID, ANDARE A CERCARE
        // IN INDIRIZZO-FORNITORE.FORNITORE QUELL ID, PRENDERE QUELL INDIRIZZO FORNITORE, ED ELIMINARE PURE QUELLO
        dispatch(fetchAllDelete(URL_FORNITORE + payload.id, token));
        break;
      case "incarico-fornitore":
        // PUOI ELIMINARE UN INCARICO FORNITORE IN QUALUNQUE MOMENTO
        dispatch(fetchAllDelete(URL_INCARICO_FORNITORE + payload.id, token));
        break;
      case "sito":
        // PUOI ELIMINARE UN SITO SOLO SE NON E GIA STATO SCELTO IN UNA DELLE SELECT CHE SI RIFERISCONO A SITO
        // (Attivita, Offerta). QUANDO STAI ELIMINANDO UN SITO, DEVI ANCHE PRENDERE IL SUO ID, ANDARE A CERCARE
        // IN INDIRIZZO-SITO.SITO QUELL ID, PRENDERE QUELL INDIRIZZO-SITO, ED ELIMINARE PURE QUELLO
        dispatch(fetchAllDelete(URL_SITO + payload.id, token));
        break;
      case "stato":
        // PUOI ELIMINARE UNO STATO SOLO SE NON E GIA STATO SCELTO IN UNA DELLE COMMESSE
        dispatch(fetchAllDelete(URL_STATO + payload.id, token));
        break;
      case "tipologia-attivita":
        // PUOI ELIMINARE UNA TIPOLOGIA ATTIVITA SOLO SE NON E GIA STATO SCELTA IN UNA DELLE ATTIVITA
        dispatch(fetchAllDelete(URL_TIPOLOGIA_ATTIVITA + payload.id, token));
        break;
      case "dipendente":
        dispatch(fetchAllDelete(URL_DIPENDENTE + payload.id, token));
      default:
        break;
    }
    handleCloseConfirmDeleteModal();
    handleClose();
  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {!show2
            ? type === "modifica"
              ? `Modifica ${classe}`
              : `Aggiungi ${classe}`
            : `Aggiungi ${classe}`}
        </Modal.Title>
      </Modal.Header>{" "}
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Modal.Body className="bg-light">
          {classe === "cliente" ? (
            <FormClienti />
          ) : classe === "fattura" ? (
            <FormFatture />
          ) : classe === "attivita" ? (
            <FormAttivita />
          ) : classe === "ordine" ? (
            <FormOrdini />
          ) : classe === "commessa" ? (
            <FormCommesse />
          ) : classe === "offerta" ? (
            <FormOfferte />
          ) : classe === "sede" ? (
            <FormSedi />
          ) : classe === "banca" ? (
            <FormBanche />
          ) : classe === "fornitore" ? (
            <FormFornitori />
          ) : classe === "incarico-fornitore" ? (
            <FormIncaricoFornitori />
          ) : classe === "sito" ? (
            <FormSiti />
          ) : classe === "stato" ? (
            <FormStati />
          ) : classe === "tipologia-attivita" ? (
            <FormTipologiaAttivita />
          ) : classe === "dipendente" ? (
            <FormDipendenti />
          ) : classe === "responsabili" ? (
            <FormResponsabile />
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between align-items center">
          <Button
            variant="outline-primary"
            disabled={!isAuthorizedDo}
            onClick={handleOpenConfirmDeleteModal}
          >
            Elimina
          </Button>
          <div>
            <Button variant="secondary me-3" onClick={handleClose}>
              Annulla
            </Button>
            <Button variant="primary" disabled={!isAuthorizedDo} type="submit">
              Salva
            </Button>
          </div>
        </Modal.Footer>

        <Modal
          show={showConfirmDeleteModal}
          onHide={handleCloseConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Conferma Eliminazione</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Sicuro di voler eliminare? L&#39;azione non è reversibile.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseConfirmDeleteModal}>
              Annulla
            </Button>
            <Button variant="primary" onClick={handleConfirmDelete}>
              Elimina
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </Modal>
  );
};

export default Modale;
