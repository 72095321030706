export const GetAttivita = (lista, lista2, lista3, lista4, lista5, setData) => {
  if (
    lista &&
    lista2 &&
    lista.length >= 0 &&
    lista2.length >= 0 &&
    lista3 &&
    lista3.length >= 0 &&
    lista4 &&
    lista4.length >= 0 &&
    lista5 &&
    lista5.length >= 0
  ) {
    const combinedList = lista.map((item) => {
      let cliente = lista2.filter((item2) => item.cliente === item2.id);
      let fullName = cliente[0].nome + " " + cliente[0].cognome;
      let combinedItem = {
        ...item,
        cliente: fullName,
        idCliente: cliente[0].id,
      };
      return combinedItem;
    });
    const combinedList2 = combinedList.map((item) => {
      let offerta = lista3.filter((item3) => item.offerta === item3.id);
      let protocollo = offerta[0].protocollo;
      let combinedItem = {
        ...item,
        offerta: protocollo,
        idOfferta: offerta[0].id,
      };

      return combinedItem;
    });
    const combinedList3 = combinedList2.map((item) => {
      let tipologia = lista4.filter((item4) => item.tipologia === item4.id);

      if (tipologia.length === 0) {
        tipologia = null;
      } else {
        tipologia = tipologia[0];
      }

      let combinedItem = {
        ...item,
        tipologia: tipologia ? tipologia.denominazione : null,
        idTipologia: tipologia ? tipologia.id : null,
      };

      return combinedItem;
    });

    const combinedList4 = combinedList3.map((item) => {
      let sito = lista5.filter((item4) => item.sito === item4.id);
      let sito_o_punto_vendita = sito[0].sito_o_punto_vendita;
      let combinedItem = {
        ...item,
        sito: sito_o_punto_vendita,
        idSito: sito[0].id,
        openModal: (
          <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
        ),
      };

      return combinedItem;
    });

    setData(combinedList4);
  } else {
    if (lista && lista.length > 0) {
      const updatedList = lista.map((item) => ({
        ...item,
        openModal: (
          <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
        ),
      }));
      setData(updatedList);
    }
  }
};
