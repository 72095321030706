import { Col, Container, Row } from "react-bootstrap";

const OrdiniDetails = ({ data }) => {
  return (
    <Container className="border bg-light">
      <Row>
        <Col className="border p-2 text-center">
          {" "}
          <p className="dettagli-title ms-2 m-0 ">N° Offerte</p>
          <span className="dettagli"> {data.offerte.length}</span>
        </Col>
        <Col className="border p-2 text-center">
          {" "}
          <p className="dettagli-title ms-2 m-0 ">N° Attività</p>
          <span className="dettagli"> {data.attivita.length}</span>
        </Col>
      </Row>
    </Container>
  );
};
export default OrdiniDetails;
