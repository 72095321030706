import {
  SET_ISLOAD_POST,
  fetchAllPut,
  fetchGeneric,
  fetchSelectFornitore,
  hideAlert,
  setIsLoadFetch,
  showAlert,
} from "../../redux/actions";
import { URL_FORNITORE, URL_INDIRIZZO_FORNITORE } from "../url";

export const fornitorePiuIndirizzoPost = async (token, payload, dispatch) => {
  try {
    const resp = await fetch(
      "https://be.civetta.maritsrl.com/api/v1/" + URL_FORNITORE,
      {
        method: "POST",
        headers: {
          Authorization: `Token ` + token,
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const data = await resp.json();

    const payload2 = { ...payload, fornitore: data.id };
    const resp2 = await fetch(
      "https://be.civetta.maritsrl.com/api/v1/" + URL_INDIRIZZO_FORNITORE,
      {
        method: "POST",
        headers: {
          Authorization: `Token ` + token,
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload2),
      }
    );
    dispatch(showAlert("Record salvato con successo", "success"));
    setTimeout(() => {
      dispatch(hideAlert());
    }, 3000);
  } catch (error) {
    dispatch(showAlert("Errore", "primary"));
    setTimeout(() => {
      dispatch(hideAlert());
    }, 3000);
  }
};

export const setFetchFornitore = (type, payload, token, dispatch) => {
  if (type === "modifica") {
    dispatch(fetchAllPut(URL_FORNITORE + payload.id, payload, token));
    if (
      payload.via &&
      payload.comune &&
      payload.provincia &&
      payload.regione &&
      payload.cap
    ) {
      if (payload.idIndirizzo) {
        dispatch(
          fetchAllPut(
            URL_INDIRIZZO_FORNITORE + payload.idIndirizzo,
            payload,
            token
          )
        );
      } else {
        payload = { ...payload, fornitore: payload.id };
        dispatch(fetchGeneric(URL_INDIRIZZO_FORNITORE, payload, token));
      }
    }
  } else if (type === "aggiungi") {
    fornitorePiuIndirizzoPost(token, payload, dispatch);
    dispatch(setIsLoadFetch(SET_ISLOAD_POST, true));
    setTimeout(() => {
      dispatch(setIsLoadFetch(SET_ISLOAD_POST, false));
    }, 1000);
    dispatch(fetchSelectFornitore(token));
  }
};
