import { Button, Container, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const redirectTo = () => {
    navigate("/");
  };
  return (
    <Container className=" d-flex  justify-content-center  align-items-center" style={{ height: "90vh" }}>
      <div>
        <Image src="/assets/positvo.png" />
        <h2>OOPS, questa pagina non esiste :/</h2>
        <p className="mt-3">Clicca qui e torna alla home page</p>
        <Button style={{ backgroundColor: "#2B75BC" }} onClick={redirectTo}>
          homepage
        </Button>
      </div>
    </Container>
  );
};

export default NotFoundPage;
