import { Col, Form, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchSelectFornitore,
  fetchSelectCommesse,
  setUrlFetch,
  SET_URL_FILTER,
  SET_SHOW_FILTER,
  setShow,
  setInfoFilter,
  setArrayFilter,
} from "../../redux/actions";
import { URL_INCARICO_FORNITORE } from "../../lib/url";
import { setUrlFilterIncaricoFornitori } from "./setUrl/setUrlFilterIncaricoFornitori";

const FilterIncaricoFornitori = ({ info, setInformazioni }) => {
  const allFornitori = useSelector((state) => state.select.dataFornitore);
  const allCommesse = useSelector((state) => state.select.dataCommesse);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.token);

  useEffect(() => {
    dispatch(fetchSelectFornitore(token));
    dispatch(fetchSelectCommesse(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeInfo = (value, name) => {
    setInformazioni({
      ...info,

      [name]: value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setInfoFilter(info));

    let nuovoOggetto = { ...info };

    if (nuovoOggetto.fornitore) {
      const z = allFornitori.filter((f) => f.id == nuovoOggetto.fornitore);
      nuovoOggetto = {
        ...nuovoOggetto,
        fornitore: z[0].nome + " " + z[0].cognome,
      };
    }

    if (nuovoOggetto.commessa) {
      const x = allCommesse.filter((f) => f.id == nuovoOggetto.commessa);
      nuovoOggetto = {
        ...nuovoOggetto,
        commessa: x[0].protocollo,
      };
    }

    const arrayPills = Object.entries(nuovoOggetto);

    dispatch(setArrayFilter(arrayPills));

    setUrlFilterIncaricoFornitori(arrayPills, info, dispatch);

    dispatch(setShow(SET_SHOW_FILTER, false));
    setInformazioni({});
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <Row>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label
              column
              sm="2"
              className="d-flex justify-content-between align-items-center"
            >
              Fornitore
            </Form.Label>
            <Form.Select
              value={info.fornitore || ""}
              onChange={(e) => {
                changeInfo(e.target.value, "fornitore");
              }}
              aria-label="Filtra per Fornitore"
            >
              <option value="" disabled hidden>
                Seleziona Fornitore
              </option>
              {allFornitori.length !== 0 ? (
                allFornitori.map((c, index) => (
                  <option key={index} value={c.id}>
                    {c.nome} {c.cognome}
                  </option>
                ))
              ) : (
                <option value="" disabled hidden>
                  Nessun Fornitore disponibile
                </option>
              )}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label
              column
              sm="2"
              className="d-flex justify-content-between align-items-center"
            >
              N° Commessa
            </Form.Label>
            <Form.Select
              value={info.commessa || ""}
              onChange={(e) => changeInfo(e.target.value, "commessa")}
              aria-label="Filtra per Commessa"
            >
              <option value="" disabled hidden>
                Seleziona commessa
              </option>
              {allCommesse.length !== 0 ? (
                allCommesse.map((c, index) => (
                  <option key={index} value={c.id}>
                    {c.protocollo}
                  </option>
                ))
              ) : (
                <option value="" disabled hidden>
                  Nessuna Commessa disponibile
                </option>
              )}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Col xs={{ span: 12 }} className="d-flex justify-content-end">
        <Button type="submit" variant="primary">
          Applica Filtri
        </Button>
      </Col>
    </Form>
  );
};

export default FilterIncaricoFornitori;
