import { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";

const NewPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.currentTarget;
    setPasswordMatch(password === confirmPassword);
    if (form.checkValidity() === false || !passwordMatch) {
      e.stopPropagation();
    } else {
      console.log("ciao");
    }
  };
  return (
    <Container className="d-flex justify-content-center pt-5">
      <div className="w-75">
        <h1 style={{ color: "#2B75BC" }} className="my-5">
          Nuova Password
        </h1>
        <p className="fs-4 pt-5">
          Dopo aver inserito la nuova password e aver validato, verrai renderizzato alla pagina di login.
        </p>
        <Form noValidate onSubmit={handleSubmit} className="mt-4">
          <Form.Group className="w-50">
            <Form.Label>Inserisci la tua password</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder="******"
              value={password}
              onChange={e => setPassword(e.target.value)}
              isInvalid={!passwordMatch}
            />
            <Form.Control.Feedback type="invalid">
              {passwordMatch ? "Inserisci una password valida" : "Le password inserite non coincidono"}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="w-50 mt-3">
            <Form.Label>Ripeti la password</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder="******"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              isInvalid={!passwordMatch}
            />
            <Form.Control.Feedback type="invalid">
              {passwordMatch ? "Inserisci una password valida" : "Le password inserite non coincidono"}
            </Form.Control.Feedback>
          </Form.Group>
          <Button style={{ backgroundColor: "#2B75BC" }} size="lg" type="submit" className="mt-3">
            invia
          </Button>
        </Form>
      </div>
    </Container>
  );
};
export default NewPassword;
