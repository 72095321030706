import { dispatch } from "d3";
import { useSelector } from "react-redux";

export const SET_PATH_NAME = "SET_PATH_NAME";
export const SET_TYPE = "SET_TYPE";
export const SET_SHOW1 = "SET_SHOW1";
export const SET_SHOW2 = "SET_SHOW2";
export const SET_SHOW3 = "SET_SHOW3";
export const SET_SHOW4 = "SET_SHOW4";
export const SET_SHOW_FILTER = "SET_SHOW_FILTER";
export const SET_INFO1 = "SET_INFO1";
export const SET_INFO2 = "SET_INFO2";
export const SET_INFO3 = "SET_INFO3";
export const SET_INFO4 = "SET_INFO4";
export const SET_CLASS1 = "SET_CLASS1";
export const SET_CLASS2 = "SET_CLASS2";
export const SET_CLASS3 = "SET_CLASS3";
export const SET_CLASS4 = "SET_CLASS4";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const ISAUTHORIZED = "ISAUTHORIZED";
export const ISAUTHORIZEDDO = "ISAUTHORIZEDDO";
export const SET_URL_PUT = "SET_URL_PUT";
export const SET_URL_GET = "SET_URL_GET";
export const SET_URL_GET2 = "SET_URL_GET2";
export const SET_URL_GET3 = "SET_URL_GET3";
export const SET_URL_GET4 = "SET_URL_GET4";
export const SET_URL_GET5 = "SET_URL_GET5";
export const SET_URL_GET6 = "SET_URL_GET6";
export const SET_URL_POST = "SET_URL_POST";
export const SET_URL_DELETE = "SET_URL_DELETE";
export const SET_URL_FILTER = "SET_URL_FILTER";
export const SET_DATA_PUT = "SET_DATA_PUT";
export const SET_DATA_POST = "SET_DATA_POST";
export const SET_DATA_DELETE = "SET_DATA_DELETE";
export const SET_DATA_GET = "SET_DATA_GET";
export const SET_DATA_GET2 = "SET_DATA_GET2";
export const SET_DATA_GET3 = "SET_DATA_GET3";
export const SET_DATA_GET4 = "SET_DATA_GET4";
export const SET_DATA_GET5 = "SET_DATA_GET5";
export const SET_DATA_GET6 = "SET_DATA_GET6";
export const SET_DATA_GET_TABLE = "SET_DATA_GET_TABLE";
export const SET_DATA_LOGS_RENDER = "SET_DATA_LOGS_RENDER";
export const SET_DATA_LOGS_DIPENDENTI = "SET_DATA_LOGS_DIPENDENTI";
export const SET_PAYLOAD_PUT = "SET_PAYLOAD_PUT";
export const SET_PAYLOAD_POST = "SET_PAYLOAD_POST";
export const SET_PAYLOAD_DEL = "SET_PAYLOAD_DEL";
export const SET_ISLOAD_PUT = "SET_ISLOAD_PUT";
export const SET_ISLOAD_POST = "SET_ISLOAD_POST";
export const SET_ISLOAD_DEL = "SET_ISLOAD_DEL";
export const SET_ISLOAD_GET = "SET_ISLOAD_GET";
export const SET_ISLOAD_GET2 = "SET_ISLOAD_GET2";
export const SET_ISLOAD_GET3 = "SET_ISLOAD_GET3";
export const SET_ISLOAD_GET4 = "SET_ISLOAD_GET4";
export const SET_ISLOAD_GET5 = "SET_ISLOAD_GET5";
export const SET_ISLOAD_GET6 = "SET_ISLOAD_GET6";
export const SET_DATA_CLIENTI = "SET_DATA_CLIENTI";
export const SET_DATA_OFFERTE = "SET_DATA_OFFERTE";
export const SET_DATA_SITI = "SET_DATA_SITI";
export const SET_DATA_BANCA = "SET_DATA_BANCA";
export const SET_DATA_FATTURE = "SET_DATA_FATTURE";
export const SET_DATA_BANCA_INTERNAL = "SET_DATA_BANCA_INTERNAL";
export const SET_DATA_FORNITORE = "SET_DATA_FORNITORE";
export const SET_DATA_ORDINE = "SET_DATA_ORDINE";
export const SET_DATA_COMMESSA = "SET_DATA_COMMESSA";
export const SET_DATA_STATI = "SET_DATA_STATI";
export const SET_DATA_TIPOLOGIA_ATTIVITA = "SET_DATA_TIPOLOGIA_ATTIVITA";
export const SET_DATA_ATTIVITA = "SET_DATA_ATTIVITA";
export const SET_DATA_PROJECT_MANAGER = "SET_DATA_PROJECT_MANAGER";
export const SET_DATA_DIPENDENTE = "SET_DATA_DIPENDENTE";
export const SET_LOGS = "SET_LOGS";
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
export const SET_ARRAY_FILTER = "SET_ARRAY_FILTER";
export const SET_INFO_FILTER = "SET_INFO_FILTER";
export const SET_DATA_LAVORI = "SET_DATA_LAVORI";
export const SET_DATA_RESPONSABILI = "SET_DATA_RESPONSABILI";
export const SET_DATA_LAVORI_PER_OFFERTE = "SET_DATA_LAVORI_PER_OFFERTE";

export const showAlert = (message, variant) => ({
  type: SHOW_ALERT,
  payload: { message, variant },
});

export const hideAlert = () => ({
  type: HIDE_ALERT,
});
export const getTokenAction = (token, stayLoggedIn, name, role) => ({
  type: LOGIN,
  payload: { token, stayLoggedIn, name, role },
});

export const logoutAction = () => ({
  type: LOGOUT,
});

export const isAuthorizedAction = (data) => ({
  type: ISAUTHORIZED,
  payload: data,
});
export const isAuthorizedDoAction = (data) => ({
  type: ISAUTHORIZEDDO,
  payload: data,
});

export const setArrayFilter = (data) => ({
  type: SET_ARRAY_FILTER,
  payload: data,
});
export const setInfoFilter = (data) => ({
  type: SET_INFO_FILTER,
  payload: data,
});
export const setPathName = (data) => ({ type: SET_PATH_NAME, payload: data });
export const setShow = (type, data) => ({ type: type, payload: data });
export const setInfo = (type, data) => ({ type: type, payload: data });
export const setType = (data) => ({ type: SET_TYPE, payload: data });
export const setClass = (type, data) => ({ type: type, payload: data });
export const setUrlFetch = (type, data) => ({ type: type, payload: data });
export const setDataFetch = (type, data) => ({ type: type, payload: data });
export const setDataGetTable = (data) => ({
  type: SET_DATA_GET_TABLE,
  payload: data,
});
export const setPayloadFetch = (type, data) => ({ type: type, payload: data });
export const setIsLoadFetch = (type, data) => ({ type: type, payload: data });
export const setLogsFetch = (type, data) => ({ type: type, payload: data });
export const setDataDipendenti = (data) => ({
  type: SET_DATA_LOGS_DIPENDENTI,
  payload: data,
});
export const setDataLogs = (data) => ({
  type: SET_DATA_LOGS_RENDER,
  payload: data,
});

export const fetchGeneric = (url, payload, token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/" + url,
        {
          method: "POST",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await resp.json();

      dispatch(setDataFetch(SET_DATA_POST, data.results));
      dispatch({ type: SET_DATA_POST, payload: data });
      dispatch({ type: SET_URL_POST, payload: "" });

      dispatch(setIsLoadFetch(SET_ISLOAD_POST, true));
      setTimeout(() => {
        dispatch(setIsLoadFetch(SET_ISLOAD_POST, false));
      }, 1000);

      if (resp.ok)
        dispatch(showAlert("Record salvato con successo", "success"));
      else dispatch(showAlert("Errore", "primary"));
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
    } catch (error) {
      dispatch(showAlert("Errore", "primary"));
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
    }
  };
};
export const fetchAllPut = (url, payload, token) => {
  return async (dispatch, getState) => {
    try {
      const normalizedUrl = url.endsWith("/") ? url : url + "/";

      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/" + normalizedUrl,
        {
          method: "PATCH",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await resp.json();

      dispatch({ type: SET_DATA_PUT, payload: data });
      dispatch({ type: SET_URL_PUT, payload: "" });
      dispatch({ type: SET_PAYLOAD_PUT, payload: "" });

      dispatch(setIsLoadFetch(SET_ISLOAD_PUT, true));
      setTimeout(() => {
        dispatch(setIsLoadFetch(SET_ISLOAD_PUT, false));
      }, 3000);
      dispatch(showAlert("Modifica eseguita con successo", "warning"));
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
    } catch (error) {
      dispatch(showAlert("Errore", "danger"));
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
    }
  };
};
export const fetchAllGet = (url, token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/" + url + "?page_size=all",
        {
          headers: {
            Authorization: `Token ` + token,
          },
        }
      );
      const data = await resp.json();
      await dispatch({ type: SET_DATA_GET, payload: data });
      dispatch(setIsLoadFetch(SET_ISLOAD_GET, true));
      setTimeout(() => {
        dispatch(setIsLoadFetch(SET_ISLOAD_GET, false));
      }, 1000);
      dispatch(setIsLoadFetch(true));
    } catch (error) {
      console.log(error);
    }
  };
};
export const fetchAllGet2 = (url, token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/" + url + "?page_size=all",
        {
          headers: {
            Authorization: `Token ` + token,
          },
        }
      );
      const data = await resp.json();
      await dispatch({ type: SET_DATA_GET2, payload: data });
      dispatch(setIsLoadFetch(SET_ISLOAD_GET2, true));
      setTimeout(() => {
        dispatch(setIsLoadFetch(SET_ISLOAD_GET2, false));
      }, 1000);
      dispatch(setIsLoadFetch(true));
    } catch (error) {
      console.log(error);
    }
  };
};
export const fetchAllGet3 = (url, token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/" + url + "?page_size=all",
        {
          headers: {
            Authorization: `Token ` + token,
          },
        }
      );
      const data = await resp.json();
      await dispatch({ type: SET_DATA_GET3, payload: data });
      dispatch(setIsLoadFetch(SET_ISLOAD_GET3, true));
      setTimeout(() => {
        dispatch(setIsLoadFetch(SET_ISLOAD_GET3, false));
      }, 1000);
      dispatch(setIsLoadFetch(true));
    } catch (error) {
      console.log(error);
    }
  };
};
export const fetchAllGet4 = (url, token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/" + url + "?page_size=all",
        {
          headers: {
            Authorization: `Token ` + token,
          },
        }
      );
      const data = await resp.json();
      await dispatch({ type: SET_DATA_GET4, payload: data });
      dispatch(setIsLoadFetch(SET_ISLOAD_GET4, true));
      setTimeout(() => {
        dispatch(setIsLoadFetch(SET_ISLOAD_GET4, false));
      }, 1000);
      dispatch(setIsLoadFetch(true));
    } catch (error) {
      console.log(error);
    }
  };
};
export const fetchAllGet5 = (url, token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/" + url + "?page_size=all",
        {
          headers: {
            Authorization: `Token ` + token,
          },
        }
      );
      const data = await resp.json();
      await dispatch({ type: SET_DATA_GET5, payload: data });
      dispatch(setIsLoadFetch(SET_ISLOAD_GET5, true));
      setTimeout(() => {
        dispatch(setIsLoadFetch(SET_ISLOAD_GET5, false));
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };
};
export const fetchAllGet6 = (url, token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/" + url + "?page_size=all",
        {
          headers: {
            Authorization: `Token ` + token,
          },
        }
      );
      const data = await resp.json();
      await dispatch({ type: SET_DATA_GET6, payload: data });
      dispatch(setIsLoadFetch(SET_ISLOAD_GET6, true));
      setTimeout(() => {
        dispatch(setIsLoadFetch(SET_ISLOAD_GET6, false));
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchAllDelete = (url, token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/" + url,
        {
          method: "DELETE",
          headers: {
            Authorization: `Token ` + token,
          },
        }
      );
      if (resp.status === 204) {
        // const data = await resp.json();
        await dispatch({ type: SET_DATA_DELETE, payload: resp });
        dispatch({ type: SET_URL_DELETE, payload: "" });

        dispatch(setIsLoadFetch(SET_ISLOAD_DEL, true));
        setTimeout(() => {
          dispatch(setIsLoadFetch(SET_ISLOAD_DEL, false));
        }, 1000);
        dispatch(showAlert("Eliminazione eseguita con successo", "danger"));
        setTimeout(() => {
          dispatch(hideAlert());
        }, 3000);
      }
    } catch (error) {
      dispatch(showAlert("Errore", "danger"));
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
    }
  };
};

export const FETCH_ATTIVITA_COUNT = "FETCH_ATTIVITA_COUNT";
export const FETCH_COMMESSE_COUNT = "FETCH_COMMESSE_COUNT";
export const FETCH_ORDINI_COUNT = "FETCH_ORDINI_COUNT";
export const FETCH_OFFERTE_COUNT = "FETCH_OFFERTE_COUNT";
export const SET_COUNT = "SET_COUNT";
// Action Creators
const fetchCount = (token, type, actionType) => {
  return async (dispatch) => {
    try {
      const url = `https://be.civetta.maritsrl.com/api/v1/${type}`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      const data = await response.json();
      dispatch({ type: actionType, payload: { type, count: data.count } });
    } catch (error) {
      console.error("Fetch Error:", error);
    }
  };
};
export const fetchAttivitaCount = (token) =>
  fetchCount(token, "attivita", FETCH_ATTIVITA_COUNT);
export const fetchCommesseCount = (token) =>
  fetchCount(token, "commessa", FETCH_COMMESSE_COUNT);
export const fetchOrdiniCount = (token) =>
  fetchCount(token, "ordine", FETCH_ORDINI_COUNT);
export const fetchOfferteCount = (token) =>
  fetchCount(token, "offerta", FETCH_OFFERTE_COUNT);

export const fetchSelectClienti = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/cliente/?page_size=all",
        {
          method: "GET",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
        }
      );
      const data = await resp.json();
      dispatch(setDataFetch(SET_DATA_CLIENTI, data));
    } catch (error) {
      console.log(error);
    }
  };
};
export const fetchSelectBanca = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/banca/?page_size=all",
        {
          method: "GET",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
        }
      );
      const data = await resp.json();
      dispatch(setDataFetch(SET_DATA_BANCA, data));
    } catch (error) {
      console.log(error);
    }
  };
};
export const fetchSelectBancaInternal = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/banca/?is_internal=true",
        {
          method: "GET",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
        }
      );
      const data = await resp.json();
      dispatch(setDataFetch(SET_DATA_BANCA_INTERNAL, data));
    } catch (error) {
      console.log(error);
    }
  };
};
export const fetchSelectOfferte = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/offerta/?page_size=all",
        {
          method: "GET",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
        }
      );
      const data = await resp.json();
      dispatch(setDataFetch(SET_DATA_OFFERTE, data));
    } catch (error) {
      console.log(error);
    }
  };
};
export const fetchSelectCommesse = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/commessa/?page_size=all",
        {
          method: "GET",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
        }
      );
      const data = await resp.json();
      dispatch(setDataFetch(SET_DATA_COMMESSA, data));
    } catch (error) {
      console.log(error);
    }
  };
};
export const fetchSelectSiti = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/sito/?page_size=all",
        {
          method: "GET",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
        }
      );
      const data = await resp.json();
      dispatch(setDataFetch(SET_DATA_SITI, data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchSelectFatture = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/fattura/?page_size=all",
        {
          method: "GET",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
        }
      );
      const data = await resp.json();
      dispatch(setDataFetch(SET_DATA_FATTURE, data));
    } catch (error) {
      console.log(error);
    }
  };
};
export const fetchSelectFornitore = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/fornitore/?page_size=all",
        {
          method: "GET",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
        }
      );
      const data = await resp.json();
      dispatch(setDataFetch(SET_DATA_FORNITORE, data));
    } catch (error) {
      console.log(error);
    }
  };
};
export const fetchSelectOrdine = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/ordine/?page_size=all",
        {
          method: "GET",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
        }
      );
      const data = await resp.json();
      dispatch(setDataFetch(SET_DATA_ORDINE, data));
    } catch (error) {
      console.log(error);
    }
  };
};
export const fetchSelectStati = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/stato/?page_size=all",
        {
          method: "GET",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
        }
      );
      const data = await resp.json();
      dispatch(setDataFetch(SET_DATA_STATI, data));
    } catch (error) {
      console.log(error);
    }
  };
};
export const fetchSelectTipologiaAttivita = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/tipologia-attivita/?page_size=all",
        {
          method: "GET",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
        }
      );
      const data = await resp.json();
      dispatch(setDataFetch(SET_DATA_TIPOLOGIA_ATTIVITA, data));
    } catch (error) {
      console.log(error);
    }
  };
};
export const fetchSelectProjectManager = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/project-manager/?page_size=all",
        {
          method: "GET",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
        }
      );
      const data = await resp.json();
      dispatch(setDataFetch(SET_DATA_PROJECT_MANAGER, data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchSelectDipendente = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/dipendente/?page_size=all",
        {
          method: "GET",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
        }
      );
      const data = await resp.json();
      dispatch(setDataFetch(SET_DATA_DIPENDENTE, data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchSelectAttivita = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/attivita/?page_size=all",
        {
          method: "GET",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
        }
      );
      const data = await resp.json();
      dispatch(setDataFetch(SET_DATA_ATTIVITA, data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchSelectLavori = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/lavori/?page_size=all",
        {
          method: "GET",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
        }
      );
      const data = await resp.json();
      dispatch(setDataFetch(SET_DATA_LAVORI, data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchSelectResponsabili = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/responsabili/?page_size=all",
        {
          method: "GET",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
        }
      );
      const data = await resp.json();
      dispatch(setDataFetch(SET_DATA_RESPONSABILI, data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchLogs = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/history/",
        {
          method: "GET",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
        }
      );
      const data = await resp.json();
      dispatch(setLogsFetch(SET_LOGS, data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchAllFilter = (url, token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/" + url,
        {
          headers: {
            Authorization: `Token ` + token,
          },
        }
      );
      const data = await resp.json();
      await dispatch({ type: SET_DATA_GET, payload: data.results });
      dispatch(setIsLoadFetch(SET_ISLOAD_GET, true));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchAllDipendenti = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/dipendente/?page_size=all",
        {
          headers: {
            Authorization: `Token ` + token,
          },
        }
      );
      const data = await resp.json();
      await dispatch({ type: SET_DATA_LOGS_DIPENDENTI, payload: data });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchAllLogs = (token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/history/?page_size=all",
        {
          headers: {
            Authorization: `Token ` + token,
          },
        }
      );
      const data = await resp.json();
      await dispatch({ type: SET_DATA_LOGS_RENDER, payload: data });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchDipendentiERuolo = (url, payload, token) => {
  return async (dispatch, getState) => {
    try {
      const resp = await fetch(
        "https://be.civetta.maritsrl.com/api/v1/dipendente/",
        {
          method: "POST",
          headers: {
            Authorization: `Token ` + token,
            "Content-type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      if (resp.ok) {
        const data = await resp.json();
        const user = { dipendente: data.id, mansione: payload.mansione };
        const resp2 = await fetch(
          "https://be.civetta.maritsrl.com/api/v1/" + url + "/",
          {
            method: "POST",
            headers: {
              Authorization: `Token ` + token,
              "Content-type": "application/json",
            },
            body: JSON.stringify(user),
          }
        );
        dispatch(setIsLoadFetch(SET_ISLOAD_POST, true));
        setTimeout(() => {
          dispatch(setIsLoadFetch(SET_ISLOAD_POST, false));
        }, 1000);
        dispatch(showAlert("Record salvato con successo", "success"));
        setTimeout(() => {
          dispatch(hideAlert());
        }, 3000);
      }
    } catch (error) {
      dispatch(showAlert("Errore", "danger"));
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
    }
  };
};

// export const fetchNewRole = (url, url2, dipendente, payload, token) => {
//   return async (dispatch, getState) => {
//     try {
//       const resp = await fetch("https://be.civetta.maritsrl.com/api/v1/" + url + "/?dipendente=" + dipendente, {
//         headers: {
//           Authorization: `Token ` + token,
//         },
//       });
//       if (resp.ok) {
//         const data = await resp.json();
//         const id = data.id;
//         const resp2 = await fetch("https://be.civetta.maritsrl.com/api/v1/" + url + "/" + id + "/", {
//           method: "DELETE",
//           headers: {
//             Authorization: `Token ` + token,
//           },
//         });
//         if (resp2.ok) {
//           const resp3 = await fetch("https://be.civetta.maritsrl.com/api/v1/" + url2 + "/", {
//             method: "POST",
//             headers: {
//               Authorization: `Token ` + token,
//               "Content-type": "application/json",
//             },
//             body: JSON.stringify(payload),
//           });
//         }
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
// };
