import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUrlFilterBanche } from "../filters/setUrl/setUrlFilterBanche";
import { setUrlFilterSedi } from "../filters/setUrl/setUrlFilterSedi";
import { setUrlFilterFornitori } from "../filters/setUrl/setUrlFilterFornitori";
import { setArrayFilter, setInfoFilter } from "../../redux/actions";
import { setUrlFilterTipologiaAttivita } from "../filters/setUrl/setUrlFilterTipologiaAttivita";
import { setUrlFilterAttivita } from "../filters/setUrl/setUrlFilterAttivita";
import { setUrlFilterCommesse } from "../filters/setUrl/setUrlFilterCommesse";
import { setUrlFilterClienti } from "../filters/setUrl/setUrlFilterClienti";
import { setUrlFilterFatture } from "../filters/setUrl/setUrlFilterFatture";
import { setUrlFilterOrdini } from "../filters/setUrl/setUrlFilterOrdini";
import { setUrlFilterOfferta } from "../filters/setUrl/setUrlFilterOfferta";
import { setUrlFilterIncaricoFornitori } from "../filters/setUrl/setUrlFilterIncaricoFornitori";
import { setUrlFilterSiti } from "../filters/setUrl/setUrlFIlterSiti";
import { setUrlFilterStati } from "../filters/setUrl/setUrlFilterStati";
import { setUrlFilterDipendenti } from "../filters/setUrl/setUrlFilterDipendenti";

const Pills = () => {
  const dispatch = useDispatch();
  const arrayPills = useSelector((state) => state.filter.arrayPill);
  const path = useSelector((state) => state.locationPage.path);
  const dataFilter = useSelector((state) => state.filter.dataFilter);

  const [arrayLocale, setArrayLocale] = useState([]);

  useEffect(() => {
    setArrayLocale(arrayPills);
  }, [arrayPills]);

  useEffect(() => {
    dispatch(setArrayFilter([]));
    dispatch(setInfoFilter({}));
  }, [path]);

  const handleDeletePill = (str, index) => {
    const newArray = [...arrayLocale];
    newArray.splice(index, 1);
    setArrayLocale(newArray);

    const oggetto = { ...dataFilter };

    const nuovoOggetto = Object.fromEntries(
      Object.entries(oggetto).filter(([chiave, _]) => chiave !== str)
    );

    dispatch(setArrayFilter(newArray));
    dispatch(setInfoFilter(nuovoOggetto));

    switch (path) {
      case "cliente":
        setUrlFilterClienti(newArray, nuovoOggetto, dispatch);
        break;
      case "fattura":
        setUrlFilterFatture(newArray, nuovoOggetto, dispatch);
        break;
      case "ordine":
        setUrlFilterOrdini(newArray, nuovoOggetto, dispatch);
        break;
      case "attivita":
        setUrlFilterAttivita(newArray, nuovoOggetto, dispatch);
        break;
      case "offerta":
        setUrlFilterOfferta(newArray, nuovoOggetto, dispatch);
        break;
      case "commessa":
        setUrlFilterCommesse(newArray, nuovoOggetto, dispatch);
        break;
      case "sede":
        setUrlFilterSedi(newArray, nuovoOggetto, dispatch);
        break;
      case "banca":
        setUrlFilterBanche(newArray, nuovoOggetto, dispatch);
        break;
      case "fornitore":
        setUrlFilterFornitori(newArray, nuovoOggetto, dispatch);
        break;
      case "incarico-fornitore":
        setUrlFilterIncaricoFornitori(newArray, nuovoOggetto, dispatch);
        break;
      case "sito":
        setUrlFilterSiti(newArray, nuovoOggetto, dispatch);
        break;
      case "stato":
        setUrlFilterStati(newArray, nuovoOggetto, dispatch);
        break;
      case "tipologia-attivita":
        setUrlFilterTipologiaAttivita(newArray, nuovoOggetto, dispatch);
        break;
      case "dipendente":
        setUrlFilterDipendenti(newArray, nuovoOggetto, dispatch);
    }
  };

  return (
    <>
      <div>
        {arrayLocale.map(
          (pill, index) =>
            pill[1] !== "" && (
              <span
                key={index}
                className="btn btn-outline-primary me-2 mb-3 ps-3"
                onClick={() => handleDeletePill(pill[0], index)}
              >
                <i className="bi bi-x-circle me-2"></i>
                {pill[0] === "incassata"
                  ? pill[1]
                    ? "Incassata"
                    : "Non incassata"
                  : typeof pill[1] === "boolean"
                  ? pill[1]
                    ? "Oil"
                    : "Non-oil"
                  : pill[1]}
              </span>
            )
        )}
      </div>
    </>
  );
};
export default Pills;
