import { Col, Container, Row } from "react-bootstrap";

const FattureDetails = ({ data }) => {
  return (
    <Container className="border bg-light">
      <Row>
        <Col className="border p-2 text-center">
          {" "}
          <p className="dettagli-title ms-2 m-0 text-center">Banca</p>
          <span className="dettagli"> {data.banca}</span>
        </Col>
        <Col className="border p-2 text-center">
          {" "}
          <p className="dettagli-title ms-2 m-0 text-center">Anno Competenze</p>
          <span className="dettagli">{data.anno_competenza}</span>
        </Col>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">Data Emissione</p>
          <span className="dettagli">{data.data_emissione}</span>
        </Col>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">Note </p>
          <span className="dettagli">{data.note}</span>
        </Col>
      </Row>
    </Container>
  );
};
export default FattureDetails;
