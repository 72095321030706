import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_CLASS1,
  SET_INFO1,
  SET_SHOW1,
  SET_SHOW_FILTER,
  isAuthorizedDoAction,
  setClass,
  setInfo,
  setShow,
  setType,
} from "../../redux/actions";
import {
  ADD_FATTURE,
  ADD_ATTIVITA,
  ADD_OFFERTE,
  ADD_ORDINI,
  ADD_COMMESSE,
  ADD_SEDI,
  ADD_BANCHE,
  ADD_FORNITORI,
  ADD_INCARICO_FORNITORI,
  ADD_CLIENTE,
  ADD_SITI,
  ADD_STATI,
  ADD_TIPOLOGIA_ATTIVITA,
  ADD_DIPENDENTE,
} from "../../lib/payloads";
import { useState } from "react";

const Header = () => {
  const path = useSelector(state => state.locationPage.path);
  const name = useSelector(state => state.locationPage.name);
  const role = useSelector(state => state.login.role);

  //const [isAuthorized, setIsAuthorized] = useState(true);
  const isAuthorizedDo = useSelector(state => state.login.isAuthorizedDo);
  const allowedRolesAA = ["Amministratore", "Amministrazione"];
  const allowedRolesAAC = ["Amministratore", "Amministrazione", "Commerciale"];
  const allowedRolesAAP = ["Amministratore", "Amministrazione", "ProjectManager"];
  // const allowedRolesAACP = [
  //   "Amministratore",
  //   "Amministrazione",
  //   "Project Manager",
  //   "Commerciale",
  // ];

  const dispatch = useDispatch();

  const handleShowModal = () => {
    switch (path) {
      case "cliente":
        dispatch(setInfo(SET_INFO1, ADD_CLIENTE));
        break;
      case "fattura":
        dispatch(setInfo(SET_INFO1, ADD_FATTURE));
        break;
      case "attivita":
        dispatch(setInfo(SET_INFO1, ADD_ATTIVITA));
        break;
      case "offerta":
        dispatch(setInfo(SET_INFO1, ADD_OFFERTE));
        break;
      case "ordine":
        dispatch(setInfo(SET_INFO1, ADD_ORDINI));
        break;
      case "commessa":
        dispatch(setInfo(SET_INFO1, ADD_COMMESSE));
        break;
      case "sede":
        dispatch(setInfo(SET_INFO1, ADD_SEDI));
        break;
      case "banca":
        dispatch(setInfo(SET_INFO1, ADD_BANCHE));
        break;
      case "fornitore":
        dispatch(setInfo(SET_INFO1, ADD_FORNITORI));
        break;
      case "incarico-fornitore":
        dispatch(setInfo(SET_INFO1, ADD_INCARICO_FORNITORI));
        break;
      case "sito":
        dispatch(setInfo(SET_INFO1, ADD_SITI));
        break;
      case "stato":
        dispatch(setInfo(SET_INFO1, ADD_STATI));
        break;
      case "tipologia-attivita":
        dispatch(setInfo(SET_INFO1, ADD_TIPOLOGIA_ATTIVITA));
        break;
      case "dipendente":
        dispatch(setInfo(SET_INFO1, ADD_DIPENDENTE));
        break;
      default:
        break;
    }
    dispatch(setType("aggiungi"));
    dispatch(setClass(SET_CLASS1, path));
    dispatch(setShow(SET_SHOW1, true));
  };
  const handleShowFilter = () => {
    switch (path) {
      case "cliente":
        dispatch(setInfo(SET_INFO1, ADD_CLIENTE));
        break;
      case "fattura":
        dispatch(setInfo(SET_INFO1, ADD_FATTURE));
        break;
      case "attivita":
        dispatch(setInfo(SET_INFO1, ADD_ATTIVITA));
        break;
      case "offerta":
        dispatch(setInfo(SET_INFO1, ADD_OFFERTE));
        break;
      case "ordine":
        dispatch(setInfo(SET_INFO1, ADD_ORDINI));
        break;
      case "commessa":
        dispatch(setInfo(SET_INFO1, ADD_COMMESSE));
        break;
      case "sede":
        dispatch(setInfo(SET_INFO1, ADD_SEDI));
        break;
      case "banca":
        dispatch(setInfo(SET_INFO1, ADD_BANCHE));
        break;
      case "fornitore":
        dispatch(setInfo(SET_INFO1, ADD_FORNITORI));
        break;
      case "incarico-fornitore":
        dispatch(setInfo(SET_INFO1, ADD_INCARICO_FORNITORI));
        break;
      case "sito":
        dispatch(setInfo(SET_INFO1, ADD_SITI));
        break;
      case "stato":
        dispatch(setInfo(SET_INFO1, ADD_STATI));
        break;
      case "tipologia-attivita":
        dispatch(setInfo(SET_INFO1, ADD_TIPOLOGIA_ATTIVITA));
        break;
      case "dipendente":
        dispatch(setInfo(SET_INFO1, ADD_DIPENDENTE));
        break;
      default:
        break;
    }
    dispatch(setType("filter"));
    dispatch(setClass(SET_CLASS1, path));
    dispatch(setShow(SET_SHOW_FILTER, true));
  };

  return (
    <Container className="mb-3">
      <Row className="d-flex justify-content-between align-items-center">
        <Col xs="6">
          <h1 className="m-0">{name}</h1>
        </Col>
        <Col xs="6" className="text-end">
          {path !== "attivita" && isAuthorizedDo && (
            <Button className="btn-primary " onClick={() => handleShowModal()}>
              <i className="bi bi-plus"></i>
            </Button>
          )}
          {path === "stato" ? (
            <div style={{ display: "inline-block", margin: "0 0px" }}></div>
          ) : (
            <Button className="btn-primary ms-3" onClick={() => handleShowFilter()}>
              <i className="bi bi-filter"></i>
            </Button>
          )}
          {/* <Button className="btn-primary" id={`downloadCsv_${path}`}>
            <i className="bi bi-download"></i>
          </Button> */}
        </Col>
      </Row>
    </Container>
  );
};
export default Header;
