export const URL_AMMINISTRATORE = "amministratore/";
export const URL_AMMINISTRAZIONE = "amministrazione/";
export const URL_ANAGRAFICA_DIPENDENTE = "anagrafica-dipendente/";
export const URL_ATTIVITA = "attivita/";
export const URL_TIPOLOGIA_ATTIVITA = "tipologia-attivita/";
export const URL_INTERVENTO_RAPIDO = "intervento-rapido/";
export const URL_REGISTRAZIONE = "auth/registration/";
export const URL_LOGIN = "auth/login/";
export const URL_CHECK_TOKEN = "check-token/";
export const URL_CLIENTI = "cliente/";
export const URL_COMMERCIALE = "commerciale/";
export const URL_COMMESSA = "commessa/";
export const URL_COORDINAMENTO = "coordinamento/";
export const URL_FORNITORE = "fornitore/";
export const URL_INCARICO_FORNITORE = "incarico-fornitore/";
export const URL_INDIRIZZO_FORNITORE = "indirizzo-fornitore/";
export const URL_BANCA = "banca/";
export const URL_COUNT = "count/works/";
export const URL_ESTREMI_PA = "estremi-pa/";
export const URL_ESTREMI_AZIENDA = "estremi-azienda/";
export const URL_ESTREMI_PRIVATO = "estremi-privato/";
export const URL_FATTURA = "fattura/";
export const URL_DIPENDENTE = "dipendente/";
export const URL_INDIRIZZO_CLIENTE = "indirizzo-cliente/";
export const URL_INFORMAZIONI_CLIENTE = "informazioni-cliente/";
export const URL_INDIRIZZO_DIPENDENTE = "indirizzo-dipendente/";
export const URL_INDIRIZZO_SITO = "indirizzo-sito/";
export const URL_OFFERTA = "offerta/";
export const URL_ORDINE = "ordine/";
export const URL_PROJECT_MANAGER = "project-manager/";
export const URL_SITO = "sito/";
export const URL_SEDE = "sede/";
export const URL_STATO = "stato/";
export const URL_SOTTO_ATTIVITA = "sotto-attivita/";
export const URL_TECNICO = "tecnico/";
export const URL_RESPONSABILI = "responsabili/";
export const URL_LAVORI = "lavori/";
