import {
  SET_CLASS1,
  SET_CLASS2,
  SET_CLASS3,
  SET_CLASS4,
  SET_DATA_LAVORI_PER_OFFERTE,
  SET_INFO1,
  SET_INFO2,
  SET_INFO3,
  SET_INFO4,
  SET_SHOW1,
  SET_SHOW2,
  SET_SHOW3,
  SET_SHOW4,
  SET_SHOW_FILTER,
  SET_TYPE,
} from "../actions";

const initialState = {
  type: "", //tipo modifica o aggiungi
  show1: false,
  show2: false,
  show3: false,
  show4: false,
  showFilter: false,
  info1: {}, // payload per la post o put
  info2: [], // lista di attivita legate ad offerte
  info3: {}, // payload per la post o put
  info4: {}, // payload per la post o put
  lavoriPerOfferte: [], //lista ei lavori da creare
  class1: "", //classe di riferimento su cui fare post o put
  class2: "", //classe di riferimento su cui fare post o put
  class3: "", //classe di riferimento su cui fare post o put
  class4: "", //classe di riferimento su cui fare post o put
};

const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case SET_SHOW1:
      return {
        ...state,
        show1: action.payload,
      };
    case SET_SHOW2:
      return {
        ...state,
        show2: action.payload,
      };
    case SET_SHOW3:
      return {
        ...state,
        show3: action.payload,
      };
    case SET_SHOW4:
      return {
        ...state,
        show4: action.payload,
      };
    case SET_SHOW_FILTER:
      return {
        ...state,
        showFilter: action.payload,
      };
    case SET_INFO1:
      return {
        ...state,
        info1: { ...state.info, ...action.payload },
      };
    case SET_INFO2:
      return {
        ...state,
        info2: { ...state.info, ...action.payload },
      };
    case SET_INFO3:
      return {
        ...state,
        info3: { ...state.info, ...action.payload },
      };
    case SET_INFO4:
      return {
        ...state,
        info4: { ...state.info, ...action.payload },
      };
    case SET_DATA_LAVORI_PER_OFFERTE:
      return {
        ...state,
        lavoriPerOfferte: { ...state.info, ...action.payload },
      };
    case SET_CLASS1:
      return {
        ...state,
        class1: action.payload,
      };
    case SET_CLASS2:
      return {
        ...state,
        class2: action.payload,
      };
    case SET_CLASS3:
      return {
        ...state,
        class3: action.payload,
      };
    case SET_CLASS4:
      return {
        ...state,
        class4: action.payload,
      };

    default:
      return state;
  }
};
export default ModalReducer;
