import {
  fetchAllPut,
  fetchGeneric,
  fetchSelectStati,
} from "../../redux/actions";
import { URL_STATO } from "../url";

export const setFetchStato = (type, payload, token, dispatch) => {
  if (type === "modifica") {
    dispatch(fetchAllPut(URL_STATO + payload.id, payload, token));
  } else if (type === "aggiungi") {
    dispatch(fetchGeneric(URL_STATO, payload, token));
    dispatch(fetchSelectStati(token));
  }
};
