import { Col, Form, Row, Button } from "react-bootstrap";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  setArrayFilter,
  setInfoFilter,
  setShow,
  setUrlFetch,
} from "../../redux/actions";
import { URL_SEDE } from "../../lib/url";
import { useDispatch } from "react-redux";
import { setUrlFilterSedi } from "./setUrl/setUrlFilterSedi";

const FilterSedi = ({ info, setInformazioni }) => {
  const dispatch = useDispatch();

  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setInfoFilter(info));
    const arrayPills = Object.entries(info);
    dispatch(setArrayFilter(arrayPills));
    setUrlFilterSedi(arrayPills, info, dispatch);
    dispatch(setShow(SET_SHOW_FILTER, false));
    setInformazioni({});
  };
  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <Row>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Denominazione
            </Form.Label>
            <Form.Control
              type="text"
              value={info.nome}
              onChange={(e) => changeInfo(e.target.value, "nome")}
              placeholder="Inserisci Denominazione"
            />
          </Form.Group>
        </Col>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Regione
            </Form.Label>
            <Form.Control
              type="text"
              value={info.regione}
              onChange={(e) => changeInfo(e.target.value, "regione")}
              placeholder="Inserisci Regione"
            />
          </Form.Group>
        </Col>
      </Row>{" "}
      <Col xs={{ span: 12 }} className="d-flex justify-content-end">
        <Button type="submit" variant="primary">
          Applica Filtri
        </Button>
      </Col>
    </Form>
  );
};

export default FilterSedi;
