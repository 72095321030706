import { Col, Form, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSelectTipologiaAttivita,
  fetchSelectOfferte,
  fetchSelectClienti,
  fetchSelectSiti,
  SET_URL_FILTER,
  setUrlFetch,
  setShow,
  SET_SHOW_FILTER,
  setInfoFilter,
  setArrayFilter,
} from "../../redux/actions";
import { useEffect } from "react";
import { URL_ATTIVITA } from "../../lib/url";
import { setUrlFilterAttivita } from "./setUrl/setUrlFilterAttivita";

const FilterAttivita = ({ info, setInformazioni }) => {
  const token = useSelector((state) => state.login.token);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSelectTipologiaAttivita(token));
    dispatch(fetchSelectOfferte(token));
    dispatch(fetchSelectClienti(token));
    dispatch(fetchSelectSiti(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allTipologiaAttivita = useSelector(
    (state) => state.select.dataTipologiaAttivita
  );
  const allOfferte = useSelector((state) => state.select.dataOfferte);
  const allClienti = useSelector((state) => state.select.dataCliente);
  const allSiti = useSelector((state) => state.select.dataSiti);

  const changeInfo = (value, name) => {
    setInformazioni({
      ...info,
      [name]: value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setInfoFilter(info));

    let nuovoOggetto = { ...info };

    if (nuovoOggetto.offerta) {
      const x = allOfferte.filter((f) => f.id == nuovoOggetto.offerta);
      nuovoOggetto = { ...nuovoOggetto, offerta: x[0].protocollo };
    }
    if (nuovoOggetto.cliente) {
      const y = allClienti.filter((f) => f.id == nuovoOggetto.cliente);
      nuovoOggetto = {
        ...nuovoOggetto,
        cliente: y[0].nome + " " + y[0].cognome,
      };
    }
    if (nuovoOggetto.tipologia) {
      const z = allTipologiaAttivita.filter(
        (f) => f.id == nuovoOggetto.tipologia
      );
      nuovoOggetto = { ...nuovoOggetto, tipologia: z[0].denominazione };
    }
    if (nuovoOggetto.sito) {
      const j = allSiti.filter((f) => f.id == nuovoOggetto.sito);
      nuovoOggetto = { ...nuovoOggetto, sito: j[0].sito_o_punto_vendita };
    }

    const arrayPills = Object.entries(nuovoOggetto);

    dispatch(setArrayFilter(arrayPills));

    setUrlFilterAttivita(arrayPills, info, dispatch);

    dispatch(setShow(SET_SHOW_FILTER, false));
    setInformazioni({});
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <Row>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Nome Attività
            </Form.Label>
            <Form.Control
              type="text"
              value={info.denominazione}
              onChange={(e) => changeInfo(e.target.value, "denominazione")}
              placeholder="Inserisci nome attività"
            />
          </Form.Group>
        </Col>

        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label
              column
              sm="2"
              className="d-flex justify-content-between align-items-center"
            >
              Offerta
            </Form.Label>
            <Form.Select
              value={info.offerta || ""}
              onChange={(e) => changeInfo(e.target.value, "offerta")}
              aria-label="Filtra per Offerta"
            >
              <option value="" disabled hidden>
                Seleziona Offerta
              </option>
              {allOfferte.length !== 0 ? (
                allOfferte.map((c, index) => (
                  <option key={index} value={c.id}>
                    {c.protocollo}
                  </option>
                ))
              ) : (
                <option value="" disabled hidden>
                  Nessuna offerta disponibile
                </option>
              )}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label
              column
              sm="2"
              className="d-flex justify-content-between align-items-center"
            >
              Cliente
            </Form.Label>
            <Form.Select
              value={info.cliente || ""}
              onChange={(e) => changeInfo(e.target.value, "cliente")}
              aria-label="Filtra per Cliente"
            >
              <option value="" disabled hidden>
                Seleziona Cliente
              </option>
              {allClienti.length !== 0 ? (
                allClienti.map((c, index) => (
                  <option key={index} value={c.id}>
                    {c.nome} {c.cognome}
                  </option>
                ))
              ) : (
                <option value="" disabled hidden>
                  Nessun Cliente disponibile
                </option>
              )}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label
              column
              sm="2"
              className="d-flex justify-content-between align-items-center"
            >
              Tipologia
            </Form.Label>
            <Form.Select
              value={info.tipologia || ""}
              onChange={(e) => changeInfo(e.target.value, "tipologia")}
              aria-label="Seleziona Tipologia"
            >
              <option value="" disabled hidden>
                Seleziona Tipologia Attività
              </option>
              {allTipologiaAttivita.length !== 0 ? (
                allTipologiaAttivita.map((c, index) => (
                  <option key={index} value={c.id}>
                    {c.denominazione}
                  </option>
                ))
              ) : (
                <option value="" disabled hidden>
                  Nessuna tipologia disponibile
                </option>
              )}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              campo obbligatorio
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label
              column
              sm="2"
              className="d-flex justify-content-between align-items-center"
            >
              Sito
            </Form.Label>
            <Form.Select
              value={info.sito || ""}
              onChange={(e) => changeInfo(e.target.value, "sito")}
              aria-label="Filtra per Sito"
            >
              <option value="" disabled hidden>
                Seleziona Sito
              </option>
              {allSiti.length !== 0 ? (
                allSiti.map((c, index) => (
                  <option key={index} value={c.id}>
                    {c.sito_o_punto_vendita}
                  </option>
                ))
              ) : (
                <option value="" disabled hidden>
                  Nessun sito disponibile
                </option>
              )}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Col xs={{ span: 12 }} className="d-flex justify-content-end">
        <Button type="submit" variant="primary">
          Applica Filtri
        </Button>
      </Col>
    </Form>
  );
};

export default FilterAttivita;
