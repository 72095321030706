import { Col, Form, Row, Button } from "react-bootstrap";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  setArrayFilter,
  setInfoFilter,
  setShow,
  setUrlFetch,
} from "../../redux/actions";
import { useDispatch } from "react-redux";
import { setUrlFilterDipendenti } from "./setUrl/setUrlFilterDipendenti";

const FilterDipendenti = ({ info, setInformazioni }) => {
  const dispatch = useDispatch();

  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setInfoFilter(info));

    const arrayPills = Object.entries(info);
    dispatch(setArrayFilter(arrayPills));

    setUrlFilterDipendenti(arrayPills, info, dispatch);

    dispatch(setShow(SET_SHOW_FILTER, false));
    setInformazioni({});
  };
  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <Row>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Nome
            </Form.Label>
            <Form.Control
              type="text"
              value={info.nome}
              onChange={(e) => changeInfo(e.target.value, "nome")}
              placeholder="Inserisci Nome"
            />
          </Form.Group>
        </Col>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Cognome
            </Form.Label>
            <Form.Control
              type="text"
              value={info.cognome}
              onChange={(e) => changeInfo(e.target.value, "cognome")}
              placeholder="Inserisci Cognome"
            />
          </Form.Group>
        </Col>
        {/* <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Ruolo
            </Form.Label>

            <Form.Select
              value={info.ruolo || ""}
              className="custom-select"
              onChange={(e) => changeInfo(e.target.value, "ruolo")}
              aria-label="Seleziona ruolo"
            >
              <option value="" disabled hidden>
                Seleziona ruolo
              </option>
              <option value="Amministratore">Amministratore</option>
              <option value="Amministrazione">Amministrazione</option>
              <option value="Commerciale">Commerciale</option>
              <option value="Project manager">Project Manager</option>
              <option value="Tecnico">Tecnico</option>
            </Form.Select>
          </Form.Group>
        </Col> */}
      </Row>{" "}
      <Col xs={{ span: 12 }} className="d-flex justify-content-end">
        <Button type="submit" variant="primary">
          Applica Filtri
        </Button>
      </Col>
    </Form>
  );
};

export default FilterDipendenti;
