import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ADD_FORNITORI } from "../../lib/payloads";
import {
  SET_INFO1,
  SET_INFO3,
  fetchSelectAttivita,
  fetchSelectBanca,
  fetchSelectTipologiaAttivita,
  setInfo,
} from "../../redux/actions";

const FormFornitori = () => {
  const info1 = useSelector((state) => state.modal.info1);
  const show1 = useSelector((state) => state.modal.show1);
  const show2 = useSelector((state) => state.modal.show2);
  const show3 = useSelector((state) => state.modal.show3);
  const show4 = useSelector((state) => state.modal.show4);
  const dispatch = useDispatch();
  const [info, setInformazioni] = useState({});
  const [bancheFiltrateIntestatario, setBancheFiltrateIntestatario] = useState([]);
  const path = useSelector((state) => state.locationPage.path);
  const token = useSelector((state) => state.login.token);
  const allBanche = useSelector((state) => state.select.dataBanca);
  const allTipologiaAttivita = useSelector((state) => state.select.dataTipologiaAttivita);
  const tipoModal = useSelector((state) => state.modal.type);
  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };

  useEffect(() => {
    if (path == "fornitore") {
      dispatch(setInfo(SET_INFO1, info));
    } else {
      dispatch(setInfo(SET_INFO3, info));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  useEffect(() => {
    dispatch(fetchSelectBanca(token));
    dispatch(fetchSelectTipologiaAttivita(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (show1 && show2 && show3 && show4) {
      setInformazioni(ADD_FORNITORI);
    } else if (show1 && show2 && show3) {
      setInformazioni(ADD_FORNITORI);
    } else if (show1 && show2) {
      setInformazioni(ADD_FORNITORI);
    } else {
      setInformazioni(info1);

      if (tipoModal === "modifica") {
        setInformazioni({
          ...info1,
          banca: info1.idBanca,
          tipologia_attivita: info1.idTipologiaAttivita,
        });
        const bancheFiltrate = allBanche.filter((b) => {
          const intestatario = (info1.nome + info1.cognome).toLowerCase();
          return intestatario == b.intestatario;
        });
        setBancheFiltrateIntestatario(bancheFiltrate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBanche, allTipologiaAttivita]);

  const getIbanForSelectedBanca = () => {
    if (info.banca) {
      const selectedBanca = allBanche.find((banca) => banca.id == info.banca);
      return selectedBanca ? selectedBanca.iban : "";
    }
    return "";
  };

  return (
    <Row>
      {" "}
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Cognome *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.cognome}
            onChange={(e) => changeInfo(e.target.value, "cognome")}
            placeholder="inserisci Cognome"
            required
          />
          <Form.Control.Feedback type="invalid">campo obbligatorio</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Nome *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.nome}
            onChange={(e) => changeInfo(e.target.value, "nome")}
            placeholder="Inserisci Nome"
            required
          />
          <Form.Control.Feedback type="invalid">campo obbligatorio</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Termini Pagamento
          </Form.Label>
          <Form.Control
            type="text"
            value={info.termini_pagamento}
            onChange={(e) => changeInfo(e.target.value, "termini_pagamento")}
            placeholder="Inserisci Termine Pagamento"
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Partita Iva *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.partita_iva}
            onChange={(e) => changeInfo(e.target.value, "partita_iva")}
            placeholder="Inserisci Partita Iva"
            required
          />
          <Form.Control.Feedback type="invalid">campo obbligatorio</Form.Control.Feedback>
        </Form.Group>
      </Col>
      {tipoModal === "modifica" ? (
        <>
          <Col xs="4">
            <Form.Group className="mb-3">
              <Form.Label column sm="2" className="d-flex justify-content-between align-items-center">
                Banca
              </Form.Label>
              <Form.Select
                value={info.banca || ""}
                className="custom-select"
                onChange={(e) => changeInfo(e.target.value, "banca")}
                aria-label="Seleziona Banca"
                disabled={bancheFiltrateIntestatario.length === 0}
              >
                {bancheFiltrateIntestatario.length === 0 ? (
                  <option value="" disabled hidden>
                    Nessuna banca per il fornitore
                  </option>
                ) : (
                  <option value="" disabled hidden>
                    Seleziona Banca
                  </option>
                )}

                {bancheFiltrateIntestatario &&
                  bancheFiltrateIntestatario.map((b, index) => (
                    <option key={index} value={b.id}>
                      {b.nome}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">campo obbligatorio</Form.Control.Feedback>
            </Form.Group>
          </Col>
          {bancheFiltrateIntestatario.length > 0 ? (
            <Col xs="4">
              <Form.Group className="mb-3">
                <Form.Label column sm="2">
                  Iban
                </Form.Label>
                <Form.Control
                  type="text"
                  value={getIbanForSelectedBanca()}
                  placeholder="Campo autogenerato"
                  required
                  disabled
                />
              </Form.Group>
            </Col>
          ) : (
            ""
          )}
        </>
      ) : null}
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2" className="d-flex justify-content-between align-items-center">
            Tipologia Attività *
          </Form.Label>
          <Form.Select
            value={info.tipologia_attivita || ""}
            className="custom-select"
            onChange={(e) => changeInfo(e.target.value, "tipologia_attivita")}
            aria-label="Seleziona Tipologia Attivita"
            required
          >
            <option value="" disabled hidden>
              Seleziona Tipologia Attività
            </option>
            {allTipologiaAttivita &&
              allTipologiaAttivita.map((b, index) => (
                <option key={index} value={b.id}>
                  {b.denominazione}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Email *
          </Form.Label>

          <Form.Control
            type="text"
            value={info.email}
            onChange={(e) => changeInfo(e.target.value, "email")}
            placeholder="inserisci Email"
            required
          />
          <Form.Control.Feedback type="invalid">campo obbligatorio</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Telefono *
          </Form.Label>

          <Form.Control
            type="text"
            value={info.telefono}
            onChange={(e) => changeInfo(e.target.value, "telefono")}
            placeholder="inserisci Telefono"
            required
          />
        </Form.Group>
        <Form.Control.Feedback type="invalid">campo obbligatorio</Form.Control.Feedback>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Località
          </Form.Label>

          <Form.Control
            type="text"
            value={info.via}
            onChange={(e) => changeInfo(e.target.value, "via")}
            placeholder="inserisci Località"
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Comune
          </Form.Label>

          <Form.Control
            type="text"
            value={info.comune}
            onChange={(e) => changeInfo(e.target.value, "comune")}
            placeholder="inserisci Comune"
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Provincia
          </Form.Label>

          <Form.Control
            type="text"
            value={info.provincia}
            onChange={(e) => changeInfo(e.target.value, "provincia")}
            placeholder="inserisci Provincia"
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Regione
          </Form.Label>

          <Form.Control
            type="text"
            value={info.regione}
            onChange={(e) => changeInfo(e.target.value, "regione")}
            placeholder="inserisci Regione"
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        {" "}
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Cap
          </Form.Label>

          <Form.Control
            type="text"
            value={info.cap}
            onChange={(e) => changeInfo(e.target.value, "cap")}
            placeholder="inserisciCap"
          />
        </Form.Group>
      </Col>
    </Row>
  );
};
export default FormFornitori;
