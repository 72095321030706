export const GetCommessa = (
  lista,
  lista2,
  lista3,
  lista4,
  lista5,
  lista6,
  lista7,
  lista8,
  setData
) => {
  if (
    lista &&
    lista2 &&
    lista.length >= 0 &&
    lista2.length >= 0 &&
    lista3 &&
    lista3.length >= 0 &&
    lista4 &&
    lista4.length >= 0 &&
    lista5.length >= 0 &&
    lista5 &&
    lista6.length >= 0 &&
    lista6 &&
    lista7.length >= 0 &&
    lista7 &&
    lista8.length >= 0 &&
    lista8
  ) {
    const combinedList = lista.map((item) => {
      let cliente = lista2.filter((item2) => item.cliente === item2.id);
      let fullName = cliente[0].nome + " " + cliente[0].cognome;
      let combinedItem = {
        ...item,
        cliente: fullName,
        idCliente: cliente[0].id,
      };
      return combinedItem;
    });

    const combinedList2 = combinedList.map((item) => {
      let fornitore = lista3.filter((item3) => item.fornitore === item3.id);
      let combinedItem = {};
      if (fornitore.length > 0) {
        let fullName = fornitore[0].nome + " " + fornitore[0].cognome;

        combinedItem = {
          ...item,
          fornitore: fullName,
          idFornitore: fornitore[0].id,
          openModal: (
            <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
          ),
        };
      } else {
        combinedItem = {
          ...item,
          openModal: (
            <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
          ),
        };
      }
      return combinedItem;
    });

    const combinedList3 = combinedList2.map((item) => {
      let stato = lista4.filter((item4) => item.stato === item4.id);
      let colore = stato[0].colore;
      let combinedItem = {
        ...item,
        coloreStato: colore,
        idStato: stato[0].id,
        openModal: (
          <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
        ),
      };
      return combinedItem;
    });

    // TO-DO : QUANDO CI SARA' CAMPO SITO DA BACKEND, REINSERIRE IL CODICE COMMENTATO
    const combinedList4 = combinedList3.map((item) => {
      let sito = lista5.filter((item5) => {
        return item.sito == item5.id;
      });
      let combinedItem = {};
      if (sito.length > 0) {
        let sitoPuntovendita = sito[0].sito_o_punto_vendita;
        combinedItem = {
          ...item,
          sito: sitoPuntovendita,
          sitoId: sito[0].id,
          openModal: (
            <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
          ),
        };
      } else {
        combinedItem = {
          ...item,
          openModal: (
            <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
          ),
        };
      }

      return combinedItem;
    });

    const combinedList5 = combinedList4.map((item) => {
      let responsabile = lista6.filter(
        (item6) => item.responsabile == item6.id
      );

      if (responsabile.length === 0) {
        responsabile = null;
      } else {
        responsabile = responsabile[0];
      }

      let combinedItem = {
        ...item,
        responsabile: responsabile
          ? responsabile.nome + " " + responsabile.cognome
          : null,
        idResponsabile: responsabile ? responsabile.id : null,
      };

      return combinedItem;
    });

    const combinedList6 = combinedList5.map((item) => {
      let project_manager = lista7.filter(
        (item7) => item.project_manager == item7.id
      );

      if (project_manager.length === 0) {
        project_manager = null;
      } else {
        project_manager = project_manager[0];
      }

      let combinedItem = {
        ...item,
        dipendente: project_manager ? project_manager.dipendente : null,
      };

      return combinedItem;
    });

    const combinedList7 = combinedList6.map((item) => {
      let dipendente = lista8.filter((item8) => item.dipendente === item8.id);

      let combinedItem = { ...item };

      // Controllo se l'array dipendente è vuoto
      if (dipendente.length === 0) {
        combinedItem = {
          ...item,
          dipendente: null,
        };
      } else {
        let fullName = dipendente[0].nome + " " + dipendente[0].cognome;
        combinedItem = {
          ...item,
          dipendente: fullName,
        };
      }

      return combinedItem;
    });

    setData(combinedList7);
  } else {
    if (lista && lista.length > 0) {
      const updatedList = lista.map((item) => ({
        ...item,
        openModal: (
          <i className="bi bi-three-dots m-0" style={{ margin: "0" }}></i>
        ),
      }));
      setData(updatedList);
    }
  }
};
