import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { encryptTransform } from "redux-persist-transform-encrypt";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import locationReducer from "../reducers/locationReducer";
import ModalReducer from "../reducers/ModalReducer";
import loginReducer from "../reducers/loginReducer";
import fetchReducer from "../reducers/fetchReducer";
import statsReducer from "../reducers/statsReducer";
import selectReducer from "../reducers/selectReducer";
import AlertToastReducer from "../reducers/alertToastReducer";
import filterReducer from "../reducers/filterReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["login", "locationPage"],
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_PERSIST_KEY,
    }),
  ],
};

const rootReducer = combineReducers({
  filter: filterReducer,
  select: selectReducer,
  fetch: fetchReducer,
  login: loginReducer,
  locationPage: locationReducer,
  modal: ModalReducer,
  stats: statsReducer,
  alertToast: AlertToastReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
export const persistor = persistStore(store);
