import { Col, Container, Row } from "react-bootstrap";

const SitiDetails = ({ data }) => {
  return (
    <Container className="border bg-light">
      <Row>
        <Col className="border p-2 text-center">
          {" "}
          <p className="dettagli-title ms-2 m-0 text-center">Codice Vecchio</p>
          <span className="dettagli">{data.codice_vecchio}</span>
        </Col>
        <Col className="border p-2 text-center">
          {" "}
          <p className="dettagli-title ms-2 m-0 text-center">Provincia</p>
          <span className="dettagli">{data.provincia}</span>
        </Col>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">Regione</p>
          <span className="dettagli">{data.regione}</span>
        </Col>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">Cap </p>
          <span className="dettagli"> {data.cap}</span>
        </Col>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">Coordinate</p>
          <span className="dettagli"> {data.coordinate_o_link}</span>
        </Col>
      </Row>
    </Container>
  );
};
export default SitiDetails;
