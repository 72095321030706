import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ADD_BANCHE } from "../../lib/payloads";
import {
  SET_INFO1,
  SET_INFO3,
  fetchSelectFornitore,
  setInfo,
} from "../../redux/actions";

const FormBanche = () => {
  const info1 = useSelector((state) => state.modal.info1);
  const show1 = useSelector((state) => state.modal.show1);
  const show2 = useSelector((state) => state.modal.show2);
  const show3 = useSelector((state) => state.modal.show3);
  const show4 = useSelector((state) => state.modal.show4);
  const dispatch = useDispatch();
  const [info, setInformazioni] = useState({});
  const path = useSelector((state) => state.locationPage.path);
  const allFornitori = useSelector((state) => state.select.dataFornitore);
  const token = useSelector((state) => state.login.token);

  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };

  useEffect(() => {
    if (path == "banca") {
      dispatch(setInfo(SET_INFO1, info));
    } else {
      dispatch(setInfo(SET_INFO3, info));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  useEffect(() => {
    if (show1 && show2 && show3 && show4) {
      setInformazioni(ADD_BANCHE);
    } else if (show1 && show2 && show3) {
      setInformazioni(ADD_BANCHE);
    } else if (show1 && show2) {
      setInformazioni(ADD_BANCHE);
    } else {
      setInformazioni(info1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFornitori]);

  useEffect(() => {
    dispatch(fetchSelectFornitore(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Nome *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.nome}
            onChange={(e) => changeInfo(e.target.value, "nome")}
            placeholder="Inserisci Nome"
            required
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Intestatario *
          </Form.Label>
          <Form.Select
            className="custom-select"
            value={info.intestatario}
            onChange={(e) => changeInfo(e.target.value, "intestatario")}
            required
            aria-label="Seleziona Intestatario"
          >
            <option value="" disabled hidden>
              Seleziona Intestatario
            </option>
            <option value="Ecotec">Ecotec</option>
            {allFornitori &&
              allFornitori.map((f, index) => (
                <option key={index} value={f.nome + f.cognome}>
                  {f.nome} {f.cognome}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Iban *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.iban}
            onChange={(e) => changeInfo(e.target.value, "iban")}
            placeholder="Inserisci IBAN"
            required
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Via *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.via}
            onChange={(e) => changeInfo(e.target.value, "via")}
            placeholder="Inserisci via"
            required
          />{" "}
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Numero *
          </Form.Label>

          <Form.Control
            type="text"
            value={info.nro}
            onChange={(e) => changeInfo(e.target.value, "nro")}
            placeholder="Inserisci numero"
            required
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Comune *
          </Form.Label>

          <Form.Control
            type="text"
            value={info.comune}
            onChange={(e) => changeInfo(e.target.value, "comune")}
            placeholder="Inserisci comune"
            required
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Regione *
          </Form.Label>

          <Form.Control
            type="text"
            value={info.regione}
            onChange={(e) => changeInfo(e.target.value, "regione")}
            placeholder="Inserisci regione"
            required
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Cap *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.cap}
            onChange={(e) => changeInfo(e.target.value, "cap")}
            placeholder="Inserisci cap"
            required
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Telefono
          </Form.Label>
          <Form.Control
            type="text"
            value={info.telefono}
            onChange={(e) => changeInfo(e.target.value, "telefono")}
            placeholder="Inserisci Telefono"
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Email
          </Form.Label>
          <Form.Control
            type="text"
            value={info.mail}
            onChange={(e) => changeInfo(e.target.value, "mail")}
            placeholder="Inserisci mail"
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Nome Consulente
          </Form.Label>
          <Form.Control
            type="text"
            value={info.nome_consulente}
            onChange={(e) => changeInfo(e.target.value, "nome_consulente")}
            placeholder="Inserisci Nome Consulente"
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default FormBanche;
