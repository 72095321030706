const initialState = {
  token: "",
  stayLoggedIn: false,
  name: "",
  role: "",
  isAuthorized: false,
  isAuthorizedDo: true,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        stayLoggedIn: action.payload.stayLoggedIn,
        token: action.payload.token,
        name: action.payload.name,
        role: action.payload.role,
      };
      break;

    case "ISAUTHORIZED":
      return {
        ...state,
        isAuthorized: action.payload,
      };
      break;
    case "ISAUTHORIZEDDO":
      return {
        ...state,
        isAuthorizedDo: action.payload,
      };
      break;

    case "LOGOUT":
      return {
        ...state,
        token: "",
        stayLoggedIn: false,
        name: "",
        role: "",
      };

    default:
      return state;
      break;
  }
};

export default loginReducer;
