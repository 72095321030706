import { useState } from "react";
import {
  fetchAllPut,
  fetchGeneric,
  fetchSelectOrdine,
  fetchSelectLavori,
  fetchAllDelete,
} from "../../redux/actions";
import { URL_COMMESSA, URL_LAVORI, URL_ORDINE } from "../url";

export const CreaLavoro = async (token, payload) => {
  console.log("sono nella funzione crea lavoro!");
  try {
    const resp = await fetch(
      "https://be.civetta.maritsrl.com/api/v1/" + URL_LAVORI,
      {
        method: "POST",
        headers: {
          Authorization: `Token ` + token,
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    if (resp.ok) {
      const data = await resp.json();
      console.log("risposta fetch", data);
      return data.id;
    } else {
      console.log("fetch offerta fallita");
    }
  } catch (error) {
    console.log("ERRORE CREAZIONE LAVORO!!!");
  }
};
export const aggiornaLavori = async (token) => {
  try {
    const resp = await fetch(
      "https://be.civetta.maritsrl.com/api/v1/lavori/?page_size=all",
      {
        method: "GET",
        headers: {
          Authorization: `Token ` + token,
          "Content-type": "application/json",
        },
      }
    );
    if (resp.ok) {
      const lavoriAggiornati = await resp.json();
      return lavoriAggiornati;
    }
  } catch (error) {
    console.log("ERRORE GET AGGIORNALAVORI");
  }
};
export const CreaOrdine = async (token, payload) => {
  console.log("sono nella funzione crea ordine!");
  try {
    const resp = await fetch(
      "https://be.civetta.maritsrl.com/api/v1/" + URL_ORDINE,
      {
        method: "POST",
        headers: {
          Authorization: `Token ` + token,
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    if (resp.ok) {
      const data = await resp.json();
      console.log("risposta fetch", data);
      return data.id;
    } else {
      console.log("fetch offerta fallita");
    }
  } catch (error) {
    console.log("ERRORE CREAZIONE ORDINE!!!");
  }
};

export const aggiornaCommesseConOrdini = (commesseDaLavori, ordineId) => {
  // Crea nuove istanze di commessa per ogni modifica
  const commesseAggiornate = commesseDaLavori.map((commessa) => {
    // Verifica se l'ordineId è già presente
    if (!commessa.ordini.includes(ordineId)) {
      // Crea e restituisci una nuova istanza di commessa con l'ordineId aggiunto
      return {
        ...commessa,
        ordini: [...commessa.ordini, ordineId],
      };
    }
    // Nessuna modifica necessaria, restituisci l'istanza esistente
    return commessa;
  });

  return commesseAggiornate;
};

export const setFetchOrdine = async (
  type,
  payload,
  token,
  dispatch,
  oggettoLavori,
  allCommesse
) => {
  if (type === "modifica") {
    // console.log("type", type);
    // console.log("payload", payload);
    // console.log("token", token);
    // console.log("dispatch", dispatch);
    // console.log("oggettoLavori", oggettoLavori);
    // console.log("allCommesse", allCommesse);

    const lavoriArray = Object.values(oggettoLavori).flat();
    payload = { ...payload, cliente: payload.clienteId };

    const lavoriDaCreare = lavoriArray.filter((lavoro) => !lavoro.id);
    const lavoriDaAggiornare = lavoriArray.filter((lavoro) => lavoro.id);
    const lavoriDaEliminare = payload.listaLavori
      .map((lavoro) => lavoro.id)
      .filter((id) => !payload.lavori.includes(id));

    const idLavoriNuoviCreati = await Promise.all(
      lavoriDaCreare.map(async (lavoro) => {
        const idCreato = await CreaLavoro(token, lavoro);
        return idCreato; // Assumendo che CreaLavoro restituisca l'ID del nuovo lavoro creato
      })
    );

    // Raccogliere gli ID dei lavori esistenti che vengono aggiornati
    const idLavoriEsistentiAggiornati = lavoriDaAggiornare.map(
      (lavoro) => lavoro.id
    );

    // Eseguire l'aggiornamento per i lavori esistenti
    lavoriDaAggiornare.forEach((lavoro) => {
      dispatch(fetchAllPut(`lavori/${lavoro.id}`, lavoro, token));
    });

    // lavoriDaEliminare.forEach((lavoro) => {
    //   dispatch(fetchAllDelete(`lavori/${lavoro}`, token));
    // });

    // Combinare gli ID dei lavori nuovi creati con quelli esistenti aggiornati
    const idLavoriCreati = [
      ...idLavoriNuoviCreati,
      ...idLavoriEsistentiAggiornati,
    ];

    console.log("lavori creati", idLavoriCreati);
    payload = { ...payload, lavori: idLavoriCreati };
    console.log("payload", payload);
    dispatch(fetchAllPut(`ordine/${payload.id}/`, payload, token));

    const lavoriTotali = idLavoriCreati;
    let lavoriAggiornati;
    if (lavoriTotali.length > 0) {
      lavoriAggiornati = await aggiornaLavori(token); // Attesa del risultato di aggiornaLavori
    }

    const ordineId = payload.id;
    console.log("lavori totali", lavoriTotali);
    if (ordineId && lavoriTotali) {
      console.log("sono qui dentroo");
      const commesseDaLavoriAggiornati = lavoriTotali
        .map((lavoroId) => {
          const lavoroIntero = lavoriAggiornati.find(
            (oggettoLavoro) => oggettoLavoro.id === lavoroId
          );
          return (
            allCommesse.find(
              (oggettoCommessa) => oggettoCommessa.id === lavoroIntero?.commessa
            ) || null
          );
        })
        .filter((c) => c !== null);
      console.log(commesseDaLavoriAggiornati);
      const commesseAggiornate = aggiornaCommesseConOrdini(
        commesseDaLavoriAggiornati,
        ordineId
      );
      console.log(commesseAggiornate);
      commesseAggiornate.forEach((c) => {
        // Assicurati che la commessa sia una di quelle da aggiornare
        dispatch(fetchAllPut(URL_COMMESSA + c.id + "/", c, token));
      });
    }
  } else if (type === "aggiungi") {
    const listaLavori = Object.values(oggettoLavori).flat();
    // let commesseUniche = [...new Set(listaLavori.map(lavoro => lavoro.commessa))];
    const lavoriFiltrati = listaLavori.filter((lavoro) =>
      payload.attivita.includes(lavoro.attivita)
    );

    // Utilizza Promise.all per attendere la creazione di tutti i lavori
    const listaID = await Promise.all(
      lavoriFiltrati.map(async (lavoro) => {
        return await CreaLavoro(token, lavoro); // Restituisce direttamente l'ID del lavoro
      })
    );
    let lavoriAggiornati;
    if (listaID.length > 0) {
      lavoriAggiornati = await aggiornaLavori(token); // Attesa del risultato di aggiornaLavori
    }
    // Filtra eventuali ID nulli o non definiti
    const lavoriValidi = listaID.filter((id) => id != null);

    if (lavoriValidi.length > 0) {
      // Assicurati di passare un array non annidato a `lavori`
      payload = { ...payload, lavori: lavoriValidi };

      const ordineId = await CreaOrdine(token, payload);
      console.log("ordineID", ordineId);
      if (ordineId && lavoriAggiornati) {
        const commesseDaLavori = lavoriValidi
          .map((lavoroId) => {
            const lavoroIntero = lavoriAggiornati.find(
              (oggettoLavoro) => oggettoLavoro.id === lavoroId
            );
            return (
              allCommesse.find(
                (oggettoCommessa) =>
                  oggettoCommessa.id === lavoroIntero?.commessa
              ) || null
            );
          })
          .filter((c) => c !== null);

        console.log("cms da lavori", commesseDaLavori);
        // Aggiorna l'array `ordini` di ciascuna commessa interessata
        const commesseAggiornate = aggiornaCommesseConOrdini(
          commesseDaLavori,
          ordineId
        );

        // Dopo aver aggiornato le commesse, esegui la dispatch per ciascuna di esse
        commesseAggiornate.forEach((c) => {
          // Assicurati che la commessa sia una di quelle da aggiornare
          dispatch(fetchAllPut(URL_COMMESSA + c.id + "/", c, token));
        });
      }
    }
  }
};
