import { fetchAllPut, fetchDipendentiERuolo, fetchGeneric, fetchNewRole } from "../../redux/actions";
import { URL_DIPENDENTE } from "../url";

export const SetFetchDipendenti = (type, payload, token, dispatch) => {
  let ruolo = payload.ruolo.toLowerCase().replace(" ", "-");
  if (type === "modifica") {
    dispatch(fetchAllPut(URL_DIPENDENTE + payload.id, payload, token));
  } else if (type === "aggiungi") {
    dispatch(fetchDipendentiERuolo(ruolo, payload, token));
  }
};
