import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ADD_SEDI } from "../../lib/payloads";
import { SET_INFO1, setInfo } from "../../redux/actions";

const FormSedi = () => {
  const info1 = useSelector((state) => state.modal.info1);
  const show1 = useSelector((state) => state.modal.show1);
  const show2 = useSelector((state) => state.modal.show2);
  const show3 = useSelector((state) => state.modal.show3);
  const show4 = useSelector((state) => state.modal.show4);
  const dispatch = useDispatch();
  const [info, setInformazioni] = useState({});

  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };

  useEffect(() => {
    dispatch(setInfo(SET_INFO1, info));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);
  useEffect(() => {
    if (show1 && show2 && show3 && show4) {
      setInformazioni(ADD_SEDI);
    } else if (show1 && show2 && show3) {
      setInformazioni(ADD_SEDI);
    } else if (show1 && show2) {
      setInformazioni(ADD_SEDI);
    } else {
      setInformazioni(info1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Nome *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.nome}
            onChange={(e) => changeInfo(e.target.value, "nome")}
            placeholder="Inserisci Nome"
            required
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Telefono *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.telefono}
            onChange={(e) => changeInfo(e.target.value, "telefono")}
            placeholder="Inserisci Telefono"
            required
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Località *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.via}
            onChange={(e) => changeInfo(e.target.value, "via")}
            placeholder="Inserisci Località"
            required
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Numero civico *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.numero_civico}
            onChange={(e) => changeInfo(e.target.value, "numero_civico")}
            placeholder="Inserisci Numero Civico"
            required
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      {/* <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Comune *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.comune}
            onChange={(e) => changeInfo(e.target.value, "comune")}
            placeholder="Inserisci Comune"
            required
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col> */}
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            CAP *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.cap}
            onChange={(e) => changeInfo(e.target.value, "cap")}
            placeholder="Inserisci CAP"
            required
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Provincia *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.provincia}
            onChange={(e) => changeInfo(e.target.value, "provincia")}
            placeholder="Inserisci Provincia"
            required
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Regione *
          </Form.Label>
          <Form.Control
            type="text"
            value={info.regione}
            onChange={(e) => changeInfo(e.target.value, "regione")}
            placeholder="Inserisci Regione"
            required
          />
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default FormSedi;
