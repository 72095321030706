import {
  SET_ISLOAD_POST,
  fetchAllPut,
  fetchGeneric,
  fetchSelectClienti,
  hideAlert,
  setIsLoadFetch,
  showAlert,
} from "../../redux/actions";
import {
  URL_CLIENTI,
  URL_INDIRIZZO_CLIENTE,
  URL_INFORMAZIONI_CLIENTE,
} from "../url";

export const clientePiuIndirizzopiuInformazioniPost = async (
  token,
  payload,
  dispatch
) => {
  try {
    const resp = await fetch(
      "https://be.civetta.maritsrl.com/api/v1/" + URL_CLIENTI,
      {
        method: "POST",
        headers: {
          Authorization: `Token ` + token,
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const data = await resp.json();

    const payload2 = { ...payload, cliente: data.id };
    const resp2 = await fetch(
      "https://be.civetta.maritsrl.com/api/v1/" + URL_INDIRIZZO_CLIENTE,
      {
        method: "POST",
        headers: {
          Authorization: `Token ` + token,
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload2),
      }
    );
    const payload3 = { ...payload, cliente: data.id };
    const resp3 = await fetch(
      "https://be.civetta.maritsrl.com/api/v1/" + URL_INFORMAZIONI_CLIENTE,
      {
        method: "POST",
        headers: {
          Authorization: `Token ` + token,
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload3),
      }
    );

    dispatch(showAlert("Record salvato con successo", "success"));
    setTimeout(() => {
      dispatch(hideAlert());
    }, 3000);
  } catch (error) {
    dispatch(showAlert("Errore", "primary"));
    setTimeout(() => {
      dispatch(hideAlert());
    }, 3000);
  }
};

export const setFetchCliente = (type, payload, token, dispatch) => {
  if (type === "modifica") {
    dispatch(fetchAllPut(URL_CLIENTI + payload.id, payload, token));
    if (
      payload.comune &&
      payload.via &&
      payload.regione &&
      payload.provincia &&
      payload.cap
    ) {
      if (payload.idIndirizzo) {
        dispatch(
          fetchAllPut(
            URL_INDIRIZZO_CLIENTE + payload.idIndirizzo,
            payload,
            token
          )
        );
      } else {
        payload = { ...payload, cliente: payload.id };
        dispatch(fetchGeneric(URL_INDIRIZZO_CLIENTE, payload, token));
      }
    }

    if (payload.sito_web && payload.fatturazione_elettronica) {
      if (payload.idInformazioni) {
        dispatch(
          fetchAllPut(
            URL_INFORMAZIONI_CLIENTE + payload.idInformazioni,
            payload,
            token
          )
        );
      } else {
        payload = { ...payload, cliente: payload.id };
        dispatch(fetchGeneric(URL_INFORMAZIONI_CLIENTE, payload, token));
      }
    }
  } else if (type === "aggiungi") {
    clientePiuIndirizzopiuInformazioniPost(token, payload, dispatch);
    dispatch(setIsLoadFetch(SET_ISLOAD_POST, true));
    setTimeout(() => {
      dispatch(setIsLoadFetch(SET_ISLOAD_POST, false));
    }, 1000);
    dispatch(fetchSelectClienti(token));
  }
};
