import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSelectAttivita,
  fetchSelectBanca,
  fetchSelectClienti,
  fetchSelectCommesse,
  fetchSelectDipendente,
  fetchSelectFornitore,
  fetchSelectOfferte,
  fetchSelectOrdine,
  fetchSelectProjectManager,
  fetchSelectSiti,
  fetchSelectStati,
  fetchSelectTipologiaAttivita,
} from "../redux/actions";

function LogDetailsModal({ show, handleClose, logItem }) {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.login.token);

  const allClienti = useSelector((state) => state.select.dataCliente);
  const allStati = useSelector((state) => state.select.dataStati);
  const allAttivita = useSelector((state) => state.select.dataAttivita);
  const allBanche = useSelector((state) => state.select.dataBanca);
  const allOrdine = useSelector((state) => state.select.dataOrdine);
  const allSiti = useSelector((state) => state.select.dataSiti);
  const allOfferte = useSelector((state) => state.select.dataOfferte);
  const allFornitori = useSelector((state) => state.select.dataFornitore);
  const allCommesse = useSelector((state) => state.select.dataCommesse);
  const allProjectManager = useSelector(
    (state) => state.select.dataProjectManager
  );
  const allDipendenti = useSelector((state) => state.select.dataDipendente);
  const allTipologia = useSelector(
    (state) => state.select.dataTipologiaAttivita
  );

  useEffect(() => {
    switch (logItem.model_name) {
      // case "ViewCliente":
      //   break;
      case "ViewIndirizzoCliente":
        dispatch(fetchSelectClienti(token));
        break;
      case "ViewFatture":
        dispatch(fetchSelectStati(token));
        dispatch(fetchSelectOrdine(token));
        dispatch(fetchSelectClienti(token));
        dispatch(fetchSelectBanca(token));
        break;
      case "ViewOrdini":
        dispatch(fetchSelectClienti(token));
        dispatch(fetchSelectOfferte(token));
        dispatch(fetchSelectAttivita(token));
        break;
      case "ViewAttivita":
        dispatch(fetchSelectClienti(token));
        dispatch(fetchSelectSiti(token));
        dispatch(fetchSelectOfferte(token));
        dispatch(fetchSelectTipologiaAttivita(token));
        break;
      case "ViewOfferta":
        dispatch(fetchSelectClienti(token));
        dispatch(fetchSelectSiti(token));
        break;
      case "ViewCommessa":
        dispatch(fetchSelectStati(token));
        dispatch(fetchSelectOrdine(token));
        dispatch(fetchSelectClienti(token));
        dispatch(fetchSelectAttivita(token));
        dispatch(fetchSelectFornitore(token));
        dispatch(fetchSelectProjectManager(token));
        dispatch(fetchSelectDipendente(token));
        break;
      // case "ViewSede":
      //   break;
      // case "ViewBanca":
      //   break;
      // case "ViewFornitore":
      //   break;
      case "ViewIndirizzoFornitore":
        dispatch(fetchSelectFornitore(token));
        break;
      case "ViewIncaricoFornitore":
        dispatch(fetchSelectFornitore(token));
        dispatch(fetchSelectCommesse(token));
        break;
      case "ViewSito":
        dispatch(fetchSelectClienti(token));
        break;
      case "ViewIndirizzoSito":
        dispatch(fetchSelectSiti(token));
        break;
      // case "ViewStato":
      //   break;
      // case "ViewTipologiaAttivita":
      //   break;
      default:
        break;
    }
  }, [logItem, token, dispatch]);

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const renderKeyValuePairs = (obj) => {
    return Object.entries(obj).map(([key, value], index) => {
      if (key !== "id") {
        const formattedKey = key
          .replace(/_/g, " ")
          .toLowerCase()
          .replace(/^\w/, (c) => c.toUpperCase());

        let displayValue = value;

        if (key === "cliente") {
          const cliente = allClienti.find((c) => c.id == value);
          displayValue = cliente
            ? `${cliente.nome} ${cliente.cognome} `
            : "Cliente non trovato";
        }
        if (key === "fornitore") {
          const fornitore = allFornitori.find((c) => c.id == value);
          displayValue = fornitore
            ? `${fornitore.nome} ${fornitore.cognome}`
            : "Fornitore non trovato";
        }
        if (key === "banca") {
          const banca = allBanche.find((c) => c.id == value);
          displayValue = banca ? `${banca.nome}` : "Banca non trovato";
        }
        if (key === "ordine") {
          const ordine = allOrdine.find((c) => c.id == value);
          displayValue = ordine ? `${ordine.protocollo}` : "Ordine non trovato";
        }
        if (key === "stato") {
          const stato = allStati.find((c) => c.id == value);
          displayValue = stato ? `${stato.nome}` : "Stato non trovato";
        }
        if (key === "sito") {
          const sito = allSiti.find((c) => c.id == value);
          displayValue = sito ? `${sito.sito_o_punto_vendita}` : "vari";
        }
        if (key === "tipologia") {
          if (typeof value === "number") {
            const tipologia = allTipologia.find((c) => c.id == value);
            displayValue = tipologia
              ? `${tipologia.denominazione}`
              : "Tipologia non trovata";
          }
        }
        if (key === "offerta") {
          const offerta = allOfferte.find((c) => c.id == value);
          displayValue = offerta
            ? `${offerta.protocollo}`
            : "Offerta non trovata";
        }

        if (key === "commessa") {
          const commessa = allCommesse.find((c) => c.id == value);
          displayValue = commessa
            ? `${commessa.protocollo}`
            : "Commessa non trovata";
        }

        if (key === "project_manager") {
          const projectManager = allProjectManager.find((pm) => pm.id == value);
          if (projectManager) {
            const dipendente = allDipendenti.find(
              (d) => d.id == projectManager.dipendente
            );
            displayValue = dipendente
              ? `${dipendente.nome} ${dipendente.cognome}`
              : "Project Manager non trovato";
          } else {
            displayValue = "Project Manager non trovato";
          }
        }
        if (key === "colore") {
          displayValue = (
            <div
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor: value,
                display: "inline-block",
                marginLeft: "10px",
                verticalAlign: "middle",
              }}
            />
          );
        }
        if (key === "attivita") {
          if (Array.isArray(value)) {
            displayValue = value.map((id, idx) => {
              const attivita = allAttivita.find((a) => a.id === id);
              return (
                <div key={idx}>
                  {attivita
                    ? `- ${attivita.protocollo}`
                    : "Attività non trovata"}
                </div>
              );
            });
          } else {
            const attivita = allAttivita.find((a) => a.id === value);
            displayValue = attivita
              ? `${attivita.protocollo}`
              : "Attività non trovata";
          }
        }
        if (key === "offerte") {
          if (Array.isArray(value)) {
            displayValue = value.map((id, idx) => {
              const offerta = allOfferte.find((a) => a.id === id);
              return (
                <div key={idx}>
                  {offerta ? `- ${offerta.protocollo}` : "Offerta non trovata"}
                </div>
              );
            });
          } else {
            const offerta = allOfferte.find((a) => a.id === value);
            displayValue = offerta
              ? `${offerta.protocollo}`
              : "Offerta non trovata";
          }
        }
        if (key === "ordini") {
          if (Array.isArray(value)) {
            displayValue = value.map((id, idx) => {
              const ordine = allOrdine.find((a) => a.id === id);
              return (
                <div key={idx}>
                  {ordine ? `- ${ordine.protocollo}` : "Ordine non trovato"}
                </div>
              );
            });
          } else {
            const ordine = allOrdine.find((a) => a.id === value);
            displayValue = ordine
              ? `${ordine.protocollo})`
              : "Ordine non trovato";
          }
        }
        if (typeof value === "boolean") {
          displayValue = value ? "Si" : "No";
        }

        return (
          <p key={index}>
            <b>{formattedKey}:</b> {displayValue}
          </p>
        );
      }
      return null;
    });
  };

  const getTitle = () => {
    let action = "";
    switch (logItem.action) {
      case "create":
        action = "Creazione";
        break;
      case "update":
        action = "Modifica";
        break;
      case "delete":
        action = "Eliminazione";
        break;
      default:
        action = logItem.action;
    }
    let model_name = logItem.model_name
      ? logItem.model_name.slice(4)
      : "Nome modello non disponibile";

    // Aggiungi spazi prima delle lettere maiuscole
    model_name = model_name.replace(/([a-z])([A-Z])/g, "$1 $2");

    return `${action} - ${model_name}`;
  };

  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }
    return true;
  };

  const renderUpdateDetails = () => {
    const versionKeys = Object.keys(logItem.version);

    const formatKey = (key) => {
      return key
        .replace(/_/g, " ")
        .toLowerCase()
        .replace(/^\w/, (c) => c.toUpperCase());
    };

    const formatValue = (key, value) => {
      if (key === "cliente") {
        const cliente = allClienti.find((c) => c.id == value);
        return cliente
          ? `${cliente.nome} ${cliente.cognome}`
          : "Cliente non trovato";
      }
      if (key === "fornitore") {
        const fornitore = allFornitori.find((c) => c.id == value);
        return fornitore
          ? `${fornitore.nome} ${fornitore.cognome}`
          : "Fornitore non trovato";
      }
      if (key === "banca") {
        const banca = allBanche.find((c) => c.id == value);
        return banca ? `${banca.nome}` : "Banca non trovato";
      }
      if (key === "ordine") {
        const ordine = allOrdine.find((c) => c.id == value);
        return ordine ? `${ordine.protocollo}` : "Ordine non trovato";
      }
      if (key === "stato") {
        const stato = allStati.find((c) => c.id == value);
        return stato ? `${stato.nome}` : "Stato non trovato";
      }
      if (key === "sito") {
        const sito = allSiti.find((c) => c.id == value);
        return sito ? `${sito.sito_o_punto_vendita}` : "vari";
      }
      if (key === "tipologia") {
        if (typeof value === "number") {
          const tipologia = allTipologia.find((c) => c.id == value);
          return tipologia
            ? `${tipologia.denominazione}`
            : "Tipologia non trovata";
        }
      }
      if (key === "offerta") {
        const offerta = allOfferte.find((c) => c.id == value);
        return offerta ? `${offerta.protocollo}` : "Offerta non trovata";
      }

      if (key === "commessa") {
        const commessa = allCommesse.find((c) => c.id == value);
        return commessa ? `${commessa.protocollo}` : "Commessa non trovata";
      }

      if (key === "project_manager") {
        const projectManager = allProjectManager.find((pm) => pm.id == value);
        if (projectManager) {
          const dipendente = allDipendenti.find(
            (d) => d.id == projectManager.dipendente
          );
          return dipendente
            ? `${dipendente.nome} ${dipendente.cognome}`
            : "Project Manager non trovato";
        } else {
          return "Project Manager non trovato";
        }
      }
      if (key === "colore") {
        return (
          <div
            style={{
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              backgroundColor: value,
              display: "inline-block",
              marginLeft: "10px",
              verticalAlign: "middle",
            }}
          />
        );
      }
      if (key === "attivita") {
        if (Array.isArray(value)) {
          return value.map((id, idx) => {
            const attivita = allAttivita.find((a) => a.id === id);
            return (
              <div key={idx}>
                {attivita ? `- ${attivita.protocollo}` : "Attività non trovata"}
              </div>
            );
          });
        } else {
          const attivita = allAttivita.find((a) => a.id === value);
          return attivita ? `(${attivita.protocollo}` : "Attività non trovata";
        }
      }
      if (key === "offerte") {
        if (Array.isArray(value)) {
          return value.map((id, idx) => {
            const offerta = allOfferte.find((a) => a.id === id);
            return (
              <div key={idx}>
                {offerta ? `- ${offerta.protocollo}` : "Offerta non trovata"}
              </div>
            );
          });
        } else {
          const offerta = allOfferte.find((a) => a.id === value);
          return offerta ? `${offerta.protocollo}` : "Offerta non trovata";
        }
      }
      if (key === "ordini") {
        if (Array.isArray(value)) {
          return value.map((id, idx) => {
            const ordine = allOrdine.find((a) => a.id === id);
            return (
              <div key={idx}>
                {ordine ? `- ${ordine.protocollo}` : "Ordine non trovato"}
              </div>
            );
          });
        } else {
          const ordine = allOrdine.find((a) => a.id === value);
          return ordine ? `${ordine.protocollo}` : "Ordine non trovato";
        }
      }

      if (typeof value === "boolean") {
        return value ? "Si" : "No";
      }
      return value;
    };

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flex: 1, marginRight: "10px" }}>
          <h5 style={{ color: "#8f0024" }}>Versione Precedente</h5>
          {versionKeys.map((key, index) => {
            if (key !== "id") {
              const isValueChanged =
                Array.isArray(logItem.version[key]) &&
                Array.isArray(logItem.new_version[key])
                  ? !areArraysEqual(
                      logItem.version[key],
                      logItem.new_version[key]
                    )
                  : logItem.new_version[key] !== logItem.version[key];
              return (
                <p
                  style={{
                    background: isValueChanged ? "#fa8072" : "none",
                    padding: "5px",
                    flex: 1,
                  }}
                >
                  <b>{formatKey(key)}:</b>{" "}
                  {formatValue(key, logItem.version[key])}
                </p>
              );
            }
            return null;
          })}
        </div>
        <div>
          <i
            className="bi bi-shuffle"
            style={{ fontSize: "24px", color: "black" }}
          ></i>
        </div>
        <div style={{ flex: 1, marginLeft: "10px" }}>
          <h5 style={{ color: "#8f0024" }}>Nuova Versione</h5>
          {versionKeys.map((key, index) => {
            if (key !== "id") {
              const isValueChanged =
                Array.isArray(logItem.version[key]) &&
                Array.isArray(logItem.new_version[key])
                  ? !areArraysEqual(
                      logItem.version[key],
                      logItem.new_version[key]
                    )
                  : logItem.new_version[key] !== logItem.version[key];

              return (
                <p
                  style={{
                    background: isValueChanged ? "#98ff98" : "none",
                    padding: "5px",
                    flex: 1,
                  }}
                >
                  <b>{formatKey(key)}:</b>{" "}
                  {formatValue(key, logItem.new_version[key])}
                </p>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  };

  return (
    <Modal show={show} onHide={handleClose} id="logDetailsModal">
      <Modal.Header closeButton style={{ backgroundColor: "#EDEDED" }}>
        <Modal.Title>{getTitle()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <b>Azione:</b> {getTitle()}
        </p>
        <p>
          <b>Data:</b> {formatDate(logItem.date)}
        </p>
        <p>
          <b>Ora:</b> {formatTime(logItem.date)}
        </p>
        <p>
          <b>Dipendente:</b> {logItem.user}
        </p>

        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "#000",
            margin: "10px 0",
          }}
        ></div>

        {logItem.action === "create" &&
          renderKeyValuePairs(logItem.new_version)}
        {logItem.action === "delete" && renderKeyValuePairs(logItem.version)}
        {logItem.action === "update" && renderUpdateDetails()}
      </Modal.Body>
    </Modal>
  );
}

export default LogDetailsModal;
