import { Col, Form, Row, Button } from "react-bootstrap";
import { URL_BANCA } from "../../lib/url";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  setArrayFilter,
  setInfoFilter,
  setShow,
  setUrlFetch,
} from "../../redux/actions";
import { useDispatch } from "react-redux";
import { setUrlFilterBanche } from "./setUrl/setUrlFilterBanche";

const FilterBanche = ({ info, setInformazioni }) => {
  const dispatch = useDispatch();

  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setInfoFilter(info));

    const arrayPills = Object.entries(info);

    dispatch(setArrayFilter(arrayPills));

    setUrlFilterBanche(arrayPills, info, dispatch);

    dispatch(setShow(SET_SHOW_FILTER, false));
    setInformazioni({});
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <Row>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Nome
            </Form.Label>
            <Form.Control
              type="text"
              value={info.nome}
              onChange={(e) => changeInfo(e.target.value, "nome")}
              placeholder="Filtra per Nome"
            />
          </Form.Group>
        </Col>

        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Comune
            </Form.Label>
            <Form.Control
              type="text"
              value={info.comune}
              onChange={(e) => changeInfo(e.target.value, "comune")}
              placeholder="Filtra per comune"
            />
          </Form.Group>
        </Col>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Regione
            </Form.Label>
            <Form.Control
              type="text"
              value={info.regione}
              onChange={(e) => changeInfo(e.target.value, "regione")}
              placeholder="Filtra per regione"
            />
          </Form.Group>
        </Col>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Nome Consulente
            </Form.Label>
            <Form.Control
              type="text"
              value={info.nome_consulente}
              onChange={(e) => changeInfo(e.target.value, "nome_consulente")}
              placeholder="Filtra per Nome Consulente"
            />
          </Form.Group>
        </Col>
      </Row>{" "}
      <Col xs={{ span: 12 }} className="d-flex justify-content-end">
        <Button type="submit" variant="primary">
          Applica Filtri
        </Button>
      </Col>
    </Form>
  );
};

export default FilterBanche;
