import { Col, Form, Row, Button } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  fetchSelectClienti,
  setArrayFilter,
  setInfoFilter,
  setShow,
  setUrlFetch,
} from "../../redux/actions";
import { useEffect } from "react";
import { URL_SITO } from "../../lib/url";
import { setUrlFilterSiti } from "./setUrl/setUrlFIlterSiti";

const FilterSiti = ({ info, setInformazioni }) => {
  const token = useSelector((state) => state.login.token);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSelectClienti(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allClienti = useSelector((state) => state.select.dataCliente);

  const changeInfo = (value, name) => {
    setInformazioni({
      ...info,
      [name]: value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setInfoFilter(info));

    let nuovoOggetto = { ...info };
    if (nuovoOggetto.cliente) {
      const y = allClienti.filter((f) => f.id == nuovoOggetto.cliente);
      nuovoOggetto = {
        ...nuovoOggetto,
        cliente: y[0].nome + " " + y[0].cognome,
      };
    }

    const arrayPills = Object.entries(nuovoOggetto);

    dispatch(setArrayFilter(arrayPills));

    setUrlFilterSiti(arrayPills, info, dispatch);

    dispatch(setShow(SET_SHOW_FILTER, false));
    setInformazioni({});
  };
  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <Row>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Sito / n° pv
            </Form.Label>
            <Form.Control
              type="text"
              value={info.sito_o_punto_vendita}
              onChange={(e) =>
                changeInfo(e.target.value, "sito_o_punto_vendita")
              }
              placeholder="Inserisci il sito o n° punto vendita"
            />
          </Form.Group>
        </Col>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label
              column
              sm="2"
              className="d-flex justify-content-between align-items-center"
            >
              Cliente
            </Form.Label>
            <Form.Select
              value={info.cliente || ""}
              onChange={(e) => changeInfo(e.target.value, "cliente")}
              aria-label="Filtra per Cliente"
            >
              <option value="" disabled hidden>
                Filtra per Cliente
              </option>
              {allClienti.length !== 0 ? (
                allClienti.map((c, index) => (
                  <option key={index} value={c.id}>
                    {c.nome} {c.cognome}
                  </option>
                ))
              ) : (
                <option value="" disabled hidden>
                  Nessun Cliente disponibile
                </option>
              )}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Codice Vecchio
            </Form.Label>

            <Form.Control
              type="text"
              value={info.codice_vecchio}
              onChange={(e) => changeInfo(e.target.value, "codice_vecchio")}
              placeholder="inserisci Codice Vecchio"
            />
          </Form.Group>
        </Col>
      </Row>{" "}
      <Col xs={{ span: 12 }} className="d-flex justify-content-end">
        <Button type="submit" variant="primary">
          Applica Filtri
        </Button>
      </Col>
    </Form>
  );
};

export default FilterSiti;
