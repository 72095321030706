import { URL_ATTIVITA } from "../../../lib/url";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  SET_URL_GET,
  setShow,
  setUrlFetch,
} from "../../../redux/actions";

export const setUrlFilterAttivita = (arrayPills, info, dispatch) => {
  let url = "?";
  if (arrayPills.length > 0) {
    if (info.denominazione) url = url + "denominazione=" + info.denominazione;
    if (info.offerta && url !== "?") url = url + "&&offerta=" + info.offerta;
    else {
      if (info.offerta) url = url + "offerta=" + info.offerta;
    }
    if (info.cliente && url !== "?") url = url + "&&cliente=" + info.cliente;
    else {
      if (info.cliente) url = url + "cliente=" + info.cliente;
    }
    if (info.tipologia && url !== "?")
      url = url + "&&tipologia=" + info.tipologia;
    else {
      if (info.tipologia) url = url + "tipologia=" + info.tipologia;
    }
    if (info.sito && url !== "?") url = url + "&&sito=" + info.sito;
    else {
      if (info.sito) url = url + "sito=" + info.sito;
    }
    if (info.data_inizio && url !== "?")
      url = url + "&&data_inizio=" + info.data_inizio;
    else {
      if (info.data_inizio) url = url + "data_inizio=" + info.data_inizio;
    }
  } else {
    dispatch(setUrlFetch(SET_URL_GET, URL_ATTIVITA));
  }

  if (url !== "?") {
    url = URL_ATTIVITA + url;
    dispatch(setUrlFetch(SET_URL_FILTER, url));
  }
  //   dispatch(setShow(SET_SHOW_FILTER, false));
};
