import { Col, Form, Row, Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import {
  fetchSelectTipologiaAttivita,
  fetchSelectSiti,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import {
  SET_CLASS2,
  SET_CLASS3,
  SET_CLASS4,
  SET_SHOW2,
  SET_SHOW3,
  SET_SHOW4,
  setClass,
  setShow,
} from "../../redux/actions";

const InlineAttivitaOff = ({
  info,
  onUpdate,
  onRemove,
  sitoUnico,
  sitoEreditato,
  clienteSelezionato,
}) => {
  const info1 = useSelector((state) => state.modal.info1);
  const show1 = useSelector((state) => state.modal.show1);
  const show2 = useSelector((state) => state.modal.show2);
  const show3 = useSelector((state) => state.modal.show3);
  const show4 = useSelector((state) => state.modal.show4);
  const tipoModal = useSelector((state) => state.modal.type);
  const allTipologiaAttivita = useSelector(
    (state) => state.select.dataTipologiaAttivita
  );
  const allSiti = useSelector((state) => state.select.dataSiti);
  const dispatch = useDispatch();

  const changeInfo = useCallback(
    (value, name) => {
      onUpdate({ ...info, [name]: value });
    },
    [info, onUpdate]
  );

  useEffect(() => {
    if (sitoUnico && info.sito !== sitoEreditato) {
      onUpdate({ ...info, sito: sitoEreditato });
    }
  }, [sitoUnico, sitoEreditato, onUpdate, info]);

  useEffect(() => {
    if (!sitoUnico) {
      onUpdate({ ...info, sito: "" });
    }
  }, [sitoUnico]);
  useEffect(() => {
    if (clienteSelezionato && info.cliente !== clienteSelezionato) {
      onUpdate({ ...info, cliente: clienteSelezionato });
    }
  }, [clienteSelezionato, onUpdate, info]);

  useEffect(() => {
    dispatch(fetchSelectSiti(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const token = useSelector((state) => state.login.token);

  useEffect(() => {
    dispatch(fetchSelectTipologiaAttivita(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenNextModal = (classe) => {
    if (show1 && show2 && show3) {
      dispatch(setShow(SET_SHOW4, true));
      dispatch(setClass(SET_CLASS4, classe));
    } else if (show1 && show2) {
      dispatch(setShow(SET_SHOW3, true));
      dispatch(setClass(SET_CLASS3, classe));
    } else {
      dispatch(setShow(SET_SHOW2, true));
      dispatch(setClass(SET_CLASS2, classe));
    }
  };

  const sitiFiltrati = allSiti.filter(
    (sito) => sito.cliente == clienteSelezionato
  );

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleRemoveClick = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmRemove = () => {
    onRemove();
    setShowConfirmModal(false);
  };

  const handleCloseModal = () => {
    setShowConfirmModal(false);
  };

  return (
    <div
      style={{
        backgroundColor: "lightgray",
        padding: "15px",
        borderRadius: "8px",
        marginBottom: "10px",
      }}
    >
      <Row className="align-items-center">
        <Col xs="12" className="text-end">
          <span onClick={handleRemoveClick}>
            {" "}
            <i className="bi bi-x-lg"></i>
          </span>
        </Col>
        <Col xs="4">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Nome dell Attività *
            </Form.Label>
            <Form.Control
              type="text"
              value={info.denominazione}
              onChange={(e) => changeInfo(e.target.value, "denominazione")}
              placeholder="Inserisci nome attività"
              required
            />
          </Form.Group>
        </Col>
        <Col xs="4">
          <Form.Group className="mb-3">
            <Form.Label
              column
              sm="2"
              className="d-flex justify-content-between align-items-center"
            >
              Sito *
              {tipoModal !== "modifica" && (
                <span
                  onClick={() =>
                    sitoUnico ? null : handleOpenNextModal("sito")
                  }
                  className={`text-${
                    sitoUnico ? "secondary" : "primary"
                  } text-decoration-none me-2`}
                >
                  <i className="bi bi-plus-circle-fill"></i>
                </span>
              )}
            </Form.Label>
            <Form.Select
              value={sitoUnico ? sitoEreditato : info.sito || ""}
              onChange={(e) => changeInfo(e.target.value, "sito")}
              disabled={sitoUnico || sitiFiltrati.length === 0}
            >
              <option value="" disabled hidden>
                Seleziona sito
              </option>
              {sitoUnico ? (
                <option value={sitoEreditato}>Sito ereditato</option>
              ) : sitiFiltrati.length !== 0 ? (
                sitiFiltrati.map((sito, index) => (
                  <option key={index} value={sito.id}>
                    {sito.sito_o_punto_vendita}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  Nessun sito disponibile per questo cliente
                </option>
              )}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs="4">
          <Form.Group className="mb-3">
            <Form.Label
              column
              sm="2"
              className="d-flex justify-content-between align-items-center"
            >
              Tipologia
              {tipoModal !== "modifica" && (
                <span
                  onClick={() => handleOpenNextModal("tipologia-attivita")}
                  className="text-primary text-decoration-none me-2"
                >
                  <i className="bi bi-plus-circle-fill"></i>
                </span>
              )}
            </Form.Label>
            <Form.Select
              value={info.tipologia || ""}
              onChange={(e) => changeInfo(e.target.value, "tipologia")}
            >
              <option value="" disabled hidden>
                Seleziona Tipologia
              </option>
              {allTipologiaAttivita.map((tipologia, index) => (
                <option key={index} value={tipologia.id}>
                  {tipologia.denominazione}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Campo obbligatorio
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs="4">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Importo *
            </Form.Label>
            <div className="d-flex align-items-center">
              <span style={{ marginRight: "8px" }}>€</span>
              <Form.Control
                type="number"
                value={info.importo}
                onChange={(e) => changeInfo(e.target.value, "importo")}
                placeholder="Inserisci importo"
                required
              />
            </div>
          </Form.Group>
        </Col>

        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label column sm="2">
              Specifica Attività
            </Form.Label>
            <Form.Control
              as="textarea"
              value={info.descrizione || ""}
              rows={3}
              onChange={(e) => changeInfo(e.target.value, "descrizione")}
              placeholder="Inserisci una descrizione"
            />
          </Form.Group>
        </Col>
      </Row>
      <Modal show={showConfirmModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Conferma Eliminazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sicuro di eliminare questa attività? L&#39;azione non è reversibile.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Annulla
          </Button>
          <Button variant="primary" onClick={handleConfirmRemove}>
            Elimina
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InlineAttivitaOff;
