import { Col, Container, Row } from "react-bootstrap";

const CommesseDetails = ({ data }) => {
  return (
    <Container className="border bg-light">
      <Row>
        <Col className="border p-2 text-center">
          {" "}
          <p className="dettagli-title ms-2 m-0 text-center">Note</p>
          <span className="dettagli">{data.note}</span>
        </Col>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">Data Chiusura</p>
          <span className="dettagli">{data.data_fine}</span>
        </Col>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">N° Attività </p>
          <span className="dettagli">{data.attivita.length}</span>
        </Col>
        <Col className="border p-2 text-center">
          <p className="dettagli-title ms-2 m-0 text-center">N° Ordini </p>
          <span className="dettagli">{data.ordini.length}</span>
        </Col>
      </Row>
    </Container>
  );
};
export default CommesseDetails;
