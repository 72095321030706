import {
  fetchAllPut,
  fetchGeneric,
  fetchSelectAttivita,
} from "../../redux/actions";
import { URL_ATTIVITA } from "../url";

export const setFetchAttivita = (type, payload, token, dispatch) => {
  if (type === "modifica") {
    payload = {
      ...payload,
      cliente: payload.idCliente,
      offerta: payload.idOfferta,
      sito: payload.idSito,
      tipologia: payload.idTipologia,
    };

    dispatch(fetchAllPut(URL_ATTIVITA + payload.id, payload, token));
    dispatch(fetchSelectAttivita(token));
    // POST DI ATTIVITA GESTITA DENTRO POST E PUT DI OFFERTA
  }
};
