import { Col, Form, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ADD_OFFERTE } from "../../lib/payloads";
import { SET_INFO1, SET_INFO2, setInfo } from "../../redux/actions";
import {
  SET_CLASS2,
  SET_CLASS3,
  SET_CLASS4,
  SET_SHOW2,
  SET_SHOW3,
  SET_SHOW4,
  setClass,
  setShow,
} from "../../redux/actions";
import InlineAttivitaOff from "./InLineAttivitaOff";
import {
  fetchSelectClienti,
  fetchSelectSiti,
  fetchSelectAttivita,
} from "../../redux/actions";

const FormOfferte = () => {
  const [sitoUnico, setSitoUnico] = useState(false);
  const [listaAttivita, Attivita] = useState([]);
  const info1 = useSelector((state) => state.modal.info1);
  const info2 = useSelector((state) => state.modal.info2);
  const show1 = useSelector((state) => state.modal.show1);
  const show2 = useSelector((state) => state.modal.show2);
  const show3 = useSelector((state) => state.modal.show3);
  const show4 = useSelector((state) => state.modal.show4);
  const class2 = useSelector((state) => state.modal.class2);
  const allClienti = useSelector((state) => state.select.dataCliente);
  const allAttivita = useSelector((state) => state.select.dataAttivita);
  const allSiti = useSelector((state) => state.select.dataSiti);
  const token = useSelector((state) => state.login.token);
  const dispatch = useDispatch();
  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const tipoModal = useSelector((state) => state.modal.type);
  const [sitiFiltrati, setSitiFiltrati] = useState([]);

  const [info, setInformazioni] = useState({});

  useEffect(() => {
    if (show1 && show2 && show3 && show4) {
      setInformazioni(ADD_OFFERTE);
    } else if (show1 && show2 && show3) {
      setInformazioni(ADD_OFFERTE);
    } else if (show1 && show2) {
      if (class2 !== "sito") {
        setInformazioni(ADD_OFFERTE);
      }
    } else {
      setInformazioni(info1);
      const sitoIniziale = info1.sito;
      setSitoUnico(sitoIniziale !== "" && sitoIniziale !== "vari");
      if (tipoModal === "modifica") {
        const newsitiFiltrati = allSiti.filter(
          (sito) => sito.cliente == info1.clienteId
        );
        setSitiFiltrati(newsitiFiltrati);
        setInformazioni({
          ...info1,
          sito: info1.sitoId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allClienti, allSiti, allAttivita]);

  useEffect(() => {
    dispatch(fetchSelectClienti(token));
    dispatch(fetchSelectSiti(token));
    dispatch(fetchSelectAttivita(token));
  }, []);

  useEffect(() => {
    setIsFormDisabled(!info.cliente);
  }, [info.cliente]);

  const handleClienteChange = (value) => {
    changeInfo(value, "cliente");
    const newsitiFiltrati = allSiti.filter((sito) => sito.cliente == value);
    setSitiFiltrati(newsitiFiltrati);
  };

  const handleSitoUnicoChange = (e) => {
    const isSitoUnico = e.target.checked;
    setSitoUnico(isSitoUnico);
    changeInfo(isSitoUnico ? "" : "vari", "sito");
    // QUANDO TORNO A SITO UNICO DOPO AVER FATTO DUE GIRI, L'OPTION NON DEV'ESSERE PRE-SETTATA
  };

  const aggiungiAttivita = () => {
    Attivita(
      listaAttivita.concat({
        /* dati iniziali dell'attività */
      })
    );
  };

  const rimuoviAttivita = (index) => {
    Attivita(listaAttivita.filter((_, i) => i !== index));

    if (tipoModal === "modifica") {
      //delete lista attivita [0] . index
    }
  };

  const aggiornaAttivita = (index, nuovaAttivita) => {
    let nuoveAttivita = [...listaAttivita];
    nuoveAttivita[index] = nuovaAttivita;

    Attivita(nuoveAttivita);
  };

  const changeInfo = (value, name) => {
    setInformazioni({ ...info, [name]: value });
  };

  useEffect(() => {
    dispatch(setInfo(SET_INFO1, info));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  useEffect(() => {
    setSitoUnico(info.sito !== "" || info.sito !== "vari");
    // if (sitoUnico) {
    //   setInformazioni({ ...info, sitoId: info.sito });
    // }
  }, [info.sito]);

  useEffect(() => {
    if (info.sito === "vari") {
      setSitoUnico(false);
    } else {
      setSitoUnico(true);
    }
  }, [info.sito]);

  const handleOpenNextModal = (classe) => {
    if (show1 && show2 && show3) {
      dispatch(setShow(SET_SHOW4, true));
      dispatch(setClass(SET_CLASS4, classe));
    } else if (show1 && show2) {
      dispatch(setShow(SET_SHOW3, true));
      dispatch(setClass(SET_CLASS3, classe));
    } else {
      dispatch(setShow(SET_SHOW2, true));
      dispatch(setClass(SET_CLASS2, classe));
    }
  };

  useEffect(() => {
    if (info.id) {
      const attivitaCorrelate = allAttivita.filter(
        (attivita) => attivita.offerta === info.id
      );
      Attivita(attivitaCorrelate);
    }
  }, [info.id, allAttivita]);

  useEffect(() => {
    dispatch(setInfo(SET_INFO2, listaAttivita));
  }, [listaAttivita]);

  return (
    <Row>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Protocollo / N° Offerta
          </Form.Label>
          <Form.Control
            type="text"
            value={info.protocollo}
            placeholder={
              info.protocollo ? info.protocollo : "Campo autogenerato"
            }
            disabled
          />
        </Form.Group>
      </Col>
      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Data Offerta *
          </Form.Label>
          <Form.Control
            type="date"
            value={info.data_offerta}
            onChange={(e) => changeInfo(e.target.value, "data_offerta")}
            required
            disabled={isFormDisabled}
          />{" "}
          <Form.Control.Feedback type="invalid">
            campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>

      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label
            column
            sm="2"
            className="d-flex justify-content-between align-items-center"
          >
            Cliente *
            {tipoModal !== "modifica" && (
              <span
                onClick={() => handleOpenNextModal("cliente")}
                className="text-primary text-decoration-none me-2"
              >
                <i className="bi bi-plus-circle-fill"></i>
              </span>
            )}
          </Form.Label>

          <Form.Select
            value={info.clienteId || info.cliente || ""}
            onChange={(e) => handleClienteChange(e.target.value)}
            required
            disabled={tipoModal === "modifica"}
          >
            <option value="" disabled hidden>
              Seleziona Cliente
            </option>
            {allClienti.map((cliente, index) => (
              <option key={index} value={cliente.id}>
                {cliente.nome} {cliente.cognome}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Campo obbligatorio
          </Form.Control.Feedback>
        </Form.Group>
      </Col>

      <Col xs="4">
        <Form.Group className="mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Form.Label column sm="2" className="d-flex align-items-center">
                Sito unico *
                <Form.Check
                  type="switch"
                  id="sito-unico-switch"
                  className="ms-2"
                  checked={sitoUnico}
                  onChange={handleSitoUnicoChange}
                  disabled={isFormDisabled || tipoModal == "modifica"}
                />
              </Form.Label>
            </div>
            {sitoUnico && tipoModal !== "modifica" && (
              <span
                onClick={() =>
                  isFormDisabled ? null : handleOpenNextModal("sito")
                }
                className={`text-${isFormDisabled ? "secondary" : "primary"}`}
              >
                <i className="bi bi-plus-circle-fill me-2"></i>
              </span>
            )}
          </div>
          {sitoUnico && (
            <Form.Select
              value={info.sito}
              onChange={(e) => {
                changeInfo(e.target.value, "sito");
              }}
              required
              disabled={isFormDisabled || sitiFiltrati.length === 0}
            >
              <option value="" disabled hidden>
                Seleziona sito
              </option>
              {sitiFiltrati.length !== 0 ? (
                sitiFiltrati.map((sito, index) => (
                  <option key={index} value={sito.id}>
                    {sito.sito_o_punto_vendita}
                  </option>
                ))
              ) : (
                <option value="" disabled hidden>
                  Nessun sito disponibile per questo cliente
                </option>
              )}
            </Form.Select>
          )}
        </Form.Group>
      </Col>

      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Referente Cliente
          </Form.Label>
          <Form.Control
            type="text"
            value={info.referente_cliente}
            onChange={(e) => changeInfo(e.target.value, "referente_cliente")}
            placeholder="Inserisci Referente Cliente"
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>

      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Telefono Referente Cliente
          </Form.Label>
          <Form.Control
            type="text"
            value={info.tel_referente_cliente}
            onChange={(e) =>
              changeInfo(e.target.value, "tel_referente_cliente")
            }
            placeholder="Inserisci Telefono Referente Cliente"
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>

      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Email referente Cliente
          </Form.Label>
          <Form.Control
            type="text"
            value={info.tel_referente_interno}
            onChange={(e) =>
              changeInfo(e.target.value, "tel_referente_interno")
            }
            placeholder="Inserisci  Email referente Cliente"
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>

      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Referente Interno
          </Form.Label>
          <Form.Control
            type="text"
            value={info.referente_interno}
            onChange={(e) => changeInfo(e.target.value, "referente_interno")}
            placeholder="Inserisci Referente Interno"
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>

      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Numero Contratto
          </Form.Label>
          <Form.Control
            type="text"
            value={info.n_contratto}
            onChange={(e) => changeInfo(e.target.value, "n_contratto")}
            placeholder="Inserisci il numero del contratto"
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>

      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Data Inizio Contratto
          </Form.Label>
          <Form.Control
            type="date"
            value={info.data_inizio_contratto}
            onChange={(e) =>
              changeInfo(e.target.value, "data_inizio_contratto")
            }
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>

      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Data Fine Contratto
          </Form.Label>
          <Form.Control
            type="date"
            value={info.data_fine_contratto}
            onChange={(e) => changeInfo(e.target.value, "data_fine_contratto")}
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>

      <Col xs="4">
        <Form.Group className="mb-3">
          <Form.Label>Oggetto</Form.Label>
          <Form.Control
            type="text"
            value={info.oggetto}
            onChange={(e) => changeInfo(e.target.value, "oggetto")}
            placeholder="Inserisci oggetto offerta"
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>
      <Col xs="12">
        <Form.Group className="mb-3">
          <Form.Label column sm="2">
            Note
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={info.note}
            onChange={(e) => changeInfo(e.target.value, "note")}
            placeholder="Inserisci note aggiuntive"
            disabled={isFormDisabled}
          />
        </Form.Group>
      </Col>

      <Col
        xs="12"
        className="d-flex justify-content-between align-items-center mb-3"
      >
        <h5>Lista Attività</h5>
        {listaAttivita.length === 0 && (
          <Button
            disabled={isFormDisabled}
            variant={isFormDisabled ? "secondary" : "primary"}
            onClick={() => {
              if (!isFormDisabled) {
                aggiungiAttivita();
              }
            }}
          >
            <i className="bi bi-plus-circle-fill"></i>
          </Button>
        )}
      </Col>

      {listaAttivita &&
        listaAttivita.map((attivita, index) => (
          <InlineAttivitaOff
            key={index}
            info={attivita}
            onUpdate={(nuovaAttivita) => aggiornaAttivita(index, nuovaAttivita)}
            onRemove={() => rimuoviAttivita(index)}
            sitoUnico={sitoUnico}
            sitoEreditato={info.sito}
            clienteSelezionato={
              tipoModal === "modifica" ? info.clienteId : info.cliente
            }
          />
        ))}

      {listaAttivita.length > 0 && (
        <Col
          xs="12"
          className="d-flex justify-content-end align-items-center mb-3"
        >
          <Button
            disabled={isFormDisabled}
            variant={isFormDisabled ? "secondary" : "primary"}
            onClick={() => {
              if (!isFormDisabled) {
                aggiungiAttivita();
              }
            }}
          >
            <i className="bi bi-plus-circle-fill"></i>
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default FormOfferte;
