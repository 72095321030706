import { useDispatch } from "react-redux";
import {
  SET_ISLOAD_POST,
  fetchAllPut,
  fetchGeneric,
  fetchSelectOfferte,
  hideAlert,
  setIsLoadFetch,
  showAlert,
} from "../../redux/actions";
import { URL_ATTIVITA, URL_OFFERTA } from "../url";

export const attivitaCicloPost = async (token, payload, dispatch) => {
  try {
    const resp = await fetch(
      "https://be.civetta.maritsrl.com/api/v1/" + URL_ATTIVITA,
      {
        method: "POST",
        headers: {
          Authorization: `Token ` + token,
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    dispatch(showAlert("Record salvato con successo", "success"));
    setTimeout(() => {
      dispatch(hideAlert());
    }, 3000);
  } catch (error) {
    dispatch(showAlert("Errore", "primary"));
    setTimeout(() => {
      dispatch(hideAlert());
    }, 3000);
  }
};

export const offertaPiuAttivitaPost = async (token, payload, dispatch) => {
  try {
    const resp = await fetch(
      "https://be.civetta.maritsrl.com/api/v1/" + URL_OFFERTA,
      {
        method: "POST",
        headers: {
          Authorization: `Token ` + token,
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    if (resp.ok) {
      const data = await resp.json();

      dispatch(setIsLoadFetch(SET_ISLOAD_POST, true));
      setTimeout(() => {
        dispatch(setIsLoadFetch(SET_ISLOAD_POST, false));
      }, 1000);

      payload.lista.map((attivita, index) => {
        const payload2 = { ...attivita, offerta: data.id };
        return attivitaCicloPost(token, payload2, dispatch);
      });
    } else {
      console.log("fetch offerta fallita");
    }
    dispatch(showAlert("Record salvato con successo", "success"));
    setTimeout(() => {
      dispatch(hideAlert());
    }, 3000);
  } catch (error) {
    dispatch(showAlert("Errore", "primary"));
    setTimeout(() => {
      dispatch(hideAlert());
    }, 3000);
  }
};
export const setFetchOfferta = (type, payload, token, dispatch) => {
  if (type === "modifica") {
    const sito = payload.sito;
    const cliente = payload.clienteId;
    //LA PUT DI OFFERTA E LEGATA A QUELLA DELLE ATTIVITA IN ESSA CONTENUTE (FORM INLINE)
    const payloadOfferta = {
      ...payload,
      cliente: cliente,
      sito: sito,
    };
    dispatch(fetchAllPut(URL_OFFERTA + payload.id, payloadOfferta, token));
    payload.lista.map((attivita, i) => {
      let payloadAttivita = {
        ...attivita,
        offerta: payload.id,
        cliente: cliente,
      };
      if (sito !== "vari") payloadAttivita = { ...payloadAttivita, sito: sito };

      if (payloadAttivita.id) {
        return dispatch(
          fetchAllPut(URL_ATTIVITA + payloadAttivita.id, payloadAttivita, token)
        );
      }
      return dispatch(fetchGeneric(URL_ATTIVITA, payloadAttivita, token));
    });
  } else if (type === "aggiungi") {
    offertaPiuAttivitaPost(token, payload, dispatch);
    dispatch(fetchSelectOfferte(token));
  }
};
