import { URL_BANCA } from "../../../lib/url";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  SET_URL_GET,
  setShow,
  setUrlFetch,
} from "../../../redux/actions";

export const setUrlFilterBanche = (arrayPills, info, dispatch) => {
  let url = "?";
  if (arrayPills.length > 0) {
    if (info.nome) url = url + "nome=" + info.nome;
    if (info.comune && url !== "?") url = url + "&&comune=" + info.comune;
    else {
      if (info.comune) url = url + "comune=" + info.comune;
    }
    if (info.regione && url !== "?") url = url + "&&regione=" + info.regione;
    else {
      if (info.regione) url = url + "regione=" + info.regione;
    }
    if (info.nome_consulente && url !== "?")
      url = url + "&&nome_consulente=" + info.nome_consulente;
    else {
      if (info.nome_consulente)
        url = url + "nome_consulente=" + info.nome_consulente;
    }
  } else {
    dispatch(setUrlFetch(SET_URL_GET, URL_BANCA));
  }

  if (url !== "?") {
    url = URL_BANCA + url;
    dispatch(setUrlFetch(SET_URL_FILTER, url));
  }
  //   dispatch(setShow(SET_SHOW_FILTER, false));
};
