import { URL_BANCA, URL_FATTURA } from "../../../lib/url";
import {
  SET_SHOW_FILTER,
  SET_URL_FILTER,
  SET_URL_GET,
  setShow,
  setUrlFetch,
} from "../../../redux/actions";

export const setUrlFilterFatture = (arrayPills, info, dispatch) => {
  let url = "?";
  if (arrayPills.length > 0) {
    if (info.n_fattura) url = url + "n_fattura=" + info.n_fattura;
    if (info.cliente && url !== "?") url = url + "&&cliente=" + info.cliente;
    else {
      if (info.cliente) url = url + "cliente=" + info.cliente;
    }
    if (info.banca && url !== "?") url = url + "&&banca=" + info.banca;
    else {
      if (info.banca) url = url + "banca=" + info.banca;
    }
    if (info.ordine && url !== "?") url = url + "&&ordine=" + info.ordine;
    else {
      if (info.ordine) url = url + "ordine=" + info.ordine;
    }
    if (info.anno_competenza && url !== "?")
      url = url + "&&anno_competenza=" + info.anno_competenza;
    else {
      if (info.anno_competenza)
        url = url + "anno_competenza=" + info.anno_competenza;
    }
    if (info.incassata !== undefined && url !== "?")
      url = url + "&&incassata=" + info.incassata;
    else {
      if (info.incassata) url = url + "incassata=" + info.incassata;
    }
  } else {
    dispatch(setUrlFetch(SET_URL_GET, URL_FATTURA));
  }

  if (url !== "?") {
    url = URL_FATTURA + url;
    dispatch(setUrlFetch(SET_URL_FILTER, url));
  }
  //   dispatch(setShow(SET_SHOW_FILTER, false));
};
