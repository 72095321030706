import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { useDispatch, useSelector } from "react-redux";

import { fetchSelectFatture, fetchSelectOrdine } from "../../redux/actions";
import { Form } from "react-bootstrap";

export default function TabsStats() {
  const dispatch = useDispatch();
  const allOrdini = useSelector((state) => state.select.dataOrdine);
  const allFatture = useSelector((state) => state.select.dataFatture);
  const path = useSelector((state) => state.locationPage.path);
  const token = useSelector((state) => state.login.token);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  useEffect(() => {
    dispatch(fetchSelectOrdine(token));
    dispatch(fetchSelectFatture(token));
  }, [dispatch, token]);

  const yearOptions = () => {
    const years = [];
    for (let year = 2020; year <= 2050; year++) {
      years.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }
    return years;
  };

  const formatCurrency = (amount) => {
    return amount.toLocaleString("it-IT", {
      style: "currency",
      currency: "EUR",
    });
  };

  const totOrdini = allOrdini
    .reduce((total, ordine) => {
      if (
        ordine.protocollo &&
        ordine.protocollo.includes(selectedYear.toString())
      ) {
        const importo = parseFloat(ordine.importo_totale);
        return total + (isNaN(importo) ? 0 : importo);
      }
      return total;
    }, 0)
    .toFixed(2);

  const totImponibileFatture = allFatture
    .reduce((total, fattura) => {
      if (
        fattura.anno_competenza &&
        fattura.anno_competenza == selectedYear.toString()
      ) {
        const importo = parseFloat(fattura.imponibile);
        return total + (isNaN(importo) ? 0 : importo);
      }
      return total;
    }, 0)
    .toFixed(2);

  const totImponibileIncassatoFatture = allFatture
    .reduce((total, fattura) => {
      if (
        fattura.incassata &&
        fattura.anno_competenza == selectedYear.toString()
      ) {
        const importo = parseFloat(fattura.imponibile);
        return total + (isNaN(importo) ? 0 : importo);
      }
      return total;
    }, 0)
    .toFixed(2);

  const ancoraDaFatturare = (
    parseFloat(totOrdini) - parseFloat(totImponibileFatture)
  ).toFixed(2);

  const arrayOrdini = [
    {
      id: 0,
      title: "Importo totale ordini ",
      anno: "",
      tot: formatCurrency(parseFloat(totOrdini)),
    },
    {
      id: 1,
      title: "Residuo da fatturare " + selectedYear,
      tot: formatCurrency(parseFloat(ancoraDaFatturare)),
    },
  ];

  const arrayFatture = [
    {
      id: 0,
      title: "Totale imponibile ",
      anno: "",
      tot: formatCurrency(parseFloat(totImponibileFatture)),
    },
    {
      id: 1,
      title: "Imponibile incassato " + selectedYear,
      tot: formatCurrency(parseFloat(totImponibileIncassatoFatture)),
    },
    {
      id: 2,
      title: "Ancora da fatturare " + selectedYear,
      tot: formatCurrency(parseFloat(ancoraDaFatturare)),
    },
  ];

  return (
    <div className="row">
      {path == "ordine"
        ? arrayOrdini.map((e, i) => (
            <div key={`item_${i}`} className="col-12 col-sm-6 col-xl-3 mb-4">
              <div className="card">
                <div
                  className="card-body d-flex flex-column justify-content-between"
                  style={{ height: "200px", borderRadius: "30px" }}
                >
                  <div className="h-50">
                    <h3 className="card-title mt-2 mb-4">{e.title}</h3>
                  </div>
                  {i == 0 && (
                    <Form>
                      <Form.Group controlId="yearSelector">
                        <Form.Label>Seleziona Anno</Form.Label>
                        <Form.Select
                          style={{ cursor: "pointer" }}
                          value={selectedYear}
                          onChange={(e) => setSelectedYear(e.target.value)}
                        >
                          {yearOptions()}
                        </Form.Select>
                      </Form.Group>
                    </Form>
                  )}
                  <div className="border-top pt-2 d-flex flex-column justify-content-between">
                    <p className="m-0">Totale: {e.tot}</p>
                  </div>
                </div>
              </div>
            </div>
          ))
        : arrayFatture.map((e, i) => (
            <div key={`item_${i}`} className="col-12 col-sm-6 col-xl-3 mb-4">
              <div className="card">
                <div
                  className="card-body d-flex flex-column justify-content-between"
                  style={{ height: "200px", borderRadius: "30px" }}
                >
                  <div className="h-50">
                    <h3 className="card-title mt-2 mb-4">{e.title}</h3>
                  </div>
                  {i == 0 && (
                    <Form>
                      <Form.Group controlId="yearSelector">
                        <Form.Label>Seleziona Anno</Form.Label>
                        <Form.Select
                          style={{ cursor: "pointer" }}
                          value={selectedYear}
                          onChange={(e) => setSelectedYear(e.target.value)}
                        >
                          {yearOptions()}
                        </Form.Select>
                      </Form.Group>
                    </Form>
                  )}
                  <div className="border-top pt-2 d-flex flex-column justify-content-between">
                    <p className="m-0">Totale: {e.tot}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
    </div>
  );
}
