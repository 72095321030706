import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, InputGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getTokenAction, logoutAction } from "../../redux/actions";

const LoginPage = () => {
  const [validated, setValidated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [stayLoggedIn, setStayLoggedIn] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const baseEndpoint = "https://be.civetta.maritsrl.com/api/v1/";
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    dispatch(logoutAction());
  }, [window.onload]);

  const handleSubmit = async e => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      const data = {
        email: username,
        password: password,
      };
      await postLogin(data);
    }
    setValidated(true);
    navigate("/");
  };

  const handleForgotPassword = () => {
    navigate("/request-password");
  };

  const postLogin = async data => {
    try {
      const response = await fetch(baseEndpoint + "auth/login/", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const responseData = await response.json();
        const token = responseData.key;
        const name = responseData.nome;
        const role = responseData.ruolo;
        dispatch(getTokenAction(token, stayLoggedIn, name, role));
      } else {
        alert("Error login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container className="d-none d-md-block m-0 p-0">
      <Row>
        <Col md={4} className="100%">
          <Image src="/assets/bkg.jpg" style={{ height: "100vh", width: "100%" }} />
        </Col>
        <Col md={8} className="d-flex flex-column align-items-center justify-content-center">
          <div className="w-75 ms-4 justify-content-start">
            <Image src="/assets/positvo.png" />
          </div>
          <Form noValidate validated={validated} onSubmit={handleSubmit} className="mt-4">
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  placeholder="mario.rossi"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">Inserisci un nuovo username.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mt-3" style={{ width: "30rem" }}>
              <Form.Label>Password</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type={passwordShown ? "text" : "password"}
                  placeholder="*******"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <InputGroup.Text onClick={togglePasswordVisibility}>
                  {passwordShown ? <i className="bi bi-eye-slash-fill"></i> : <i className="bi bi-eye-fill"></i>}
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">Password errata.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <div className="d-flex align-items-center">
              <Form.Check
                variant="info"
                label="Resta connesso"
                className="mt-3 me-0"
                checked={stayLoggedIn}
                onChange={e => setStayLoggedIn(e.target.value)}
              />
              <p className=" ms-auto mt-3 mb-0">
                <a href="#" onClick={handleForgotPassword}>
                  Password dimenticata?
                </a>
              </p>
            </div>
            <Button style={{ backgroundColor: "#2B75BC" }} size="lg" type="submit" className="mt-3">
              Accedi
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
export default LoginPage;
