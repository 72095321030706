import { Col, Form, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSelectClienti,
  fetchSelectOfferte,
  fetchSelectAttivita,
  SET_URL_FILTER,
  SET_SHOW_FILTER,
  setUrlFetch,
  setShow,
  setInfoFilter,
  setArrayFilter,
} from "../../redux/actions";
import { useEffect } from "react";
import { URL_ORDINE } from "../../lib/url";
import { setUrlFilterOrdini } from "./setUrl/setUrlFilterOrdini";

const FilterOrdini = ({ info, setInformazioni }) => {
  const token = useSelector((state) => state.login.token);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSelectClienti(token));
    dispatch(fetchSelectOfferte(token));
    dispatch(fetchSelectAttivita(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allClienti = useSelector((state) => state.select.dataCliente);
  const allOfferte = useSelector((state) => state.select.dataOfferte);
  const allAttivita = useSelector((state) => state.select.dataAttivita);

  const changeInfo = (value, name) => {
    setInformazioni({
      ...info,
      [name]: value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setInfoFilter(info));

    let nuovoOggetto = { ...info };

    if (nuovoOggetto.offerte) {
      const x = allOfferte.filter((f) => f.id == nuovoOggetto.offerte);
      nuovoOggetto = { ...nuovoOggetto, offerte: x[0].protocollo };
    }
    if (nuovoOggetto.cliente) {
      const y = allClienti.filter((f) => f.id == nuovoOggetto.cliente);
      nuovoOggetto = {
        ...nuovoOggetto,
        cliente: y[0].nome + " " + y[0].cognome,
      };
    }
    if (nuovoOggetto.attivita) {
      const j = allAttivita.filter((f) => f.id == nuovoOggetto.attivita);
      nuovoOggetto = { ...nuovoOggetto, attivita: j[0].protocollo };
    }

    const arrayPills = Object.entries(nuovoOggetto);

    dispatch(setArrayFilter(arrayPills));

    setUrlFilterOrdini(arrayPills, info, dispatch);

    dispatch(setShow(SET_SHOW_FILTER, false));
    setInformazioni({});
  };
  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <Row>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label
              column
              sm="2"
              className="d-flex justify-content-between align-items-center"
            >
              Cliente
            </Form.Label>
            <Form.Select
              value={info.cliente || ""}
              onChange={(e) => changeInfo(e.target.value, "cliente")}
              aria-label="Filtra per Cliente"
            >
              <option value="" disabled hidden>
                Filtra per Cliente
              </option>
              {allClienti.length !== 0 ? (
                allClienti.map((c, index) => (
                  <option key={index} value={c.id}>
                    {c.nome} {c.cognome}
                  </option>
                ))
              ) : (
                <option value="" disabled hidden>
                  Nessun Cliente disponibile
                </option>
              )}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label
              column
              sm="2"
              className="d-flex justify-content-between align-items-center"
            >
              Offerta
            </Form.Label>
            <Form.Select
              value={info.offerte || ""}
              onChange={(e) => changeInfo(e.target.value, "offerte")}
              aria-label="Filtra per offerta"
            >
              <option value="" disabled hidden>
                Filtra per offerta
              </option>
              {allOfferte.length !== 0 ? (
                allOfferte.map((c, index) => (
                  <option key={index} value={c.id}>
                    {c.protocollo}
                  </option>
                ))
              ) : (
                <option value="" disabled hidden>
                  Nessun Offerta disponibile
                </option>
              )}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label
              column
              sm="2"
              className="d-flex justify-content-between align-items-center"
            >
              Attivita
            </Form.Label>
            <Form.Select
              value={info.attivita || ""}
              onChange={(e) => changeInfo(e.target.value, "attivita")}
              aria-label="Filtra per attivita"
            >
              <option value="" disabled hidden>
                Filtra per attivita
              </option>
              {allAttivita.length !== 0 ? (
                allAttivita.map((c, index) => (
                  <option key={index} value={c.id}>
                    {c.denominazione}
                  </option>
                ))
              ) : (
                <option value="" disabled hidden>
                  Nessun Attività disponibile
                </option>
              )}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Col xs={{ span: 12 }} className="d-flex justify-content-end">
        <Button type="submit" variant="primary">
          Applica Filtri
        </Button>
      </Col>
    </Form>
  );
};

export default FilterOrdini;
